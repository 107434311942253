import { call, takeLatest, all, put } from 'redux-saga/effects'
import { CONTROLLERS, apiPost } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { clearCreatePackagesState, setPackagesSnapshotMeta } from '../actions/create-customer-packages.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import { getCustomerPackageInfo, setCustomerInfoAuthorization } from '../actions/customer.actions';
import { store } from '../configureStore';
import { withActionPromise } from '../actions/app.actions';
import { getAllCustomerStores } from '../actions/store.actions';

const { REACT_APP_ROLES_GUEST } = process.env;

function* runPackagePayment(action) {
    const payload = action.payload;
    const path = `/PackagePayment`;
    var customer = store.getState().customer;

    // // guest
    // if (customer.customerInfo?.role?.includes(REACT_APP_ROLES_GUEST)){
    //     yield all([
    //         put(setPackagesSnapshotMeta({ "packagesCreateSubmitted": true })),
    //         put(setCustomerInfoAuthorization([{
    //             roleIds: [REACT_APP_ROLES_USER]
    //         }])),
    //         put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))

    //     ]);  
    // }
    // else {
    //     yield all([
    //         put(setPackagesSnapshotMeta({ "packagesCreateSubmitted": true })),
    //         // put(setCustomerInfoAuthorization([{
    //         //     roleIds: [REACT_APP_ROLES_USER]
    //         // }])),
    //         put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))

    //     ]);  
    // }


    const response = yield call(apiPost(path, payload, false, CONTROLLERS.PACKAGE, true, true));
    if (response && response.isSuccess) {
        var customerSettings = store.getState().settings;
        // guest
        if (customer.customerInfo?.role?.includes(REACT_APP_ROLES_GUEST)) {
            if (response.data.roleId) {
                yield all([
                    put(setPackagesSnapshotMeta({ "packagesCreateSubmitted": true })),
                    put(setCustomerInfoAuthorization([{
                        roleIds: [response.data.roleId]
                    }])),
                    put(withActionPromise(getCustomerPackageInfo())),
                    put(withActionPromise(getAllCustomerStores(customerSettings?.lastVisitedMarketId || ""))),
                    put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
                ]);


            } else {
                yield all([
                    put(setPackagesSnapshotMeta({ "packagesCreateSubmitted": true })),
                    put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
                ]);
            }
        }
        else {
            yield all([
                put(setPackagesSnapshotMeta({ "packagesCreateSubmitted": true })),
                put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
            ]);
        }
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* createCustomerPackagesSaga() {
    yield all([
        takeLatest(types.CREATE_CUSTOMER_PACKAGES_REQUESTED, safe(runPackagePayment))
    ]);
}