import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, apiPut, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { setBulkOperationData, setBulkOperationResponse } from '../actions/utilities.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import { setLoadIndicator, withActionPromise } from '../actions/app.actions';
import { store } from '../configureStore';

function* runSendMail(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/SendEmail`, payload, false, CONTROLLERS.UTILS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        //send email
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info15'), 'success'))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error41') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runCreateBulkOperationRequest(action) {
    const payload = action.payload;

    const showLoaderCount = store.getState().app.showLoader;

    if (!(payload.bulkOperationData?.totalJobs > 0)) {

        yield all([
            put(setBulkOperationData({ totalJobs: payload.totalJobs })),
            // put(setLoadIndicator(showLoaderCount + 1))
        ]);
    }

    var filteredArray = payload.requestData.filter(function (requestElement) {
        return payload.bulkOperationData?.responses.filter(function (responseElement) {
            return requestElement.checkValue === responseElement.value;
        }).length === 0
    });

    var request = filteredArray?.length > 0 ? filteredArray[0] : null;

    if (request) {
        let response = null;
        if (payload.apiType === 'POST') {
            response = yield call(apiPost(payload.path, request, false, payload.apiPath, true, false));
        }
        if (payload.apiType === 'PUT') {
            response = yield call(apiPut(payload.path, request, false, payload.apiPath, false, true));
        }
        if (payload.apiType === 'GET') {
            response = yield call(apiGet((payload.path + request.checkValue), payload.apiPath, true, false));
        }

        setTimeout(function () { console.log('xyz') }, 3000);

        if (response && response.isSuccess) {

            yield all([
                put(setBulkOperationResponse(
                    { value: request.checkValue, isSuccesfull: true, message: response.message }
                )),
                put(withActionPromise(setBulkOperationData(
                    {
                        completedJobs: ((payload.bulkOperationData?.completedJobs || 0) + 1)
                    }
                ))),
                // put(setLoadIndicator(showLoaderCount + (payload.totalJobs > 0 && payload.bulkOperationData?.completedJobs + 1 >= payload.bulkOperationData?.totalJobs ? -1 : 1)))
            ]);
        }
        else {
            yield all([
                put(setBulkOperationResponse(
                    { value: request.checkValue, isSuccesfull: false, message: response.message }
                )),
                put(withActionPromise(setBulkOperationData(
                    {
                        completedJobs: ((payload.bulkOperationData?.completedJobs || 0) + 1)
                    }
                ))),
                // put(setLoadIndicator(showLoaderCount + (payload.totalJobs > 0 && payload.bulkOperationData?.completedJobs + 1 >= payload.bulkOperationData?.totalJobs ? -1 : 1)))
            ]);
        }
    }
}

function* runCreateBulkOperationRequest2(action) {
    const payload = action.payload;

    if (!(payload.bulkOperationData?.totalJobs > 0)) {

        yield all([
            put(setBulkOperationData({ totalJobs: payload.totalJobs })),
            // put(setLoadIndicator(showLoaderCount + 1))
        ]);
    }

    if (payload.request) {
        let response = null;
        if (payload.apiType === 'POST') {
            response = yield call(apiPost(payload.path, payload.request, false, payload.apiPath, true, false));
        }
        if (payload.apiType === 'PUT') {
            response = yield call(apiPut(payload.path, payload.request, false, payload.apiPath, false, true));
        }
        if (payload.apiType === 'GET') {
            response = yield call(apiGet((payload.path + payload.request.checkValue), payload.apiPath, true, false));
        }

        setTimeout(function () { console.log('xyz') }, 3000);

        if (response && response.isSuccess) {

            yield all([
                put(setBulkOperationResponse(
                    { value: payload.request.checkValue, isSuccesfull: true, message: response.message }
                )),
                // put(setLoadIndicator(showLoaderCount + (payload.totalJobs > 0 && payload.bulkOperationData?.completedJobs + 1 >= payload.bulkOperationData?.totalJobs ? -1 : 1)))
            ]);
        }
        else {
            yield all([
                put(setBulkOperationResponse(
                    { value: payload.request.checkValue, isSuccesfull: false, message: response.message }
                )),
                // put(setLoadIndicator(showLoaderCount + (payload.totalJobs > 0 && payload.bulkOperationData?.completedJobs + 1 >= payload.bulkOperationData?.totalJobs ? -1 : 1)))
            ]);
        }
    }
}


export default function* utilitiesSaga() {
    yield all([
        takeLatest(types.SEND_MAIL_REQUESTED, safe(runSendMail)),
        takeLatest(types.CREATE_BULK_OPERATION_REQUESTED, safe(runCreateBulkOperationRequest)),
        takeLatest(types.CREATE_BULK_OPERATION_REQUESTED2, safe(runCreateBulkOperationRequest2))
    ]);
}