import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { pageFilterTypes } from '../../utils/constants';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
import moment from 'moment';
import { getPreRegisteredPersonList } from '../../state-management/actions/create-customer.actions';

function LandingListPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('landingListPage');
    const preRegisteredPersonList = useSelector(state => state.createCustomerSnapshot.preRegisteredPersonList);

    useEffect(() => {
        dispatch(withActionPromise(getPreRegisteredPersonList()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { data: "id", title: "", width: "5%", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "id" },
        { data: "firstName", title: "First Name", minWidth: "90px", searchable: true },
        { data: "lastName", title: "Last Name", minWidth: "90px", searchable: true },
        { data: "email", title: "Email", width: "20%", minWidth: "150px", searchable: true },
        { data: "phone", title: "Phone", width: "20%", minWidth: "90px", searchable: false },
        { data: "createdDate", title: "Created Date", width: "10%", minWidth: "100px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {!rowData.isBlocked &&
                            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.id}
                                onChange={(e) => {
                                    e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                                }}
                            />
                        }
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.firstName}</span>
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.lastName}</span>
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.email}</span>
                    </>
                );
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.phone}</span>
                    </>
                );
            }
        },
        {
            targets: 5,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{moment.utc(rowData.createdDate).format('L LT')}</span>

                    </>
                );
            }
        }
    ];

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

        if (selectedOperation === "0" || selectedOperation === "1") //export
        {
            var data = preRegisteredPersonList.filter(item1 =>
                !!checkedValues.find(item2 => item1.id === item2));

            if (selectedOperation === "0" && data?.length > 0) {
                ExportToExcel({ excelData: data, fileName: "landinglist" });
            }
            else if (selectedOperation === "1" && data?.length > 0) {
                const tableColumn = ["FIRSTNAME", "LASTNAME", "EMAIL", "PHONE", "CREATEDDATE"];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                data.forEach(person => {
                    const landingListData = [
                        person.firstName,
                        person.lastName,
                        person.email,
                        person.phone,
                        moment.utc(person.createdDate).format('L LT'),

                    ];
                    // push each tickcet's info into a row
                    tableRows.push(landingListData);
                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Pre-Registered", orientation: 'l', reportName: "pre_registered_persons" });

            }
        }

    }

    const refreshClickedCallBack = () => {
        dispatch(withActionPromise(getPreRegisteredPersonList()));
    };

    return (

        <div style={{ marginBottom: '150px' }}>


                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.administrator'), navigationPath: "" },
                        { navigationText: i18next.t('constants:breadCrumps.landingList'), navigationPath: "" }]} >
                    </DrBreadCrump>



            <div className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            {preRegisteredPersonList?.length > 0 &&
                                <DrDataTable
                                    data={preRegisteredPersonList}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    // headerSelectbox={true}
                                    minWidth="850px"
                                    search={true}
                                    order={[[1, 'asc']]}
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            // filterType={pageFilterTypes.categories}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                        >
                                        </DrPageFilter>
                                    }
                                />}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingListPage