import React from 'react'
import { DrInput, DrNavigator, DrSelectBox, DrTagifyInput } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setSmartPricerPageSubmission, setSmartPricerSnapshotMeta, setSmartPricerTargetAsins, setSmartPricerTargetCategory } from '../../state-management/actions/create-smartpricer-filter.actions';
import { getProductsCategories } from '../../state-management/actions/inventory.actions';
import { useEffect } from 'react';

function SmartPricerTargetPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('smartPricerPage');
    const smartPricerSnapshot = useSelector(state => state.smartPricerSnapshot);
    const productCategories = useSelector(state => state.inventory.productsCategories);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const smartPricerStrategies = useSelector(state => state.smartPricer.smartPricerStrategies);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(withActionPromise(getProductsCategories(selectedStore?.customerMainSellerId)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = {
        targetType: smartPricerSnapshot?.targetType || 0,
        targetAsins: smartPricerSnapshot?.targetProductsAsins || null,
        targetCategoryId: smartPricerSnapshot?.targetCategoryId || null
    }

    const smartpricerTargetPageSchema = Yup.object().shape({

        targetType: Yup.string()
            .min(1, 'Too Short!')
            .required('Your need to select target.'),

        targetCategoryId: Yup.string().nullable().when('targetType', {
            is: (value) => value && value === 1,
            then: () => Yup.string().nullable(true).required('Target Category is required').test(
                'empty-check',
                'You need select category',
                targetCategoryId => !targetCategoryId || targetCategoryId.length > 0)
                .min(1, 'Too Short!'),
            otherwise: () => Yup.string().nullable(),
        }),

        targetAsins: Yup.string().nullable().when('targetType', {
            is: (value) => value && value === 2,
            then: () => Yup.array()
                .of(Yup.string()
                    .min(10, "Asins needs to be ten characters")
                    .max(10, "Asins needs to be ten characters")
                    .matches(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/, "Asins characters may only contains numbers and letters")
                    .required("At least one asin is required")
                ).test({
                    message: 'At least one asin is required',
                    test: arr => arr.length !== 0,
                }),
            otherwise: () => Yup.array().nullable(),
        })
    });


    return (
        <Formik
            initialValues={initialValues}
            validateOnBlur={true}
            validationSchema={smartpricerTargetPageSchema}
            enableReinitialize={true}
            validateOnMount={true}
        >
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (

                <div className="content clearfix">
                    <form name="smartpricer-target-form" className="needs-validation" autoComplete="off">

                        <div className="row row-md d-flex justify-content-center">
                            <div className="col-sm-8 col-lg-12 col-md-8">
                                <div className="card custom-card card-body shadow-none">
                                    <h3>{t('targetPage.contentTitle')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row row-md row-lg justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card custom-card">
                                    <section>
                                        <div className="row px-3">
                                            <div className="col-md-4 col-lg-3">
                                                <label htmlFor="enableDefaultInput" className="noselect pt-2 text-start cursor-pointer">
                                                    {t('targetPage.targetDefault')}
                                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                </label>
                                            </div>
                                            <div className="col-md-8 col-lg-9">
                                                <div className="row">
                                                    <div className="col-auto col-md-2 col-lg-2 col-xl-2">
                                                        <div className="form-check form-check-md form-switch">
                                                            <DrInput id="enableDefaultInput" value="" checked={values.targetType === 0} className="form-check-input form-checked-success" role="switch" placeholder="" name="targetType" type="checkbox"
                                                                onChange={(e) => {
                                                                    setFieldValue("targetType", 0);
                                                                    setFieldValue("targetAsins", null);
                                                                    setFieldValue("targetCategoryId", null);
                                                                }}
                                                                onBlur={() => { setTouched({ ...touched, 'targetType': true, 'targetAsins': true, 'targetCategoryId': true }) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col col-md-10 col-lg-10 col-xl-10 my-auto">
                                                        <label className={`form-check-label fs-12 cursor-pointer no-select ${(values.targetType === 0) ? "" : "text-muted"}`} htmlFor="enableDefaultInput"
                                                            style={{ marginTop: '2px' }}>
                                                            {t('targetPage.targetDefaultDescription')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!(smartPricerStrategies?.length > 0 && !smartPricerStrategies.find(x => x.isBuyBoxGroup || x.isLowestGroup)) &&
                                            !(smartPricerSnapshot?.filterTypeSelection === "1" || smartPricerSnapshot?.filterTypeSelection === "2") &&
                                            <>
                                                <hr className="my-3 mx-1"></hr>
                                                <div className="row px-3">
                                                    <div className="col-md-4 col-lg-3">
                                                        <label htmlFor="enableCategoryInput" className="noselect pt-2 text-start cursor-pointer">
                                                            {t('targetPage.targetCategory')}
                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 col-lg-9">
                                                        <div className="row">
                                                            <div className="col-auto col-md-2 col-lg-2 col-xl-2">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="enableCategoryInput" value="" checked={values.targetType === 1} className="form-check-input form-checked-success" role="switch" placeholder="" name="targetType" type="checkbox"
                                                                        onChange={(e) => {
                                                                            setFieldValue("targetType", 1);
                                                                            setFieldValue("targetAsins", null);
                                                                            setFieldValue("targetCategoryId", null);
                                                                        }}
                                                                        onBlur={() => { setTouched({ ...touched, 'targetType': true, 'targetAsins': true, 'targetCategoryId': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col col-md-10 col-lg-10 col-xl-10 my-auto">
                                                                <label className={`form-check-label fs-12 cursor-pointer no-select ${(values.targetType === 1) ? "" : "text-muted"}`} htmlFor="enableCategoryInput"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('targetPage.targetCategoryDescription')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-start">
                                                            <div className="col col-md-10 col-lg-8 col-xl-7 mt-2">
                                                                <Field onBlur={() => {
                                                                    setTouched({ ...touched.targetCategoryId, 'targetCategoryId': true });
                                                                }}
                                                                    name={'targetCategoryId'}
                                                                    component={DrSelectBox}
                                                                    placeholder={t('targetPage.targetCategory')}
                                                                    disabled={values.targetType !== 1}
                                                                    options={productCategories?.map(x => ({ value: x.id, label: x.categoryName }))} />
                                                                {(touched.targetCategoryId && errors.targetCategoryId) && <label type="invalid" className="form-control is-invalid">
                                                                    {errors.targetCategoryId}
                                                                </label>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="my-3 mx-1"></hr>
                                                <div className="row px-3 mb-3">
                                                    <div className="col-md-4 col-lg-3">
                                                        <label htmlFor="enableTargetAsinsInput" className="noselect mt-3 pt-2 text-start cursor-pointer">
                                                            {t('targetPage.targetAsins')}
                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                        </label>
                                                    </div>
                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                        <div className="row">
                                                            <div className="col-auto col-md-2 col-lg-2 col-xl-2">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="enableTargetAsinsInput" value="" checked={values.targetType === 2} className="form-check-input form-checked-success" role="switch" placeholder="" type="checkbox"
                                                                        name="targetType"
                                                                        onChange={(e) => {
                                                                            setFieldValue("targetType", 2);
                                                                            setFieldValue("targetCategoryId", null);
                                                                            setFieldValue("targetAsins", null);
                                                                        }}
                                                                        onBlur={() => { setTouched({ ...touched, 'targetType': true, 'targetAsins': true, 'targetCategoryId': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col col-md-10 col-lg-10 col-xl-10 my-auto">
                                                                <label className={`form-check-label fs-12 cursor-pointer no-select ${(values.targetType === 1) ? "" : "text-muted"}`} htmlFor="enableTargetAsinsInput"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('targetPage.targetAsinsDescription')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col mt-2">
                                                                <DrTagifyInput id="targetAsinsInput" value={values.targetAsins} disabled={values.targetType !== 2}
                                                                    settings={{
                                                                        pattern: /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]{10}$/,
                                                                        delimiters: ",| |:|[\\n\\r]"
                                                                    }}
                                                                    className="tags-input-control"
                                                                    isvalid={(touched.targetAsins && !errors.targetAsins)}
                                                                    isinvalid={(touched.targetAsins && errors.targetAsins)}
                                                                    onBlur={(e) => {
                                                                        setTouched({ ...touched, 'targetAsins': true });
                                                                        setFieldValue("targetAsins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onRemove={(e) => {
                                                                        setTouched({ ...touched, 'targetAsins': true });
                                                                        setFieldValue("targetAsins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}
                                                                    onEditUpdated={(e) => {
                                                                        setTouched({ ...touched, 'targetAsins': true });
                                                                        setFieldValue("targetAsins", e.detail.tagify.value.map(a => a.value.trim().toUpperCase()));
                                                                    }}>
                                                                </DrTagifyInput>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        }


                                    </section>
                                </div>
                            </div>
                        </div>
                        {<DrNavigator backAddress="SMARTPRICER_FILTERS" nextAddress="SMARTPRICER_COMPLETE" disabled={!isValid}
                            callback={() => {
                                return Promise.all([
                                    dispatch(withActionPromise(setSmartPricerSnapshotMeta({ "targetType": values.targetType }))),
                                    dispatch(withActionPromise(setSmartPricerTargetAsins(values.targetAsins))),
                                    dispatch(withActionPromise(setSmartPricerTargetCategory(values.targetCategoryId))),
                                    dispatch(withActionPromise(setSmartPricerPageSubmission({ smartpricerTargetPage: true })))
                                ]);
                            }}
                        />}
                    </form>
                </div>
            )
            }
        </Formik>
    )
}

export default SmartPricerTargetPage;