import { useTranslation } from "react-i18next";
import { Formik } from 'formik';
import { clearActiveChooseColumnsModal } from "../../../../state-management/actions/app.actions";
import { useDispatch } from "react-redux";
import $ from 'jquery';
import { DrInput } from "../../../component-index";

function DrChooseColumnsModal({ display, source, persistModal = false, onColumnsSelectedCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drChooseColumnsModal');


    const initialValues = {
        columns: source,
        checkAll: !source?.find(x => x.value === false) ? false : true
    }


    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="captchaModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveChooseColumnsModal());
                }
            }}>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                enableReinitialize={true}

            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue
                }) => (

                    <div className="form needs-validation modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header" >
                                <h6 className="modal-title" >{t('title')}</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => dispatch(clearActiveChooseColumnsModal())} >
                                </button>
                            </div>
                            <div className="modal-body justify-contents-center">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="row my-1">
                                            <div className="col-auto mt-0">
                                                <div className="form-check form-check-md form-switch">
                                                    <DrInput id="checkAllColumns" className="form-check-input form-checked-success" role="switch" placeholder="" name="checkAll" type="checkbox"
                                                        value=""
                                                        checked={values.columns?.find(x => x.value === false) ? false : true}
                                                        onChange={(e) => {
                                                            var newColumns = values.columns?.map(x => ({ ...x, value: e.currentTarget.checked }));
                                                            setFieldValue("columns", newColumns);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col mt-0">
                                                <label className={`form-check-label cursor-pointer no-select fs-16 fw-bold my-auto"}`} htmlFor="checkAllColumns">
                                                    {t('checkAll')}
                                                </label>
                                            </div>

                                        </div>
                                        <hr></hr>
                                        <div className="row my-1">
                                            {values.columns?.map((column, index) => {

                                                return (

                                                    <div key={"tableColumn_" + index} className="col-12 col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                        <div className="row">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id={column.fieldName} className="form-check-input form-checked-success" role="switch" placeholder="" name={column.fieldName} type="checkbox"
                                                                        value=""
                                                                        checked={column.value}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`columns[${index}].value`, e.currentTarget.checked)
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(column.fieldName) ? "" : "text-muted"}`} htmlFor={column.fieldName}
                                                                    style={{ marginTop: '2px' }}>
                                                                    {column.title}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )

                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row justify-content-end w-100">

                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (onColumnsSelectedCallBack) {
                                                    var selectedColumns = values.columns.filter(x => x.value === true)?.map(x => x.fieldName);
                                                    onColumnsSelectedCallBack(selectedColumns);
                                                }
                                                dispatch(clearActiveChooseColumnsModal());
                                            }}>
                                            {t('save')}
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                )
                }
            </Formik>
        </div>

    )
}

export default DrChooseColumnsModal