const types = {
    CLEAR_ALL_REPORTS: "CLEAR_ALL_REPORTS",
    GET_BEST_SELLING_PRODUCTS_REQUESTED: "GET_BEST_SELLING_PRODUCTS_REQUESTED",
    GET_BEST_SELLING_PRODUCTS_SUCCESS: "GET_BEST_SELLING_PRODUCTS_SUCCESS",

    GET_INVENTORY_PRODUCT_CATEGORIES_REQUESTED: "GET_INVENTORY_PRODUCT_CATEGORIES_REQUESTED",
    GET_INVENTORY_PRODUCT_CATEGORIES_SUCCESS: "GET_INVENTORY_PRODUCT_CATEGORIES_SUCCESS",

    GET_INVENTORY_PRODUCT_STATUS_COUNTS_REQUESTED: "GET_INVENTORY_PRODUCT_STATUS_COUNTS_REQUESTED",
    GET_INVENTORY_PRODUCT_STATUS_COUNTS_SUCCESS: "GET_INVENTORY_PRODUCT_STATUS_COUNTS_SUCCESS",

    GET_SALES_GENERAL_REPORT_REQUESTED: "GET_SALES_GENERAL_REPORT_REQUESTED",
    GET_SALES_REPORT_REQUESTED: "GET_SALES_REPORT_REQUESTED",
    GET_SALES_SUMMARY_REPORT_REQUESTED: "GET_SALES_SUMMARY_REPORT_REQUESTED",
    GET_PROFIT_REPORT_REQUESTED: "GET_PROFIT_REPORT_REQUESTED",
    GET_DELIVERY_REPORT_REQUESTED: "GET_DELIVERY_REPORT_REQUESTED",
    GET_REPORT_SUCCESS: "GET_REPORT_SUCCESS",
}

export default types;


export const clearAllReports = () => {
    return { type: types.CLEAR_ALL_REPORTS };
};

export const getBestSellingProducts = (payload) => {
    return { type: types.GET_BEST_SELLING_PRODUCTS_REQUESTED, payload };
};

export const getBestSellingProductsSuccess = (payload) => {
    return { type: types.GET_BEST_SELLING_PRODUCTS_SUCCESS, payload };
};

export const getInventoryProductCategories = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_CATEGORIES_REQUESTED, payload };
};

export const getInventoryProductCategoriesSuccess = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_CATEGORIES_SUCCESS, payload };
};

export const getInventoryProductStatusCounts = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_STATUS_COUNTS_REQUESTED, payload };
};

export const getInventoryProductStatusCountsSuccess = (payload) => {
    return { type: types.GET_INVENTORY_PRODUCT_STATUS_COUNTS_SUCCESS, payload };
};

export const getSalesGeneralReport = (payload) => {
    return { type: types.GET_SALES_GENERAL_REPORT_REQUESTED, payload };
};

export const getSalesReport = (payload) => {
    return { type: types.GET_SALES_REPORT_REQUESTED, payload };
};

export const getSalesSummaryReport = (payload) => {
    return { type: types.GET_SALES_SUMMARY_REPORT_REQUESTED, payload };
};

export const getProfitReport = (payload) => {
    return { type: types.GET_PROFIT_REPORT_REQUESTED, payload };
};

export const getDeliveryReport = (payload) => {
    return { type: types.GET_DELIVERY_REPORT_REQUESTED, payload };
};

export const getReportSuccess = (payload) => {
    return { type: types.GET_REPORT_SUCCESS, payload };
};