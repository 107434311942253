import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { getProductsCategories, saveProductCategoryBlock } from '../../state-management/actions/inventory.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { pageFilterTypes } from '../../utils/constants';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
import { useNavigate, useParams } from 'react-router-dom';
import $ from 'jquery';
import { format } from '../../components/core/DataTable/dr-datatable.component';

function CategoriesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('categoriesPage');
  const { category } = useParams();
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const productCategories = useSelector(state => state.inventory.productsCategories);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getProductsCategories(selectedStore?.customerMainSellerId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (category) {
      var rowData = productCategories?.find(x => x.categoryName === category);
      if (rowData) {
        rowData.isSubTableShown = true;
        document.querySelector("tr.selected td.dt-control i")?.click();
        //  document.querySelector("tr.selected td.dt-control i")?.dispatchEvent(new Event('click'));
      }
    }
    console.log("productCategories", productCategories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, productCategories]);

  const columns = [
    { data: "categoryName", title: "", minWidth: "40px", maxWidth: "40px", searchable: false, checkall: true, checkData: "categoryName" },
    { data: null, title: "", minWidth: "40px", maxWidth: "40px", searchable: false },
    { data: "categoryName", title: t('dataTable.category'), width: "15%", searchable: true },
    { data: "estimatedProfit", title: t('dataTable.estimatedProfit'), width: "15%", searchable: false },
    { data: "categoryOrderCount", title: t('dataTable.order'), width: "15%", searchable: false },
    { data: "categoryProductCount", title: t('dataTable.productCount'), width: "15%", searchable: false },
    { data: "isBlocked", title: "", width: "10%", minWidth: "100px", searchable: false }
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: false,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        if (rowData.isBlocked) {
          td.closest("tr").classList.toggle("greyed-out");
        }
        if ((category && category === rowData.categoryName)) {
          td.closest("tr").classList.toggle("selected");
        }

        ReactDOM.createRoot(td).render(
          <>

            <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.categoryName} defaultChecked={(category && category === rowData.categoryName)}
              onChange={(e) => {
                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
              }}
            />

          </>
        )
      }
    },
    {
      targets: 1,
      orderable: false,
      className: "text-center dt-control",
      createdCell: (td, cellData, rowData) => {

        if ((category && category === rowData.categoryName)) {

          var table = $('#dtable').DataTable();
          console.log("table",table)
          if (table) {
            let tr = td.closest('tr');
            let row = table.row(tr);
            if (row.data()?.subCategories && columns) {
              row.child(format(columns, row.data().subCategories)).show();
              // console.log(format(props.columns, row.data().subCategories))
            }
          }
        }

        ReactDOM.createRoot(td).render(
          <>
            {(category && category === rowData.categoryName) && <i className="fe fe-minus-circle fw-bold text-teal fs-16  waves-effect waves-light cursor-pointer"></i>}

            {((category && category !== rowData.categoryName) || !category) && <i className="fe fe-plus-circle fw-bold text-teal fs-16  waves-effect waves-light cursor-pointer"></i>}
          </>
        )
      }
    },
    {
      targets: 2,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
              onClick={(e) => {
                e.preventDefault();
                navigate('/inventory/c/' + rowData.categoryName);
              }}
            >
              {rowData.categoryName}
            </button>
          </>);
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            {rowData.estimatedProfit && <span className="badge bg-success my-auto mx-0 p-0">{selectedStoreCurrencyType.currencyCode}{Number(rowData.estimatedProfit).toFixed(2)}</span>}
          </>);
      }
    },
    {
      targets: 4,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <div className="text-center" style={{ fontSize: '16px' }}>
            <span className="badge bg-info">{rowData.categoryOrderCount}</span><br />
          </div>);
      }
    },
    {
      targets: 5,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="badge bg-info">{rowData.categoryProductCount}</span><br />
          </>);
      }
    },
    {
      targets: 6,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <button className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${rowData.isBlocked ? "btn-warning" : "btn-danger"}`} rel="noreferrer" value=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(withActionPromise(saveProductCategoryBlock({
                customerMainSellerId: selectedStore.customerMainSellerId,
                productCategoryNames: [rowData.categoryName],
                isBlocked: !rowData.isBlocked
              })));

            }}>
            {rowData.isBlocked ? "UnBlock" : "Block"}

          </button>
        );
      }
    }
  ];

  const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

    if (selectedOperation === "0" || selectedOperation === "1") //export
    {
      var data = productCategories.filter(item1 =>
        !!checkedValues.find(item2 => item1.categoryName === item2));

      if (selectedOperation === "0" && data?.length > 0) {
        ExportToExcel({ excelData: data, fileName: "categories" });
      }
      else if (selectedOperation === "1" && data?.length > 0) {
        const tableColumn = ["CATEGORY NAME", "ESTIMATED PROFIT", "ORDER COUNT", "PRDDUCT COUNT"];
        // define an empty array of rows
        const tableRows = [];

        // for each ticket pass all its data into an array
        data.forEach(category => {
          const categoryData = [
            category.categoryName,
            "$" + Number(category.estimatedProfit).toFixed(2),
            category.categoryOrderCount,
            category.categoryProductCount
          ];
          // push each tickcet's info into a row
          tableRows.push(categoryData);
        });
        generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Product Categories", orientation: 'l', reportName: "product_categories" });

      }
    }
    if (selectedOperation === "18" || selectedOperation === "19") {

      var requestData = {
        customerMainSellerId: selectedStore.customerMainSellerId,
        productCategoryNames: [
          ...checkedValues
        ],
        isBlocked: selectedOperation === "18" ? true : false
      }
      dispatch(withActionPromise(saveProductCategoryBlock(requestData)));
    }

  }

  const refreshClickedCallBack = () => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getProductsCategories(selectedStore?.customerMainSellerId)));
    }
  };

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
        { navigationText: i18next.t('constants:breadCrumps.categories'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              {productCategories && <>
                <DrDataTable
                  data={productCategories}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  // headerSelectbox={true}
                  minWidth="850px"
                  search={true}
                  order={[[1, 'asc']]}
                  isMultiSelectEnabled={true}
                  detailedSearchChildren={
                    <DrPageFilter
                      refreshClickedCallBack={refreshClickedCallBack}
                      filterType={pageFilterTypes.categories}
                      selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                    >
                    </DrPageFilter>
                  }
                />
              </>
              }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default CategoriesPage