import { useTranslation } from 'react-i18next';
import { DrBreadCrump, DrInput, DrMaskedInput } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import i18next from 'i18next';
import { getRouteByKey } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { mywalletMockData } from '../../utils/mocks-data';
import { getCardNumber, getCreditCardIcon, getNumbersFromString } from '../../utils/utils';
import empty from '../../assets/img/cards/empty.png'
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { amexCardNumberMask, cardNumberMask, expirationDateMask, fourDigitCVC, threeDigitCVC } from '../../utils/masks';
import { cardBrandNames, cardBrands, modals } from '../../utils/constants';
import Card from "react-credit-cards-2";
import { setSelectedCreditCard } from '../../state-management/actions/wallet.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import moment from 'moment';

function CardSelectionPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('walletPage');
    const walletInfo = useSelector(state => state.wallet.walletInfo);
    const [focusedInput, setFocusedInput] = useState("name");
    const [creditCardBrand, setCreditCardBrand] = useState("");
    const [creditCardMask, setCreditCardMask] = useState(amexCardNumberMask);
    const [cvcMask, setCvcMask] = useState(threeDigitCVC);



    // useEffect(() => {
    //     setStripePromise(loadStripe("pk_live_51OkR3LJg8A74hXJKpqKje27zQQJil6OYf4xh7I1kdcjaL4XkFCgMuw5wLF4gQBmQFCGyIFAYlokFjCszUTkWfG2k00uqiYvqXf"));
    //     setClientSecret("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")
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const initialValues = {
        cardHolderName: null,
        cardNumber: null,
        expirationDate: null,
        expirationDateYear: null,
        expirationDateMonth: null,
        cvc: null,
        isSaveCard: false
    };


    const handleSubmit = async (values) => {
        //dispatch(createCustomer(createCustomerSnapshot));
    }



    Yup.addMethod(Yup.string, "expirationDate", function (errorMessage) {
        return this.test(`expirationDate`, errorMessage, function (value) {
            if (value && value.includes("/")) {
                const { path, createError } = this;
                const terms = value.split("/");
                let month = terms[0], year = terms[1];

                if ((typeof month === "number" && isNaN(month))
                    || (typeof year === "number" && isNaN(year))
                    || parseInt(month) > 12
                    || parseInt(month) === 0
                    || year.includes("_")
                    || month.includes("_")) {
                    return createError({ path, message: "Expiration date is not valid" });
                }

                year = parseInt("20" + year);
                month = parseInt(month);
                const dateNow = new Date();
                const yearNow = dateNow.getFullYear();
                const monthNow = dateNow.getMonth() + 1;
                if (year > yearNow) {
                    return true;
                }

                if ((yearNow === year && month < monthNow) || year < yearNow) {
                    return createError({ path, message: errorMessage });
                }

                return true;
            }
        });
    });

    Yup.addMethod(Yup.string, "cardBrand", function (errorMessage) {
        return this.test(`cardNumber`, errorMessage, function () {
            const { path, createError } = this;

            if (creditCardBrand === cardBrands.unknown) {
                return createError({ path, message: errorMessage });
            }

            return true;
        });
    });

    Yup.addMethod(Yup.string, "cardNumber", function (errorMessage) {
        return this.test(`cardNumber`, errorMessage, function (value) {
            if (!value) {
                return true;
            }

            const { path, createError } = this;

            var creditCardNumber = getNumbersFromString(value);
            if (creditCardBrand === cardBrands.amex) {
                return creditCardNumber.length === 15 || createError({ path, message: errorMessage });
            }

            return creditCardNumber.length === 16 || createError({ path, message: errorMessage });
        });
    });

    Yup.addMethod(Yup.string, "cvc", function (errorMessage) {
        return this.test(`cvc`, errorMessage, function (value) {
            if (!value) {
                return true;
            }

            const { path, createError } = this;

            var cvc = getNumbersFromString(value);
            return cvc.length === cvcMask?.length || createError({ path, message: errorMessage });
            // if (creditCardBrand === cardBrands.amex || creditCardBrand === cardBrands.discover) {
            //     return cvc.length === cvcMask?.length || createError({ path, message: errorMessage });
            // }

            // return cvc.length === 3 || createError({ path, message: errorMessage });
        });
    });

    const paymentValidationSchema = Yup.object().shape({
        cardHolderName: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Name is required'),
        cardNumber: Yup.string().required('Card Number is required').cardNumber("Please enter a valid credit card number"),//.cardBrand("Card Brand is unknown"),
        expirationDate: Yup.string().required('Expiration Date is required').expirationDate("Card is expired"),
        cvc: Yup.string().required("CVC is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .cvc("CVC is not valid")
    });

    return (
        <div style={{ marginBottom: '100px' }}>
            <div className="page-header mb-0 mt-4 row row-md row-lg mb-2 d-flex justify-content-between">
                <div className="col-auto">
                    <DrBreadCrump
                        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.myWallet'), navigationPath: "/mywallet" },
                        { navigationText: i18next.t('constants:breadCrumps.paymentMethod'), navigationPath: "/payment-method" },
                        { navigationText: i18next.t('constants:breadCrumps.cardSelection'), navigationPath: "" }]} >
                    </DrBreadCrump>
                </div>
            </div>
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row row-md row-lg justify-content-center">
                                <div className="col-12 col-md-10 col-lg-8 col-xl-8">
                                    <h3 className="mb-3 mt-3 text-center">
                                        {t('walletCardSelectionPage.title')}
                                    </h3>
                                    {walletInfo && walletInfo.myCards?.length > 0 && walletInfo.myCards?.map((card, index) => {

                                        return (
                                            <>
                                                <div key={"mycard_" + index} className="row row-md row-lg border border-gray-300 rounded m-1">
                                                    <div className="col-12 px-0">
                                                        <label className="row my-0 ms-0 me-0 bg-gray-200 px-0 py-3 cursor-pointer"
                                                            onClick={(e) => {
                                                                dispatch(withActionPromise(setSelectedCreditCard(card)));
                                                                navigate(getRouteByKey("MY_WALLET_PAYMENT_PAGE"))
                                                            }}>
                                                            <div className="col-auto my-auto">
                                                                {/* <i className={`w-6 me-2  ${getCreditCardIconText(card.cardNumber)}`} style={{ fontSize: '1.4em', color: 'var(--blue)' }}></i> */}
                                                                <img src={getCreditCardIcon(card.cardNumber)} alt="img" style={{ height: '36px' }} ></img>
                                                                {/* <i className="w-6 me-2 fa fa-credit-card" style={{ fontSize: '1.42em', color: 'var(--primary-bg-color)' }}></i> */}
                                                            </div>
                                                            <div className="col my-auto px-0">
                                                                <div className="row justify-content-start">
                                                                    <div className="col-12">
                                                                        <span>{card.cardHolderName}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row justify-content-start">
                                                                    <div className="col-12">
                                                                        <span>{getCardNumber(card.cardNumber)}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="col-auto my-auto">
                                                                <i className="w-6 me-2 fa fa-chevron-right"></i>
                                                            </div>

                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    <div className="row row-md row-lg border border-gray-300 rounded m-1">
                                        <div className="col-12 px-0">
                                            <label className="row my-0 ms-0 me-0 bg-gray-200 px-0 py-3 cursor-pointer collapsed" data-bs-toggle="collapse" data-bs-target={("#newcard_")}
                                            >
                                                <div className="col-auto my-auto">
                                                    {/* <i className="w-6 me-2 fa fa-cc-stripe" style={{ fontSize: '1.4em', color: 'var(--primary-bg-color)' }}></i> */}
                                                    <img src={empty} alt="img" style={{ height: '36px' }} ></img>
                                                    {/* <i className="w-6 me-2 fa fa-credit-card" style={{ fontSize: '1.42em', color: 'var(--primary-bg-color)' }}></i> */}
                                                </div>
                                                <div className="col my-auto px-0">
                                                    <span>{t('walletCardSelectionPage.addNewCard')}</span>
                                                </div>
                                                <div className="col-auto my-auto">
                                                    <i className="w-6 me-2 fa fa-chevron-down"></i>
                                                </div>
                                            </label>
                                            <div id="newcard_" className="col-12 py-3 collapse">
                                                <Formik
                                                    initialValues={initialValues}
                                                    onSubmit={handleSubmit}
                                                    validationSchema={paymentValidationSchema}
                                                    enableReinitialize={true}
                                                    validateOnMount={true}>
                                                    {({
                                                        isValid,
                                                        values,
                                                        errors,
                                                        touched,
                                                        handleChange,
                                                        handleBlur,
                                                        setTouched,
                                                        setFieldValue
                                                    }) => (
                                                        <div className="content clearfix">
                                                            <form name="payment-form" autoComplete="off">
                                                                <section>
                                                                    <div className="row row-md row-lg">
                                                                        <div className="col-12">
                                                                            <Card
                                                                                preview={true}
                                                                                // number={values.cardNumber}
                                                                                number={values.cardNumber ? getCardNumber(values.cardNumber) : ""}
                                                                                name={values.cardHolderName || ""}
                                                                                expiry={values.expirationDate || ""}
                                                                                cvc={values.cvc || ""}
                                                                                focused={focusedInput}
                                                                                callback={(e) => {
                                                                                    if (e.issuer === cardBrandNames[cardBrands.amex]) {
                                                                                        setCreditCardBrand(0);
                                                                                    }
                                                                                    else if (e.issuer === cardBrandNames[cardBrands.discover]) {
                                                                                        setCreditCardBrand(2);
                                                                                    }
                                                                                    else {
                                                                                        setCreditCardBrand(cardBrands[e.issuer]);
                                                                                    }

                                                                                    if (e.issuer === cardBrandNames[cardBrands.amex]
                                                                                        // || e.issuer === cardBrandNames[cardBrands.discover]
                                                                                    ) {
                                                                                        setCreditCardMask(amexCardNumberMask);
                                                                                        setCvcMask(fourDigitCVC);
                                                                                    } else {
                                                                                        setCreditCardMask(cardNumberMask);
                                                                                        setCvcMask(threeDigitCVC);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row row-md row-lg">
                                                                        <div className="col-12">
                                                                            <div className="card card-body border-0 pd-20 pd-md-40 d-inline">
                                                                                <div id="card-element" className="col-12 col-md-11 col-lg-9 col-xl-9">
                                                                                    <div className="form-group">
                                                                                        <label className="main-content-label tx-11 tx-medium tx-gray-600">{t('walletCardSelectionPage.cardHolderName')}</label>
                                                                                        <DrInput
                                                                                            value={values.cardHolderName || ""}
                                                                                            placeholder={t('walletCardSelectionPage.cardHolderName')}
                                                                                            name="cardHolderName"
                                                                                            type="text"
                                                                                            className="form-control pd-r-80"
                                                                                            onChange={handleChange}
                                                                                            onBlur={handleBlur}
                                                                                            // onFocus={() => { setFocusedInput("cardHolderName") }} 
                                                                                            isInvalid={(touched.cardHolderName && errors.cardHolderName)}
                                                                                            isValid={touched.cardHolderName && !errors.cardHolderName}
                                                                                            validationText={errors.cardHolderName}
                                                                                        />

                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <label className="main-content-label tx-11 tx-medium tx-gray-600">{t('walletCardSelectionPage.cardNumber')}</label>
                                                                                        <Field
                                                                                            placeholder={t('walletCardSelectionPage.cardNumber')}
                                                                                            onBlur={() => { setTouched({ ...touched, 'cardNumber': true }) }}
                                                                                            name={'cardNumber'}
                                                                                            className="form-control"
                                                                                            component={DrMaskedInput}
                                                                                            mask={values?.cardNumber?.slice(0, 1) === "3" ? amexCardNumberMask : cardNumberMask}
                                                                                            type="text"
                                                                                            rawvalue={values.cardNumber || ""}
                                                                                            onChange={(e) => {
                                                                                                setFieldValue("cardNumber", e.currentTarget.value);
                                                                                            }}
                                                                                            onFocus={() => { setFocusedInput("number") }}
                                                                                        />
                                                                                        {(touched.cardNumber && errors.cardNumber) && <div type="invalid" className="form-control is-invalid">
                                                                                            {errors.cardNumber}
                                                                                        </div>}
                                                                                    </div>
                                                                                    <div className="form-group">
                                                                                        <div className="row row-sm px-1">
                                                                                            <div className="col-sm-8">
                                                                                                <label className="main-content-label tx-11 tx-medium tx-gray-600">{t('walletCardSelectionPage.expirationDate')}</label>
                                                                                                <Field
                                                                                                    placeholder="MM/YY"
                                                                                                    onBlur={() => { setTouched({ ...touched, 'expirationDate': true, 'expirationDateMonth': true, 'expirationDateYear': true }) }}
                                                                                                    name={'expirationDate'}
                                                                                                    className="form-control"
                                                                                                    component={DrMaskedInput}
                                                                                                    mask={expirationDateMask}
                                                                                                    type="text"
                                                                                                    onChange={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        if (moment.utc(e.target.value, "MM/YY", true).isValid()) {
                                                                                                            setFieldValue("expirationDate", e.target.value);
                                                                                                            var month = moment.utc(e.target.value, "MM/YY").format('MM').valueOf();
                                                                                                            var year = moment.utc(e.target.value, "MM/YY").format('YY').valueOf();
                                                                                                            setFieldValue("expirationDateMonth", parseInt(month));
                                                                                                            setFieldValue("expirationDateYear", parseInt(year));
                                                                                                        }
                                                                                                    }}
                                                                                                    onFocus={() => { setFocusedInput("expiry") }}
                                                                                                />
                                                                                                {(touched.expirationDate && errors.expirationDate) && <div type="invalid" className="form-control is-invalid">
                                                                                                    {errors.expirationDate}
                                                                                                </div>}

                                                                                            </div>
                                                                                            <div className="col-sm-4 mg-t-20 mg-sm-t-0">
                                                                                                <label className="main-content-label tx-11 tx-medium tx-gray-600 ms-2">CVC</label>
                                                                                                <Field
                                                                                                    placeholder="CVC"
                                                                                                    onBlur={() => { setTouched({ ...touched, 'cvc': true }) }}
                                                                                                    name="cvc"
                                                                                                    className="form-control"
                                                                                                    component={DrMaskedInput}
                                                                                                    mask={values?.cardNumber?.slice(0, 1) === "3" ? fourDigitCVC : threeDigitCVC}
                                                                                                    type="text"
                                                                                                    onChange={handleChange}
                                                                                                    onFocus={() => { setFocusedInput("cvc") }}
                                                                                                />
                                                                                                {(touched.cvc && errors.cvc) && <div type="invalid" className="form-control is-invalid">
                                                                                                    {errors.cvc}
                                                                                                </div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="form-group mg-b-20">
                                                                                        <label className="ckbox">
                                                                                            <DrInput checked={values.isSaveCard} label="Subscription Type" name="isSaveCard" type="checkbox"
                                                                                                onChange={handleChange} onBlur={handleBlur} />
                                                                                            {(touched.isSaveCard && errors.isSaveCard) && <div type="invalid" className="form-control is-invalid">
                                                                                                {errors.isSaveCard}
                                                                                            </div>}
                                                                                            <span className="tx-13">{t('walletCardSelectionPage.saveCard')}</span>
                                                                                        </label>

                                                                                    </div> */}
                                                                                    <div className="row row-md row-lg align-items-center px-3 py-2 justify-content-start">

                                                                                        <div className="col-auto px-0 mt-0 me-2 text-end">
                                                                                            <label className="custom-switch cursor-pointer">
                                                                                                <DrInput id="saveCardInput" value="" checked={values.isSaveCard} className="form-check-input" placeholder="" name="isSaveCard" type="checkbox"
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={() => { setTouched({ ...touched, 'isSaveCard': true }) }}>
                                                                                                </DrInput>
                                                                                            </label>
                                                                                        </div>
                                                                                        <label htmlFor="saveCardInput" className="col-6 col-sm-8 col-xl-9 col-lg-8 col-md-8 px-0 my-auto cursor-pointer">
                                                                                            <span className={`custom-switch-description ${values.isSaveCard ? "tx-dark" : ""}`} >{t('walletCardSelectionPage.saveCard')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="row row-lg row-md d-flex justify-content-center">
                                                                                        <div className="col-auto">
                                                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow btn-block ${isValid ? "" : "disabled"}`}
                                                                                                onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    if (isValid) {
                                                                                                        var newCard = {
                                                                                                            cardHolderName: values.cardHolderName,
                                                                                                            cardNumber: values.cardNumber,
                                                                                                            expirationDate: values.expirationDate,
                                                                                                            expirationDateMonth: values.expirationDateMonth,
                                                                                                            expirationDateYear: values.expirationDateYear,
                                                                                                            cvc: values.cvc,
                                                                                                            isSaveCard: values.isSaveCard
                                                                                                        };

                                                                                                        dispatch(withActionPromise(setSelectedCreditCard(newCard)));
                                                                                                        navigate(getRouteByKey("MY_WALLET_PAYMENT_PAGE"))
                                                                                                    }
                                                                                                }}
                                                                                            >{t('walletCardSelectionPage.saveAndGo')}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </form>
                                                        </div>
                                                    )
                                                    }
                                                </Formik>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default CardSelectionPage

