import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import defaultWarehouselogo from "../../../assets/img/media/amazonshipping1.png"
import DrNewWarehouse from "./dr-new-warehouse.component";
import DrNewWarehouseShipping from "./dr-new-shipping.component";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { deleteWarehouse, deleteWarehouseShipping, deleteWarehouseSuit, saveWarehouseSuit, updateWarehouseDefault, updateWarehouseSuitDefault } from "../../../state-management/actions/warehouses.actions";
import Restricted from "../../../security/Restricted";
import { staticWarehouseSelectionComponentKey } from "../../../utils/constants";
import $ from 'jquery';
import { generatePassword } from "../../../utils/utils";
import { toastr } from "react-redux-toastr";

function DrWarehouseSelection({ componentKey, source, warehouseShippingsSelectedCallback }) {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN, REACT_APP_ROLES_GUEST, REACT_APP_ROLES_USER } = process.env;
    const dispatch = useDispatch();
    const allMarkets = useSelector(state => state.markets.allMarkets);
    const allShippings = useSelector(state => state.warehouses.allShippings);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const shipstationWarehouses = useSelector(state => state.shipstation.shipstationWarehouses);
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);

    const [warehouses, setWarehouses] = useState(source);
    const [selectedShippingIds, setSelectedShippingIds] = useState([]);

    useEffect(() => {
        setWarehouses(source);
        // console.log("accordion data:", source)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    useEffect(() => {
        if (warehouseShippingsSelectedCallback) {
            warehouseShippingsSelectedCallback(selectedShippingIds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedShippingIds]);

    const saveCustomerWarehouseSuit = (warehouseId, customerMainSellerId, wareHouseShippingId, suitName) => {
        if (componentKey === staticWarehouseSelectionComponentKey.saveorupdateWarehouseSuit) {
            var data = {
                warehouseSuitData: {
                    customerMainSellerId: customerMainSellerId,
                    wareHouseId: warehouseId,
                    wareHouseShippingId: wareHouseShippingId,
                    suitName: suitName
                }
            }
            dispatch(withActionPromise(saveWarehouseSuit(data)));
        }
        else if (componentKey === staticWarehouseSelectionComponentKey.selectWarehouseSuit) {
            let newWarehouses = Object.assign([], warehouses);
            let newSelectedShippingIds = Object.assign([], selectedShippingIds);

            newWarehouses.find(x => x.id === warehouseId)?.warehouseShippings.forEach(shipping => {
                if (shipping.wareHouseShippingId === wareHouseShippingId) {
                    shipping.isSelected = true;
                    newSelectedShippingIds.push(wareHouseShippingId);
                }
                else {
                    shipping.isSelected = false;
                    newSelectedShippingIds = Object.assign([], newSelectedShippingIds.filter(x => x !== shipping.wareHouseShippingId));
                }
            });
            setSelectedShippingIds(newSelectedShippingIds);
            setWarehouses(newWarehouses);
        }
    };

    const deleteCustomerWarehouseSuit = (warehouseId, customerMainSellerId, warehouseSuitId, wareHouseShippingId) => {
        if (componentKey === staticWarehouseSelectionComponentKey.saveorupdateWarehouseSuit) {
            var data = {
                customerMainSellerId: customerMainSellerId,
                warehouseSuitId: warehouseSuitId
            }
            if (warehouseSuitId) {
                dispatch(withActionPromise(deleteWarehouseSuit(data)));
            }
        }
        else if (componentKey === staticWarehouseSelectionComponentKey.selectWarehouseSuit) {
            let newWarehouses = Object.assign([], warehouses);
            setSelectedShippingIds(Object.assign([], selectedShippingIds.filter(x => x !== wareHouseShippingId)));

            newWarehouses.find(x => x.id === warehouseId)?.warehouseShippings.forEach(shipping => {
                if (shipping.wareHouseShippingId === wareHouseShippingId) {
                    shipping.isSelected = false;
                }
            });
            setWarehouses(newWarehouses);
        }
    };

    $(document.querySelectorAll('[data-parent="#warehouseGroup"')).on("click", function (e) {
        var collapsableElements = document.querySelectorAll('[data-parent="#warehouseGroup"');
        collapsableElements.forEach(element => {
            var currentElementTargetId = $(e.currentTarget).attr('data-bs-target');
            var elementTargetId = $(element).attr('data-bs-target');

            var targetElement = document.querySelector(elementTargetId);
            if (elementTargetId !== currentElementTargetId) {
                $(element).addClass('collapsed');
                if ($(targetElement)) {
                    $(targetElement).removeClass('show');
                }
            }
        });
    });

    $(document.querySelectorAll('[data-parent="#shipmentGroup"')).on("click", function (e) {
        var collapsableElements = document.querySelectorAll('[data-parent="#shipmentGroup"');
        collapsableElements.forEach(element => {
            var currentElementTargetId = $(e.currentTarget).attr('data-bs-target');
            var elementTargetId = $(element).attr('data-bs-target');
            var targetElement = document.querySelector(elementTargetId);
            if (elementTargetId !== currentElementTargetId) {
                $(element).addClass('collapsed');
                if ($(targetElement)) {
                    $(targetElement).removeClass('show');
                }
            }
        });
    });

    return (
        <>
            {warehouses && warehouses.length > 0 && warehouses.map((warehouse, index) => {
                let wareHouseLogo = warehouse.logo && warehouse.logo.length > 0 ? warehouse.logo : defaultWarehouselogo;
                let isWarehouseSuitDefault = (warehouse.customerMainSellerId && warehouse.warehouseShippings.find(x => x.isSelected)?.isDefault) || false;
                let isSellerDopingDefault = warehouse.isDefault || false;
                let IsDefaultWarehouse = warehouse.isAnyWarehouseSuitIsDefault === true && isWarehouseSuitDefault === true ? true : (!warehouse.isAnyWarehouseSuitIsDefault ? warehouse.isDefault : false);
                let customerSuitName = warehouse.warehouseShippings.find(x => x.wareHouseSuitId?.length > 0)?.suitName || "";

                let existedSuitName = "";
                warehouses.forEach(element => {
                    if (element.warehouseShippings?.length > 0) {
                        element.warehouseShippings.forEach(shippingElement => {
                            if (shippingElement.suitName?.length > 0) {
                                existedSuitName = shippingElement.suitName;
                            }
                        });
                    }
                });

                let newSuitName = existedSuitName || ("SD-" + (generatePassword(6)).toUpperCase());
                return (

                    <div key={(index + 1)} className={`position-relative row row-md row-lg border rounded m-1 ${IsDefaultWarehouse ? "border-success" : "border-gray-300"}`} >
                        <div className="col-12 px-0">
                            <div className={`row my-0 ms-0 me-0 px-0 bg-info bg-opacity-10`}>

                                <div className="position-absolute" style={{ top: "-1px", left: "0px" }}>
                                    <div className="row m-0">
                                        <div className="col-auto ps-0">
                                            {isSellerDopingDefault && <span className="badge bg-success p" >SellerDoping Default</span>}
                                        </div>
                                        <div className="col-auto ps-0">
                                            {isWarehouseSuitDefault && <span className="badge bg-teal " >Admin Default</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-auto m-auto" style={{ minWidth: "66px" }}>
                                    <Restricted allowedTo={[
                                        REACT_APP_ROLES_GUEST,
                                        REACT_APP_ROLES_ADMIN,
                                        REACT_APP_ROLES_SUSPENDED_ADMIN,
                                        REACT_APP_ROLES_USER
                                    ]}>
                                        {warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0 &&
                                            <>
                                                <label key={(componentKey + "_label_" + (index + 1))} className="custom-switch cursor-pointer">
                                                    <input id={(componentKey + "_input_" + (index + 1))} key={(componentKey + "_input_" + (index + 1))} value="" className="form-check-input" placeholder="" name={("warehouse_" + (index + 1))} type="checkbox"
                                                        checked={(warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0 && warehouse.warehouseShippings.find(x => x.isSelected)) || false}
                                                        onChange={(e) => {
                                                            if (!e.currentTarget.checked) {
                                                                deleteCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseSuitId, warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseShippingId);
                                                            }
                                                            else {
                                                                if (warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0) {
                                                                    saveCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, warehouse.warehouseShippings[0].wareHouseShippingId, newSuitName);
                                                                    // addRemoveShipments(warehouse.id, warehouse.shipmentList[0].id, true);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    {/* <DrSweetAlertButton
                                                        className={`ms-2 btn btn-danger btn-wave waves-effect waves-light shadow btn-sm`}
                                                        buttonText=""
                                                        buttonType={buttonTypes.checkBox}
                                                        id={(componentKey + "_input_" + (index + 1))} key={(componentKey + "_input_" + (index + 1))} value=""
                                                        placeholder="" name={("warehouse_" + (index + 1))} type="checkbox"
                                                        checked={(warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0 && warehouse.warehouseShippings.find(x => x.isSelected)) || false}

                                                        onCloseConfirmCallBack={(e) => {
                                                            if (!e.checked) {
                                                                deleteCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseSuitId, warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseShippingId);
                                                            }
                                                            else {
                                                                if (warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0) {
                                                                    saveCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, warehouse.warehouseShippings[0].wareHouseShippingId, e.value);
                                                                    // addRemoveShipments(warehouse.id, warehouse.shipmentList[0].id, true);
                                                                }
                                                            }
                                                        }}
                                                        sweetAlertVariant={sweetAlertVariants.inputTextAlert}
                                                    >
                                                    </DrSweetAlertButton> */}
                                                </label>

                                            </>

                                        }
                                    </Restricted>
                                </div>
                                <label id={(componentKey + "_shipments_" + (index + 1))} className="col m-0 cursor-pointer collapsed" data-parent="#warehouseGroup" data-bs-toggle="collapse" data-bs-target={("#warehouse_" + (warehouse.id))} >
                                    <div className="row h-75 my-auto justify-content-center">
                                        <div className="col-md-5 col-sm-4 col-lg-3 col-xl-2 py-4 d-flex">
                                            <label className="m-auto cursor-pointer">
                                                <img
                                                    src={wareHouseLogo}
                                                    alt={warehouse.wareHouseName}
                                                    style={{ height: '25px' }}
                                                />
                                            </label>
                                        </div>
                                        <div className="col-auto  py-4 d-flex">
                                            <h4 className="my-auto mx-2">{allMarkets?.find(m => m.id === warehouse.marketId)?.countryCode}</h4>
                                        </div>
                                        <div className="col py-4">
                                            <h4 className="my-auto mx-2">
                                                {warehouse.wareHouseName}
                                                <i className="fe fe-info text-teal ms-2" style={{ width: "12px" }} data-bs-placement="right" data-bs-toggle="tooltip"
                                                    title={warehouse.addressLine1 + " " + warehouse.postalCode + (warehouse.addressLine1 ? ", " : "") + warehouse.district + " " + warehouse.stateOrRegion + " " + warehouse.phone}>
                                                </i>
                                            </h4>
                                        </div>
                                        <Restricted allowedTo={[
                                            REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN]
                                        }>
                                            {customerSuitName &&
                                                <div className="col-auto col-xl-3 py-4 d-flex">
                                                    <div className="row">
                                                        <div className="col-auto px-0 my-auto">
                                                            <span className="fw-bold text-muted fs-11">
                                                                SuitName:
                                                            </span>
                                                        </div>
                                                        <div className="col-auto ps-0 my-auto">
                                                            <span className="text-success fs-11">{customerSuitName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Restricted>
                                        {/* <div className="col-auto col-xl-3 my-auto">
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className="custom-switch-description">{warehouse.webSiteLink}</small>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className="custom-switch-description">{warehouse.phoneNumber}</small>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row h-25">
                                        <div className="col my-auto">
                                            <small className="my-auto mx-2 text-muted">
                                                {warehouse.addressLine1 + " " + warehouse.postalCode + (warehouse.addressLine1 ? ", " : "") + warehouse.district + " " + warehouse.stateOrRegion + " " + warehouse.phone}
                                            </small>
                                            <i className="ms-1 bx bxs-copy bx-xs bx-burst-hover fw-bold text-success cursor-pointer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigator.clipboard.writeText((warehouse.addressLine1 + " " + warehouse.postalCode + (warehouse.addressLine1 ? ", " : "") + warehouse.district + " " + warehouse.stateOrRegion + " " + warehouse.phone));
                                                    toastr.info("Info", "Warehouse address is copied to clipboard", { timeOut: 1000 })
                                                }} > </i>
                                        </div>
                                    </div>
                                </label>
                                <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                    {!warehouse.isDefault && <label className={`col-auto fs-12 fs-bold px-0 my-auto me-2 cursor-pointer ripple ${!IsDefaultWarehouse ? "text-primary" : "text-danger"}`}
                                        onClick={(e) => {
                                            //dispatch(withActionPromise(deleteWarehouse(warehouse.id)));
                                            e.preventDefault();
                                            // set süper admin default
                                            if (!warehouse.isDefault) {
                                                // set 
                                                dispatch(withActionPromise(updateWarehouseDefault({ id: warehouse.id })))
                                            } else {
                                                //remove default
                                            }

                                        }}
                                    >
                                        {!warehouse.isDefault ? "Set as Default" : "Remove Default"}
                                    </label>}
                                </Restricted>
                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN]}>
                                    {selectedStore?.customerMainSellerId && warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseSuitId &&
                                        <label className={`col-auto fs-12 fs-bold px-0 my-auto me-2 cursor-pointer ripple ${!isWarehouseSuitDefault ? "text-primary" : "text-danger"}`}
                                            onClick={() => {

                                                var requestData = {
                                                    id: warehouse.warehouseShippings.find(x => x.isSelected)?.wareHouseSuitId,
                                                    customerMainSellerId: warehouse.customerMainSellerId,
                                                    isDefault: !isWarehouseSuitDefault
                                                }
                                                dispatch(withActionPromise(updateWarehouseSuitDefault(requestData)));

                                            }}
                                        >
                                            {!isWarehouseSuitDefault ? "Set as Default" : "Remove Default"}
                                        </label>
                                    }
                                </Restricted>
                                <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                    <label className="col-auto px-0 my-auto me-2 cursor-pointer ripple collapsed" data-parent="#warehouseGroup" data-bs-toggle="collapse" data-bs-target={("#warehouseDetail_" + warehouse.id)} >
                                        <i className="fe fe-edit fs-16 text-teal" style={{ fontSize: '1.2em', color: 'var(--primary-bg-color)' }}></i>
                                    </label>
                                </Restricted>
                                <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                    <label className="col-auto px-0 my-auto me-2 cursor-pointer ripple"
                                        onClick={() => {
                                            dispatch(withActionPromise(deleteWarehouse(warehouse.id)));
                                        }}
                                    >
                                        <i className="fe fe-trash-2 fs-16 text-danger"></i>
                                    </label>
                                </Restricted>
                            </div>
                            <div className="row m-1 justify-content-end collapse" id={("warehouse_" + (warehouse.id))}>

                                <div className="col-md-11 col-lg-11 col-sm-12 col-xs-12 col-12 py-2 px-0">
                                    {/* <hr className="my-2"></hr> */}
                                    {warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0 && warehouse.warehouseShippings.sort((a, b) => a.shippingName.localeCompare(b.shippingName)).map((shipment, index2) => {
                                        var shipmentLogo = shipment.logo && shipment.logo.length > 0 ? shipment.logo : defaultWarehouselogo;
                                        console.log("shipment", shipment);

                                        return (
                                            <div className="border border-gray-300 rounded mb-1 bg-info bg-opacity-10 " key={(componentKey + "_shipment_" + (index2 + 1))} >
                                                <div className="row m-0 py-1">
                                                    <div className="col-auto my-auto" style={{ minWidth: "66px" }}>
                                                        <Restricted allowedTo={[
                                                            REACT_APP_ROLES_GUEST,
                                                            REACT_APP_ROLES_USER,
                                                            REACT_APP_ROLES_ADMIN,
                                                            REACT_APP_ROLES_SUSPENDED_ADMIN]
                                                        }>
                                                            <label htmlFor={(componentKey + "_carrier_" + (index + 1) + "_" + (index2 + 1))} className="custom-switch cursor-pointer ms-2">

                                                                {/* {suitName?.length > 0 && */}
                                                                <input id={(componentKey + "_carrier_" + (index + 1) + "_" + (index2 + 1))} className="form-check-input" placeholder="" name={("warehouse_" + (index + 1) + "_selection")} type="checkbox"
                                                                    value=""
                                                                    checked={shipment.isSelected}
                                                                    onChange={(e) => {
                                                                        if (e.currentTarget.checked) {
                                                                            saveCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, shipment.wareHouseShippingId, newSuitName);
                                                                        }
                                                                    }}
                                                                />
                                                                {/* } */}

                                                                {/* {!(suitName?.length > 0) &&
                                                                    <DrSweetAlertButton
                                                                        buttonText=""
                                                                        buttonType={buttonTypes.checkBox}
                                                                        id={(componentKey + "_carrier_" + (index + 1) + "_" + (index2 + 1))} placeholder="" name={("warehouse_" + (index + 1) + "_selection")}
                                                                        checked={shipment.isSelected}

                                                                        onCloseConfirmCallBack={(e) => {
                                                                            if (e.checked) {
                                                                                saveCustomerWarehouseSuit(warehouse.id, warehouse.customerMainSellerId, shipment.wareHouseShippingId, e.value);
                                                                            }
                                                                        }}
                                                                        sweetAlertVariant={sweetAlertVariants.inputTextAlert}
                                                                    >
                                                                    </DrSweetAlertButton>} */}
                                                            </label>
                                                        </Restricted>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span>
                                                            <label className="ms-2 my-auto text-center" style={{ minWidth: "130px", maxWidth: "130px" }}>
                                                                <img src={shipmentLogo} className="ms-1" alt="" />
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <label htmlFor={(componentKey + "_carrier_" + (index + 1) + "_" + (index2 + 1))} className="row m-0 h-100">
                                                            <span className="col-auto fw-bold my-auto">{shipment.shippingName}</span>
                                                            {/* <span className="col-auto small">DDP</span>
                                                            <span className="col-auto small">$11.73'dan başlayan fiyatlar.</span> */}
                                                            {!warehouse.isManual &&
                                                                <>
                                                                <span className="col-auto fw-bold my-auto text-danger">Carrier :</span>
                                                                    <span className="col-auto fw-bold my-auto px-0">{shipment.carrierCode}</span>
                                                                    <span className="col-auto fw-bold my-auto text-danger">Carrier Service :</span>
                                                                    <span className="col-auto fw-bold my-auto px-0">{shipment.carrierServiceCode}</span>
                                                                </>
                                                            }

                                                        </label>
                                                    </div>
                                                    <div className="col-auto px-0 me-1 my-auto">
                                                        <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                                            <label className="col-auto px-0 my-auto me-2 cursor-pointer ripple collapsed" data-parent="#shipmentGroup" data-bs-toggle="collapse" data-bs-target={("#warehouseShippingDetail_" + warehouse.id + "_" + shipment.wareHouseShippingId)} >
                                                                <i className="fe fe-edit fs-16 text-teal"></i>
                                                            </label>
                                                        </Restricted>
                                                    </div>
                                                    <div className="col-auto px-0 me-2 my-auto">
                                                        <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                                            <label className="col-auto px-0 my-auto me-2 cursor-pointer ripple"
                                                                onClick={() => {
                                                                    var data = {
                                                                        customerMainSellerId: warehouse.customerMainSellerId,
                                                                        wareHouseShippingId: shipment.wareHouseShippingId
                                                                    }
                                                                    dispatch(withActionPromise(deleteWarehouseShipping(data)));
                                                                }}
                                                            >
                                                                <i className="fe fe-trash-2 fs-16 text-danger"></i>
                                                            </label>

                                                        </Restricted>
                                                    </div>
                                                </div>
                                                <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                                    <DrNewWarehouseShipping
                                                        source={{
                                                            ...shipment,
                                                            warehouseId: warehouse.id,
                                                            mapWareHouseId: shipment.mapWareHouseId,
                                                            carrierCode: shipment.carrierCode,
                                                            carrierServiceCode: shipment.carrierServiceCode,
                                                            isManual: warehouse.isManual
                                                        }}
                                                        shippingOptions={allShippings.map(x => ({
                                                            value: x.id, label: x.shippingName,
                                                            icon: <img src={x.logo} style={{ height: 16 }} className="w-6 me-2" alt="" />
                                                        }))}
                                                        shipstationWarehouses={shipstationWarehouses}
                                                        shipstationCarriers={shipstationCarriers} >
                                                    </DrNewWarehouseShipping>
                                                </Restricted>
                                            </div>

                                        )
                                    })}
                                    <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                                        <div className="row row-md row-lg border border-gray-100 rounded m-0">
                                            <div className="col-12 px-0">
                                                <div className="row my-0 ms-0 me-0 bg-gray-200 px-0 justify-content-center" >
                                                    <label className="py-1 mb-0 w-100 text-center col-auto cursor-pointer ripple collapsed" data-parent="#shipmentGroup" data-bs-toggle="collapse" data-bs-target={("#warehouseShippingDetail_" + warehouse.id + "_")} >
                                                        <i className="fe fe-plus-circle fs-32 text-teal" style={{ fontSize: '1.9em', color: 'var(--primary-bg-color)' }}></i>
                                                    </label>
                                                </div>
                                                <DrNewWarehouseShipping
                                                    source={{
                                                        warehouseId: warehouse.id,
                                                        mapWareHouseId: 0,
                                                        carrierCode: "",
                                                        carrierServiceCode: "",
                                                        isManual: warehouse.isManual
                                                    }}
                                                    shippingOptions={allShippings.map(x => ({
                                                        value: x.id, label: x.shippingName,
                                                        icon: <img src={x.logo} style={{ height: 16 }} className="w-6 me-2" alt="" />
                                                    }))}
                                                    shipstationWarehouses={shipstationWarehouses}
                                                    shipstationCarriers={shipstationCarriers} >
                                                </DrNewWarehouseShipping>
                                            </div>
                                        </div>
                                    </Restricted>
                                </div>

                            </div>
                            <div className="row m-0 justify-content-end ">
                                <div className="col-md-11 col-lg-11 col-sm-12 col-xs-12 col-12 m-0 py-0 px-0">
                                    <DrNewWarehouse mapWareHouseSource={source.mapWareHouseSource} warehouse={warehouse}></DrNewWarehouse>
                                </div>
                            </div>

                        </div>
                    </div>

                )
            })}
            <Restricted allowedTo={[REACT_APP_ROLES_SUPERADMIN]}>
                <div key="add-new" className="row row-md row-lg border border-gray-300 rounded m-1">
                    <div className="col-12 px-0">
                        <div className="row my-0 ms-0 me-0 bg-gray-200 px-0 justify-content-center" >
                            <label className="py-2 mb-0 w-100 text-center col-auto cursor-pointer collapsed ripple" data-parent="#warehouseGroup" data-bs-toggle="collapse" data-bs-target={("#warehouseDetail_")} >
                                <i className="fe fe-plus-circle fs-32 text-teal"></i>
                            </label>
                        </div>

                        <DrNewWarehouse mapWareHouseSource={source.mapWareHouseSource} ></DrNewWarehouse>

                    </div>
                </div>
            </Restricted>
        </>

    )
}

export default DrWarehouseSelection