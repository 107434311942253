
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrDualDatePicker, DrPageFilter, DrReadMore } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants, modals } from '../../utils/constants';
import { setActiveModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import i18next from 'i18next';
import { timeOptions, transactionSummaryTypeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { getTransactionSummary, getWalletInfo, setSelectedCreditCard } from '../../state-management/actions/wallet.actions';
import { getCardNumber } from '../../utils/utils';
import Card from "react-credit-cards-2";
import { getRouteByKey } from '../../routes';
import { setMyWalletPageSelections } from '../../state-management/actions/settings.actions';

function MyWalletPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('walletPage');
    const pageSelections = useSelector(state => state.settings?.pageSelections?.walletPageSelections);
    const walletInfo = useSelector(state => state.wallet.walletInfo);
    const selectedCreditCard = useSelector(state => state.wallet.selectedCreditCard);
    const transactionSummary = useSelector(state => state.wallet.transactionSummary);
    const [transactionSummaryData, setTransactionSummaryData] = useState([]);

    useEffect(() => {
        dispatch(setSelectedCreditCard(null));
        dispatch(getWalletInfo());
        dispatch(getTransactionSummary());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (walletInfo && walletInfo.myCards && selectedCreditCard?.cardId) {
            dispatch(setSelectedCreditCard(walletInfo.myCards?.find(x => x.cardId === selectedCreditCard?.cardId)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [walletInfo]);

    useEffect(() => {

        let localDate = new Date();
        let compareDateStart = pageSelections?.dateInterval?.length > 0 ? (pageSelections.dateInterval[0] && moment.utc(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
        let compareDateEnd = pageSelections?.dateInterval?.length > 1 ? (pageSelections.dateInterval[1] && moment.utc(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;

        let requestData = {
            transactionType: pageSelections?.transactionSummaryTypeOption || null,
            startDate: compareDateStart,
            endDate: compareDateEnd,
        }

        dispatch(getTransactionSummary(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.dateInterval, pageSelections?.transactionSummaryTypeOption]);

    const columns = [
        { data: "id", title: "", width: "10%", minWidth: "45px", maxWidth: "45px", searchable: false, checkall: true, checkData: "id" },
        { data: "transactionSource", title: t('myWalletPage.dataTable.transactionSource'), width: "20%", minWidth: "100px", searchable: true },
        { data: "transactionType", title: t('myWalletPage.dataTable.type'), width: "20%", minWidth: "100px", maxWidth: "100px", searchable: false },
        { data: "currency", title: t('myWalletPage.dataTable.currency'), width: "20%", minWidth: "100px", maxWidth: "100px", searchable: true },
        { data: "transactionDate", title: t('myWalletPage.dataTable.date'), width: "25%", minWidth: "130px", maxWidth: "130px", searchable: true },
        { data: "description", title: t('myWalletPage.dataTable.description'), width: "25%", minWidth: "130px", maxWidth: "130px", searchable: true },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <input type="checkbox" className="ckbox ms-3" name="name" value={rowData.id}
                        onChange={(e) => {
                            e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                        }}
                    />)
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.transactionSource}</span>
                    </>)
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className={`badge cursor-pointer ${rowData.transactionType === "1" ? "bg-success" : "bg-danger"}`}
                            data-bs-placement="right" data-bs-toggle="tooltip"
                            title={rowData.description}>
                            {rowData.transactionType === "0" ? "Income" : "Outgoing"}
                        </span>
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className={`badge ${rowData.transactionType.toString() === "1" ? "bg-success" : "bg-danger text-line-through"}`}>
                            {rowData.currencyCode}{rowData.transactionType.toString() === "1" ? " " : " -"}{Number(rowData.currency).toFixed(3)}
                        </span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {rowData.transactionDate && moment.utc(rowData.transactionDate).format('L LT')}
                        </span><br />

                    </div>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <DrReadMore max={25} text={rowData.description} classes='fs-12'
                            data-bs-placement="right" data-bs-toggle="tooltip"
                            title={rowData.description}
                        ></DrReadMore>

                    </>);
            }
        }

    ];

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

    }

    const optionClickCallBack = (value) => {
        dispatch(withActionPromise(setMyWalletPageSelections({
            dateInterval: pageSelections?.dateInterval,
            transactionSummaryTypeOption: value
        })));
    };

    const timeIntervalOptionCallBack = (dateValues) => {
        dispatch(withActionPromise(setMyWalletPageSelections({
            dateInterval: dateValues,
            transactionSummaryTypeOption: pageSelections?.transactionSummaryTypeOption
        })));
    };

    return (
        <div style={{ marginBottom: '150px' }}>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.myWallet'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row mb-2">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card p-2 bg-primary bg-opacity-75 my-0 shadow">
                        <div className="card-body horizontal-scrollable">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <div className="row">
                                        <div className="col-auto px-1">
                                            <span className="fs-20 fw-bold text-white">{t('myWalletPage.myBalance')}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto px-1 py-auto my-auto">
                                            <i className="fe fe-dollar-sign fs-20 text-white"></i>
                                        </div>
                                        <div className="col-auto px-0 py-auto my-auto">
                                            <span className="fs-32 fw-bold text-white">{walletInfo?.totalBalance || 0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto my-auto">
                                    <button className="btn btn-teal btn-wave waves-effect waves-light  btn-lg rounded-pill pulse pulse-danger"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(withActionPromise(setSelectedCreditCard(null)));
                                            navigate(getRouteByKey("MY_WALLET_PAYMENT_METHOD"));
                                        }} >
                                        <i className="me-2 fe fe-plus"></i>
                                        {t('myWalletPage.depositCash')}
                                    </button>
                                </div>
                            </div>
                            <div className="row flex-nowrap overflow-x  justify-content-start">
                                <div className="col-12 container testimonial-group no-select">
                                    <div className="row py-4">
                                        {walletInfo && walletInfo.myCards?.length > 0 && walletInfo.myCards?.sort((b, a) => a.isDefault - b.isDefault).map((card, index) => {
                                            return (
                                                <>
                                                    <div key={"mycard_" + index} className="col-auto no-select cursor-pointer btn ripple" style={{ minWidth: "240px" }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            dispatch(withActionPromise(setSelectedCreditCard(card)));
                                                            dispatch(setActiveModal({
                                                                name: modals.creditCard,
                                                                isGlobal: true,
                                                                title: t('myWalletPage.creditCardModal.title'),
                                                                header: "",
                                                                text: "",
                                                                issuer: "visa"
                                                            }))
                                                        }} >
                                                        {card.isDefault && <i className="fa fa-star tx-20 tx-light-yellow" style={{ position: "absolute", top: "7px", left: "17px", zIndex: "1500" }}></i>}
                                                        <Card
                                                            preview={true}
                                                            number={getCardNumber(card.cardNumber)}
                                                            name={card.cardHolderName}
                                                            expiry=""
                                                            cvc=""
                                                            locale={{ valid: "valid thru" }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-end mb-2">

                <div className="col-auto">
                    <DrButtonDropdown className="shadow" options={transactionSummaryTypeOptions}
                        optionClickCallBack={optionClickCallBack}
                        selectedValue={pageSelections?.transactionSummaryTypeOption || "0"}
                        variant={buttonVariants.primary}
                    >
                    </DrButtonDropdown>
                </div>

            </div>
            <div id="transactionTable" className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            <DrDataTable
                                data={transactionSummary}
                                columns={columns}
                                columnDefs={columnDefs}
                                doesHaveBorder={false}
                                headerSearch={true}
                                //headerSelectbox={true}
                                order={[[4, 'desc']]}
                                search={true}
                                minWidth="850px"
                                isMultiSelectEnabled={true}
                                detailedSearchChildren={
                                    <DrPageFilter
                                        isSearchSavingActive={false}
                                        isSearchActive={true}
                                        selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                        timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                        selectedDates={pageSelections?.dateInterval}
                                    >
                                    </DrPageFilter>
                                }
                            />


                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default MyWalletPage