
import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrDataTable, DrPageFilter, DrPagination } from '../../components/component-index';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// import { salesGeneralReport as tableData } from '../../utils/reports-mock-data';
import { deliveryReportColumns, profitReportColumns, salesGeneralReportColumns, salesReportColumns, summarySalesReportColumns } from '../../components/hoc/Constants/dr-constants.component';
import { generatePdfReport, includeFieldsFromArray } from '../../utils/utils';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryReport, getProfitReport, getSalesGeneralReport, getSalesReport, getSalesSummaryReport } from '../../state-management/actions/reports.actions';
import { pageFilterTypes } from '../../utils/constants';
import { ExcelExport2 } from '../../excelexport';
import { setSalesDeliveryReportPageSelections, setSalesGeneralReportPageSelections, setSalesProfitReportPageSelections, setSalesReportPageSelections, setSalesSummaryReportPageSelections } from '../../state-management/actions/settings.actions';
import moment from 'moment';
import { apiPost, CONTROLLERS } from '../../utils/axiosApiCalls';

function AllReportsPage() {
    const { t } = useTranslation('allReportsPage');
    const { reportType } = useParams();
    const dispatch = useDispatch();
    const language = useSelector(state => state.settings.language);
    const tableData = useSelector(state => state.reports.reportData);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allReportsPageSelections = useSelector(state => state.settings?.pageSelections?.allReportsPageSelections);
    const [navigationText, setNavigationText] = useState([]);

    const [currentTableColumns, setCurrentTableColumns] = useState([]);
    const [currentReportUniqueIdField, setCurrentReportUniqueIdField] = useState("");
    const [chooseColumnsData, setChooseColumnsData] = useState([]);
    const [columnsSalesGeneralReport, setColumnsSalesGeneralReportColumns] = useState(salesGeneralReportColumns);
    const [columnsSalesReportColumns, setColumnsSalesReportColumns] = useState(salesReportColumns);
    const [columnsSummarySalesReport, setSummarySalesReportColumns] = useState(summarySalesReportColumns);
    const [columnsProfitReport, setProfitReportColumns] = useState(profitReportColumns);
    const [columnsDeliveryReport, setDeliveryReportColumns] = useState(deliveryReportColumns);
    const [checkAllData, setCheckAllData] = useState(null);

    useEffect(() => {
        if (!reportType || reportType === "sales-general-report") {
            setNavigationText(i18next.t('constants:breadCrumps.salesGeneralReport'));
        }
        else if (reportType === "sales-report") {
            setNavigationText(i18next.t('constants:breadCrumps.salesReport'));
        }
        else if (reportType === "summary-sales-report") {
            setNavigationText(i18next.t('constants:breadCrumps.summarySalesReport'));
        }
        else if (reportType === "profit-report") {
            setNavigationText(i18next.t('constants:breadCrumps.profitReport'));
        }
        else if (reportType === "delivery-report") {
            setNavigationText(i18next.t('constants:breadCrumps.deliveryReport'));
        }
        setCheckAllData(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType]);


    const getReportRequest = () => {

        let localDate = new Date();
        let compareDateStart = null;
        let compareDateEnd = null;
        let requestData = null;
        if (!reportType || reportType === "sales-general-report") {

            compareDateStart = allReportsPageSelections?.salesGeneralReport?.dateInterval?.length > 0 ? (allReportsPageSelections?.salesGeneralReport?.dateInterval[0] && moment.utc(allReportsPageSelections?.salesGeneralReport?.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            compareDateEnd = allReportsPageSelections?.salesGeneralReport?.dateInterval?.length > 1 ? (allReportsPageSelections?.salesGeneralReport?.dateInterval[1] && moment.utc(allReportsPageSelections?.salesGeneralReport?.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;
            requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                skip: allReportsPageSelections?.salesGeneralReport?.tableSkip,
                pageSize: allReportsPageSelections?.salesGeneralReport?.pagelength !== -1 ? allReportsPageSelections?.salesGeneralReport?.pagelength : tableData?.totalRecordCount
            };
            if (compareDateStart) {
                requestData.startDate = compareDateStart;
            }
            if (compareDateEnd) {
                requestData.endDate = compareDateEnd;
            }
        }
        else if (reportType === "sales-report") {

            compareDateStart = allReportsPageSelections?.salesReport?.dateInterval?.length > 0 ? (allReportsPageSelections?.salesReport?.dateInterval[0] && moment.utc(allReportsPageSelections?.salesReport?.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            compareDateEnd = allReportsPageSelections?.salesReport?.dateInterval?.length > 1 ? (allReportsPageSelections?.salesReport?.dateInterval[1] && moment.utc(allReportsPageSelections?.salesReport?.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;
            requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                skip: allReportsPageSelections?.salesReport?.tableSkip,
                pageSize: allReportsPageSelections?.salesReport?.pagelength !== -1 ? allReportsPageSelections?.salesReport?.pagelength : tableData?.totalRecordCount
            };
            if (compareDateStart) {
                requestData.startDate = compareDateStart;
            }
            if (compareDateEnd) {
                requestData.endDate = compareDateEnd;
            }
        }
        else if (reportType === "summary-sales-report") {


            compareDateStart = allReportsPageSelections?.summarySalesReport?.dateInterval?.length > 0 ? (allReportsPageSelections?.summarySalesReport?.dateInterval[0] && moment.utc(allReportsPageSelections?.summarySalesReport?.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            compareDateEnd = allReportsPageSelections?.summarySalesReport?.dateInterval?.length > 1 ? (allReportsPageSelections?.summarySalesReport?.dateInterval[1] && moment.utc(allReportsPageSelections?.summarySalesReport?.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;
            requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                skip: allReportsPageSelections?.summarySalesReport?.tableSkip,
                pageSize: allReportsPageSelections?.summarySalesReport?.pagelength !== -1 ? allReportsPageSelections?.summarySalesReport?.pagelength : tableData?.totalRecordCount
            };
            if (compareDateStart) {
                requestData.startDate = compareDateStart;
            }
            if (compareDateEnd) {
                requestData.endDate = compareDateEnd;
            }
        }
        else if (reportType === "profit-report") {


            compareDateStart = allReportsPageSelections?.profitReport?.dateInterval?.length > 0 ? (allReportsPageSelections?.profitReport?.dateInterval[0] && moment.utc(allReportsPageSelections?.profitReport?.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            compareDateEnd = allReportsPageSelections?.profitReport?.dateInterval?.length > 1 ? (allReportsPageSelections?.profitReport?.dateInterval[1] && moment.utc(allReportsPageSelections?.profitReport?.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;
            requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                skip: allReportsPageSelections?.profitReport?.tableSkip,
                pageSize: allReportsPageSelections?.profitReport?.pagelength !== -1 ? allReportsPageSelections?.profitReport?.pagelength : tableData?.totalRecordCount
            };
            if (compareDateStart) {
                requestData.startDate = compareDateStart;
            }
            if (compareDateEnd) {
                requestData.endDate = compareDateEnd;
            }
        }
        else if (reportType === "delivery-report") {


            compareDateStart = allReportsPageSelections?.deliveryReport?.dateInterval?.length > 0 ? (allReportsPageSelections?.deliveryReport?.dateInterval[0] && moment.utc(allReportsPageSelections?.deliveryReport?.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            compareDateEnd = allReportsPageSelections?.deliveryReport?.dateInterval?.length > 1 ? (allReportsPageSelections?.deliveryReport?.dateInterval[1] && moment.utc(allReportsPageSelections?.deliveryReport?.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;
            requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                skip: allReportsPageSelections?.deliveryReport?.tableSkip,
                pageSize: allReportsPageSelections?.deliveryReport?.pagelength !== -1 ? allReportsPageSelections?.deliveryReport?.pagelength : tableData?.totalRecordCount
            };
            if (compareDateStart) {
                requestData.startDate = compareDateStart;
            }
            if (compareDateEnd) {
                requestData.endDate = compareDateEnd;
            }
        }

        return requestData;
    }

    useEffect(() => {
        let requestData = getReportRequest();
        if (!reportType || reportType === "sales-general-report") {
            dispatch(getSalesGeneralReport(requestData));
        }
        else if (reportType === "sales-report") {
            dispatch(getSalesReport(requestData));
        }
        else if (reportType === "summary-sales-report") {
            dispatch(getSalesSummaryReport(requestData))
        }
        else if (reportType === "profit-report") {
            dispatch(getProfitReport(requestData))
        }
        else if (reportType === "delivery-report") {
            dispatch(getDeliveryReport(requestData))
        }
        setCheckAllData(null);
        // setTableData(salesGeneralReport);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType,
        allReportsPageSelections?.salesGeneralReport?.dateInterval, allReportsPageSelections?.salesGeneralReport?.tableSkip, allReportsPageSelections?.salesGeneralReport?.pagelength,
        allReportsPageSelections?.salesReport?.dateInterval, , allReportsPageSelections?.salesReport?.tableSkip, allReportsPageSelections?.salesReport?.pagelength,
        allReportsPageSelections?.summarySalesReport?.dateInterval, , allReportsPageSelections?.summarySalesReport?.tableSkip, allReportsPageSelections?.summarySalesReport?.pagelength,
        allReportsPageSelections?.profitReport?.dateInterval, , allReportsPageSelections?.profitReport?.tableSkip, allReportsPageSelections?.profitReport?.pagelength,
        allReportsPageSelections?.deliveryReport?.dateInterval, allReportsPageSelections?.deliveryReport?.tableSkip, allReportsPageSelections?.deliveryReport?.pagelength]);

    useEffect(() => {

        if (!reportType || reportType === "sales-general-report") {

            let currentAvailableColumns = salesGeneralReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ ...x, isNotVisible: allReportsPageSelections?.salesGeneralReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? false : true }))
            let chooseColumnData = salesGeneralReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ fieldName: x.data, title: x.title, value: allReportsPageSelections?.salesGeneralReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? true : false }));
            setColumnsSalesGeneralReportColumns(currentAvailableColumns);
            setCurrentTableColumns(currentAvailableColumns);
            setChooseColumnsData(chooseColumnData);
            setCurrentReportUniqueIdField(salesGeneralReportColumns.find(x => x.checkall)?.data);
        }
        else if (reportType === "sales-report") {

            let currentAvailableColumns = salesReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ ...x, isNotVisible: allReportsPageSelections?.salesReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? false : true }))
            let chooseColumnData = salesReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ fieldName: x.data, title: x.title, value: allReportsPageSelections?.salesReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? true : false }));
            setColumnsSalesReportColumns(currentAvailableColumns);
            setCurrentTableColumns(currentAvailableColumns);
            setChooseColumnsData(chooseColumnData);
            setCurrentReportUniqueIdField(salesReportColumns.find(x => x.checkall)?.data);
        }
        else if (reportType === "summary-sales-report") {
            let currentAvailableColumns = summarySalesReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ ...x, isNotVisible: allReportsPageSelections?.summarySalesReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? false : true }))
            let chooseColumnData = summarySalesReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ fieldName: x.data, title: x.title, value: allReportsPageSelections?.summarySalesReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? true : false }));
            setSummarySalesReportColumns(currentAvailableColumns);
            setCurrentTableColumns(currentAvailableColumns);
            setChooseColumnsData(chooseColumnData);
            setCurrentReportUniqueIdField(summarySalesReportColumns.find(x => x.checkall)?.data);
        }
        else if (reportType === "profit-report") {

            let currentAvailableColumns = profitReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ ...x, isNotVisible: allReportsPageSelections?.profitReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? false : true }))
            let chooseColumnData = profitReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ fieldName: x.data, title: x.title, value: allReportsPageSelections?.profitReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? true : false }));
            setProfitReportColumns(currentAvailableColumns);
            setCurrentTableColumns(currentAvailableColumns);
            setChooseColumnsData(chooseColumnData);
            setCurrentReportUniqueIdField(profitReportColumns.find(x => x.checkall)?.data);
        }
        else if (reportType === "delivery-report") {
            let currentAvailableColumns = deliveryReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ ...x, isNotVisible: allReportsPageSelections?.deliveryReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? false : true }))
            let chooseColumnData = deliveryReportColumns?.filter(x => x.data !== null && !x.checkall).map(x => ({ fieldName: x.data, title: x.title, value: allReportsPageSelections?.deliveryReport?.selectedColumns?.find(fieldName => fieldName === x.data) ? true : false }));
            setDeliveryReportColumns(currentAvailableColumns);
            setCurrentTableColumns(currentAvailableColumns);
            setChooseColumnsData(chooseColumnData);
            setCurrentReportUniqueIdField(deliveryReportColumns.find(x => x.checkall)?.data);

        }

        // setTableData(salesGeneralReport);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportType,
        allReportsPageSelections?.salesGeneralReport?.selectedColumns, salesGeneralReportColumns,
        allReportsPageSelections?.salesReport?.selectedColumns, salesReportColumns,
        allReportsPageSelections?.summarySalesReport?.selectedColumns, summarySalesReportColumns,
        allReportsPageSelections?.profitReport?.selectedColumns, profitReportColumns,
        allReportsPageSelections?.deliveryReport?.selectedColumns, deliveryReportColumns]);

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {
        let selectedDataIds = []
        let exportData = [];
        if (checkAllData && checkAllData.selectedValue === 1) {

            let requestData = getReportRequest();
            requestData.pageSize = tableData?.totalRecordCount || 0;
            requestData.skip = 0;

            if (!reportType || reportType === "sales-general-report") {
                apiPost(`/SalesGeneralReport`, requestData, false, CONTROLLERS.REPORTS, true, true)().then((response) => {
                    if (response && response.isSuccess && response.resultType === 1) {
                        exportData = Object.assign([], response.data?.data || []);
                        console.log("exportData", exportData)
                        ExportReport(exportData, selectedOperation);
                    }
                }).catch((err) => {

                });
            }
            else if (reportType === "sales-report") {
                apiPost(`/SalesReport`, requestData, false, CONTROLLERS.REPORTS, true, true)().then((response) => {
                    if (response && response.isSuccess && response.resultType === 1) {
                        exportData = Object.assign([], response.data?.data || []);
                        ExportReport(exportData, selectedOperation);
                    }
                }).catch((err) => {

                });
            }
            else if (reportType === "summary-sales-report") {
                apiPost(`/SummarySalesReport`, requestData, false, CONTROLLERS.REPORTS, true, true)().then((response) => {
                    if (response && response.isSuccess && response.resultType === 1) {
                        exportData = Object.assign([], response.data?.data || []);
                        ExportReport(exportData, selectedOperation);
                    }
                }).catch((err) => {

                });
            }
            else if (reportType === "profit-report") {
                apiPost(`/ProfitReport`, requestData, false, CONTROLLERS.REPORTS, true, true)().then((response) => {
                    if (response && response.isSuccess && response.resultType === 1) {
                        exportData = Object.assign([], response.data?.data || []);
                        ExportReport(exportData, selectedOperation);
                    }
                }).catch((err) => {

                });
            }
            else if (reportType === "delivery-report") {
                apiPost(`/DeliveryReport`, requestData, false, CONTROLLERS.REPORTS, true, true)().then((response) => {
                    if (response && response.isSuccess && response.resultType === 1) {
                        exportData = Object.assign([], response.data?.data || []);
                        ExportReport(exportData, selectedOperation);
                    }
                }).catch((err) => {

                });
            }
        }
        else {
            selectedDataIds = [...checkedValues];
            exportData = Object.assign([], tableData?.data?.data?.filter(item1 =>
                !!selectedDataIds.find(item2 => item1[currentReportUniqueIdField] === item2)) || []);
            ExportReport(exportData, selectedOperation);
        }


    }

    const ExportReport = (exportData, selectedOperation) => {

        if (selectedOperation === "0" || selectedOperation === "1" || selectedOperation === "22" || selectedOperation === "23") //export
        {
            if (selectedOperation === "0" || selectedOperation === "22") {
                let tableColumn = currentTableColumns.filter(c => c.isNotVisible !== (selectedOperation === "22" ? true : false) && !c.checkall).map(x => ({ header: x.title, key: x.data, width: "40" }));
                ExcelExport2({ excelData: includeFieldsFromArray(exportData, currentTableColumns.filter(c => c.isNotVisible !== (selectedOperation === "22" ? true : false) && !c.checkall).map(x => x.data)), columns: tableColumn, fileName: "report" });
            }
            else if (selectedOperation === "1" || selectedOperation === "23") {
                let tableColumn = currentTableColumns.filter(c => c.isNotVisible !== (selectedOperation === "23" ? true : false) && !c.checkall).map(x => ({ header: x.title, key: x.data, width: "40" }));

                let pageFormat = "a4";
                let pageOrientation = "l";
                if (tableColumn.length < 5) {
                    pageFormat = "a4";
                    pageOrientation = "p";
                }
                else if (tableColumn.length >= 5 && tableColumn.length < 10) {
                    pageOrientation = "l";
                }
                else if (tableColumn.length >= 10 && tableColumn.length < 15) {
                    pageFormat = "a3";
                }
                else if (tableColumn.length >= 15 && tableColumn.length < 20) {
                    pageFormat = "a2";
                }
                else if (tableColumn.length >= 20) {
                    pageFormat = "a1";
                }
                generatePdfReport({ columns: tableColumn, dataSource: includeFieldsFromArray(exportData, currentTableColumns.filter(c => c.isNotVisible !== (selectedOperation === "23" ? true : false) && !c.checkall).map(x => x.data)), title: "Report", format: pageFormat, orientation: pageOrientation, reportName: "report" });

            }
        }
    }

    const timeIntervalOptionCallBack = (dateValues) => {

        if (!reportType || reportType === "sales-general-report") {
            dispatch(setSalesGeneralReportPageSelections({ selectedColumns: allReportsPageSelections?.salesGeneralReport?.selectedColumns, dateInterval: dateValues }));
        }
        else if (reportType === "sales-report") {
            dispatch(setSalesReportPageSelections({ selectedColumns: allReportsPageSelections?.salesReport?.selectedColumns, dateInterval: dateValues }));
        }
        else if (reportType === "summary-sales-report") {
            dispatch(setSalesSummaryReportPageSelections({ selectedColumns: allReportsPageSelections?.summarySalesReport?.selectedColumns, dateInterval: dateValues }));
        }
        else if (reportType === "profit-report") {
            dispatch(setSalesProfitReportPageSelections({ selectedColumns: allReportsPageSelections?.profitReport?.selectedColumns, dateInterval: dateValues }));
        }
        else if (reportType === "delivery-report") {
            dispatch(setSalesDeliveryReportPageSelections({ selectedColumns: allReportsPageSelections?.deliveryReport?.selectedColumns, dateInterval: dateValues }));
        }

    };

    const onColumnsSelectedCallBack = (selectedColumns) => {
        if (!reportType || reportType === "sales-general-report") {
            dispatch(setSalesGeneralReportPageSelections({ selectedColumns: selectedColumns, dateInterval: allReportsPageSelections?.salesGeneralReport?.dateInterval }));
        }
        else if (reportType === "sales-report") {
            dispatch(setSalesReportPageSelections({ selectedColumns: selectedColumns, dateInterval: allReportsPageSelections?.salesReport?.dateInterval }));
        }
        else if (reportType === "summary-sales-report") {
            dispatch(setSalesSummaryReportPageSelections({ selectedColumns: selectedColumns, dateInterval: allReportsPageSelections?.summarySalesReport?.dateInterval }));
        }
        else if (reportType === "profit-report") {
            dispatch(setSalesProfitReportPageSelections({ selectedColumns: selectedColumns, dateInterval: allReportsPageSelections?.profitReport?.dateInterval }));
        }
        else if (reportType === "delivery-report") {
            dispatch(setSalesDeliveryReportPageSelections({ selectedColumns: selectedColumns, dateInterval: allReportsPageSelections?.deliveryReport?.dateInterval }));
        }

    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.reportingAndAnalysis'), navigationPath: "" },
                { navigationText: navigationText, navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row mb-2" >
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Toplam Ciro</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                                    <h5 className="text-success fw-bold fs-20 m-auto">{Number(tableData?.data?.totalEarnings || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Toplam Kar</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                                    <h5 className="text-success fw-bold fs-20 m-auto">{Number(tableData?.data?.totalProfit || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Teslim Edilen Sipariş Kar</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                                    <h5 className="text-success fw-bold fs-20 m-auto">{Number(tableData?.data?.totalDeliveredProfit || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Sipariş Ortalama Kar</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                                    <h5 className="text-success  fw-bold fs-20 m-auto">{Number(tableData?.data?.orderAvgProfit || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-2" >
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Sipariş Sayısı</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">

                                    <h5 className="text-success fw-bold fs-20 m-auto">{tableData?.data?.orderCount || 0}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-danger bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Toplam Maliyet</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-down text-danger fw-bold fs-24"></i>
                                    <h5 className="text-danger fw-bold fs-20 m-auto">{Number(tableData?.data?.totalCost || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-danger bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Sipariş Ortalama Maliyet</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-down text-danger fw-bold fs-24"></i>
                                    <h5 className="text-danger fw-bold fs-20 m-auto">{Number(tableData?.data?.orderAvgCost || 0).toFixed(2)}</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-xl-3 d-flex">
                    <div className="card custom-card transform-top-hover bg-success bg-opacity-10 shadow m-1">
                        <div className="card-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="row justify-content-start">
                                        <div className="col-auto">
                                            <h5 className="fw-semibold">Kar Yüzdesi</h5>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row justify-content-start">
                                <div className="col-auto d-flex ">
                                    <i className="fe fe-arrow-up text-success fw-bold fs-24"></i>
                                    <h5 className="text-success fw-bold fs-20 m-auto">{Number(tableData?.data?.profitAvgPercent || 0).toFixed(2)}%</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div id="reportTable" className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        {reportType === "sales-general-report" &&
                            <div className={`card-body horizontal-scrollable ${(reportType === "sales-general-report") ? "" : "d-none"}`}>
                                <DrDataTable
                                    id="dtableSalesGeneralReport"

                                    data={tableData?.data?.data}
                                    columns={salesGeneralReportColumns}
                                    availableColumns={columnsSalesGeneralReport}
                                    autoColumnDef={true}
                                    // columnDefs={createDatatableColumnDef(salesGeneralReportColumns)}
                                    doesHaveBorder={true}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    onCheckAllClicked={(e) => {

                                        if (e.checked) {
                                            let checkAllData = {
                                                selectedDataCount: e.count,
                                                filteredDataCount: tableData?.totalRecordCount || 0,
                                                selectedValue: 0
                                            }
                                            setCheckAllData(checkAllData);

                                        } else {
                                            setCheckAllData(null);
                                        }
                                    }}
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="Search..."
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={allReportsPageSelections?.salesGeneralReport?.dateInterval}
                                            filterType={pageFilterTypes.allReports}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            onColumnsSelectedCallBack={onColumnsSelectedCallBack}
                                            availableTableColumns={chooseColumnsData}
                                            checkAllData={checkAllData}
                                            checkAllSelectedOptionCallback={(value) => {
                                                setCheckAllData(value);
                                            }}

                                        >
                                        </DrPageFilter>
                                    }
                                    pageLength={allReportsPageSelections?.salesGeneralReport?.pagelength || 5}
                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (allReportsPageSelections?.salesGeneralReport?.tableSkip || 0),
                                                    totalRecordCount: tableData?.totalRecordCount || allReportsPageSelections?.salesGeneralReport?.tableLength,
                                                    tablePageLength: allReportsPageSelections?.salesGeneralReport?.pagelength || 5
                                                }
                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(setSalesGeneralReportPageSelections({ tableSkip: 0, pagelength: e }));

                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(setSalesGeneralReportPageSelections({ tableSkip: e }));
                                            }}
                                        >
                                        </DrPagination>
                                    }
                                />
                            </div>}
                        {reportType === "sales-report" &&
                            <div className={`card-body horizontal-scrollable ${(reportType === "sales-report") ? "" : "d-none"}`} >
                                <DrDataTable
                                    id="dtableSalesReport"
                                    data={tableData?.data?.data}
                                    columns={salesReportColumns}
                                    autoColumnDef={true}
                                    availableColumns={columnsSalesReportColumns}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="Search..."
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={allReportsPageSelections?.salesReport?.dateInterval}
                                            filterType={pageFilterTypes.allReports}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            onColumnsSelectedCallBack={onColumnsSelectedCallBack}
                                            availableTableColumns={chooseColumnsData}


                                        >
                                        </DrPageFilter>
                                    }
                                    pageLength={allReportsPageSelections?.salesReport?.pagelength || 5}
                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (allReportsPageSelections?.salesReport?.tableSkip || 0),
                                                    totalRecordCount: tableData?.totalRecordCount || allReportsPageSelections?.salesReport?.tableLength,
                                                    tablePageLength: allReportsPageSelections?.salesReport?.pagelength || 5
                                                }
                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(setSalesReportPageSelections({ tableSkip: 0, pagelength: e }));

                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(setSalesReportPageSelections({ tableSkip: e }));
                                            }}
                                        >
                                        </DrPagination>
                                    }
                                />
                            </div>}
                        {reportType === "summary-sales-report" &&
                            <div className={`card-body horizontal-scrollable ${(reportType === "summary-sales-report") ? "" : "d-none"}`} >
                                <DrDataTable
                                    id="dtableSummarySalesReport"
                                    data={tableData?.data?.data}
                                    columns={summarySalesReportColumns}
                                    autoColumnDef={true}
                                    availableColumns={columnsSummarySalesReport}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="Search..."
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={allReportsPageSelections?.summarySalesReport?.dateInterval}
                                            filterType={pageFilterTypes.allReports}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            onColumnsSelectedCallBack={onColumnsSelectedCallBack}
                                            availableTableColumns={chooseColumnsData}


                                        >
                                        </DrPageFilter>
                                    }
                                    pageLength={allReportsPageSelections?.summarySalesReport?.pagelength || 5}
                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (allReportsPageSelections?.summarySalesReport?.tableSkip || 0),
                                                    totalRecordCount: tableData?.totalRecordCount || allReportsPageSelections?.summarySalesReport?.tableLength,
                                                    tablePageLength: allReportsPageSelections?.summarySalesReport?.pagelength || 5
                                                }
                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(setSalesSummaryReportPageSelections({ tableSkip: 0, pagelength: e }));

                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(setSalesSummaryReportPageSelections({ tableSkip: e }));
                                            }}
                                        >
                                        </DrPagination>
                                    }
                                />
                            </div>}
                        {reportType === "profit-report" &&
                            <div className={`card-body horizontal-scrollable ${(reportType === "profit-report") ? "" : "d-none"}`} >
                                <DrDataTable
                                    id="dtableProfitReport"
                                    data={tableData?.data?.data}
                                    columns={profitReportColumns}
                                    autoColumnDef={true}
                                    availableColumns={columnsProfitReport}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="Search..."
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={allReportsPageSelections?.profitReport?.dateInterval}
                                            filterType={pageFilterTypes.allReports}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            onColumnsSelectedCallBack={onColumnsSelectedCallBack}
                                            availableTableColumns={chooseColumnsData}


                                        >
                                        </DrPageFilter>
                                    }
                                    pageLength={allReportsPageSelections?.profitReport?.pagelength || 5}
                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (allReportsPageSelections?.profitReport?.tableSkip || 0),
                                                    totalRecordCount: tableData?.totalRecordCount || allReportsPageSelections?.profitReport?.tableLength,
                                                    tablePageLength: allReportsPageSelections?.profitReport?.pagelength || 5
                                                }
                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(setSalesProfitReportPageSelections({ tableSkip: 0, pagelength: e }));

                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(setSalesProfitReportPageSelections({ tableSkip: e }));
                                            }}
                                        >
                                        </DrPagination>
                                    }
                                />
                            </div>}
                        {reportType === "delivery-report" &&
                            <div className={`card-body horizontal-scrollable ${(reportType === "delivery-report") ? "" : "d-none"}`} >
                                <DrDataTable
                                    id="dtableDeliveryReport"
                                    data={tableData?.data?.data}
                                    columns={deliveryReportColumns}
                                    autoColumnDef={true}
                                    availableColumns={columnsDeliveryReport}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="Search..."
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={allReportsPageSelections?.deliveryReport?.dateInterval}
                                            filterType={pageFilterTypes.allReports}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            onColumnsSelectedCallBack={onColumnsSelectedCallBack}
                                            availableTableColumns={chooseColumnsData}


                                        >
                                        </DrPageFilter>
                                    }
                                    pageLength={allReportsPageSelections?.deliveryReport?.pagelength || 5}
                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (allReportsPageSelections?.deliveryReport?.tableSkip || 0),
                                                    totalRecordCount: tableData?.totalRecordCount || allReportsPageSelections?.deliveryReport?.tableLength,
                                                    tablePageLength: allReportsPageSelections?.deliveryReport?.pagelength || 5
                                                }
                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(setSalesDeliveryReportPageSelections({ tableSkip: 0, pagelength: e }));

                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(setSalesDeliveryReportPageSelections({ tableSkip: e }));
                                            }}
                                        >
                                        </DrPagination>
                                    }
                                />
                            </div>}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AllReportsPage