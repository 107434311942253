const types = {
    SEND_MAIL_REQUESTED: "SEND_MAIL_REQUESTED",
    SET_BULK_OPERATION_DATA: "SET_BULK_OPERATION_DATA",
    SET_BULK_OPERATION_RESPONSE: "SET_BULK_OPERATION_RESPONSE",
    CLEAR_BULK_OPERATION_DATA: "CLEAR_BULK_OPERATION_DATA",
    CREATE_BULK_OPERATION_REQUESTED: "CREATE_BULK_OPERATION_REQUESTED",
    CREATE_BULK_OPERATION_REQUESTED2: "CREATE_BULK_OPERATION_REQUESTED2",
}

export default types;

export const sendMail = (payload) => {
    return { type: types.SEND_MAIL_REQUESTED, payload }
}

export const setBulkOperationData = (payload) => {
    return { type: types.SET_BULK_OPERATION_DATA, payload };
};

export const setBulkOperationResponse = (payload) => {
    return { type: types.SET_BULK_OPERATION_RESPONSE, payload };
};

export const clearBulkOperationData = (payload) => {
    return { type: types.CLEAR_BULK_OPERATION_DATA, payload };
};

export const createBulkOperationRequest = (payload) => {
    return { type: types.CREATE_BULK_OPERATION_REQUESTED, payload };
};

export const createBulkOperationRequest2 = (payload) => {
    return { type: types.CREATE_BULK_OPERATION_REQUESTED2, payload };
};
