import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { deleteFeedbackCampaignSuccess, getAmazonFeedbackSuccess, getFeedbackCampaign, getFeedbackCampaignSendEmailsSuccess, getFeedbackCampaignSuccess, getRatingPercentageSuccess, upsertFeedbackCampaignSuccess } from '../actions/feedback.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetFeedbackCampaigns(action) {
    const payload = action.payload;
    var path = `?CustomerMainSellerId=${payload.customerMainSellerId}`;
    if (payload.id) {
        path += `&Id=${payload.id}`;
    }
    const response = yield call(apiGet(path, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getFeedbackCampaignSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetAmazonFeedback(action) {
    const payload = action.payload;
    var path = `/GetAmazonFeedback?CustomerMainSellerId=${payload.customerMainSellerId}&Skip=${payload.skip}&PageSize=${payload.pageSize}`;
    if (payload.orderByParam) {
        path += `&OrderByParam=${payload.orderByParam}`;
    }

    const response = yield call(apiGet(path, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield put(getAmazonFeedbackSuccess(response));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetRatingPercentage(action) {
    const payload = action.payload;
    var path = `/GetRatingPercentage?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getRatingPercentageSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runUpsertFeedbackCampaign(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield all([
            put(upsertFeedbackCampaignSuccess(response.data)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteFeedbackCampaign(action) {
    const payload = action.payload;
    var path = `?id=${payload.id}`;

    const response = yield call(apiDelete(path, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess) {

        yield all([
            // put(deleteFeedbackCampaignSuccess()),
            put(getFeedbackCampaign({ customerMainSellerId: payload.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runFeedbackActiveAndDeActive(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/FeedbackActiveAndDeActive`, payload.requestActivateFeedback, false, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield all([
            put(getFeedbackCampaign({ customerMainSellerId: payload.customerMainSellerId })),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetFeedbackCampaignSendEmails(action) {
    const payload = action.payload;
    var path = `/GetFeedbackCampaignSendEmails?CustomerMainSellerId=${payload.customerMainSellerId}&Skip=${payload.skip}&PageSize=${payload.pageSize}`;
    if (payload.orderByParam) {
        path += `&OrderByParam=${payload.orderByParam}`;
    }

    const response = yield call(apiGet(path, CONTROLLERS.FEEDBACK, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield put(getFeedbackCampaignSendEmailsSuccess(response));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* feedbackSaga() {
    yield all([
        takeLatest(types.GET_ALL_FEEDBACK_CAMPAIGN_LIST_REQUESTED, safe(runGetFeedbackCampaigns)),
        takeLatest(types.GET_AMAZON_FEEDBACK_LIST_REQUESTED, safe(runGetAmazonFeedback)),
        takeLatest(types.GET_AMAZON_FEEDBACK_RATING_PERCENT_REQUESTED, safe(runGetRatingPercentage)),
        takeLatest(types.UPSERT_FEEDBACK_CAMPAIGN_REQUESTED, safe(runUpsertFeedbackCampaign)),
        takeLatest(types.DELETE_FEEDBACK_CAMPAIGN_REQUESTED, safe(runDeleteFeedbackCampaign)),
        takeLatest(types.UPDATE_FEEDBACKS_ACTIVE_REQUESTED, safe(runFeedbackActiveAndDeActive)),
        takeLatest(types.GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_REQUESTED, safe(runGetFeedbackCampaignSendEmails)),
    ]);
}