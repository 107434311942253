import { useTranslation } from "react-i18next";
import { clearActiveTermAndConditionsModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import "./dr-terms-conditions.component.scss";
import parse from 'html-react-parser';
import { agreementRequestTypes } from "../../../../utils/constants";
import { loginWithEmail } from "../../../../state-management/actions/customer.actions";

function DrTermsAndConditionsModal({ display, source, persistModal = false, requestType = agreementRequestTypes.register, onSelectionCallback }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drTermsAndConditionsModal');
    const agreement = useSelector(state => state.agreement);

    const handleSubmit = (e, value) => {
     
        if (requestType === agreementRequestTypes.register) {
            if (onSelectionCallback) {
                onSelectionCallback(value);
            }
        }
        else if (requestType === agreementRequestTypes.login) {
            if (value.isAccepted) {
                dispatch(withActionPromise(loginWithEmail({ ...source, agreementId: value.agreementId })));
            }
            dispatch(clearActiveTermAndConditionsModal());
        }
    };

    return (

        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="termsAndConditionsModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveTermAndConditionsModal());
                }
            }}>


            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-full-height" role="document">
                <div className="modal-content">
                    <div className="modal-header" >
                        <h6 className="modal-title" >Terms And Conditions</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveTermAndConditionsModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        <div className="row">
                            <div className="col-12">

                                {parse("" + agreement?.agreements?.agreementHtml)}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end w-100">
                            <div className="col-auto">
                                <button className={`btn btn-danger-ghost btn-wave waves-effect waves-light shadow`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e, { isAccepted: false, agreementId: agreement?.agreements?.id });
                                    }}
                                >
                                    Decline
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e, { isAccepted: true, agreementId: agreement?.agreements?.id });
                                    }}
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default DrTermsAndConditionsModal