import { useSelector } from "react-redux";
import Cookies from 'js-cookie'

function ExtensionTokenPage() {
    const customer = useSelector(state => state.customer);


    return (
        <div>
            {/* {customer?.token || Cookies.get("AuthToken")};{customer?.customerConfig?.lastVisitedMarketId} */}
            <input id="input-token" value={customer?.token || Cookies.get("AuthToken") + ";" + customer?.customerConfig?.lastVisitedMarketId}></input>
        </div>


    )
}

export default ExtensionTokenPage;