import axios from 'axios'
import { store } from "../state-management/configureStore";
import { setLoadIndicator, setPageLoadIndicator } from "../state-management/actions/app.actions";
import Cookies from 'js-cookie'

//const { REACT_APP_API_HOST } = process.env;
const REACT_APP_API_HOST = "https://hub.sellerdoping.com.tr";
//const REACT_APP_API_HOST = "http://74.234.34.76:5000";
const { REACT_APP_PUBLIC_TOKEN } = process.env;

const setLoaderIndicator = (e) => {
    const val = e ? 1 : -1;
    const showLoaderCount = store.getState().app.showLoader;
    store.dispatch(setLoadIndicator(showLoaderCount + val));
}

const setPageLoaderIndicator = (e) => {
    const val = e ? 1 : -1;
    const showLoaderCount = store.getState().app.showPageLoader;
    store.dispatch(setPageLoadIndicator(showLoaderCount + val));
}

export const CONTROLLERS = {
    LOGIN: "/api/login",
    MARKETPLACE: "/api/Market",
    CUSTOMER: "/api/customer",
    CUSTOMERMAINSELLER: "/api/CustomerMainSeller",
    CUSTOMERMAINSELLERSFILTER: "/api/CustomerMainSellerFilter",
    CUSTOMERMAINSELLERSSHIPPING: "/api/CustomerMainSellerShipping",
    CUSTOMERMAINSELLERSSETTING: "/api/CustomerMainSellerSetting",
    STORES: "/api/Stores",
    PRODUCT: "/api/Product",
    REPRIZER: "/api/Reprizer",
    ROLE: "/api/Role",
    UTILS: "/api/Utils",
    ORDER: "/api/Order",
    PAGEFILTER: "/api/PageFilter",
    WAREHOUSE: "/api/Warehouse",
    SHIPPING: "/api/Shipping",
    WAREHOUSESHIPPING: "/api/WareHouseShipping",
    WAREHOUSESUIT: "/api/WareHouseSuit",
    KEEPA: "/api/Keepa",
    SMARTPRICER: "/api/SmartPricer",
    SHIPSTATION: "/api/ShipStation",
    WALLET: "/api/Wallet",
    NOTIFICATION: "/api/Notification",
    REPORTS: "/api/Report",
    LANDINGPAGE: "/api/LandingPage",
    DASHBOARD: "/api/Dashboard",
    PRODUCTBRAND: "/api/ProductBrand",
    PRODUCTCATEGORY: "/api/ProductCategory",
    PRODUCTNONPRICED: "/api/ProductNonPriced",
    SELLER: "/api/Seller",
    TRADEMARK: "/api/Trademark",
    INVOICE: "/api/Invoice",
    PACKAGE: "/api/Package",
    COUPON: "/api/Coupon",
    CUSTOMERWAREHOUSE: "/api/CustomerWarehouse",
    AGREEMENT: "/api/Agreement",
    MESSAGING: "/api/Messaging",
    FEEDBACK: "/api/Feedback"
}

export const apiGet = (path, apiPath = CONTROLLERS.LOGIN, requireAuthToken = true, showLoaderIndicator = false, isBlob = false, showPageLoaderIndicator = false) => {
    var authTokenCookie = Cookies.get("AuthToken");//|| store.getState()?.customer?.token; 
    const culture = Cookies.get("Culture");

    showLoaderIndicator && setLoaderIndicator(true);
    showPageLoaderIndicator && setPageLoaderIndicator(true);

    return () => axios.get(`${REACT_APP_API_HOST || ""}${apiPath}${path}`,
        {
            headers: {
                Authorization: requireAuthToken && authTokenCookie ? `Bearer ${authTokenCookie}` : "",
                ...(culture && { "Culture": culture })
            },
            responseType: isBlob ? "blob" : ""
        })
        .then(response => {
            showLoaderIndicator && setLoaderIndicator(false);
            return response.data;
        });
}

export const apiPost = (path, payload, hasFormData = false, apiPath = CONTROLLERS.LOGIN, requireAuthToken = true, showLoaderIndicator = false, showPageLoaderIndicator = false) => {
    showLoaderIndicator && setLoaderIndicator(true);
    showPageLoaderIndicator && setPageLoaderIndicator(true);
    var authTokenCookie = Cookies.get("AuthToken");//|| store.getState()?.customer?.token; 
    if (!authTokenCookie) {
        authTokenCookie = localStorage.getItem("AuthToken");
    }

    if (!authTokenCookie && apiPath === CONTROLLERS.UTILS) {
        authTokenCookie = REACT_APP_PUBLIC_TOKEN;
    }

    const culture = Cookies.get("Culture");

    return () =>
        axios.post(`${REACT_APP_API_HOST || ""}${apiPath}${path}`,
            payload,
            {
                headers: {
                    Authorization: requireAuthToken && authTokenCookie ? `Bearer ${authTokenCookie}` : "",
                    ...(hasFormData && { "Content-Type": "multipart/form-data" }),
                    ...(culture && { "Culture": culture })
                },
            })
            .then(response => {
                showLoaderIndicator && setLoaderIndicator(false);
                return response.data
            });
}

export const apiPut = (path, payload, hasFormData = false, apiPath = CONTROLLERS.LOGIN, showLoaderIndicator = false, showPageLoaderIndicator = false) => {
    showLoaderIndicator && setLoaderIndicator(true);
    showPageLoaderIndicator && setPageLoaderIndicator(true);

    var authTokenCookie = Cookies.get("AuthToken");//|| store.getState()?.customer?.token; 
    const culture = Cookies.get("Culture");

    return () => axios.put(`${REACT_APP_API_HOST || ""}${apiPath}${path}`,
        payload,
        {
            headers: {
                Authorization: authTokenCookie ? `Bearer ${authTokenCookie}` : "",
                ...(hasFormData && { "Content-Type": "multipart/form-data" }),
                ...(culture && { "Culture": culture })
            }
        })
        .then(response => {
            showLoaderIndicator && setLoaderIndicator(false);
            return response.data
        });
}

export const apiDelete = (path, apiPath = CONTROLLERS.LOGIN, showLoaderIndicator = false, showPageLoaderIndicator = false) => {
    showLoaderIndicator && setLoaderIndicator(true);
    showPageLoaderIndicator && setPageLoaderIndicator(true);

    var authTokenCookie = Cookies.get("AuthToken");//|| store.getState()?.customer?.token; 
    const culture = Cookies.get("Culture");

    return () => axios.delete(`${REACT_APP_API_HOST || ""}${apiPath}${path}`,
        {
            headers: {
                Authorization: authTokenCookie ? `Bearer ${authTokenCookie}` : "",
                ...(culture && { "Culture": culture })
            }
        }
    ).then(response => {
        showLoaderIndicator && setLoaderIndicator(false);
        return response.data
    });
}
