import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { DrDualDatePicker, DrInput, DrSelectBox } from '../../component-index';
import { useDispatch, useSelector } from 'react-redux';
import { modals, pageFilterTypes } from '../../../utils/constants';
import { clearActiveSavePagefilterModal, setActiveSavePagefilterModal } from '../../../state-management/actions/app.actions';
import i18next from 'i18next';
import { buyerOrderStatusOptions, flagOptions, orderStatusOptions, sellerOrderStatusOptions, timeOptions } from '../Constants/dr-constants.component';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getShipStationCarrierServices } from '../../../state-management/actions/shipstation.actions';
import { getDirtyValues } from '../../../utils/utils';

function DrOrdersFilter({ detailedFilterData, selectedFilterId, detailedSearchClickCallBack }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('storeFiltersPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);
    const pageFilters = useSelector(state => state.pageFilter);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.ordersPageSelections);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        setFilter(detailedFilterData);
        setFormData(detailedFilterData);
        if (detailedFilterData) {
            console.log(detailedFilterData)
            // var searchData = JSON.parse(filter.filterJson);
            var formData = Object.assign({}, detailedFilterData);

            formData.orderFlag = formData.orderFlag?.length > 0 ?
                flagOptions.filter(function (o) {
                    return formData.orderFlag.find(r => r === o.value);
                }) : [];

            setFormData(formData);
        }
        else {
            setFormData(defaultValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailedFilterData]);

    useEffect(() => {


        if (pageFilters.ordersPageFilters && pageFilters.ordersPageFilters.length > 0) {
            var selectedFilter = pageFilters.ordersPageFilters.find(x => x.id === selectedFilterId);
            setFilter(selectedFilter || null);

            if (selectedFilter) {
                var searchData = JSON.parse(selectedFilter.filterJson);
                var formData = Object.assign({}, searchData);

                formData.orderFlag = formData.orderFlag?.length > 0 ?
                    flagOptions.filter(function (o) {
                        return formData.orderFlag.find(r => r === o.value);
                    }) : [];

                setFormData(formData);
            }
            else {
                setFormData(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageFilters, selectedFilterId]);

    const defaultValues = {

        customerMainSellerId: selectedStore?.customerMainSellerId,
        startOrderDate: null,//
        endOrderDate: null,//7777
        orderStatus: null,//
        orderMinPrice: null,//
        orderMaxPrice: null,//
        name: null,
        profitMinPrice: null,
        progitMaxPrice: null,
        profitMinPercent: null,
        progitMaxPercent: null,
        orderFlag: [],//
        deliveryStartDate: null,//
        deliveryEndDate: null,//
        isBuyerSellerOrderNumber: false,//
        isFastDelivery: false,//
        isNoTrackingNumber: false,//
        isAmazonRejectedPayment: false,//
        productCategory: null,
        isApplied: false,
        isFastShipping: false,

        sourceMarketStock: null,
        carrierCode: "",
        carrierServiceCode: "",
        buyerEmailAddress: "",
        buyerOrderStatus: null,//

    }

    const [filter, setFilter] = useState(null);


    const initialValues = {

        customerMainSellerId: selectedStore?.customerMainSellerId,
        startOrderDate: formData?.startOrderDate || null,
        endOrderDate: formData?.endOrderDate || null,
        orderStatus: formData?.orderStatus || null,
        orderMinPrice: formData?.orderMinPrice || null,
        orderMaxPrice: formData?.orderMaxPrice || null,
        name: formData?.name || null,
        profitMinPrice: formData?.profitMinPrice || null,
        progitMaxPrice: formData?.progitMaxPrice || null,
        profitMinPercent: formData?.profitMinPercent || null,
        progitMaxPercent: formData?.progitMaxPercent || null,
        orderFlag: formData?.orderFlag || [],
        deliveryStartDate: formData?.deliveryStartDate || null,
        deliveryEndDate: formData?.deliveryEndDate || null,
        isBuyerSellerOrderNumber: formData?.isBuyerSellerOrderNumber || false,
        isFastDelivery: formData?.isFastDelivery || false,
        isFastShipping: formData?.isFastShipping || false,
        isNoTrackingNumber: formData?.isNoTrackingNumber || false,
        isAmazonRejectedPayment: formData?.isAmazonRejectedPayment || false,
        productCategory: formData?.productCategory || null,
        isApplied: formData?.isApplied || false,

        sourceMarketStock: formData?.sourceMarketStock || null,
        carrierCode: formData?.carrierCode || "",
        carrierServiceCode: formData?.carrierServiceCode || "",
        buyerEmailAddress: formData?.buyerEmailAddress || ""

    }

    // useEffect(() => {

    //     if (filter) {
    //         console.log(filter)
    //         var searchData = JSON.parse(filter.filterJson);
    //         var formData = Object.assign({}, searchData);

    //         formData.orderFlag = searchData.orderFlag?.length > 0 ?
    //             flagOptions.filter(function (o) {
    //                 return searchData.orderFlag.find(r => r === o.value);
    //             }) : [];

    //         setFormData(formData);
    //     }
    //     else {
    //         setFormData(defaultValues)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filter]);

    const detailedSearchClick = (e, searchModel) => {

        if (searchModel) {
            let searchDirty = getDirtyValues(searchModel, defaultValues);
            detailedSearchClickCallBack(e, searchDirty);
        }
        else {
            detailedSearchClickCallBack(e, null);
        }
    };

    const callCarrierService = (value) => {
        if (!(shipstationCarrierServices?.length > 0 && shipstationCarrierServices?.find(x => x.carrierCode === value))) {
            dispatch(getShipStationCarrierServices(value));
        }
    }

    return (
        <div>
            {formData &&
                <Formik
                    initialValues={initialValues}
                    validateOnBlur={true}
                    // onSubmit={handleSubmit}
                    enableReinitialize={true}
                    validateOnMount={true}
                    validateOnChange={true}
                >
                    {({
                        isValid,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setTouched,
                        setFieldValue,
                        resetForm
                    }) => (
                        <form>
                            <div id="dtableDetailSearch" className="row row-md row-lg">
                                <div className="col-lg-12 col-xl-12 col-md-12 px-0">
                                    <div className="card custom-card bg-info bg-opacity-10">
                                        <div className="card-body">
                                            <div className={`row row-md row-lg my-1 justify-content-end`} >
                                                <div className="col-auto my-auto">
                                                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFormData(defaultValues);
                                                            setFilter(null);
                                                            resetForm();
                                                            detailedSearchClick(e, null);
                                                        }}>
                                                        <i className="w-6 me-2 fa fa-plus"></i>
                                                        Yeni Filtre
                                                    </button>
                                                </div>

                                                <div className="col-auto ">
                                                    <button defaultValue="0" className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            // setFieldValue("isApplied", false);
                                                            detailedSearchClick(this, values);
                                                        }}>
                                                        <i className="fe fe-search me-2"></i>
                                                        Ara
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-xxl-4 col-lg-6 col-md-6 col-sm-12 my-1 px-1 d-flex">
                                                    <div className="card custom-card bg-light p-3 m-0">
                                                        <div className="row my-1">
                                                            <div className="col-4 my-auto">
                                                                Seller Order Status
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.orderStatus, 'orderStatus': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("orderStatus", e.value);
                                                                    }}
                                                                    name={'orderStatus'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={sellerOrderStatusOptions} />
                                                                {(touched.orderStatus && errors.orderStatus) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.orderStatus}
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-4 my-auto">Order Created Date</div>
                                                            <div className="col">
                                                                <DrDualDatePicker
                                                                    selectedDates={[values.startOrderDate, values.endOrderDate]}
                                                                    onCloseCallBack={(dateValues) => {
                                                                        var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null)];
                                                                        setFieldValue("startOrderDate", dates[0]);
                                                                        setFieldValue("endOrderDate", dates[1]);
                                                                    }}
                                                                    timeOptionsSource={timeOptions}
                                                                ></DrDualDatePicker>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-4 my-auto">Delivery Date</div>
                                                            <div className="col">
                                                                <DrDualDatePicker
                                                                    selectedDates={[values.deliveryStartDate, values.deliveryEndDate]}
                                                                    onCloseCallBack={(dateValues) => {
                                                                        var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null)];
                                                                        setFieldValue("deliveryStartDate", dates[0]);
                                                                        setFieldValue("deliveryEndDate", dates[1]);
                                                                    }}
                                                                    timeOptionsSource={timeOptions}
                                                                ></DrDualDatePicker>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-4 my-auto">Carrier Code Selection</div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.carrierCode, 'carrierCode': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue('carrierCode', e.value);
                                                                        callCarrierService(e.value);
                                                                    }}
                                                                    isMulti={false}
                                                                    name={'carrierCode'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select Carrier"
                                                                    options={shipstationCarriers?.map(x => ({ value: x.code, label: x.name }))} />
                                                            </div>

                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-4 my-auto">Carrier Service Selection</div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.carrierServiceCode, 'carrierServiceCode': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue('carrierServiceCode', e.value);
                                                                    }}
                                                                    isMulti={false}
                                                                    name={'carrierServiceCode'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select Carrier"
                                                                    options={shipstationCarrierServices?.find(x => x.carrierCode === values.carrierCode)?.data?.map(x => ({ value: x.code, label: x.name })) || []} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-4">
                                                                Flag
                                                            </div>
                                                            <div className="col">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.orderFlag, 'orderFlag': true }) }}
                                                                    onChange={(e) => {
                                                                        if (e) {
                                                                            setFieldValue("orderFlag", e.map(a => a.value));
                                                                        }
                                                                    }}
                                                                    isMulti={true}
                                                                    // maxMultiSelectionCount={1}
                                                                    name={'orderFlag'}
                                                                    component={DrSelectBox}
                                                                    placeholder={t('flagOptions')}
                                                                    options={flagOptions} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xxl-4 col-lg-6 col-md-6 col-sm-12 my-1 px-1 d-flex">
                                                    <div className="card custom-card bg-light p-3 m-0">
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                Buyer Order Status
                                                            </div>
                                                            <div className="col ps-0">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.orderStatus, 'buyerOrderStatus': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue("buyerOrderStatus", e.value);
                                                                    }}
                                                                    name={'buyerOrderStatus'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={buyerOrderStatusOptions} />
                                                                {(touched.buyerOrderStatus && errors.buyerOrderStatus) &&
                                                                    <label type="invalid" className="form-control is-invalid">
                                                                        {errors.buyerOrderStatus}
                                                                    </label>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">

                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Buyer Name</label>
                                                            </div>
                                                            <div className="col ps-0">
                                                                <DrInput value={values.name || ""} className={`form-control`}
                                                                    placeholder={t('name')}
                                                                    name="name"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'name': true }) }}
                                                                    isInvalid={(touched.name && errors.name)}
                                                                    isValid={touched.name && !errors.name}
                                                                    validationText={errors.name}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Buyer Email</label>
                                                            </div>
                                                            <div className="col ps-0">
                                                                <DrInput value={values.buyerEmailAddress || ""} className={`form-control`}
                                                                    placeholder={t('buyerEmailAddress')}
                                                                    name="buyerEmailAddress"
                                                                    type="text"
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'buyerEmailAddress': true }) }}
                                                                    isInvalid={(touched.buyerEmailAddress && errors.buyerEmailAddress)}
                                                                    isValid={touched.buyerEmailAddress && !errors.buyerEmailAddress}
                                                                    validationText={errors.buyerEmailAddress}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Price</label>
                                                            </div>
                                                            <div className="col px-0">
                                                                <DrInput className="form-control" placeholder="Min" name="orderMinPrice" type="number" min="0" step="0.1"
                                                                    value={values.orderMinPrice || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'orderMinPrice': true }) }}
                                                                    icon={<i className="fe fe-dollar-sign"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.orderMinPrice && errors.orderMinPrice)}
                                                                    isValid={touched.orderMinPrice && !errors.orderMinPrice}
                                                                    validationText={errors.orderMinPrice} />

                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Max" name="orderMaxPrice" type="number" min="0" step="0.1"
                                                                    value={values.orderMaxPrice || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'orderMaxPrice': true }) }}
                                                                    icon={<i className="fe fe-dollar-sign"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.orderMaxPrice && errors.orderMaxPrice)}
                                                                    isValid={touched.orderMaxPrice && !errors.orderMaxPrice}
                                                                    validationText={errors.orderMaxPrice} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Profit</label>
                                                            </div>
                                                            <div className="col px-0">
                                                                <DrInput className="form-control" placeholder="Min" name="profitMinPrice" type="number" min="0" step="0.1"
                                                                    value={values.profitMinPrice || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'profitMinPrice': true }) }}
                                                                    icon={<i className="fe fe-dollar-sign"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.profitMinPrice && errors.profitMinPrice)}
                                                                    isValid={touched.profitMinPrice && !errors.profitMinPrice}
                                                                    validationText={errors.profitMinPrice} />

                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Max" name="profitMaxPrice" type="number" min="0" step="0.1"
                                                                    value={values.profitMaxPrice || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'profitMaxPrice': true }) }}
                                                                    icon={<i className="fe fe-dollar-sign"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.profitMaxPrice && errors.profitMaxPrice)}
                                                                    isValid={touched.profitMaxPrice && !errors.profitMaxPrice}
                                                                    validationText={errors.profitMaxPrice} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Profit Percent%</label>
                                                            </div>
                                                            <div className="col px-0">
                                                                <DrInput className="form-control" placeholder="Min" name="profitMinPercent" type="number" min="0" step="0.1"
                                                                    value={values.profitMinPercent || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'profitMinPercent': true }) }}
                                                                    icon={<i className="fe fe-percent"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.profitMinPercent && errors.profitMinPercent)}
                                                                    isValid={touched.profitMinPercent && !errors.profitMinPercent}
                                                                    validationText={errors.profitMinPercent} />

                                                            </div>
                                                            <div className="col">
                                                                <DrInput className="form-control" placeholder="Max" name="profitMaxPercent" type="number" min="0" step="0.1"
                                                                    value={values.profitMaxPercent || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'profitMaxPercent': true }) }}
                                                                    icon={<i className="fe fe-percent"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.profitMaxPercent && errors.profitMaxPercent)}
                                                                    isValid={touched.profitMaxPercent && !errors.profitMaxPercent}
                                                                    validationText={errors.profitMaxPercent} />

                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-3 my-auto">
                                                                <label className="text-wrap">Source market max stock</label>
                                                            </div>
                                                            <div className="col px-0">
                                                                <DrInput className="form-control" placeholder="Source Market Stock" name="sourceMarketStock" type="number" min="0" step="1"
                                                                    value={values.sourceMarketStock || ""}
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'sourceMarketStock': true }) }}
                                                                    icon={<i className="fe fe-package"></i>}
                                                                    iconPosition="left"
                                                                    isInvalid={(touched.sourceMarketStock && errors.sourceMarketStock)}
                                                                    isValid={touched.sourceMarketStock && !errors.sourceMarketStock}
                                                                    validationText={errors.sourceMarketStock} />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xxl-4 col-lg-6 col-md-6 col-sm-12 my-1 px-1 d-flex">
                                                    <div className="card custom-card bg-light p-3 m-0">
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isFastDelivery" className="form-check-input form-checked-success" role="switch" placeholder="" name="isFastDelivery" type="checkbox"
                                                                        value=""
                                                                        checked={values.isFastDelivery}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isFastDelivery': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isFastDelivery) ? "" : "text-muted"}`} htmlFor="isFastDelivery"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Orders with only fast delivery option
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isNoTrackingNumber" className="form-check-input form-checked-success" role="switch" placeholder="" name="isNoTrackingNumber" type="checkbox"
                                                                        value=""
                                                                        checked={values.isNoTrackingNumber}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isNoTrackingNumber': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isNoTrackingNumber) ? "" : "text-muted"}`} htmlFor="isNoTrackingNumber"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Orders with no tracking number
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isBuyerSellerOrderNumber" className="form-check-input form-checked-success" role="switch" placeholder="" name="isBuyerSellerOrderNumber" type="checkbox"
                                                                        value=""
                                                                        checked={values.isBuyerSellerOrderNumber}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isBuyerSellerOrderNumber': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isBuyerSellerOrderNumber) ? "" : "text-muted"}`} htmlFor="isBuyerSellerOrderNumber"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Orders with no buyer's tracking number
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isAmazonRejectedPayment" className="form-check-input form-checked-success" role="switch" placeholder="" name="isAmazonRejectedPayment" type="checkbox"
                                                                        value=""
                                                                        checked={values.isAmazonRejectedPayment}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isAmazonRejectedPayment': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isAmazonRejectedPayment) ? "" : "text-muted"}`} htmlFor="isAmazonRejectedPayment"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Orders with its payment rejected by Amazon
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isFastShipping" className="form-check-input form-checked-success" role="switch" placeholder="" name="isFastShipping" type="checkbox"
                                                                        value=""
                                                                        checked={values.isFastShipping}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isFastShipping': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isFastShipping) ? "" : "text-muted"}`} htmlFor="isFastShipping"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Orders with only fast shipping option
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <div className="row justify-content-end">

                                                        <div className="col-auto">
                                                            <button className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // detailedSearchSave(e, values);

                                                                    var data = values;
                                                                    data.orderFlag = values.orderFlag?.map(e => e.value) || null;

                                                                    var filterData = {
                                                                        id: filter?.id,
                                                                        customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                        pageNumber: pageFilterTypes.orders,
                                                                        pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(1)],
                                                                        methodName: filter?.methodName || "getOrders",
                                                                        moduleName: filter?.moduleName || "orders",
                                                                        description: '',
                                                                        filterJson: JSON.stringify(data),
                                                                        isSelected: true,
                                                                        filterName: filter?.filterName || "",
                                                                    }

                                                                    dispatch(setActiveSavePagefilterModal({
                                                                        name: modals.pageFilterSave, isGlobal: true, source: filterData,
                                                                        pagefilterSavedCallBack: (response) => {
                                                                            dispatch(clearActiveSavePagefilterModal());
                                                                        }
                                                                    }));
                                                                }}>
                                                                <i className="fa fa-floppy-o me-2"></i>
                                                                Kaydet
                                                            </button>
                                                        </div>
                                                        <div className="col-auto ">
                                                            <button defaultValue="0" className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    // setFieldValue("isApplied", false);
                                                                    detailedSearchClick(this, values);
                                                                }}>
                                                                <i className="fe fe-search me-2"></i>
                                                                Ara
                                                            </button>
                                                        </div>
                                                        {/* <div className="col-auto">
                                                            <div className="row">
                                                                <div className="col-auto ">
                                                                    <button defaultValue="0" className="btn btn-info btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            // setFieldValue("isApplied", false);
                                                                            detailedSearchClick(this, values);
                                                                        }}>
                                                                        <i className="fe fe-search me-2"></i>
                                                                        Ara
                                                                    </button>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button defaultValue="0" className="btn btn-danger btn-wave waves-effect waves-light shadow" data-bs-toggle="collapse" data-bs-target="#detailedSearchContent" aria-expanded="false" aria-controls="detailedSearchContent"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}>
                                                                        <i className="fe fe-x me-2"></i>
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div> */}

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )
                    }
                </Formik >
            }
        </div >

    )
}

export default DrOrdersFilter;