import Cookies from 'js-cookie'
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as moment from 'moment';

import React from 'react'
import ReactDOM from 'react-dom/client';

import dinersclub from '../assets/img/cards/DinersClub.png'
import discover from '../assets/img/cards/Discover.png'
import mastercard from '../assets/img/cards/Mastercard.png'
import paypal from '../assets/img/cards/PayPal.png'
import stripe from '../assets/img/cards/Stripe.png'
import visa from '../assets/img/cards/Visa.png'
import maestro from '../assets/img/cards/Maestro.png'
import jcb from '../assets/img/cards/JCB.png'
import amex from '../assets/img/cards/Amex.png'
import empty from '../assets/img/cards/empty.png'
import { DateObject } from 'react-multi-date-picker';
import { modals, variableTypes } from './constants';
import { DrReadMore } from '../components/component-index';
import { setActivePictureModal } from '../state-management/actions/app.actions';

export const removeUndefinedFields = (obj) => {
    Object.keys(obj).forEach(key => !obj[key] && delete obj[key]);
    return obj;
}

export const getFileExtensionFromUrl = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const setBaseUrl = () => {
    var url = document.location.href;
    var urlparts = url.split('?');
    var urlBase = urlparts.shift();
    window.history.pushState('', document.title, urlBase);
}

export const composeFormData = (formData, data, parentKey, convertStringToBool = false) => {
    if (data && typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File) &&
        !(data instanceof Blob)) {
        Object.keys(data).forEach(key => {
            let formKey = `${parentKey}.${key}`;

            if (data instanceof Array) {
                if ((data.length > 0) && (data[0] instanceof File)) {
                    formKey = `${parentKey}`;
                }
                else {
                    formKey = `${parentKey}[${key}]`;
                }
            }

            composeFormData(formData, data[key], parentKey ? formKey : key);
        });
    }
    else {
        const value = data == null ? '' : data;

        formData.append(parentKey, (convertStringToBool && value === "1") ? true : value);
    }
}

export const removeParamFromUrl = (parameter) => {
    var url = document.location.href;
    var urlparts = url.split('?');

    if (urlparts.length >= 2) {
        var urlBase = urlparts.shift();
        var queryString = urlparts.join("?");

        var prefix = encodeURIComponent(parameter) + '=';
        var pars = queryString.split(/[&;]/g);
        for (var i = pars.length; i-- > 0;)
            if (pars[i].lastIndexOf(prefix, 0) !== -1)
                pars.splice(i, 1);
        url = urlBase + '?' + pars.join('&');
        window.history.pushState('', document.title, url); // added this line to push the new url directly to url bar .

    }
    return url;
}


export const base64Encode = (s) => {
    return btoa(unescape(encodeURIComponent(s)));
}

export const base64Decode = (s) => {
    return decodeURIComponent(escape(atob(s)));
}

export const convertSnakeCaseToCamelCase = (str) => {
    return str.toLowerCase().replace(/([-_][a-z])/g, (group) =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}

export const sendGTAGEvent = ({ action, params }) => {
    if (userConsentForAnalytics()) {
        window.gtag('event', action, params)
    }
}

export const flattenObject = (obj) => {
    const flattened = {}

    Object.keys(obj).forEach((key) => {
        const value = obj[key]

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenObject(value))
        } else {
            flattened[key] = value
        }
    })

    return flattened;
}

export const flattenWarehousesSelectedShipments = (array) => {
    var result = [];
    array.forEach(function (a) {
        a.shipmentList.forEach(function (b) {
            if (b.selected === true) {
                result.push(b.id)
            }
        })
    });
    return result;
}

export const getNumbersFromString = (str) => {
    return str.replace(/\D/g, "");
}

export const userConsentForAnalytics = () => {
    try {
        var consentCookiesObj = JSON.parse(Cookies.get("cc_cookie"));
        if (consentCookiesObj.level === null || !consentCookiesObj.level.find(level => level === "analytics")) {
            return false;
        }
        return true;
    } catch (error) {
        return false;
    }
}

export const convertDateObject = (date, format) => {
    let object = { date, format }
    return new DateObject(object).format();
}

export const getURLParams = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
}

export const getURLParams2 = (key, url) => {
    const searchParams = new URLSearchParams(url);
    return searchParams.get(key);
}

export function generateRandomUniqueId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export const generatePassword = (size) => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let array = new Uint8Array(size);
    window.crypto.getRandomValues(array);
    array = array.map((x) => validChars.codePointAt(x % validChars.length));
    const password = String.fromCharCode.apply(null, array);
    return password;
};

export function replacer(key, value) {
    if (typeof value === 'boolean') {
        return value ? 'true' : 'false';
    }
    return value;
}


export const generateStringToHtml = (text) => {
    var lines = text.split('\n').filter(x => x !== '');
    var html = '<p>';
    html += text.replace(/\n/g, "<br />");
    // for (var i = 0; i < lines.length; i++) {
    //     if (i !== 0) {
    //         html += '<br></br>'
    //     }

    //     // if (lines[i] === "") {
    //     //     html += '<br></br>'
    //     // }
    //     // else {
    //     html += lines[i];
    //     // }
    //     // if (lines[i] && lines[i] !== "") {
    //     // html += lines[i];
    //     // }
    // }
    return html + '</p>';
}

export const generateHtmlToString = (html) => {
    var regex = /<br\s*[\/]?>/gi;
    var myString = html.replace(regex, "\n")
    myString = myString.replace('</p>', '');
    myString = myString.replace('<p>', '');
    return myString;
}


export const getCardNumber = (cardNumber) => {

    var numbers = cardNumber.replace(/ /g, "");
    var firstTwo = numbers.slice(0, 2);

    var lastFour = "";

    var name = "AlB2C3D4";
    var output = numbers.replace(/^(..)(.*)(....)$/, (a, b, c, d) => b + c.replace(/./g, "*") + d);

    return output;
}

/*
master 23,24,25,26  ,51-55
maestro 56-64 , 66-69
discoer 65
amex 34,37
dinner 36,38,39
visa 40- 49
 
*/
export const getCreditCardType = (cardNumber) => {
    var numbers = cardNumber.replace(/ /g, "");
    var firstTwo = numbers.slice(0, 2);
    if (firstTwo === "23" || firstTwo === "24" || firstTwo === "25" || firstTwo === "26" || firstTwo === "51" || firstTwo === "52" || firstTwo === "53" || firstTwo === "54" || firstTwo === "55") {
        return "mastercard";
    }
    else if (firstTwo === "56" || firstTwo === "57" || firstTwo === "58" || firstTwo === "59" || firstTwo === "60" || firstTwo === "61" || firstTwo === "62" || firstTwo === "63" || firstTwo === "64") {
        return "maestro";
    }
    else if (firstTwo === "65") {
        return "discover";
    }
    else if (firstTwo === "34" || firstTwo === "37") {
        return "american-express";
    }
    else if (firstTwo === "36" || firstTwo === "38" || firstTwo === "37") {
        return "diners-club";
    }
    else if (firstTwo === "40" || firstTwo === "41" || firstTwo === "42" || firstTwo === "43" || firstTwo === "44" || firstTwo === "45" || firstTwo === "46" || firstTwo === "47" || firstTwo === "48" || firstTwo === "49") {
        return "visa";
    }
    else if (firstTwo === "35") {
        return "jcb";
    }
    else {
        return "unknown";
    }
}

export const getCreditCardIconText = (cardNumber) => {
    var cardType = getCreditCardType(cardNumber);
    if (cardType === "visa") {
        return "fa fa-cc-visa";
    }
    else if (cardType === "mastercard") {
        return "fa fa-cc-mastercard";
    }
    else if (cardType === "maestro") {
        return "fa fa-cc";
    }
    else if (cardType === "discover") {
        return "fa fa-cc-discover";
    }
    else if (cardType === "american-express") {
        return "fa fa-cc-amex";
    }
    else if (cardType === "diners-club") {
        return "fa fa-cc-diners-club";
    }
    else if (cardType === "jcb") {
        return "fa fa-cc-jcb";
    }
    else {
        return "fa fa-cc";
    }
}

export const getCreditCardIcon = (cardNumber) => {
    var cardType = getCreditCardType(cardNumber);
    if (cardType === "visa") {
        return visa;
    }
    else if (cardType === "mastercard") {
        return mastercard;
    }
    else if (cardType === "maestro") {
        return maestro;
    }
    else if (cardType === "discover") {
        return discover;
    }
    else if (cardType === "american-express") {
        return amex;
    }
    else if (cardType === "diners-club") {
        return dinersclub;
    }
    else if (cardType === "jcb") {
        return jcb;
    }
    else {
        return empty;
    }
}

export function maskCreditCard(cardNumber) {
    const realCardNumberLength = 16;
    const n = cardNumber.length;

    var firstTwo = cardNumber.substring(0, 2);
    var middleChars = cardNumber.substring(2, (cardNumber.length <= 12 ? cardNumber.length : 12))
    const lastFour = cardNumber.length > 12 ? cardNumber.substring(12, cardNumber.length) : "";

    // const remaining = cardNumber.slice(0, n - 4);

    // // mask the remaining numbers with asterisks
    // const masked = "*".repeat(remaining.length) + lastFour;
    return firstTwo + middleChars + lastFour;
}

export const discardFieldsFromArray = (obj, fields) => {
    const result = {};
    fields = new Set(fields);

    console.log("fields", fields);
    console.log(Object.keys(obj));
    console.log("entru", Object.fromEntries(obj))

    for (const field of Object.keys(obj)) {
        console.log("field", field);

        if (fields.has(field)) {
            result[field] = obj[field];
        }
    }
    return result;
}

export const includeFieldsFromArray = (data, fields) => {

    let result = data.map(item => {
        return Object.keys(item)
          .filter(key => fields.includes(key))
          .reduce((data, key) => {
            data[key] = item[key];
            return data;
          }, {})
      });

      return result
}

export const generatePdfReport = ({ columns = [], dataSource = [], isHtml = false, orientation = "p", format = "a4", htmlTableId = "#dtable", title = "", reportName = "report" }) => {

    const doc = new jsPDF(
        {
            orientation: orientation,
            unit: 'mm',
            format: format,
            putOnlyUsedFonts: true
        }
    );

    var offsetY = 30; //var offsetY is for spacing
    var lineHeight = 6.49111111111111; //var lineHeight is for Spacing
    var fontSize = 12;
    if (isHtml) {
        doc.autoTable({ startY: 15, html: htmlTableId, styles: { halign: 'start' }, headStyles: { fillColor: [30, 118, 193] }, alternateRowStyles: { fillColor: [240, 240, 248] }, tableLineColor: [30, 118, 193], tableLineWidth: 0.1, });
    }
    else {
        doc.autoTable(columns, dataSource, { startY: 15, styles: { halign: 'start' }, headStyles: { fillColor: [30, 118, 193] }, alternateRowStyles: { fillColor: [240, 240, 248] }, tableLineColor: [30, 118, 193], tableLineWidth: 0.1, });
    }
    doc.setFontSize(fontSize);


    // var img = new Image(); //this mount a variable to img
    // img.src = 'images/signaturePDF.png' //asign the src to the img variable
    // doc.addImage(img, 'png', 100, doc.autoTable.previous.finalY + lineHeight * 1.5 + offsetY, 20, 20)// use the method doc.autoTable.previous.finalY + lineHeight * 1.5 + offsetY to be able to position the image of the signature below the table at a safe distance from it 
    // doc.text(90, doc.autoTable.previous.finalY + lineHeight * 2 + offsetY, "Juan Jose Urquiza") // later add the text below the signature
    // doc.text(89, doc.autoTable.previous.finalY + lineHeight * 3 + offsetY, "Gerente FinanceAR") //more text

    addFooters(doc, orientation);
    doc.save(`${reportName}_${moment.utc().format('L')}.pdf`);

}

export const generatePdfReportFromHtml = ({ html, orientation = "p", format = "a4", title = "", reportName = "report" }) => {

    const doc = new jsPDF(
        {
            orientation: orientation,
            unit: 'mm',
            format: format,
            putOnlyUsedFonts: true
        }
    );

    var offsetY = 30; //var offsetY is for spacing
    var lineHeight = 6.49111111111111; //var lineHeight is for Spacing
    var fontSize = 12;
    doc.html(html);
    doc.setFontSize(fontSize);


    // var img = new Image(); //this mount a variable to img
    // img.src = 'images/signaturePDF.png' //asign the src to the img variable
    // doc.addImage(img, 'png', 100, doc.autoTable.previous.finalY + lineHeight * 1.5 + offsetY, 20, 20)// use the method doc.autoTable.previous.finalY + lineHeight * 1.5 + offsetY to be able to position the image of the signature below the table at a safe distance from it 
    // doc.text(90, doc.autoTable.previous.finalY + lineHeight * 2 + offsetY, "Juan Jose Urquiza") // later add the text below the signature
    // doc.text(89, doc.autoTable.previous.finalY + lineHeight * 3 + offsetY, "Gerente FinanceAR") //more text

    addFooters(doc, orientation);
    doc.save(`${reportName}_${moment.utc().format('L')}.pdf`);

}

const addFooters = (doc, orientation) => {
    const pageCount = doc.internal.getNumberOfPages()
    // console.log(doc)
    // doc.setFont('helvetica', 'italic')
    doc.setFontSize(8)
    for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, (doc.internal.pageSize.height - 5), {
            align: 'center'
        })
    }
}

export const getDirtyValues = (values, initialObject) => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    const dirtyValues = keyValues.filter(
        (keyValue) => (data[keyValue] === "" && initialObject[keyValue] !== "" ? null : data[keyValue]) !== initialObject[keyValue],
    );

    keyValues.forEach((key) => {
        if (!dirtyValues.includes(key)) delete data[key];
    });

    return data;
}

export const fixThousandSeperatedValues = (values) => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    // var thousandSeperatedRegex = /^-?(\d+,?)*(\.\d+)?$/; 
    var thousandSeperatedRegex = /^[0-9]{1,3}(,[0-9]{3})*(\.[0-9]+)?$/;
    var floatRegex = /^-?\d+(?:[.,]\d*?)?$/;
    var intRegex = /^-?\d+$/;

    keyValues.forEach((key) => {

        if (thousandSeperatedRegex.test(data[key])) {
            if (data[key].toString())
                data[key] = data[key].toString().replace(',', '');


            if (floatRegex.test(data[key])) {
                data[key] = parseFloat(data[key]);
            }
            else if (intRegex.test(data[key])) {
                data[key] = parseInt(data[key]);
            }
        }

    });

    return data;
}


export const createDatatableColumnDef = (columns) => {

    var columnDef = [];

    columnDef = columns?.sort((a, b) => a.sortOrder - b.sortOrder)?.map((column, index) =>
    (
        {

            targets: index,
            orderable: !column.checkall ? column.orderable : false,
            className: `text-center ${(column.checkall && column.type === variableTypes.picture) ? "position-relative" : ""}`,
            createdCell: (td, cellData, rowData, row, col) => {

                ReactDOM.createRoot(td).render(
                    <>
                        {column.checkall &&

                            <input type="checkbox" className={`ckbox form-check-input form-checked-info ${(column.checkall && column.type === variableTypes.picture) ? "position-absolute" : ""}`} name="name" value={rowData[column.data]}
                                onChange={(e) => {
                                    e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                                }}
                            />
                        }
                        {column.type === variableTypes.boolean &&
                            <span>{rowData[column.data] ? <i className="fe fe-check fs-16 text-success fw-bold"></i> : <i className="fe fe-x fs-16 text-danger fw-bold"></i>}</span>
                        }
                        {column.type === variableTypes.text &&
                            <span className="mb-0 mt-1 fs-12 px-1 py-1">{(column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + rowData[column.data]}</span>

                        }
                        {column.type === variableTypes.integer &&
                            <span className="badge border bg-teal custom-badge cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                                title={column.title}>
                                {((column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + rowData[column.data]) || 0}
                            </span>
                        }
                        {column.type === variableTypes.float &&
                            <span className={`badge border custom-badge cursor-pointer ${(Number(rowData[column.data] || 0) < 0) ? "bg-danger" : "bg-teal"}`} data-bs-placement="right" data-bs-toggle="tooltip"
                                title={column.title}>
                                {(column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + Number(rowData[column.data] || 0).toFixed(2)}
                            </span>
                        }
                        {column.type === variableTypes.datetime &&
                            <>
                                {moment.utc(rowData[column.data]).isValid() &&
                                    <span className="fs-12 my-auto mx-0 p-0">{(column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + moment.utc(rowData[column.data]).format("YYYY/MM/DD")}</span>
                                }
                            </>

                        }
                        {column.type === variableTypes.largetext &&
                            <DrReadMore max={50} text={((column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + rowData[column.data]) || ""} classes='fs-12'
                                data-bs-placement="right" data-bs-toggle="tooltip"
                                title={(column.data2?.length > 0 ? column.data2.split(',').filter(key => rowData[key.toString()]?.length > 0).map(x => rowData[x.toString()]).join(', ') : "") + rowData[column.data]}
                            >
                            </DrReadMore>
                        }
                        {column.type === variableTypes.picture &&
                            <div className="img-sm h-100 p-0 m-auto rounded-0">
                                <img src={rowData[column.data]} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"

                                ></img>
                            </div>
                        }
                        {column.type === variableTypes.url &&
                            <>
                                {rowData[column.data] &&
                                    <a className="cursor-pointer" target="_blank" rel="noreferrer" data-bs-placement="right" data-bs-toggle="tooltip"
                                        title={rowData[column.data]}
                                        href={rowData[column.data]}>
                                        {column.title}
                                    </a>
                                }
                            </>
                        }
                    </>

                );
            }

        }
    ));

    return columnDef;
}