import types from "../actions/store.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CUSTOMER_STORES:
            return {
                ...state, "customerStores": action.payload
            };
        case types.SET_SELECTED_STORE:
            return {
                ...state,
                selectedStore: action.payload
            };
        case types.SET_MY_STORE:
            return {
                ...state,
                myStore: action.payload
            };
        case types.SET_SELECTED_STORE_FILTER:
            return {
                ...state,
                selectedStoreFilter: action.payload
            }
        case types.SET_MY_STORE_SHIPPINGS:
            return {
                ...state,
                "myStore": {
                    ...state.myStore,
                    "shippingIds": action.payload
                }
            }
        case types.SET_MY_STORE_IS_REFRESH_TOKEN_VALID:
            return {
                ...state,
                "myStore": {
                    ...state.myStore,
                    "isRefreshTokenValid": action.payload
                }
            }
        case types.SET_SELECTED_STORE_IS_REFRESH_TOKEN_VALID:
            return {
                ...state,
                "selectedStore": {
                    ...state.selectedStore,
                    "isRefreshTokenValid": action.payload
                }
            }
        case types.SET_SELECTED_WAREHOUSE_SETTINGS:
            return {
                ...state,
                selectedMarketWarehouseSettings: action.payload
            }
        case types.SET_SELECTED_PRICING_DATA:
            return {
                ...state,
                selectedMarketPricingData: action.payload
            }
        case types.SET_LAST_SELECTED_STORE_MARKET_ID:
            return {
                ...state,
                lastSelectedStoreMarketId: action.payload
            }
        case types.GET_STORE_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                storeDashBoardData: action.payload
            }
        case types.GET_STORE_DASHBOARD_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                storeOrderStatusCounts: action.payload
            }
        case types.CLEAR_STORE_FILTER:
            return {
                ...state,
                "selectedStoreFilter": null
            }
        case types.CLEAR_SELECTED_STORE:
            return {
                ...state,
                "selectedStore": null
            }
        case types.CLEAR_MY_STORE:
            return {
                ...state,
                "myStore": null
            }
        case types.CLEAR_SELECTED_WAREHOUSE_SETTINGS:
            return {
                ...state,
                "selectedMarketWarehouseSettings": null
            }
        case types.CLEAR_SELECTED_PRICING_DATA:
            return {
                ...state,
                "selectedMarketPricingData": null
            }

        case types.CLEAR_STORE_STATE:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "customerStores": [],
    "selectedStore": null,
    "lastSelectedStoreMarketId": "",
    "myStore": null,
    "selectedStoreFilter": null,
    "selectedMarketWarehouseSettings": null,
    "selectedMarketPricingData": null,
    "storeDashBoardData": null,
    "storeOrderStatusCounts": null
}

export default reducer;