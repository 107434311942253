


export const detailedSearchList = [
  {
    onlyMe: true,
    onlyBuyBox: true,
    noBuybox: false,
    onlyLowest: true,
    noLowest: false,
    onlyMinimumPrice: true
  },
  {
    onlyMe: false,
    onlyBuyBox: true,
    noBuybox: true,
    onlyLowest: false,
    noLowest: false,
    onlyMinimumPrice: false
  },
  {
    onlyMe: true,
    onlyBuyBox: false,
    noBuybox: false,
    onlyLowest: false,
    noLowest: true,
    onlyMinimumPrice: true
  }
];

export const bestSellingMockData = [
  {
    productName: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Baby Products",
    brand: "Munchkin",
    amount: "11",
  },
  {
    productName: "MANCJE LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " A07Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Car Products",
    brand: "Lotus",
    amount: "15",
  },
  {
    productName: "LUA-VIKE VIPRAETE",
    asin: " B09Y5KJSCC",
    sku: "ZR43411688508570",
    category: "Car Products",
    brand: "Lake",
    amount: "11"
  },

];
export const updateListMockData = [
  {
    price: "18",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Baby Products",
    brand: "11",
    minPrice: "11",
    maxPrice: "25",
    time: "Updated",
    action: "1 dakika önce"
  },
  {
    price: "18",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Baby Products",
    brand: "13",
    minPrice: "11",
    maxPrice: "25",
    time: "Updated",
    action: "1 dakika önce"
  },
  {
    price: "18",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Baby Products",
    brand: "12",
    minPrice: "11",
    maxPrice: "25",
    time: "Updated",
    action: "1 dakika önce"
  },

];
export const duplicateMockData = [
  {
    productName: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Baby Products",
    updateDate: "1 dakika önce",
  },
  {
    productName: "MANCJE LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " A07Y5KNXXN",
    sku: "AMZRJP11688508570",
    category: "Car Products",
    updateDate: "10 dakika önce",
  },
  {
    productName: "LUA-VIKE VIPRAETE",
    asin: " B09Y5KJSCC",
    sku: "ZR43411688508570",
    category: "Car Products",
    updateDate: "11 dakika önce"
  },
  {
    productName: "KRMAKLS-VIKE VIPRAETE",
    asin: " B09Y5KJSCC",
    sku: "ZR43411688508570",
    category: "Car Products",
    updateDate: "20 dakika önce"
  },

];

export const productVariantsMockData = [
  {

    asin: " B09Y5KNXXN",
    createDate: "1 dakika önce",
  },
  {
    asin: " A07Y5KNXXN",
    createDate: "10 dakika önce",
  },
  {
    asin: " B09Y5KJSCC",
    createDate: "11 dakika önce"
  },
  {
    asin: " B09Y5KJSCC",
    createDate: "20 dakika önce"
  },

];


export const categoriesMockData = [
  {
    category: "AUTO_ACCESSORY",
    percentile: "%23",
    estimatedprofit: "$243.70",
    order: "35",
    productcount: "4",
  },
  {
    category: "ART_CRAFT_KIT",
    percentile: "&12",
    estimatedprofit: "$111.70",
    order: "46",
    productcount: "2",
  },
  {
    category: "ARTIFICIAL_TREE",
    percentile: "%31",
    estimatedprofit: "$343.70",
    order: "65",
    productcount: "7",
  },
  {
    category: "AIR_FRYER",
    percentile: "%13",
    estimatedprofit: "$767.70",
    order: "12",
    productcount: "32",
  },
  {
    category: "ABIS_MUSIC",
    percentile: "%53",
    estimatedprofit: "$550.70",
    order: "6",
    productcount: "117",
  },

];
export const blacklistMockData = [
  {
    id: "1",
    category: "ABIS_MUSIC",
    created: " 27.05.2023",
  },
  {
    id: "2",
    category: "AIR_FRYER",
    created: "27.05.2023",
  },
  {
    id: "3",
    category: "ARTIFICIAL_TREE",
    created: "27.05.2023",
  },
  {
    id: "4",
    category: "ART_CRAFT_KIT",
    created: "27.05.2023",
  },
  {
    id: "5",
    category: "AUTO_ACCESSORY",
    created: "27.05.2023",
  }
];
export const brandlistMockData = [
  {
    brand: "Camel",
    percentile: " Delivered",
    estimatedprofit: "CN$50.70",
    order: "Tamam",
    productcount: "5",
  },
  {
    brand: "Redbul",
    percentile: " Delivered",
    estimatedprofit: "CN$50.70",
    order: "Tamam",
    productcount: "5",
  },
  {
    brand: "Camel",
    percentile: " Delivered",
    estimatedprofit: "CN$50.70",
    order: "Tamam",
    productcount: "5",
  },
  {
    brand: "Korko",
    percentile: " Delivered",
    estimatedprofit: "CN$50.70",
    order: "Tamam",
    productcount: "5",
  },
  {
    brand: "Yorgo",
    percentile: " Delivered",
    estimatedprofit: "CN$50.70",
    order: "Tamam",
    productcount: "5",
  },

];
export const brandblackwhitelistMockData = [
  {
    brand: "Yorgo",
    trademark: "aaa",
    created: " 27.05.2023",
  },
  {
    brand: "Korko",
    trademark: "bbb",
    created: " 27.05.2023",
  },
  {
    brand: "Redbul",
    trademark: "ccc",
    created: " 27.05.2023",
  },
  {
    brand: "Camel",
    trademark: "ddd",
    created: " 27.05.2023",
  },
  {
    brand: "Camel",
    trademark: "eee",
    created: " 27.05.2023",
  },
  {
    brand: "Camel",
    trademark: "fff",
    created: " 27.05.2023",
  },
  {
    brand: "House",
    trademark: "ggg",
    created: " 27.05.2023",
  },
];
export const restrictedAndExcludedProductsMockData = [
  {
    productName: "Product AA",
    asin: "B11Y5KNXXN",
    brand: "Zonli",
    createdDate: "3 ay önce",
  },
  {
    productName: "Product BB",
    asin: "B13Y5KNXXN",
    brand: "Monli",
    createdDate: "5 ay önce",

  },
  {
    productName: "Product CC",
    asin: "B09Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 5",
    asin: "B12Y5KNXXN",
    brand: "Zonli",
    createdDate: "3 ay önce",
  },
  {
    productName: "Product 6",
    asin: "B99Y5KNXXN",
    brand: "Monli",
    createdDate: "5 ay önce",
  },
  {
    productName: "Product 7",
    asin: "B87Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 15",
    asin: "B44Y5KNXXN",
    brand: "Zonli",
    createdDate: "3 ay önce",
  },
  {
    productName: "Product 16",
    asin: "B67Y5KNXXN",
    brand: "Monli",
    createdDate: "5 ay önce",
  },
  {
    productName: "Product 17",
    asin: "B69Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 18",
    asin: "B88Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 19",
    asin: "B00Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 20",
    asin: "B01Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  },
  {
    productName: "Product 25",
    asin: "B02Y5KNXXN",
    brand: "Zonli",
    createdDate: "3 ay önce",
  },
  {
    productName: "Product 26",
    asin: "B03Y5KNXXN",
    brand: "Monli",
    createdDate: "5 ay önce",
  },
  {
    productName: "Product 27",
    asin: "B04Y5KNXXN",
    brand: "Tonli",
    createdDate: "8 ay önce",
  }
];
export const salesReportMockData = [
  {
    orderNo: "701-4275008-1293805",
    buyerOrderNo: "114-9003653-8337068",
    status: "Delivered",
    price: "CN$102.99",
    earning: "CN$9.79",
    earning2: "13%",
    date: "8 ay önce",
    fee: "CN$6.25",
  },
  {
    orderNo: "702-4826094-9142628",
    buyerOrderNo: "114-9003653-8337068",
    status: " Delivered",
    price: "CN$41.66",
    earning: "CN$1.33",
    earning2: "4%",
    date: "4 ay önce",
    fee: "CN$6.25",
  },
  {
    orderNo: "701-9987595-0200238",
    buyerOrderNo: "114-9003653-8337068",
    status: "Delivered",
    price: "CN$70.02",
    earning: "CN$-5.46",
    earning2: "-8%",
    date: "8 ay önce",
    fee: "CN$6.25",
  },
  {
    orderNo: "702-4826094-9142628",
    buyerOrderNo: "114-9003653-8337068",
    status: " Delivered",
    price: "CN$41.66",
    earning: "CN$1.33",
    earning2: "4%",
    date: "4 ay önce",
    fee: "CN$6.25",
  },
  {
    orderNo: "702-4826094-9142628",
    buyerOrderNo: "114-9003653-8337068",
    status: " Delivered",
    price: "CN$41.66",
    earning: "CN$1.33",
    earning2: "4%",
    date: "4 ay önce",
    fee: "CN$6.25",
  },
  {
    orderNo: "702-4826094-9142628",
    buyerOrderNo: "114-9003653-8337068",
    status: " Delivered",
    price: "CN$41.66",
    earning: "CN$1.33",
    earning2: "4%",
    date: "4 ay önce",
    fee: "CN$6.25",
  }

];
export const problematicProductMockData = [
  {
    fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    alerttype: "Baby Products",
    explanation: "Baby Products",
    updateDate: "1 dakika önce",
  },
  {
    fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    alerttype: "Baby Products",
    explanation: "Baby Products",
    updateDate: "1 dakika önce",
  },
  {
    fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    alerttype: "Baby Products",
    explanation: "Baby Products",
    updateDate: "1 dakika önce",
  },
  {
    fieldname: "MUNCHKIN® LULLA-VIBE™ VIBRATING MATTRESS PAD SLEE",
    asin: " B09Y5KNXXN",
    sku: "AMZRJP11688508570",
    alerttype: "Baby Products",
    explanation: "Baby Products",
    updateDate: "1 dakika önce",
  },

];


export const myStoresMockData = [];

export const warehouseOrdersMockData = [
  {
    "id": "3f676e87-69f3-4c27-8e02-9e7359b42188",
    "customerMainSellerId": "e1afa174-bde1-48ae-b643-ae0b57164295",
    "buyerEmail": "c7f6mlhnywk2pqs@marketplace.amazon.ca",
    "buyerName": "",
    "sellersEmail": "sellersmart@gmail.com",
    "orderNumber": "702-1851066-9364219",
    "salesChannel": "Amazon.ca",
    "orderType": "StandardOrder",
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "imageUrl": null,
    "firstASIN": "B08JH9ZDR1",
    "warehouseSuitName": "W-2343"
  }
]

export const carrierPackages = [
  {
    "carrierCode": "ups_walleted",
    "name": "Package",
    "code": "package",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS  Express® Box - Large",
    "code": "ups__express_box_large",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS 10 KG Box®",
    "code": "ups_10_kg_box",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS 25 KG Box®",
    "code": "ups_25_kg_box",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Express® Box",
    "code": "ups_express_box",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Express® Box - Medium",
    "code": "ups_express_box_medium",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Express® Box - Small",
    "code": "ups_express_box_small",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Express® Pak",
    "code": "ups_express_pak",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Letter",
    "code": "ups_letter",
    "domestic": true,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "name": "UPS Tube",
    "code": "ups_tube",
    "domestic": true,
    "international": true
  }
]

export const carrierServices = [
  {
    "carrierCode": "ups_walleted",
    "code": "ups_ground_saver",
    "name": "UPS® Ground Saver",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_ground",
    "name": "UPS® Ground",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_ground_international",
    "name": "UPS Ground® (International)",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_standard_international",
    "name": "UPS Standard®",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_3_day_select",
    "name": "UPS 3 Day Select®",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_worldwide_saver",
    "name": "UPS Worldwide Saver®",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_worldwide_express",
    "name": "UPS Worldwide Express®",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_2nd_day_air",
    "name": "UPS 2nd Day Air®",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_worldwide_expedited",
    "name": "UPS Worldwide Expedited®",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_worldwide_express_plus",
    "name": "UPS Worldwide Express Plus®",
    "domestic": false,
    "international": true
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_2nd_day_air_am",
    "name": "UPS 2nd Day Air AM®",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_next_day_air_saver",
    "name": "UPS Next Day Air Saver®",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_next_day_air",
    "name": "UPS Next Day Air®",
    "domestic": true,
    "international": false
  },
  {
    "carrierCode": "ups_walleted",
    "code": "ups_next_day_air_early_am",
    "name": "UPS Next Day Air® Early",
    "domestic": true,
    "international": false
  }
]


export const messagesMockData = [
  {
    messageId: "1",
    amazonOrderNumber: "702-1173636-9225028",
    orderId: "7ee037a8-6c58-422b-9057-ef04690c3114",
    emailTo: "mesut@gmail.com",
    firstName: "Mesut",
    lastName: "Bayir",
    lastMessageDate: "2023-01-29T11:04:09Z",
    totalMessageCount: 3,
    messageSource: 1,
    isRead: false
  },
  {
    messageId: "2",
    amazonOrderNumber: "701-7772425-3119457",
    orderId: "364a3cdb-fc27-4c2b-a6f0-57cc889eed00",
    emailTo: "koray@gmail.com",
    firstName: "Koray",
    lastName: "Papağan",
    lastMessageDate: "2022-11-22T12:41:56Z",
    totalMessageCount: 2,
    messageSource: 1,
    isRead: true
  },
  {
    messageId: "3",
    amazonOrderNumber: "702-0335281-5869059",
    orderId: "2c4c3088-0bb4-46c0-aede-0a8e6760fc4c",
    emailTo: "serkan@gmail.com",
    firstName: "Serkan",
    lastName: "Doğan",
    lastMessageDate: "2022-11-15T11:36:55Z",
    totalMessageCount: 1,
    messageSource: 1,
    isRead: false
  },
  {
    messageId: "4",
    amazonOrderNumber: "702-7423687-5156205",
    orderId: "14697e10-cc56-4796-b134-a31d3f2d80c9",
    emailTo: "ismail@gmail.com",
    firstName: "İsmail Cem",
    lastName: "Babaoğlu",
    lastMessageDate: "2023-01-06T16:33:00Z",
    totalMessageCount: 2,
    messageSource: 1,
    isRead: true
  }
];

export const messageDetailsMockData = [
  {
    emailFrom: "mesut@gmail.com",
    firstName: "Mesut",
    lastName: "Bayir",
    sendDate: "2024-02-01T01:05:55Z",
    emailBody: "",
    isSendMe: true,
    messageText: `Hello - are you able to provide any information on the shipping/tracking of this item? It was supposed to be delivered yesterday and I have not received it. `,

  },
  {
    emailFrom: "sellerzeynepyucel@gmail.com",
    firstName: "Zeynep",
    lastName: "Yücel",
    sendDate: "2024-03-03T09:47:55Z",
    emailBody: "",
    isSendMe: false,
    messageText: `Hello Candice,\n\nWe've received your message regarding shipment tracking information for order 701-5085630-6133803. Listed below is the requested information.\n\n--------------------\nCarrier: Canada Post\nTracking number: 7262314449192019\n--------------------\n\nSincerely,\nGaint XL Shop `,

  },
  {
    emailFrom: "sellerzeynepyucel@gmail.com",
    firstName: "Zeynep",
    lastName: "Yücel",
    sendDate: "2024-03-06T17:12:11Z",
    emailBody: "",
    isSendMe: false,
    messageText: `Hi Candice:

        Thank you for shopping with us. Amazon seller Giant XL Shop has encountered an unexpected problem with completing your order.
        
        Message from seller Giant XL Shop:
        
        Our dear customer,
        
        When we control the systems, we see that your product has been delivered to you. Your satisfaction is very important to us.
        Did the product reach you? Did it earn your satisfaction? Your feedback is very important to us.
        
        Best Regards
        Giant XL Shop
        
        You can send an anonymous message to the seller Giant XL Shop, that does not expose your real email address, by replying to this email.
        
        If you were contacted inappropriately by the seller, please report this message.
        
        We hope to see you again soon. `,

  },
  {
    emailFrom: "mesut@gmail.com",
    firstName: "Mesut",
    lastName: "Bayir",
    sendDate: "2024-03-07T13:21:27Z",
    emailBody: "",
    isSendMe: true,
    messageText: `Hello - thank you. :) `,

  }
]

export const mywalletMockData = {
  myCurrency: 352.83,
  mycurrencyCode: "USD",
  myCards: [
    {
      cardId: "guid",
      cardHolderName: "Koray Papağan",
      cardNumber: "5445000044542344",
      isDefault: true
    },
    {
      cardId: "guid",
      cardHolderName: "Mesut Bayir",
      cardNumber: "344500004454234",
      isDefault: false
    },
    {
      cardId: "guid",
      cardHolderName: "Serkan Doğan",
      cardNumber: "454500004454234",
      isDefault: false
    },
    {
      cardId: "guid",
      cardHolderName: "Serkan Doğan",
      cardNumber: "634500004454234",
      isDefault: false
    }
  ]
}

export const walletSummaryMockData = [
  {
    id: "1",
    currency: 11.2,
    currencyCode: "$",
    transactionType: "1", //0 -> income, 1-> outgoing
    transactionDate: "2024-02-24T11:36:55Z",
    transactionSource: "SellerDoping",
    description: "Geri İade"
  },
  {
    id: "2",
    currency: 350,
    currencyCode: "$",
    transactionType: "1", //0 -> income, 1-> outgoing
    transactionDate: "2024-02-25T14:36:55Z",
    transactionSource: "Wallet",
    description: "Cüzdana Yüklendi"
  },
  {
    id: "3",
    currency: 50,
    currencyCode: "$",
    transactionType: "2", //0 -> income, 1-> outgoing
    transactionDate: "2024-02-01T11:36:55Z",
    transactionSource: "Wallet",
    description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
  },
  {
    id: "4",
    currency: 50,
    currencyCode: "$",
    transactionType: "2", //0 -> income, 1-> outgoing
    transactionDate: "2024-01-01T11:36:55Z",
    transactionSource: "Wallet",
    description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
  },
  {
    id: "5",
    currency: 50,
    currencyCode: "$",
    transactionType: "2", //0 -> income, 1-> outgoing
    transactionDate: "2023-12-01T11:36:55Z",
    transactionSource: "Wallet",
    description: "Otomatik Aylık Üyelik Cüzdandan Çekildi"
  }

]

export const inventoryPageFilterMockData = {
  onlySoldByMe: false,
  onlyIfNoBuyboxPriceAtDestinationMarket: false,
  onlyIfMeBuyboxSeller: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfMeLowestSeller: "0",
  onlyIfPrimeSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfWithSalesRankAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfWithChineseSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfWithAmazonSellersAtDestinationMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfProductPriceIsMinOrMax: "0", // 0 - kapalı, 1 - Min, 2 - Max


  onlyIfShippingPriceIsHigherThanPriceAtSourceMarket: false,
  onlyIfUnAvailableProductsAtSourceMarket: false,
  onlyIfBrandAndSellerNameIsSameAtSourceMarket: false,
  onlyIfWithSalesRankAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfSoldByAmazonAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfFBAProductsAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfWithCouponAtSourceMarket: "0", // 0 - kapalı, 1 - evet, 2 - hayır



  onlyIfChangeToFulfilledByAmazon: false,
  onlyIfWithDiscount: false,
  onlyIfNoShip: false,
  onlyIfNoShippingInfo: false,
  onlyIfNoImportFeeInfo: false,
  onlyIfPriceAppearsInCart: false,
  onlyIfNoPriceInfo: false,

  onlyIfAsinIsDifferent: false,
  onlyIfProfitBasedOnAsin: false, // asin bazlı kar belirlediğim yani smartpricerde asin bazlı stratejisi olanlar
  onlyIfIHaveSoldBefore: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfTrademarkIsRegistered: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfSellerDopingProducts: "0", // 0 - kapalı, 1 - evet, 2 - hayır
  onlyIfNoDeliveryDateInfo: "0", // 0 - kapalı, 1 - global ürünler, 2 - sellerdoping ürünleri, 3 - hepsi

  minPrice: null,
  maxPrice: null,
  minQuantity: null,
  maxQuantity: null,
  minTotalCost: null,
  maxTotalCost: null,

  minPriceCouponValue: null,
  maxPriceCouponValue: null,
  minPercentCouponValue: null,
  maxPercentCouponValue: null,  // bazı ürünlerde direk 15 dolar indirim kuponu var örnek bazılarında %5 örneğin eğer iki kupon tipine göre filtre girersem ürünleri joinlemen gerekir

  minProfitPercent: null,
  maxProfitPercent: null,
  productCreatedDateStart: null,
  productCreatedDateEnd: null, // tarih aralığında eklenen ürünler

  lastUpdatedDateStart: null, // son 3 gün içinde eklenen ürünler isticem örneğin ben sana tarih göndericem o tarihten büyük güncelleme tarihi olanları gönderirsin

  productCategory: "guid",
  productSubCategory: "string", // elle giriliyor 
  productBrand: "string", // elle giriliyor
  minProductSourceMarketPrice: null,
  maxProductSourceMarketPrice: null,
  minShippingPrice: null,
  maxShippingPrice: null,

  minPrimeSellerCountAtSourceMarket: null,
  maxPrimeSellerCountAtSourceMarket: null,

  minSourceMarketBuyboxSellersBrandCount: null,
  maxSourceMarketBuyboxSellersBrandCount: null, // source markette buybox satıcısı olanın sattığı marka sayısı

  minQuantityAtSourceMarket: null,
  maxQuantityAtSourceMarket: null,

  minSalesRankAtSourceMarket: null,
  maxSalesRankAtSourceMarket: null,

  starsValue: null, // decimal
  starsOperation: "<", // < , <= , >, >= , =

  reviewCount: null, // int
  reviewOperation: "<", // < , <= , >, >= , =

  minSellerDopingCost: null,
  maxSellerDopingCost: null,

  minSellerDopingLBS: null, // decimal
  maxSellerDopingLBS: null,


  minSalesRankAtDestinationMarket: null,
  maxSalesRankAtDestinationMarket: null,

  minOtherSellersCount: null,
  maxOtherSellersCount: null,

  minPricePercentFromBuyboxSellerAndMe: null,// decimal
  maxPricePercentFromBuyboxSellerAndMe: null,// decimal

  minPricePercentFromLowestSellerAndMe: null,// decimal
  maxPricePercentFromLowestSellerAndMe: null,// decimal

  minPricePercentFromAveragePriceAndMyPrice: null,// decimal
  maxPricePercentFromAveragePriceAndMyPrice: null,// decimal

  minPricePercentFromManufacturerAndMe: null,// decimal
  maxPricePercentFromManufacturerAndMe: null,// decimal

  minPricePercentFromAmazonAndMe: null,// decimal
  maxPricePercentFromAmazonAndMe: null,// decimal

  minPricePercentFromCompetitionThresholdAndMe: null,// decimal
  maxPricePercentFromCompetitionThresholdAndMe: null,// decimal


}

export const smartPricerQuestionMockData = {

  "id": "00000000-0000-0000-0000-000000000000",
  "smartPricerName": null,
  "smartPricerStrategy": 0,
  "customerMainSellerId": "00000000-0000-0000-0000-000000000000",
  "productCategoryId": null,
  "asins": [],
  smartPricerCategories: [
    {
      "id": "71fb6696-1f15-463b-a1f1-f5e60c2624f0",
      "categoryName": "Taşıma süresini devredışı bırak",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "BiggerThan",       //*
      "inputType": "TextBox-Numeric", //*
      "answerId": null,
      "answerText": null,
      "sortOrder": 2,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""//*
    },
    {
      "id": "59f394cd-e0d4-45ec-8f28-5674bc72b724",
      "categoryName": "Mağaza ve ürün olarak  Backorder takip et",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "Yes/No",
      "inputType": "CheckBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 3,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""//*
    },
    {
      "id": "cb12872d-7a83-4267-868c-ebb2b5a9b797",
      "categoryName": "Feedback sayısı - Mağaza sayısı",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "BiggerThan",
      "inputType": "TextBox-Numeric",
      "answerId": null,
      "answerText": null,
      "sortOrder": 4,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""//*
    },
    {
      "id": "f2aa540f-cad1-4c85-ab54-ad344718151d",
      "categoryName": "Geri bildirim puanı",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "LessThan",
      "inputType": "Textbox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 5,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""//*
    },
    {
      "id": "30b93438-8342-4c29-8f9b-4325632d8b3e",
      "categoryName": "Rekabet",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 6,
      smartPricerTypeSubs: [
        {
          "id": "9d11e913-2462-4915-8819-565c2cd197e5",
          "typeSubName": "Buybox ve en düşük FBA",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "5a95e36e-7fb3-4d1f-af80-69c8d608cfd4",
          "typeSubName": "Sadece Buybox",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "00fd6f45-e424-4d46-83fd-1ae70574b0a6",
          "typeSubName": "En düşük FBS ve FBM",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "9e17a385-60b6-4c8b-9884-76d05c019d8d",
          "typeSubName": "Sadece FBA rekabet et",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "116ab476-15f9-4ff4-926f-1f08db43e309",
          "typeSubName": "En düşük",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "66eedd9c-4934-458b-8f68-81af80e159bf",
          "typeSubName": "En düşük FBM",
          "isSelected": false,
          "optionType": 0//*
        }
      ],
      "activeValueInputFormat": ""
    },
    {
      "id": "ffb1d634-d255-4921-ab6a-1506353f4f31",
      "categoryName": "Satıcıları Amazon ID'lerine göre tutun",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 7,
      smartPricerTypeSubs: [
        {
          "id": "cdb343b5-a4d9-425c-b5f3-0f99e1d839b7",
          "typeSubName": "Pasif",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "8ce006a4-c91b-4699-b05b-4da550bbb031",
          "typeSubName": "Aktif",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "a6c54d3e-9083-4a27-9762-1787056d511b",
          "typeSubName": "Liste ekle",
          "isSelected": false,
          "optionType": 999//*
        }
      ],
      "activeValueInputFormat": "merchant-list-dd|add-merchants-button" //*
    },
    {
      "id": "94e0a3d7-6429-489a-a954-1bc7aecd93f7",
      "categoryName": "Amazon ile Rekabet etme",
      "categoryDescription": "",
      "parentId": "cb361ec2-f33e-4668-9af5-3a8484fe22a4",
      "typeName": "Yes/No",
      "inputType": "CheckBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 8,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""
    },
    {
      "id": "a7472b21-efb1-4d53-9c74-c1beca61d1bb",
      "categoryName": "Rekabet durumunda nasıl fiyatlandırılsın  (Tutar yada Oran)",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 10,
      smartPricerTypeSubs: [
        {
          "id": "d062e2b2-e0b1-4f6d-bdd4-c0f6fc7d9950",
          "typeSubName": "Fiyatları eşitle",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "ded7c1a3-dade-45bd-8f76-e6bd4e2bafc0",
          "typeSubName": "Üzerinde kal",
          "isSelected": false,
          "optionType": 1//*
        },
        {
          "id": "7717aeff-bce5-42c3-8e54-0af2c3bb5a45",
          "typeSubName": "Buyboxkazan buybox altına in",
          "isSelected": false,
          "optionType": 1//*
        },
        {
          "id": "0ed1b5e5-59a7-415d-94a0-d67c7f63a253",
          "typeSubName": "Buyboxkazan minumuma kadar düşür",
          "isSelected": false,
          "optionType": 1//*
        },
        {
          "id": "5f16b6b2-5d41-46cb-91a0-6e1fe2b3b9eb",
          "typeSubName": "Fiyatın altına in",
          "isSelected": false,
          "optionType": 1//*
        }
      ],
      "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "17df3c3f-acee-420c-9281-fdd9a5389760",
      "categoryName": "Rekabet fiyat, fiyatla aynı kalırsa",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 11,
      smartPricerTypeSubs: [
        {
          "id": "6d0a4201-9bfb-4781-a5f8-03596641d445",
          "typeSubName": "Min fiyat kullan",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "e5412739-74c1-47c1-b63e-038af3451c67",
          "typeSubName": "Repicer yapma",
          "isSelected": false,
          "optionType": 0//*
        }
      ],
      "activeValueInputFormat": ""
    },
    {
      "id": "81fbe28b-574e-440c-8daa-21f46e347a78",
      "categoryName": "Stok miktarına göre kural uygulansın  mı?",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 12,
      smartPricerTypeSubs: [
        {
          "id": "59457b37-0474-4ee0-85f3-0b5dc3fc0c19",
          "typeSubName": "Fiyat belirleme",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "98ad8536-87a3-4749-9e6f-d547e663921b",
          "typeSubName": "Stok daha fazla ve daha az adet",
          "isSelected": false,
          "optionType": 0//*
        }
      ],
      "activeValueInputFormat": "text=Stok|more-less-dd|numeric-textbox|\n|text=Price|min-max-price-dd|price-percent-dd|numeric-textbox"//*
    },
    {
      "id": "de2cdf9b-282e-4d91-83e9-5980349fd1ac",
      "categoryName": "Rekabet fiyatı minimum altına inerse",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 13,
      smartPricerTypeSubs: [
        {
          "id": "81793691-3161-46f2-820c-6e2776ac234e",
          "typeSubName": "Min fiyat kullan",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "312ea140-c0f3-4c3d-8892-ea101d209fa0",
          "typeSubName": "Repicer yapma",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "000000000",  // bu opsion bizde yok eklemek lazım
          "typeSubName": "Fiyat belirleme",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        }
      ],
      "activeValueInputFormat": "min-max-price-dd|price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "17ecf11b-1b92-4b5c-b3f3-514ed59d26e8",
      "categoryName": "Stok bittiğinde maksimum fiyatı kullan",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Yes/No",
      "inputType": "CheckBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 14,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""
    },
    {
      "id": "ce9d2868-7f66-4cef-8552-6567875471c2",
      "categoryName": "FBA rekabeti ayrıca olsun mu (Tutar yada Oran)",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 15,
      smartPricerTypeSubs: [
        {
          "id": "7120ab46-460d-4406-bbe7-11ced1cb5130",
          "typeSubName": "Altında kal",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "98aeee57-16b0-4f06-b3e2-62021f21b6b9",
          "typeSubName": "Üstünde kal",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "2eb49f56-c31f-4497-8ce6-cf5caf02eb46",
          "typeSubName": "Fiyatları eşitle",
          "isSelected": false,
          "optionType": 0
        }
      ],
      "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "b9e7ae43-5a68-4027-8172-acf8ee7ff9d1",
      "categoryName": "Amazon'a göre fiyatlama (Tutar veya Oran) ",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 16,
      smartPricerTypeSubs: [
        {
          "id": "8304ebc5-0336-44fb-9698-683118f27934",
          "typeSubName": "Altında kal",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "50921037-9a9c-4bdb-8e92-4ad02d4385b3",
          "typeSubName": "Üstünde kal",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "c73b26da-c099-4e29-8ea1-f43a9d33e9e2",
          "typeSubName": "Fiyatları eşitle",
          "isSelected": false,
          "optionType": 0
        }
      ],
      "activeValueInputFormat": "price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "2e09b6d9-ae1a-45f4-b03e-c953a5e6b4f7",
      "categoryName": "Listede başka satıcı yoksa",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 17,
      smartPricerTypeSubs: [
        {
          "id": "033c659f-01a2-4059-a27d-902fb04179b9",
          "typeSubName": "Kademeli fiyat arttır",
          "isSelected": false,
          "optionType": 2 // optionType 2 - kademeli fiyat belirlemeli
        },
        {
          "id": "88fed535-bed6-4787-ac33-a8e4de1ca017",
          "typeSubName": "Fiyat belirleme",
          "isSelected": false,
          "optionType": 1 // optionType 1 - fiyat belirlemeli
        },
        {
          "id": "6205d7cd-21a0-40fb-91e8-05f34e33f500",
          "typeSubName": "Repricer Yapma",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "8e0e51f3-0b6a-4930-8b48-5925692dcf80",
          "typeSubName": "Maksimum fiyat kullan",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "b9e47ab5-71a7-421c-b76c-23ed7523a22a",
          "typeSubName": "Minumum fiyat kullan",
          "isSelected": false,
          "optionType": 0
        }
      ],
      "activeValueInputFormat": "optiontype=1??min-max-price-dd|optiontype=2??minutes-dd|price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "606b0300-49e9-4b69-8551-0da61ff3510a",
      "categoryName": "Listede buybox yoksa",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Selection",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 18,
      smartPricerTypeSubs: [
        {
          "id": "bb55227c-2dfd-4dd0-861f-77e8f5adfbe2",
          "typeSubName": "En düşük fiyatta kal",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "9b9929de-52c2-4a09-bef6-5d046ae67acc",
          "typeSubName": "Fiyatı belirle",
          "isSelected": false,
          "optionType": 1
        },
        {
          "id": "bc35de54-1d28-493a-abf4-b92e50fa0439",
          "typeSubName": "Repricer Yapma",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "6968f6d5-11ca-4f62-975e-a0d5c5c5483b",
          "typeSubName": "Maksimum fiyat kullan",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "0fd0f016-207b-4d75-99e9-960e7295e404",
          "typeSubName": "Minumum fiyat kullan",
          "isSelected": false,
          "optionType": 0
        }
      ],
      "activeValueInputFormat": "min-max-price-dd|price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "0dbb6a6d-6dad-42d0-bb63-409cb67451cf", // bu soruyu kaldıracakmışız ozan söyledi
      "categoryName": "Satış hızına göre karar verilsin mi?",
      "categoryDescription": "",
      "parentId": "e0c805ba-90ff-4d31-bae5-b4efb879b58d",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 19,
      smartPricerTypeSubs: [
        {
          "id": "113f9033-0235-45d3-ad5a-899993300b89",
          "typeSubName": "Zaman",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "1114496d-563e-4e21-8dd3-7a30ff3932d9",
          "typeSubName": "Adet",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "afc964dd-ec0c-4af1-98c6-5c1f64ffa8d4",
          "typeSubName": "Fiyatlandır",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "d2e44f72-89a1-4678-907c-5bc30da97f70",
          "typeSubName": "Daha çok",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "3fcc4dfb-e6fa-4241-acec-38b78d2d1bf2",
          "typeSubName": "Daha az",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "ee37e392-155e-46ef-bcc9-c153cc495262",
          "typeSubName": "Aktif",
          "isSelected": false,
          "optionType": 0//*
        },
        {
          "id": "7e35338e-8ca3-4c30-a6d9-98e55f457115",
          "typeSubName": "Pasif",
          "isSelected": false,
          "optionType": 0//*
        }
      ],
      "activeValueInputFormat": ""
    },
    {
      "id": "f6a3fb5e-c746-4d48-918b-e31c87583f2e",
      "categoryName": "Buybox'ı kazandığında",
      "categoryDescription": "",
      "parentId": "f02acf99-244d-44e3-a272-90594f417024",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": null,
      "sortOrder": 21,
      smartPricerTypeSubs: [
        {
          "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
          "typeSubName": "Repricer yapma",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
          "typeSubName": "Fiyatı düşür",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "df8780ad-51fe-4fa5-ab90-2bb99a551cf1",
          "typeSubName": "Fiyatı arttır kendi fiyatına göre (Senden bir sonraki)",
          "isSelected": false,
          "optionType": 2
        },
        {
          "id": "230fc8e0-c75b-4607-8b77-2f5c3bed00be",
          "typeSubName": "Rakibe göre fiyat arttır (en pahallıya göre)",
          "isSelected": false,
          "optionType": 1
        }
      ],
      "activeValueInputFormat": "optiontype=2??minutes-dd|price-percent-dd|numeric-textbox" //*
    },
    {
      "id": "66a19ce8-0913-48dd-a027-6aaf80354285",
      "categoryName": "Min. Kâr - Getiri",
      "categoryDescription": "",
      "parentId": "9fe81f48-ee29-4d68-80a0-4ae8158e902a",
      "typeName": "Calculation",       //*
      "inputType": "TextBox-Numeric", //*
      "answerId": null,
      "answerText": null,
      "sortOrder": 23,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""
    },
    {
      "id": "66a19ce8-0913-48dd-a027-6aaf80354285",
      "categoryName": "Max. Kâr - Getiri",
      "categoryDescription": "",
      "parentId": "9fe81f48-ee29-4d68-80a0-4ae8158e902a",
      "typeName": "Calculation",       //*
      "inputType": "TextBox-Numeric", //*
      "answerId": null,
      "answerText": null,
      "sortOrder": 24,
      "smartPricerTypeSubs": [],
      "activeValueInputFormat": ""
    },

    // {
    //     "id": "x6a3fb5e-c746-4d48-918b-e31c87583f2e",
    //     "categoryName": "deneme",
    //     "categoryDescription": "",
    //     "parentId": "f02acf99-244d-44e3-a272-90594f417024",
    //     "typeName": "Calculation",
    //     "inputType": "DropdownBox",
    //     "answerId": null,
    //     "answerText": null,
    //     "sortOrder": 21,
    //     smartPricerTypeSubs: [
    //         {
    //             "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
    //             "typeSubName": "Repricer yapma",
    //             "isSelected": false,
    //             "optionType": 0
    //         },
    //         {
    //             "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
    //             "typeSubName": "Fiyatı düşür",
    //             "isSelected": false,
    //             "optionType": 1
    //         },
    //         {
    //             "id": "df8780ad-51fe-4fa5-ab90-2bb99a551cf1",
    //             "typeSubName": "Fiyatı arttır kendi fiyatına göre (Senden bir sonraki)",
    //             "isSelected": false,
    //             "optionType": 2
    //         }
    //     ],
    //     "activeValueInputFormat": "text=Minutes|optiontype=1??minutes-dd|optiontype=2??minutes-dd|\n|optiontype=2??price-percent-dd|optiontype=2??numeric-textbox" //*
    // },



    {
      "id": "w6a3fb5e-c746-4d48-918b-e31c87583f2e",
      "categoryName": "deneme",
      "categoryDescription": "",
      "parentId": "f02acf99-244d-44e3-a272-90594f417024",
      "typeName": "Calculation",
      "inputType": "DropdownBox",
      "answerId": null,
      "answerText": "cad|12",
      "sortOrder": 21,
      smartPricerTypeSubs: [
        {
          "id": "485c105e-6433-48ae-9ba1-abc9d1998efc",
          "typeSubName": "hi.birşey yapam",
          "isSelected": false,
          "optionType": 0
        },
        {
          "id": "155cf188-a4a5-4ef9-98a5-4f468e9b5e24",
          "typeSubName": "Fiyatı düşür",
          "isSelected": false,
          "optionType": 1
        },
        {
          "id": "s55cf188-a4a5-4ef9-98a5-4f468e9b5e24",
          "typeSubName": "Fiyatı düşür",
          "isSelected": false,
          "optionType": 2
        }
      ],
      "activeValueInputFormat": "optiontype=1??minutes-dd|optiontype=2??price-percent-dd|optiontype=2??numeric-textbox" //*
    },


  ]

}


export const orderDetailSaleSummaryMockData = {
  currencyCode: "usd",
  price: 39.87,
  shippingCost: 11.45,
  cost: 23.32,
  sellerDopingShippingCost: 15.22,
  importTaxDuty: 0,
  amazonFee: 10.5,
  feeRefund: 0,
  tax: 36.6,
  earning: 42.98,
  profit: 19.2
}


export const ordersMockData = [
  {
    "id": "60a6327c-a56e-441d-968b-37b4a7fe269c",
    "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
    "buyerEmail": "776649sybj2nwhk@marketplace.amazon.ca",
    "buyerName": null,
    "buyerCounty": null,
    "buyerTaxingRegion": null,
    "buyerCompanyLegalName": null,
    "purchaseOrderNumber": null,
    "orderNumber": "702-7912556-1088208",
    "earliestDeliveryDate": "2024-07-12T07:00:00",
    "earliestShipDate": "2024-07-08T07:00:00",
    "salesChannel": "Amazon.ca",
    "orderStatus": "PartiallyShipped",
    "numberOfItemsShipped": 0,
    "orderType": "StandardOrder",
    "isPremiumOrder": false,
    "isPrime": false,
    "fulfillmentChannel": "MFN",
    "numberOfItemsUnshipped": 1,
    "hasRegulatedItems": false,
    "isReplacementOrder": false,
    "isSoldByAB": false,
    "latestShipDate": "2024-07-09T06:59:59",
    "shipServiceLevel": "Std CA D2D Dom",
    "isISPU": false,
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "latestDeliveryDate": "2024-07-19T06:59:59",
    "purchaseDate": "2024-07-08T01:02:42",
    "isAccessPointOrder": false,
    "paymentMethod": "Other",
    "isBusinessOrder": false,
    "ordertotalCurrencyCode": "CAD",
    "ordertotalAmount": 82.14,
    "isGlobalExpressEnabled": false,
    "lastUpdateDate": "2024-07-08T01:33:34",
    "shipmentServiceLevelCategory": "Standard",
    "paymentMethodDetails": "Standard",
    "hasAutomatedShippingSettings": null,
    "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
    "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
    "buyerSellerOrderNumber": null,
    "buyerSellerOrderDescription": null,
    "warehouseSuitName": null,
    "sellerMailAddress": null,
    "isGift": false,
    "trackingNumber": "1ZEK63836806854099",
    "orderUrl": null,
    "isOrderable": true,
    "orderStatusId": 3,
    "products": [
      {
        "imageUrl": "https://m.media-amazon.com/images/I/61HgxLpEqJL.jpg",
        "asin": "B01N2V5M1A",
        "quantityOrdered": 1,
        "quantityShipped": 0
      },
      {
        "imageUrl": "https://m.media-amazon.com/images/I/91mQZEeg0LL.jpg",
        "asin": "B0B5ZK7V4H",
        "quantityOrdered": 1,
        "quantityShipped": 0
      },
      {
        "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
        "asin": "B0BNQGP17C",
        "quantityOrdered": 1,
        "quantityShipped": 0
      }
    ]
  },
  {
    "id": "6384d387-2e69-481d-97be-4d36049ab886",
    "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
    "buyerEmail": "p5nyhvgwk962ggv@marketplace.amazon.ca",
    "buyerName": null,
    "buyerCounty": null,
    "buyerTaxingRegion": null,
    "buyerCompanyLegalName": null,
    "purchaseOrderNumber": null,
    "orderNumber": "702-2787362-6282629",
    "earliestDeliveryDate": "2024-07-11T07:00:00",
    "earliestShipDate": "2024-07-08T07:00:00",
    "salesChannel": "Amazon.ca",
    "orderStatus": "PartiallyShipped",
    "numberOfItemsShipped": 1,
    "orderType": "StandardOrder",
    "isPremiumOrder": false,
    "isPrime": false,
    "fulfillmentChannel": "MFN",
    "numberOfItemsUnshipped": 0,
    "hasRegulatedItems": false,
    "isReplacementOrder": false,
    "isSoldByAB": false,
    "latestShipDate": "2024-07-09T06:59:59",
    "shipServiceLevel": "Std CA D2D Dom",
    "isISPU": false,
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "latestDeliveryDate": "2024-07-16T06:59:59",
    "purchaseDate": "2024-07-07T02:56:35",
    "isAccessPointOrder": false,
    "paymentMethod": "Other",
    "isBusinessOrder": false,
    "ordertotalCurrencyCode": "CAD",
    "ordertotalAmount": 73.6,
    "isGlobalExpressEnabled": false,
    "lastUpdateDate": "2024-07-07T10:17:36",
    "shipmentServiceLevelCategory": "Standard",
    "paymentMethodDetails": "Standard",
    "hasAutomatedShippingSettings": null,
    "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
    "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
    "buyerSellerOrderNumber": null,
    "buyerSellerOrderDescription": null,
    "warehouseSuitName": null,
    "sellerMailAddress": null,
    "isGift": false,
    "trackingNumber": "1ZEK63836826965431",
    "orderUrl": null,
    "isOrderable": true,
    "orderStatusId": 3,
    "products": [
      {
        "imageUrl": "https://m.media-amazon.com/images/I/91mQZEeg0LL.jpg",
        "asin": "B0B5ZK7V4H",
        "quantityOrdered": 1,
        "quantityShipped": 0
      },
      {
        "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
        "asin": "B0BNQGP17C",
        "quantityOrdered": 1,
        "quantityShipped": 0
      }
    ]
  },
  {
    "id": "0bb44d69-4dec-4eeb-b17b-5f6998401c76",
    "customerMainSellerId": "df792f62-47d2-44b9-8945-1f292fbd25a0",
    "buyerEmail": "sxc3g6lyvm890zz@marketplace.amazon.ca",
    "buyerName": null,
    "buyerCounty": null,
    "buyerTaxingRegion": null,
    "buyerCompanyLegalName": null,
    "purchaseOrderNumber": null,
    "orderNumber": "701-7537077-8745066",
    "earliestDeliveryDate": "2024-07-11T07:00:00",
    "earliestShipDate": "2024-07-08T07:00:00",
    "salesChannel": "Amazon.ca",
    "orderStatus": "PartiallyShipped",
    "numberOfItemsShipped": 1,
    "orderType": "StandardOrder",
    "isPremiumOrder": false,
    "isPrime": false,
    "fulfillmentChannel": "MFN",
    "numberOfItemsUnshipped": 0,
    "hasRegulatedItems": false,
    "isReplacementOrder": false,
    "isSoldByAB": false,
    "latestShipDate": "2024-07-09T06:59:59",
    "shipServiceLevel": "Std CA D2D Dom",
    "isISPU": false,
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "latestDeliveryDate": "2024-07-16T06:59:59",
    "purchaseDate": "2024-07-06T00:35:11",
    "isAccessPointOrder": false,
    "paymentMethod": "Other",
    "isBusinessOrder": false,
    "ordertotalCurrencyCode": "CAD",
    "ordertotalAmount": 38.07,
    "isGlobalExpressEnabled": false,
    "lastUpdateDate": "2024-07-06T08:17:16",
    "shipmentServiceLevelCategory": "Standard",
    "paymentMethodDetails": "Standard",
    "hasAutomatedShippingSettings": null,
    "orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
    "orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
    "buyerSellerOrderNumber": "114-0828502-7658642",
    "buyerSellerOrderDescription": "Extension OrderNumber update",
    "warehouseSuitName": null,
    "sellerMailAddress": null,
    "isGift": false,
    "trackingNumber": ".",
    "orderUrl": null,
    "isOrderable": true,
    "orderStatusId": 3,
    "products": [
      {
        "imageUrl": "https://m.media-amazon.com/images/I/51Od+8vYHbL.jpg",
        "asin": "B0BNQGP17C",
        "quantityOrdered": 1,
        "quantityShipped": 0
      }
    ]
  }
]


export const selectedOrderDetail = {
  "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
  "imageUrl": null,
  "buyerEmail": "d7yhscc51720f2n@marketplace.amazon.ca",
  "buyerName": null,
  "buyerCounty": null,
  "buyerTaxingRegion": null,
  "buyerCompanyLegalName": null,
  "purchaseOrderNumber": null,
  "orderNumber": "702-1470893-9983455",
  "earliestDeliveryDate": "2024-05-03T10:00:00",
  "earliestShipDate": "2024-04-29T10:00:00",
  "salesChannel": "Amazon.ca",
  "orderStatus": "Unshipped",
  "numberOfItemsShipped": 0,
  "orderType": "StandardOrder",
  "isPremiumOrder": false,
  "isPrime": false,
  "fulfillmentChannel": "MFN",
  "numberOfItemsUnshipped": 1,
  "hasRegulatedItems": false,
  "isReplacementOrder": false,
  "isSoldByAB": false,
  "latestShipDate": "2024-05-01T09:59:59",
  "shipServiceLevel": "Std CA D2D Dom",
  "isISPU": false,
  "marketplaceId": "A2EUQ1WTGCTBG2",
  "latestDeliveryDate": "2024-05-11T09:59:59",
  "purchaseDate": "2024-04-26T15:37:47",
  "isAccessPointOrder": false,
  "paymentMethod": "Other",
  "isBusinessOrder": false,
  "ordertotalCurrencyCode": "CAD",
  "ordertotalAmount": 33.29,
  "isGlobalExpressEnabled": false,
  "lastUpdateDate": "2024-04-26T16:07:24",
  "shipmentServiceLevelCategory": "Standard",
  "paymentMethodDetails": "Standard",
  "hasAutomatedShippingSettings": null,
  "defaultShipFromLocationAddress": {
    "addressLine1": "Kashechewan, #15646431",
    "addressLine2": null,
    "addressLine3": null,
    "city": "Kashechewan",
    "county": null,
    "district": null,
    "stateOrRegion": "ON",
    "municipality": null,
    "postalCode": "P0L 1S0",
    "countryCode": "CA",
    "phone": null,
    "name": "canada"
  },
  "shippingAddress": {
    "addressLine1": null,
    "addressLine2": null,
    "addressLine3": null,
    "city": "Toronto",
    "county": null,
    "district": null,
    "stateOrRegion": "Ontario",
    "municipality": null,
    "postalCode": "M5G 2R2",
    "countryCode": "CA",
    "phone": null,
    "name": null
  },
  "orderDetailList": [
    {
      "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
      "imageUrl": "https://m.media-amazon.com/images/I/61hH5Cl6ADL.jpg",
      "deemedResellerCategory": null,
      "asin": "B0C4P1Q638",
      "sellerSKU": "SF-CA-9470196344",
      "orderItemId": "99034490971721",
      "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
      "quantityOrdered": 2,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 5,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 19.46,
      "shippingCurrencyCode": "CAD",
      "shippingPriceAmount": 29.46,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 3.83,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "0.00",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "amazonOrderNumber": null,
      "amazonOrderDescription": null,
      "trackingNumber": null,
      "deliveryTime": null
    },
    {
      "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
      "imageUrl": "https://m.media-amazon.com/images/I/619mkoZCL6L.jpg",
      "deemedResellerCategory": null,
      "asin": "B0C4P1Q638",
      "sellerSKU": "SF-CA-9470196344",
      "orderItemId": "99034490971721",
      "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
      "quantityOrdered": 1,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 5,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 29.46,
      "shippingCurrencyCode": "CAD",
      "shippingPriceAmount": 29.46,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 3.83,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "0.00",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "amazonOrderNumber": null,
      "amazonOrderDescription": null,
      "trackingNumber": null,
      "deliveryTime": null
    },
    {
      "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
      "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
      "deemedResellerCategory": null,
      "asin": "B0C4P1Q638",
      "sellerSKU": "SF-CA-9470196344",
      "orderItemId": "99034490971721",
      "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
      "quantityOrdered": 1,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 5,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 29.46,
      "shippingCurrencyCode": "CAD",
      "shippingPriceAmount": 29.46,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 3.83,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "0.00",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "amazonOrderNumber": null,
      "amazonOrderDescription": null,
      "trackingNumber": null,
      "deliveryTime": null
    },
    {
      "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
      "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
      "deemedResellerCategory": null,
      "asin": "B0C4P1Q638",
      "sellerSKU": "SF-CA-9470196344",
      "orderItemId": "99034490971721",
      "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
      "quantityOrdered": 1,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 5,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 29.46,
      "shippingCurrencyCode": "CAD",
      "shippingPriceAmount": 29.46,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 3.83,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "0.00",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "amazonOrderNumber": null,
      "amazonOrderDescription": null,
      "trackingNumber": null,
      "deliveryTime": null
    },
    {
      "orderId": "3fa09ba7-d673-4324-9554-0d442b3c57f1",
      "imageUrl": "https://m.media-amazon.com/images/I/51iUdYZKzFL.jpg",
      "deemedResellerCategory": null,
      "asin": "B0C4P1Q638",
      "sellerSKU": "SF-CA-9470196344",
      "orderItemId": "99034490971721",
      "title": "Electric Toothbrush Holder for Bathroom, Plastic Bathroom Countertop Organizer Compartment, Vanity Organizer Tray, Countertop Organizer Box, Kids Desk Organizers and Storage",
      "quantityOrdered": 1,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 5,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 29.46,
      "shippingCurrencyCode": "CAD",
      "shippingPriceAmount": 29.46,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 3.83,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "0.00",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "amazonOrderNumber": null,
      "amazonOrderDescription": null,
      "trackingNumber": null,
      "deliveryTime": null
    }
  ]
}

export const trackingStatusMockData = [
  {
    "customerMainSellerId": "0",
    "buyerSellerOrderStatus": "DELIVERED TO WAREHOUSE",
    "buyerSellerOrderNumber": "",
    "updatedDate": "2024-06-23T14:36:11Z",
    "trackingNumber": "",
    "carrierName": "",
    "groupName": "0"
  },
  {
    "customerMainSellerId": "1",
    "buyerSellerOrderStatus": "SHIPPED",
    "buyerSellerOrderNumber": "",
    "updatedDate": "2024-07-27T17:04:50Z",
    "trackingNumber": "PBXSA045337935",
    "carrierName": "Pitney Bowes",
    "groupName": "1"
  },
  {
    "customerMainSellerId": "2",
    "buyerSellerOrderStatus": "DELIVERED",
    "buyerSellerOrderNumber": "",
    "updatedDate": "2024-07-28T08:11:23Z",
    "trackingNumber": "PBXSA045337935",
    "carrierName": "Pitney Bowes",
    "groupName": "2"
  }
]


export const warehousesMockData = [
  {
    "id": "84ddd5ae-12fd-4e9d-a937-3bd4b0bdf070",
    "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
    "wareHouseName": "shipping garage ",
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "district": "",
    "stateOrRegion": "",
    "municipality": "",
    "postalCode": "",
    "phone": "",
    "wareHouseShortName": "shipping garage",
    "sellMarginDefault": "",
    "logo": "",
    "isDefault": false
  },
  {
    "id": "318abb46-2208-48ba-b9a2-243208575566",
    "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
    "wareHouseName": "Depo",
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "district": "",
    "stateOrRegion": "",
    "municipality": "",
    "postalCode": "",
    "phone": "",
    "wareHouseShortName": "DP",
    "sellMarginDefault": "",
    "logo": "",
    "isDefault": false
  },
  {
    "id": "d3b1f7d5-b760-4378-932b-93bf1df1f744",
    "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
    "wareHouseName": "New Warehouse",
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "district": "",
    "stateOrRegion": "",
    "municipality": "",
    "postalCode": "",
    "phone": "",
    "wareHouseShortName": "NW",
    "sellMarginDefault": "",
    "logo": "",
    "isDefault": false
  },
  {
    "id": "e9e5939e-7ce4-4412-b8bc-2e6160252d06",
    "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
    "wareHouseName": "us depo",
    "addressLine1": "3011 Exchange CTS Suite # 105",
    "addressLine2": "",
    "addressLine3": "",
    "city": "West Palm Beach",
    "district": "Florida",
    "stateOrRegion": "US",
    "municipality": "",
    "postalCode": "33409",
    "phone": "+1 561 909 7346",
    "wareHouseShortName": "SW.",
    "sellMarginDefault": "",
    "logo": "",
    "isDefault": false
  },
  {
    "id": "dd84265f-9866-4ed0-b6c1-d3d8a3d886e1",
    "marketId": "d3d6f74a-3b6f-4260-9866-77d58b03742f",
    "wareHouseName": "UPS",
    "addressLine1": "aaaa",
    "addressLine2": "aaaa",
    "addressLine3": "ssss",
    "city": "aasas",
    "district": "sasa",
    "stateOrRegion": "AX",
    "municipality": "asas",
    "postalCode": "asas",
    "phone": "asas",
    "wareHouseShortName": "UPS",
    "sellMarginDefault": "asas",
    "logo": "",
    "isDefault": true
  },
  {
    "id": "dc5f789b-c44d-4879-81b8-691ade6f3545",
    "marketId": "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
    "wareHouseName": "Amazon",
    "addressLine1": "",
    "addressLine2": "",
    "addressLine3": "",
    "city": "",
    "district": "",
    "stateOrRegion": "",
    "municipality": "",
    "postalCode": "",
    "phone": "",
    "wareHouseShortName": "Amz.",
    "sellMarginDefault": "",
    "logo": "https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg",
    "isDefault": false
  },
  {
    "id": "2b86b634-4040-4ec4-a0f5-077f7dd3a41c",
    "marketId": "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
    "wareHouseName": "Fedex",
    "addressLine1": null,
    "addressLine2": null,
    "addressLine3": null,
    "city": null,
    "district": null,
    "stateOrRegion": null,
    "municipality": null,
    "postalCode": null,
    "phone": "",
    "wareHouseShortName": null,
    "sellMarginDefault": null,
    "logo": "",
    "isDefault": true
  }
]


export const smartpricerStrategiesMockData = [
  {
    "id": "538a64ab-05cb-4449-a2b9-89df2049f424",
    "smartPricerName": "asin bazli",
    "smartPricerStrategy": 2,
    "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
    "productCategoryId": null,
    "asins": [
      "B088B7Z4VB"
    ],
    "isBuyBoxGroup": false,
    "isLowestGroup": false,
    "isDefault": false
  },
  {
    "id": "7dfd8141-7260-4c83-afe0-7f86ccd338f1",
    "smartPricerName": "buybox",
    "smartPricerStrategy": 0,
    "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
    "productCategoryId": null,
    "asins": [
      "B0BX3X5WM2"
    ],
    "isBuyBoxGroup": true,
    "isLowestGroup": false,
    "isDefault": false

  },
  {
    "id": "a6e85415-530e-4c3d-9f75-37a53d7643c9",
    "smartPricerName": "Lowest",
    "smartPricerStrategy": 0,
    "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
    "productCategoryId": null,
    "asins": [],
    "isBuyBoxGroup": false,
    "isLowestGroup": true,
    "isDefault": true
  },
  {
    "id": "b6451822-a08f-4b2b-89cd-8944fadd7ef0",
    "smartPricerName": "Video Games Strategy",
    "smartPricerStrategy": 1,
    "customerMainSellerId": "1b1c43d0-b504-496f-83b7-bda3efb3cfa6",
    "productCategoryId": "10ab7871-c5d8-4bc3-96e1-a4cafb92d0d8",
    "asins": [],
    "isBuyBoxGroup": false,
    "isLowestGroup": false,
    "isDefault": false
  }
]


export const copyrightBrandsMockData = [
  {
    "brandName": "NIKE S.R.L.",
    "serialNumber": "79376276",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "NIKE, INC.",
    "serialNumber": "85669743",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "JPMORGAN CHASE BANK, N.A.",
    "serialNumber": "86430379",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "ALTER DOMUS (US) LLC, AS COLLATERAL AGENT",
    "serialNumber": "73473643",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "CHEN, ZHENMU",
    "serialNumber": "90421746",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "BAUER HOCKEY, LLC",
    "serialNumber": "88904770",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "SHENZHEN NICAI TECHNOLOGY CO.,LTD.",
    "serialNumber": "88347805",
    "trademarkCountry": "USA",
    "codes": ""
  },
  {
    "brandName": "SNKSTR LLC",
    "serialNumber": "88676035",
    "trademarkCountry": "USA",
    "codes": ""
  }
]


export const orderGraphichsMockData = {
  "xAxis": [
    {
      "data": "07/25/2024 00:00:00",
      "dataType": "DateTime"
    }
  ],
  "yAxis": [
    {
      "data": "1",
      "dataType": "Int32"
    }
  ]
}


export const paymentPageMockData = [
  {
    "id": "9db5dc8d-ee16-450b-af8e-7120bda03bb4",
    "planName": "Extra Sub User +1",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 100,
    "discount": 0,
    "userCount": 1,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:39:25.838255",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T12:22:57.191649"
  },
  {
    "id": "f5bc8f95-e76c-4e08-9f3a-b2deac3e1d1e",
    "planName": "Add Drop Amazon to Amazon Option",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 250,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": true,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:36:08.443305",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T12:23:14.006703"
  },
  {
    "id": "55027759-29e3-4fa5-8901-c83d2b532bdd",
    "planName": "Add SmartPricer",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": true,
    "isWarehouse": false,
    "price": 500,
    "discount": 25,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:32:53.237887",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T12:23:31.344266"
  },
  {
    "id": "5e09f862-7a60-4e49-bd16-032038255257",
    "planName": "Extra Inventory Storage 1",
    "productCount": 1000,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 200,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T16:58:39.066297",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:31:29.23982",
    "couponList": [
      {
        "id": "344f11c1-62ac-4846-a71b-5620452fbd51",
        "couponName": "Coupon 1",
        "couponCode": "SD10000000",
        "discountRate": 10,
        "maxUseCount": 10,
        "startDate": "2024-08-01T00:00:00",
        "endDate": "2024-08-31T00:00:00",
        "multipleUseCount": 1,
        "maxDiscountPrice": 300,
        "minPackagePrice": 1000,
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-12T11:56:49.222821",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-12T12:09:32.278055"
      }
    ]
  },
  {
    "id": "1a016f57-74d0-49a4-ab56-2138d7501a7e",
    "planName": "Free",
    "productCount": 1000,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 0,
    "discount": 0,
    "userCount": 1,
    "sellerCount": 1,
    "isDisable": false,
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
    "mainPlan": true,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 100,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T15:39:52.259847",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-14T10:04:30.765556"
  },
  {
    "id": "93f57d8d-3130-4cf8-b033-51f887d280aa",
    "planName": "Extra Inventory Storage 2",
    "productCount": 1500,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 300,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T17:05:04.685121",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:31:52.876747"
  },
  {
    "id": "985f7911-904b-4a56-8b3d-f3333a71dbaf",
    "planName": "Add Warehouse Option",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": true,
    "price": 150,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:34:17.763214",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T12:26:24.779758"
  },
  {
    "id": "c12cc666-a4b3-4ebe-b777-db9e1503f353",
    "planName": "Second Plan",
    "productCount": 2000,
    "isFBA": true,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 200,
    "discount": 10,
    "userCount": 2,
    "sellerCount": 2,
    "isDisable": false,
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
    "mainPlan": true,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 200,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T16:52:08.579894",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-14T12:42:42.429976"
  },
  {
    "id": "2aa876c7-35a2-4bd0-84a4-619db6da8efd",
    "planName": "Thirth Plan",
    "productCount": 2500,
    "isFBA": true,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 450,
    "discount": 10,
    "userCount": 3,
    "sellerCount": 2,
    "isDisable": false,
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
    "mainPlan": true,
    "isDropAmazon2Amazon": true,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 500,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T17:02:08.653271",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-14T12:42:49.992379",
    "couponList": [
      {
        "id": "344f11c1-62ac-4846-a71b-5620452fbd51",
        "couponName": "Coupon 1",
        "couponCode": "SD10000000",
        "discountRate": 10,
        "maxUseCount": 10,
        "startDate": "2024-08-01T00:00:00",
        "endDate": "2024-08-31T00:00:00",
        "multipleUseCount": 1,
        "maxDiscountPrice": 300,
        "minPackagePrice": 1000,
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-12T11:56:49.222821",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-12T12:09:32.278055"
      },
      {
        "id": "d1ef303b-e7de-4602-9c57-da1d234caffe",
        "couponName": "Coupon 2",
        "couponCode": "SD10000001",
        "discountRate": 10,
        "maxUseCount": 5,
        "startDate": "2024-08-01T00:00:00",
        "endDate": "2024-08-31T00:00:00",
        "multipleUseCount": 1,
        "maxDiscountPrice": 200,
        "minPackagePrice": 500,
        "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "createdDate": "2024-08-12T12:11:33.138194",
        "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
        "updatedDate": "2024-08-12T12:11:33.138195"
      }
    ]
  },
  {
    "id": "b54c5d03-0d8d-44c3-9b6d-9f5904cb370f",
    "planName": "Fifth Plan",
    "productCount": 6000,
    "isFBA": true,
    "isSmartPricer": true,
    "isWarehouse": true,
    "price": 1000,
    "discount": 10,
    "userCount": 5,
    "sellerCount": 5,
    "isDisable": true,
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
    "mainPlan": true,
    "isDropAmazon2Amazon": true,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 2000,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-14T08:19:53.931335",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-14T15:02:12.376115"
  },
  {
    "id": "7bedca47-70a5-4097-8a90-29ca935ecb92",
    "planName": "Forth Plan",
    "productCount": 4000,
    "isFBA": true,
    "isSmartPricer": true,
    "isWarehouse": true,
    "price": 750,
    "discount": 15,
    "userCount": 5,
    "sellerCount": 3,
    "isDisable": false,
    "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
    "mainPlan": true,
    "isDropAmazon2Amazon": true,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 1000,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-11T17:25:11.426826",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T06:47:27.73432"
  },
  {
    "id": "a4508ec0-791d-4704-85b5-594e08639144",
    "planName": "Extra Seller Count +3",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 500,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:38:43.700618",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:39:57.259372"
  },
  {
    "id": "be1b078f-f600-4ace-818c-4604bcc8c6ad",
    "planName": "Extra Seller Count +2",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 350,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:38:26.464257",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:40:08.016678"
  },
  {
    "id": "5f2e37a9-de0d-491c-8319-31f04caf8fc0",
    "planName": "Extra Seller Count +1",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 200,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 1,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:38:05.78589",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:40:17.199345"
  },
  {
    "id": "ce829930-0996-4e5a-a26b-ccbbc6b2ba08",
    "planName": "Extra Inventory Storage 3",
    "productCount": 2000,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 400,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:29:33.689778",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T07:40:36.794646"
  },
  {
    "id": "cee0f636-1d3d-46fc-8559-9fb43fb2b941",
    "planName": "Add FBA Option",
    "productCount": 0,
    "isFBA": true,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 300,
    "discount": 0,
    "userCount": 0,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:33:22.136991",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T10:22:27.028105"
  },
  {
    "id": "1f17d748-667c-4bc6-9019-15076ed422ce",
    "planName": "Extra Sub User +2",
    "productCount": 0,
    "isFBA": false,
    "isSmartPricer": false,
    "isWarehouse": false,
    "price": 200,
    "discount": 0,
    "userCount": 2,
    "sellerCount": 0,
    "isDisable": false,
    "description": "",
    "mainPlan": false,
    "isDropAmazon2Amazon": false,
    "trail": 0,
    "isVisible": true,
    "scanningCount": 0,
    "mustPlan": "",
    "createdBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "createdDate": "2024-08-15T07:39:45.584567",
    "updatedBy": "6de450c3-6379-4471-9a4d-7ee39383f052",
    "updatedDate": "2024-08-15T12:22:44.664097"
  }
]



export const inventoryMockData = {
  "totalRecordCount": 198,
  "filterRecordCount": 20,
  "isSuccess": true,
  "resultType": 1,
  "message": "OK",
  "validationInformation": [],
  "data": {
    "products": [
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "d49a4c15-8266-4077-93db-eb6ea1d92770",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B07PWPFT1R",
        "productPicture": "https://m.media-amazon.com/images/I/81TQPWRzDjL.jpg",
        "productName": "Ameritex Loveseat Cover Water-Resistant Quilted Furniture Protector with Back Nonslip Paws Slipcover for Dogs, Kids, Pets Loveseat Slipcover Stay in Place for Leather (Dark Grey, 46'')",
        "brandName": "Ameritex",
        "destinationProductCategory": {
          "categoryName": "Home",
          "rank": 1459531,
          "subCategories": [
            {
              "categoryName": "Sofa Slipcovers",
              "rank": 4283
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Home & Kitchen",
          "rank": 747811,
          "subCategories": [
            {
              "categoryName": "Loveseat Slipcovers",
              "rank": 60
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 932,
        "starsValue": 4.4,
        "price": 40.9108585,
        "desi": 3.11072251682,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 81.83194812500000000000000000,
        "profitPercent": 25.0000000000000000000,
        "profitPrice": 16.36638962500000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 14.440932022058823529411764706,
        "warehousePrice": 24.5547,
        "sellingPrice": 96.27288014705882352941176471,
        "minSellingPrice": 81.63940236470588235294117647,
        "maxSellingPrice": 96.27288014705882352941176471,
        "quantity": 25,
        "status": 4,
        "sku": "test3_23734",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 97.91,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A2OINMXVDVDEZM",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 97.91,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A2OINMXVDVDEZM",
        "couponPriceValue": 4.4985,
        "couponPercentValue": 15.0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 3,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": true,
        "updatedDate": "2024-12-06T08:06:11.675296"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "f9329ac7-b248-4bcc-aee9-2208f13ae51f",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B0BZP7TSVV",
        "productPicture": "https://m.media-amazon.com/images/I/71kdV+IKamL.jpg",
        "productName": "FAHKNS Z-Axis Power Feed for Milling Machine 450 in-lb Torque 0-200RPM Table Milling Machine Power Feeder 110V for Bridgeport and Similar Knee Type Milling Machines(5/8\" Diameter Shaft)",
        "brandName": "FAHKNS",
        "destinationProductCategory": {
          "categoryName": "Industrial & Scientific",
          "rank": 194149,
          "subCategories": [
            {
              "categoryName": "Power Milling Machines",
              "rank": 715
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 1017390,
          "subCategories": [
            {
              "categoryName": "Power Milling Machines",
              "rank": 473
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 199.4250885,
        "desi": 12.2797479934,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 263.2025,
        "profitPercent": 11.405099406128632172525868460,
        "profitPrice": 26.9453615,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 46.4475,
        "warehousePrice": 36.83205,
        "sellingPrice": 309.65,
        "minSellingPrice": 294.62654918823529411764705882,
        "maxSellingPrice": 347.43696838235294117647058824,
        "quantity": 0,
        "status": 4,
        "sku": "test3_29009",
        "modelNumber": "170332-LTMHE",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 309.75,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A1QZUYQJ0BRRQK",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 309.75,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A1QZUYQJ0BRRQK",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 3,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:06:11.675295"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "17c7ca7b-8651-4251-a772-254ff359a1ba",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B083ZK1G4C",
        "productPicture": "https://m.media-amazon.com/images/I/81KhGOBWneL.jpg",
        "productName": "Tyche TruEDGE Controller Extreme Hold 3.38 Fl oz (SUGAR MELON)",
        "brandName": "NICKA K NEW YORK",
        "destinationProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 157425,
          "subCategories": [
            {
              "categoryName": "Hair Styling Gels",
              "rank": 1671
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 238373,
          "subCategories": [
            {
              "categoryName": "Hair Styling Gels",
              "rank": 2745
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 9.8764460,
        "desi": 0.35053499658,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 32.159017760000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 1.820321760000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 5.6751207811764705882352941177,
        "warehousePrice": 20.46225,
        "sellingPrice": 37.834138541176470588235294118,
        "minSellingPrice": 37.834138541176470588235294118,
        "maxSellingPrice": 44.615729411764705882352941177,
        "quantity": 0,
        "status": 4,
        "sku": "test3_57479",
        "modelNumber": "20109",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 23.97,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A1UYD1L4TSNAAV",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 23.97,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A2RRQ4ONXX3AGI",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 2,
        "hasCIPOCertified": true,
        "hasUSPTOCertified": false,
        "totalSellerCount": 2,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": true,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:06:11.675294"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "fbf46be1-8e84-49b0-afff-8b9871008f39",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B000C59STM",
        "productPicture": "https://m.media-amazon.com/images/I/51D1hu7CMgL.jpg",
        "productName": "Monroe SC2963 Magnum Steering Damper",
        "brandName": "Monroe",
        "destinationProductCategory": {
          "categoryName": "Automotive",
          "rank": 58027,
          "subCategories": [
            {
              "categoryName": "Automotive Replacement Power Steering Dampers & Stabilizers",
              "rank": 12
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Automotive",
          "rank": 53128,
          "subCategories": [
            {
              "categoryName": "Automotive Replacement Power Steering Dampers & Stabilizers",
              "rank": 22
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 569,
        "starsValue": 4.5,
        "price": 51.4830210,
        "desi": 3.89997741478,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 80.59998426000000000000000000,
        "profitPercent": 6.0000000000000000000,
        "profitPrice": 4.56226326000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 14.223526634117647058823529412,
        "warehousePrice": 24.5547,
        "sellingPrice": 94.82351089411764705882352941,
        "minSellingPrice": 94.82351089411764705882352941,
        "maxSellingPrice": 111.82017794117647058823529412,
        "quantity": 25,
        "status": 4,
        "sku": "test3_71694",
        "modelNumber": "SC2963",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 63.55,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A3DWYIK6Y9EEQB",
        "listPrice": 36.76,
        "listPriceCurrencyCode": "CAD",
        "lowestPrice": 63.55,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A3DWYIK6Y9EEQB",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": true,
        "totalSellerCount": 4,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": true,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.488408"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "fbca3942-ec8f-4bf7-ad0e-8b4baed97e5d",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B0962WJ46F",
        "productPicture": "https://m.media-amazon.com/images/I/51gXFail33S.jpg",
        "productName": "Ice Roller, Face Roller 2 PCS, Massage Tools with Mini Roller for Eye, Face and Body, Relax and Alleviate Puffiness Fine Lines Minor Pain (Green)",
        "brandName": "BFASU",
        "destinationProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 203040,
          "subCategories": [
            {
              "categoryName": "Facial Toning Devices",
              "rank": 445
            },
            {
              "categoryName": "Eye Treatment Products",
              "rank": 1708
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 189215,
          "subCategories": [
            {
              "categoryName": "Facial Rollers",
              "rank": 219
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 259,
        "starsValue": 4.1,
        "price": 13.6278585,
        "desi": 0.39903669422,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 36.13551501000,
        "profitPercent": 6.0000,
        "profitPrice": 2.04540651000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 6.37685559000,
        "warehousePrice": 20.46225,
        "sellingPrice": 42.512370600,
        "minSellingPrice": 42.512370600,
        "maxSellingPrice": 50.132512500,
        "quantity": 20,
        "status": 4,
        "sku": "test3_29770",
        "modelNumber": "MYF02+03",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 37.44,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A2OYMZQJOWF8TS",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 37.44,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A2OYMZQJOWF8TS",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 4,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.473998"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "fb7ad57a-215c-404e-ae54-b5da07318437",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B0BL2H31L4",
        "productPicture": "https://m.media-amazon.com/images/I/61IlyptsmtL.jpg",
        "productName": "AEARY Pegboard Drawer Organizer for Kitchen Cabinet, Plate Organizers for Cabinets, Adjustable Cupboard Pegboard Tray, Dish Racks Bowl Drying Racks, Expandable Plate Holders Storage (2pcs, White)",
        "brandName": "AEARY",
        "destinationProductCategory": {
          "categoryName": "Home",
          "rank": 895873,
          "subCategories": [
            {
              "categoryName": "Kitchen Storage Accessories",
              "rank": 4944
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Kitchen & Dining",
          "rank": 110289,
          "subCategories": [
            {
              "categoryName": "Dish Racks",
              "rank": 690
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 27.2420755,
        "desi": 1.58953290902,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 52.012584030000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 2.944108530000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 9.178691299411764705882352941,
        "warehousePrice": 21.8264,
        "sellingPrice": 61.191275329411764705882352941,
        "minSellingPrice": 61.191275329411764705882352941,
        "maxSellingPrice": 72.159522794117647058823529412,
        "quantity": 0,
        "status": 4,
        "sku": "test3_41360",
        "modelNumber": "Dish Rack",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 54.53,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A18E06S5AR002B",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 54.53,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A18E06S5AR002B",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 2,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.463902"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "fb2fc802-143b-40fd-8a0b-45e7fc4c4dc4",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B07T81LWPQ",
        "productPicture": "https://m.media-amazon.com/images/I/71WA1ROQGsL.jpg",
        "productName": "HangDone Ribbed Wall Anchors #4-#6 7/8-Inch with Screws 100-Pack, Yellow",
        "brandName": "HangDone",
        "destinationProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 288347,
          "subCategories": [
            {
              "categoryName": "Hollow-wall Anchors",
              "rank": 118
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Industrial & Scientific",
          "rank": 8439,
          "subCategories": [
            {
              "categoryName": "Drywall Anchors",
              "rank": 25
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 13.6278585,
        "desi": 0.4078551847,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 36.13551501000,
        "profitPercent": 6.0000,
        "profitPrice": 2.04540651000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 6.37685559000,
        "warehousePrice": 20.46225,
        "sellingPrice": 42.512370600,
        "minSellingPrice": 42.512370600,
        "maxSellingPrice": 50.132512500,
        "quantity": 0,
        "status": 4,
        "sku": "test3_95338",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 39.72,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A3RUPKIONPB2W6",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 39.72,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A3RUPKIONPB2W6",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 4,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.449572"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "df8fa1c8-c54d-4288-978a-a8ca8ed1506d",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B079CNV3R8",
        "productPicture": "https://m.media-amazon.com/images/I/51RcdchvN8L.jpg",
        "productName": "Complete Skin Solutions Copper Peptide Face Serum Collagen - With Anti Aging Skin Solutions Properties: 1oz/30ml Anti-Wrinkle Formula For Youthful Skin-Promotes Collagen Production And Cell RejuvenationÃâ‚¬â€œHeals Micro Wounds",
        "brandName": "COMPLETE SKIN SOLUTIONS",
        "destinationProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 61905,
          "subCategories": [
            {
              "categoryName": "Facial Serums",
              "rank": 871
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Beauty & Personal Care",
          "rank": 28157,
          "subCategories": [
            {
              "categoryName": "Facial Serums",
              "rank": 646
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 1060,
        "starsValue": 4.4,
        "price": 42.2750085,
        "desi": 0.20062065842,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 66.501494010000000000000000001,
        "profitPercent": 6.00,
        "profitPrice": 3.764235510000000000000000001,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 11.735557766470588235294117647,
        "warehousePrice": 20.46225,
        "sellingPrice": 78.237051776470588235294117648,
        "minSellingPrice": 78.237051776470588235294117648,
        "maxSellingPrice": 92.26067426470588235294117647,
        "quantity": 25,
        "status": 4,
        "sku": "test3_43442",
        "modelNumber": "CPS1301",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 78.3,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A1QZUYQJ0BRRQK",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 78.19,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A2UNSMLO5W5JHM",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 7,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.439475"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "d4aacba9-d88e-49d6-9b67-bd59f08d0867",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B08G58CJ2T",
        "productPicture": "https://m.media-amazon.com/images/I/71lLJZxD0lL.jpg",
        "productName": "Dynamic Discs Prime Burst Truth Disc Golf Midrange | Stable Frisbee Golf Midrange | 173-176g | Versatile Golf Disc | Stamp Colors Will Vary (Gray)",
        "brandName": "D·D DYNAMIC DISCS",
        "destinationProductCategory": {
          "categoryName": "Sports & Outdoors",
          "rank": 131007,
          "subCategories": [
            {
              "categoryName": "Midrange Disc Golf Discs",
              "rank": 92
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Sports & Outdoors",
          "rank": 121722,
          "subCategories": [
            {
              "categoryName": "Midrange Disc Golf Discs",
              "rank": 55
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 14.9920085,
        "desi": 0.50044933474,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 37.581514010000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 2.127255510000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 6.6320318841176470588235294118,
        "warehousePrice": 20.46225,
        "sellingPrice": 44.213545894117647058823529412,
        "minSellingPrice": 44.213545894117647058823529412,
        "maxSellingPrice": 52.138615441176470588235294118,
        "quantity": 0,
        "status": 4,
        "sku": "test3_37112",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 42.64,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A1KK7F8AFQNRKK",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 42.64,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A1KK7F8AFQNRKK",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 1,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.427048"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "bd563db5-1801-4985-a17c-8a26cd63275c",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B088PQ3NWW",
        "productPicture": "https://m.media-amazon.com/images/I/613RZggx+DL.jpg",
        "productName": "TBMPOY Men's 7\" / 9\" Running Hiking Shorts Quick Dry Athletic Gym Outdoor Sports Short 3 Zipper Pockets, A8-royal Blue, XX-Large",
        "brandName": "TBMPOY",
        "destinationProductCategory": {
          "categoryName": "Clothing & Accessories",
          "rank": 446221,
          "subCategories": [
            {
              "categoryName": "Men's Athletic Shorts",
              "rank": 2277
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Clothing, Shoes & Jewelry",
          "rank": 58611,
          "subCategories": [
            {
              "categoryName": "Men's Athletic Shorts",
              "rank": 200
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 27.2693585,
        "desi": 0.35053499658,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 50.595505010000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 2.863896510000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 8.928618531176470588235294118,
        "warehousePrice": 20.46225,
        "sellingPrice": 59.524123541176470588235294118,
        "minSellingPrice": 59.524123541176470588235294118,
        "maxSellingPrice": 70.193541911764705882352941177,
        "quantity": 0,
        "status": 4,
        "sku": "test3_51885",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 52.88,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A3CN2G2M09N21B",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 52.88,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A3CN2G2M09N21B",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 2,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.414756"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "bb36ba5d-19cc-4e2e-9388-2069e4b88b25",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B0BYDMX1YM",
        "productPicture": "https://m.media-amazon.com/images/I/617qFBeXAfL.jpg",
        "productName": "BLIKA 20 Pairs 4\"x4\" Invisible Cable Railing kit, 1/8\" Invisible Receiver and Swage Stud End for 2\"x2\", 4\"x4\" Wood/Metal Posts, 1/8\" Cable Railing Hardware, Invisible Cable Railing Hardware",
        "brandName": "BLIKA",
        "destinationProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 708219,
          "subCategories": [
            {
              "categoryName": "Decking & Fencing Supplies",
              "rank": 3613
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 159089,
          "subCategories": [
            {
              "categoryName": "Decking Railings",
              "rank": 249
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 86.9372795,
        "desi": 1.28088574222,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 115.28950027000,
        "profitPercent": 6.0000,
        "profitPrice": 6.52582077000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 20.34520593000,
        "warehousePrice": 21.8264,
        "sellingPrice": 135.634706200,
        "minSellingPrice": 135.634706200,
        "maxSellingPrice": 159.946587500,
        "quantity": 0,
        "status": 4,
        "sku": "test3_36940",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 128.8,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A1PHSFG0TNLCP1",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 128.61,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A24XLMXEPN33L0",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 8,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.404562"
      },
      {
        "marketplaceId": "ATVPDKIKX0DER",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "b160624c-1234-485d-a60d-a358cc85baa0",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B077TMVX65",
        "productPicture": "https://m.media-amazon.com/images/I/51ddqi3aU3L.jpg",
        "productName": "Sightline 6006 Multifocus Progressive Multifocus Reading Glasses- Prescription Quality Frame Unique Patented Lenses -Medium to Large Fit Unisex 1.50 Magnification",
        "brandName": "Sightline",
        "destinationProductCategory": {
          "categoryName": "Health & Household",
          "rank": 516722,
          "subCategories": [
            {
              "categoryName": "Reading Glasses",
              "rank": 7640
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Health & Household",
          "rank": 516722,
          "subCategories": [
            {
              "categoryName": "Reading Glasses",
              "rank": 7640
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0,
        "price": 40.8562925,
        "desi": 0.13007273458,
        "shippingCost": 0.0,
        "customsDutyPrice": 0,
        "customsDutyPriceCurrencyCode": "",
        "customsDutyRatePrice": 0,
        "progressPayment": 64.997655050000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 3.679112550000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 11.470174420588235294117647059,
        "warehousePrice": 20.46225,
        "sellingPrice": 76.467829470588235294117647059,
        "minSellingPrice": 76.467829470588235294117647059,
        "maxSellingPrice": 90.17432720588235294117647059,
        "quantity": 0,
        "status": 4,
        "sku": "test3_27799",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 29.95,
        "buyboxPriceCurrencyCode": "USD",
        "buyboxOwnerSeller": "AQ753YR94LCTO",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 29.95,
        "lowestPriceCurrencyCode": "USD",
        "lowestPriceSeller": "AQ753YR94LCTO",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0,
        "primePercentValue": 0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 1,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 1,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": true,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.392444"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "eeeeadea-33da-4a85-86c0-471d3eed690d",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B06XW4YZ1X",
        "productPicture": "https://m.media-amazon.com/images/I/71HzXMVyQ5L.jpg",
        "productName": "4 Pack Pressure Gates Threaded Spindle Rods M8 (8 mm) Baby Gates Accessory Screw Bolts Kit Fit for All Pressure Mounted Walk thru Gates (8mm 4 Pack)",
        "brandName": "vmaisi",
        "destinationProductCategory": {
          "categoryName": "Baby",
          "rank": 40569,
          "subCategories": [
            {
              "categoryName": "Indoor Safety Gates",
              "rank": 649
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Baby",
          "rank": 5464,
          "subCategories": [
            {
              "categoryName": "Door & Stair Baby Gates",
              "rank": 106
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 13.6278585,
        "desi": 0.61949895622,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 36.13551501000,
        "profitPercent": 6.0000,
        "profitPrice": 2.04540651000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 6.37685559000,
        "warehousePrice": 20.46225,
        "sellingPrice": 42.512370600,
        "minSellingPrice": 42.512370600,
        "maxSellingPrice": 50.132512500,
        "quantity": 0,
        "status": 4,
        "sku": "test2_31717",
        "modelNumber": "8mm-04",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 36.06,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A3HTUR3KFI13J1",
        "listPrice": 34.77,
        "listPriceCurrencyCode": "CAD",
        "lowestPrice": 35.91,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A3BEFDLX28Y1EC",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 10,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.377892"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "ec1dd73e-b29a-41e8-aea1-a5d4de136f4a",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B005IWQ1OO",
        "productPicture": "https://m.media-amazon.com/images/I/51LcOKUpBrL.jpg",
        "productName": "Cash Register and Point of Service Printer Paper 3 Inch X 128 Foot Rolls",
        "brandName": "STAPLES",
        "destinationProductCategory": {
          "categoryName": "Office Products",
          "rank": 207090,
          "subCategories": [
            {
              "categoryName": "Receipt Paper & Thermal Receipt Paper",
              "rank": 750
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Office Products",
          "rank": 64862,
          "subCategories": [
            {
              "categoryName": "Receipt Paper & Thermal Receipt Paper",
              "rank": 217
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 20.7487215,
        "desi": 3.97052533862,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 52.1815,
        "profitPercent": 15.182249534949584326649588710,
        "profitPrice": 6.8780785,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 9.2085,
        "warehousePrice": 24.5547,
        "sellingPrice": 61.39,
        "minSellingPrice": 56.496031517647058823529411764,
        "maxSellingPrice": 66.622678676470588235294117648,
        "quantity": 0,
        "status": 4,
        "sku": "test3_99536",
        "modelNumber": "18211",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 62.09,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A36YNX57F882Q9",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 61.49,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "ABZK2YFAUQZZD",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 1,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": true,
        "totalSellerCount": 4,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": true,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.367764"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "d2420e2e-a3aa-4377-996e-3f56f93b6030",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B0BCJRLB88",
        "productPicture": "https://m.media-amazon.com/images/I/81l1IORgb4L.jpg",
        "productName": "ANMINY 2PCS Storage Bins with Lid PU Leather Handles Storage Boxes PP Plastic Board Decorative Foldable Lidded Cotton Linen Fabric Home Cubes Baskets Closet Organizer Containers - Green, Large Size",
        "brandName": "ANMINY",
        "destinationProductCategory": {
          "categoryName": "Home",
          "rank": 105087,
          "subCategories": [
            {
              "categoryName": "Lidded Storage Bins",
              "rank": 184
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Home & Kitchen",
          "rank": 14719,
          "subCategories": [
            {
              "categoryName": "Lidded Home Storage Bins",
              "rank": 28
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 729,
        "starsValue": 4.6,
        "price": 43.3663285,
        "desi": 1.9621141318,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 69.104292210000000000000000002,
        "profitPercent": 6.00,
        "profitPrice": 3.911563710000000000000000002,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 12.194875095882352941176470588,
        "warehousePrice": 21.8264,
        "sellingPrice": 81.29916730588235294117647059,
        "minSellingPrice": 81.29916730588235294117647059,
        "maxSellingPrice": 95.87165955882352941176470588,
        "quantity": 25,
        "status": 4,
        "sku": "test3_16508",
        "modelNumber": null,
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 59.96,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A2TP9QKSZZDEEE",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 59.96,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A2TP9QKSZZDEEE",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 1,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 4,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": true,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.355517"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "c3056631-a48a-4109-9f45-7ef13f3afa21",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B09T9CB8S8",
        "productPicture": "https://m.media-amazon.com/images/I/51+FestDWIL.jpg",
        "productName": "Led Strobe Light White Amber, 12 Led Emergency Surface Mount Grille Light, 4PC Pack Mini Warning Flashing Grill Bar, Upgraded Waterproof for Tow Ram F150 F250 F350 Trucks Van Utility (CB-02-WY)",
        "brandName": "OPP ULITE",
        "destinationProductCategory": {
          "categoryName": "Automotive",
          "rank": 265001,
          "subCategories": [
            {
              "categoryName": "Automotive Light Bars",
              "rank": 2088
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Automotive",
          "rank": 619135,
          "subCategories": [
            {
              "categoryName": "Automotive Emergency Strobe Lights",
              "rank": 1027
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 44.9351010,
        "desi": 0.6393405598,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 69.32119206000,
        "profitPercent": 6.0000,
        "profitPrice": 3.92384106000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 12.23315154000,
        "warehousePrice": 20.46225,
        "sellingPrice": 81.554343600,
        "minSellingPrice": 81.554343600,
        "maxSellingPrice": 96.172575000,
        "quantity": 0,
        "status": 4,
        "sku": "2308-2_18751",
        "modelNumber": "Led Strobe Lights",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 0.0,
        "buyboxPriceCurrencyCode": "",
        "buyboxOwnerSeller": null,
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 75.63,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A1KK7F8AFQNRKK",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 1,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.343142"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "ce187239-0ffd-4b40-b54a-139eb7acf804",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B001E0HO6S",
        "productPicture": "https://m.media-amazon.com/images/I/71sXNF4+AlL.jpg",
        "productName": "Activa ArtPlaster Premium Plaster-5 pounds Casting Compound, White, Packaging May Vary",
        "brandName": "activa",
        "destinationProductCategory": {
          "categoryName": "Home",
          "rank": 232298,
          "subCategories": [
            {
              "categoryName": "Sculpture Molding & Casting Products",
              "rank": 760
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Arts, Crafts & Sewing",
          "rank": 41341,
          "subCategories": [
            {
              "categoryName": "Sculpture Molding & Casting Products",
              "rank": 312
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 319,
        "starsValue": 4.4,
        "price": 20.3940425,
        "desi": 5.070632026,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 51.9265,
        "profitPercent": 8.913005667245404326411396010,
        "profitPrice": 4.2494575,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 9.1635,
        "warehousePrice": 27.283,
        "sellingPrice": 61.09,
        "minSellingPrice": 59.456076529411764705882352941,
        "maxSellingPrice": 70.113297794117647058823529412,
        "quantity": 25,
        "status": 4,
        "sku": "test3_76576",
        "modelNumber": "225",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 0.0,
        "buyboxPriceCurrencyCode": "",
        "buyboxOwnerSeller": null,
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 61.19,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A1HAN8IZN164AD",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 3,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.332986"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "9ec2f61c-1e9f-472e-8c69-1ea5d024b475",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B07H42L159",
        "productPicture": "https://m.media-amazon.com/images/I/81b8dg+87lL.jpg",
        "productName": "Doctor Developed Thumb Brace for Arthritis/Thumb Splint/Trigger Thumb Brace - Medical Device & Handbook- Thumb Spica Splint - Thumb Brace for Right Hand/Thumb Brace for Left Hand (Black, Single)",
        "brandName": "Dr. Arthritis",
        "destinationProductCategory": {
          "categoryName": "Books",
          "rank": 826284,
          "subCategories": [
            {
              "categoryName": "Braces, Splints & Slings",
              "rank": 5571
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Health & Household",
          "rank": 35943,
          "subCategories": [
            {
              "categoryName": "Finger Splints",
              "rank": 31
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 1776,
        "starsValue": 4.4,
        "price": 23.1223425,
        "desi": 0.220462262,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 46.199668050000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 2.615075550000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 8.152882597058823529411764706,
        "warehousePrice": 20.46225,
        "sellingPrice": 54.352550647058823529411764706,
        "minSellingPrice": 54.352550647058823529411764706,
        "maxSellingPrice": 64.094988970588235294117647059,
        "quantity": 25,
        "status": 4,
        "sku": "test3_33687",
        "modelNumber": "Thumb Splint Right/Left (Black, Single)",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 49.09,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A3IGHG927UX1AE",
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 46.25,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A1KK7F8AFQNRKK",
        "couponPriceValue": 8.475,
        "couponPercentValue": 50.0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 18,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.318638"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "85eb6a52-2360-4ccc-96a3-1a2334ffa0a2",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B095LBWPT8",
        "productPicture": "https://m.media-amazon.com/images/I/61oaTZVvZaL.jpg",
        "productName": "YOUKOYI LED Desk Lamp, Flexible Gooseneck Architect Table Lamp with Clamp - Stepless Dimming&Stepless Color Temperatures, Touch Control,Eye-Care 10W Desk Light for Home/Office/Reading/Work(White)",
        "brandName": "YOUKOYI",
        "destinationProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 185100,
          "subCategories": [
            {
              "categoryName": "Desk Lamps",
              "rank": 838
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Tools & Home Improvement",
          "rank": 56041,
          "subCategories": [
            {
              "categoryName": "Desk Lamps",
              "rank": 338
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 49.0957585,
        "desi": 2.0502990366,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 76.623487010000000000000000000,
        "profitPercent": 6.00000000000000000000,
        "profitPrice": 4.337178510000000000000000000,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 13.521791825294117647058823530,
        "warehousePrice": 23.19055,
        "sellingPrice": 90.14527883529411764705882353,
        "minSellingPrice": 90.14527883529411764705882353,
        "maxSellingPrice": 106.30339485294117647058823529,
        "quantity": 0,
        "status": 4,
        "sku": "test3_63505",
        "modelNumber": "T7W",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 87.0,
        "buyboxPriceCurrencyCode": "CAD",
        "buyboxOwnerSeller": "A79HEQR7VUBTZ",
        "listPrice": 35.99,
        "listPriceCurrencyCode": "CAD",
        "lowestPrice": 87.0,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A79HEQR7VUBTZ",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 10,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.308321"
      },
      {
        "marketplaceId": "A2EUQ1WTGCTBG2",
        "sourceMarketplaceId": "ATVPDKIKX0DER",
        "customerMainSellerProductId": "b9801ac1-8ede-4d82-97e0-5db84f92b5c9",
        "customerMainSellerId": "5f462da3-6615-4fca-a327-b1a17461e9fd",
        "asin": "B07YD1TNNT",
        "productPicture": "https://m.media-amazon.com/images/I/61BZcRexjXL.jpg",
        "productName": "ANJANK Extra Loud Alarm Clock with Wireless Bed Shaker, Vibrating Dual Alarm for Heavy Sleepers, Deaf and Hearing-impaired, Adjustable Volume/Dimmer/Wake up Mode, USB Charger Port",
        "brandName": "ANJANK",
        "destinationProductCategory": {
          "categoryName": "Home",
          "rank": 378075,
          "subCategories": [
            {
              "categoryName": "Alarm Clocks (Home)",
              "rank": 1217
            }
          ]
        },
        "sourceProductCategory": {
          "categoryName": "Home & Kitchen",
          "rank": 106353,
          "subCategories": [
            {
              "categoryName": "Alarm Clocks",
              "rank": 297
            }
          ]
        },
        "reviewCount": 0,
        "ratingValue": 0,
        "starsValue": 0.0,
        "price": 40.9108585,
        "desi": 1.00089866948,
        "shippingCost": 0.0,
        "customsDutyPrice": 0.0,
        "customsDutyPriceCurrencyCode": "USD",
        "customsDutyRatePrice": 0.0,
        "progressPayment": 66.501494010000000000000000001,
        "profitPercent": 6.00,
        "profitPrice": 3.764235510000000000000000001,
        "amazonFeePercent": 15.0,
        "amazonFeePrice": 11.735557766470588235294117647,
        "warehousePrice": 21.8264,
        "sellingPrice": 78.237051776470588235294117648,
        "minSellingPrice": 78.237051776470588235294117648,
        "maxSellingPrice": 92.26067426470588235294117647,
        "quantity": 0,
        "status": 4,
        "sku": "2308-2_19559",
        "modelNumber": "Loud Alarm Clock with Bed Shaker",
        "isFavourite": false,
        "handlingTime": 2,
        "isApprove": false,
        "buyboxPrice": 0.0,
        "buyboxPriceCurrencyCode": "",
        "buyboxOwnerSeller": null,
        "listPrice": 0.0,
        "listPriceCurrencyCode": "",
        "lowestPrice": 72.6,
        "lowestPriceCurrencyCode": "CAD",
        "lowestPriceSeller": "A34LM5KZ3EOQM",
        "couponPriceValue": 0,
        "couponPercentValue": 0,
        "primePriceValue": 0.0,
        "primePercentValue": 0.0,
        "primePriceCurrencyCode": "",
        "primeSellerCount": 0,
        "hasCIPOCertified": false,
        "hasUSPTOCertified": false,
        "totalSellerCount": 1,
        "deliveryTime": "1-3 gün",
        "hasAmazonSellersAtDestinationMarket": false,
        "hasPrimeSellersAtDestinationMarket": false,
        "hasMeAsBuyboxSeller": false,
        "hasMeAsLowestSeller": false,
        "isDeleting": null,
        "updatedDate": "2024-12-06T08:02:19.295924"
      }
    ],
    "asinList": null
  }
}

export const customerPackageInfoMockData = {
  "customerMainId": "7a12c359-01f8-493a-acf1-c3c0d09a62aa",
  "customerFirstName": "eray",
  "customerLastName": "yucel",
  "totalProductCount": 1000,
  "totalUserCount": 1,
  "totalSellerCount": 1,
  "totalScanningCount": 100,
  "leftProductCount": 0,
  "leftUserCount": 0,
  "leftSellerCount": 0,
  "leftScanningCount": 0,
  "totalPrice": 1,
  "totalDiscount": 0,
  "customerPackages": [
    {
      "id": "9bd10ae6-6111-484d-833e-d98a2642ea7f",
      "planName": "Free",
      "productCount": 1000,
      "isFBA": false,
      "isSmartPricer": false,
      "isWarehouse": false,
      "price": 0,
      "discount": 0,
      "userCount": 1,
      "sellerCount": 1,
      "isDisable": false,
      "startDate": "2024-08-28T11:16:43.910546",
      "endDate": "2024-09-27T11:16:43.910195",
      "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure quos debitis aliquam .",
      "mainPlan": true,
      "isDropAmazon2Amazon": false,
      "trail": 0,
      "isVisible": true,
      "scanningCount": 100,
      "mustPlan": "",
      "couponName": "",
      "couponCode": "",
      "discountRate": 0,
      "renewal": false,
      "isPayment": true
    },
    {
      "id": "49fe7df8-0c3e-4860-8de5-e9d9767c7a57",
      "planName": "Testr",
      "productCount": 0,
      "isFBA": false,
      "isSmartPricer": true,
      "isWarehouse": false,
      "price": 1,
      "discount": 0,
      "userCount": 0,
      "sellerCount": 0,
      "isDisable": false,
      "startDate": "2024-08-28T11:16:43.910602",
      "endDate": "2024-09-27T11:16:43.910601",
      "description": "",
      "mainPlan": false,
      "isDropAmazon2Amazon": false,
      "trail": 0,
      "isVisible": true,
      "scanningCount": 0,
      "mustPlan": "1a016f57-74d0-49a4-ab56-2138d7501a7e,c12cc666-a4b3-4ebe-b777-db9e1503f353,2aa876c7-35a2-4bd0-84a4-619db6da8efd",
      "couponName": "",
      "couponCode": "",
      "discountRate": 0,
      "renewal": false,
      "isPayment": true
    }
  ]
}

export const feedbackDTO = {
  id: "guid",
  customerMainSellerId: "guid",
  subject: "konusu",
  messageBody: "",
  negativeFeedbackBuyersIncluded: false,  // daha önceki siparişlerde negatif ve nötr feedback veren alıcıları "false" ise hariç tut "true" ise bu alıcılara feedback gitsin
  negativeFeedbackOrdersIncluded: false,  // daha önce negatif ve nötr feedback almış siparişleri "false" ise hariç tut "true" ise bu siparişlerin alıcına feedback gitsin
  canceledOrReturnedOrdersIncluded: false, // iptal veya iade edilen siparişlerde "false" ise hariç tur "true" ise bu siparişlerin alıcısına feedback gitsin
  notDeliveredOnTimeORdersIncluded: false, // zamanında teslim edilemeyen siparişlerde "false" ise hariç tut "true" ise zamanında teslim edilemeyen siparişlerin alıcısına da feedback gitsin
  feedbackSendTimeDelayDays: "int", // email gönderileceği zamanın seçeneği 1 gün, 2 gün, 3gün integer yapalım bu alanı , örnek sipariş teslimatından 2 gün sonra feedback mesaj gitsin
  feedbackSendTime: "timespan", // email yukaridaki seçilen gün aşımından sonra burada vereceğimiz time span saatinde gitsin, örnek yukarıda 2 gün demiştik , sipariş teslimatından 2 gün sonra saat "9:00" da feedback gitsin
  isActive: "false", // feedback mesajı aktifmi değilmi?

  feedbackParameters: {
    amazonOrderId: false,
    asin: false, // ürünün asini
    buyerEmail: false, // alıcının email adresi
    deliveryDate: false, // teslim edilm tarihi
    feedbackLink: false, // satıcıya feedback verebilmesi için link  https://www.amazon.ca/hz/feedback/?orderID=702-4099520-342423423
    productName: false, // ürünün title alanı
    productUrl: false, // satışpazarında ürünün linki örnek body bu paramtreyi şöyle replace etmen gerekir <a href="https://www.amazon.ca/dp/B234234BD?th=1&psc=1"> https://www.amazon.ca/dp/B234234BD?th=1&psc=1 </a>
    productUrlWithName: false, // satışpazarında ürünün linki örnek body bu paramtreyi şöyle replace etmen gerekir <a href="https://www.amazon.ca/dp/B234234BD?th=1&psc=1"> productName </a>
    purchaseDate: false, // siparişin verildiği tarih
    senderName: false,  // satıcının adı , customer firstname
    shipDate: false, // ürünün kargolandığı tarih
    storeName: false, // satıcının mağaza ismi
  }
}


export const productDetailMockData = [
  {
    "customerProductId": "d49a4c15-8266-4077-93db-eb6ea1d92770",
    "marketplaceId": "A2EUQ1WTGCTBG2",
    "sourceMarketplaceId": "ATVPDKIKX0DER",
    "productName": "Ameritex Loveseat Cover Water-Resistant Quilted Furniture Protector with Back Nonslip Paws Slipcover for Dogs, Kids, Pets Loveseat Slipcover Stay in Place for Leather (Dark Grey, 46'')",
    "productSerialNumber": "",
    "productPicture": "https://m.media-amazon.com/images/I/81TQPWRzDjL.jpg",
    "sku": "test3_23734",
    "brandName": "Ameritex",
    "destinationProductCategory": {
      "categoryName": "Home",
      "rank": 1459531,
      "subCategories": [
        {
          "categoryName": "Sofa Slipcovers",
          "rank": 4283
        }
      ]
    },
    "sourceProductCategory": {
      "categoryName": "Home & Kitchen",
      "rank": 747811,
      "subCategories": [
        {
          "categoryName": "Loveseat Slipcovers",
          "rank": 60
        }
      ]
    },
    "brandId": "16ad0120-36b5-4971-8829-902eedefe8cb",
    "categoryId": "e79d1618-a0df-47a3-a01b-42bbe7dfb35f",
    "asin": "B07PWPFT1R",
    "isBuybox": false,
    "price": 40.9108585,
    "sourceMarketPrice": "USD 29.99",
    "sourceShippingPrice": "USD 0.00",
    "sourceTotalCost": "USD 47.99",
    "sellerId": "A3HMB80KCUQ0V7",
    "sourceMarketSellerName": "Ameritex",
    "sourceMarketSellerType": "Merchant",
    "couponPriceValue": 4.4985,
    "couponPercentValue": 15.0,
    "primePriceValue": 0.0,
    "primePercentValue": 0.0,
    "primePriceCurrencyCode": "",
    "primeSellerCount": 0,
    "hasCIPOCertified": false,
    "hasUSPTOCertified": false,
    "ratingValue": 932,
    "minSellingPrice": 81.63940236470588235294117647,
    "maxSellingPrice": 96.27288014705882352941176471,
    "desi": 3.11072251682,
    "customsDutyRatePrice": 0.0,
    "progressPayment": 81.83194812500000000000000000,
    "profitPercent": 25.0000000000000000000,
    "profitPrice": 16.36638962500000000000000000,
    "amazonFeePercent": 15.0,
    "amazonFeePrice": 14.440932022058823529411764706,
    "warehousePrice": "USD 18.00",
    "sellingPrice": 96.27288014705882352941176471,
    "isLowest": false,
    "stockCount": 25,
    "status": 4,
    "statusDescription": "",
    "submitTryCount": 0,
    "marketCountryCode": "CA",
    "sourceMarketCountryCode": "US",
    "isFavourite": false,
    "itemHeightUnit": "",
    "itemHeightValue": 0.0,
    "itemLengthUnit": "",
    "itemLengthValue": 0.0,
    "itemWeightUnit": "",
    "itemWeightValue": 0.0,
    "itemWidthUnit": "",
    "itemWidthValue": 0.0,
    "packageHeightUnit": "inches",
    "packageHeightValue": 6.2199999936556,
    "packageLengthUnit": "inches",
    "packageLengthValue": 15.2399999844552,
    "packageWeightUnit": "pounds",
    "packageWeightValue": 3.11072251682,
    "packageWidthUnit": "inches",
    "packageWidthValue": 12.049999987709,
    "itemPackageQuantity": "",
    "size": "46''",
    "color": "Dark Grey",
    "style": "Wrap Ring",
    "partNumber": "",
    "modelNumber": "",
    "listPriceCurrencyCode": "",
    "listPrice": 0.0,
    "productSiteLaunchDate": null,
    "modelName": "",
    "fabricType": "Leather",
    "isPrime": true,
    "isNationalPrime": false,
    "productLink": "",
    "starsValue": 4.4,
    "starsOperation": null,
    "reviewCount": 0,
    "buyboxOwnerSeller": "A2OINMXVDVDEZM",
    "buyboxPrice": 97.91,
    "buyboxPriceCurrencyCode": "CAD",
    "lowestPriceSeller": "A2OINMXVDVDEZM",
    "lowestPrice": 97.91,
    "lowestPriceCurrencyCode": "CAD",
    "shippingPrice": 0.0,
    "shippingPriceCurrencyCode": "USD",
    "customsDutyPrice": 0.0,
    "customsDutyPriceCurrencyCode": "USD",
    "totalSellerCount": 3,
    "isSoldByAmazon": false,
    "isFBASellerExists": false,
    "isChineseSellerExists": false,
    "isPrimeExclusive": true,
    "isDiscounted": false,
    "discountValue": "",
    "isLiquidContent": false,
    "isBatteryRequired": false,
    "isFillDetail": true,
    "isApprove": false,
    "lastInventoryUpdateDate": "2024-12-06T08:54:28.683071",
    "lastProductUpdateDate": "2024-12-06T08:54:28.683071",
    "deliveryTime": 2,
    "isBestSellingProduct": false,
    "isDeleting": true
  }
]



export const createLabelBulkMockData = [
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test1",
    "checkValue": "test1"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test2",
    "checkValue": "test2"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test3",
    "checkValue": "test3"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test4",
    "checkValue": "test4"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test5",
    "checkValue": "test5"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test6",
    "checkValue": "test6"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test7"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test8"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test9"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test10"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test11"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test12"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test13"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test14"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test15"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test16"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test17"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test18"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test19"
  },
  {
    "shipmentId": 0,
    "orderId": "9c53ac56-2b25-42cb-91c5-dea2ce54a5b0",
    "carrierCode": "ups_walleted",
    "carrierName": "UPS by ShipStation",
    "serviceCode": "ups_ground",
    "serviceName": "UPS® Ground",
    "packageCode": "",
    "confirmation": "online",
    "shipDate": "2024-12-17",
    "weight": {
      "value": 1.7196056436,
      "units": "ounces"
    },
    "dimensions": {
      "length": 15.7099999839758,
      "width": 12.5199999872296,
      "height": 3.5399999963892,
      "units": "inches"
    },
    "shipFrom": {
      "name": "ALPHAZONE FULFILLMENT ",
      "company": "ShipStation",
      "street1": "655 Driving Park Ave, West Dock",
      "street2": "",
      "street3": "",
      "city": "Rochester",
      "state": "New York",
      "postalCode": "14613",
      "country": "US",
      "phone": "+17165501632",
      "residential": true
    },
    "shipTo": {
      "name": "Jessica Hazelwood",
      "company": "",
      "street1": "3951 St. Thomas Street",
      "street2": "",
      "street3": "",
      "city": "Port Coquitlam",
      "state": "British Columbia",
      "postalCode": "V3B 2Z3",
      "country": "CA",
      "phone": "7788865377",
      "residential": true
    },
    "insuranceOptions": null,
    "advancedOptions": null,
    "internationalOptions": {
      "contents": "merchandise",
      "customsItems": [
        {
          "description": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
          "quantity": 1,
          "value": 187.05,
          "countryOfOrigin": "US",
          "harmonizedTariffCode": ""
        }
      ],
      "nonDelivery": "return_to_sender"
    },
    "orderItem": [
      {
        "itemName": "Alleyon David Martinez Jacket Cosplay Anime Outfit Cybe...",
        "quantity": 1,
        "price": 187.05
      }
    ],
    "testLabel": true,
    "isManual": true,
    "trackingNumber": "test7",
    "checkValue": "test20"
  }
]


export const asinlistMockData = [
  {
    "asinList": [
      "B09NY3ZDLF"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0C5J7D37D"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0963B8XX9"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B000XPE6SE"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0CQWDF1ZJ"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B09ZNMVB3D"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0C1FZWMPJ"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B07PQJ4336"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0C9V4X4R8"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0BBZT3J66"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B095L8M9RG"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0CF9KB1JG"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0BGS94QL3"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B09XDSRH4H"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B08PC624TN"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0CF9PDB5C"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0BZH3CP5G"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0BBTXV1ZD"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0BBWHS48D"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  },
  {
    "asinList": [
      "B0CL4M9PKK"
    ],
    "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796"
  }
]

/*
public Guid Id { get; set; }
  public string AmazonOrderNumber { get; set; } = default!; // Amazon sipariş no
  public string? Recipient { get; set; } = null; // Alıcı
  public string? Subject { get; set; } = null; // Kampanya adı - konu
  public bool IsSend { get; set; } = true; // Aksiyon - Gönderim durumu
  public bool IsRead { get; set; } = false; // Okunma durumu -- aktif değil henüz
  public DateTime CreatedDate { get; set; } // mail gönderim zamanı
*/
export const feedbacksendmailsMockdata = [

  {
    id: "aaf59d3a-037f-4552-aeb1-144c5143b796",
    amazonOrderNumber: "701-7537077-8745066",
    recipient: "dadadadadasd@adadadadad.com",
    subject: "Your Order Information From Amazon: {{AmazonOrderId}}",
    isSend: true,
    isRead: false,
    createdDate: "2024-12-06T08:54:28.683071"
  }
]


export const warehouseordersMockData ={
	"data": [
		{
			"id": "870d5eaa-cb6d-4435-82ba-9a20bd28afa6",
			"customerMainSellerId": "938c4529-b9d5-42a7-aa10-e915c2abb9ec",
			"buyerEmail": "lpn6tryf8tv44pm@marketplace.amazon.ca",
			"buyerName": null,
			"buyerCounty": null,
			"buyerTaxingRegion": null,
			"buyerCompanyLegalName": null,
			"purchaseOrderNumber": null,
			"orderNumber": "702-9301556-9489842",
			"earliestDeliveryDate": "2024-12-23T08:00:00",
			"earliestShipDate": "2024-12-18T08:00:00",
			"salesChannel": "Amazon.ca",
			"orderStatus": "Unshipped",
			"numberOfItemsShipped": 1,
			"orderType": "StandardOrder",
			"isPremiumOrder": false,
			"isPrime": false,
			"fulfillmentChannel": "MFN",
			"numberOfItemsUnshipped": 0,
			"hasRegulatedItems": false,
			"isReplacementOrder": false,
			"isSoldByAB": false,
			"latestShipDate": "2024-12-20T07:59:59",
			"shipServiceLevel": "Std CA D2D Dom",
			"isISPU": false,
			"marketplaceId": "A2EUQ1WTGCTBG2",
			"latestDeliveryDate": "2024-12-31T07:59:59",
			"purchaseDate": "2024-12-16T18:45:29",
			"isAccessPointOrder": false,
			"paymentMethod": "Other",
			"isBusinessOrder": false,
			"ordertotalCurrencyCode": "CAD",
			"ordertotalAmount": 32.31,
			"isGlobalExpressEnabled": false,
			"lastUpdateDate": "2024-12-18T15:54:05",
			"shipmentServiceLevelCategory": "Standard",
			"paymentMethodDetails": "Standard",
			"hasAutomatedShippingSettings": null,
			"orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
			"orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
			"buyerSellerOrderNumber": "test",
			"buyerSellerOrderDescription": null,
			"warehouseSuitName": "11223",
			"wareHouseId": "f274f468-5722-4b53-a7b8-2e2fb7634fca",
			"sellerMailAddress": null,
			"isGift": false,
			"trackingNumber": null,
			"orderUrl": null,
			"isOrderable": true,
			"orderStatusId": 10,
			"buyerOrderStatusId": 14,
			"orderFlag": null,
			"profitPrice": 4.41,
			"profitPercent": 15,
			"orderItemPriceAmount": 28.59,
			"labelData": null,
			"createdDate": "0001-01-01T00:00:00",
			"buyerOrderStatus": "Placed",
			"buyerDeliveryDateStart": null,
			"buyerDeliveryDateEnd": null,
			"isFastShipping": false,
			"customerMainSellerStoreName": "O'an Store",
			"specialCode": null,
			"extraWarehouseCost": null,
			"returnToWarehousePrice": null,
			"products": [
				{
					"imageUrl": "https://m.media-amazon.com/images/I/819Dw0-c+FL.jpg",
					"asin": "B084VFGR3V",
					"quantityOrdered": 1,
					"quantityShipped": 1,
					"productCategory": null,
					"sourceMarketCountryCode": "US",
					"couponValue": null,
					"primePriceValue": 0,
					"primePriceCurrencyCode": "",
					"packageHeightUnit": "inches",
					"packageHeightValue": 0.749999999235,
					"packageLengthUnit": "inches",
					"packageLengthValue": 5.8699999940126,
					"packageWeightUnit": "pounds",
					"packageWeightValue": 0.03968320716,
					"packageWidthUnit": "inches",
					"packageWidthValue": 3.7799999961444
				}
			],
			"shippingAddress": {
				"addressLine1": null,
				"addressLine2": null,
				"addressLine3": null,
				"city": "York",
				"county": null,
				"district": null,
				"stateOrRegion": "Ontario",
				"municipality": null,
				"postalCode": "M9N 2S4",
				"countryCode": "CA",
				"phone": null,
				"name": null
			}
		},
		{
			"id": "83cbcce7-2673-4b74-a33d-757def789c91",
			"customerMainSellerId": "938c4529-b9d5-42a7-aa10-e915c2abb9ec",
			"buyerEmail": "rf4wygkz36ny0ch@marketplace.amazon.ca",
			"buyerName": null,
			"buyerCounty": null,
			"buyerTaxingRegion": null,
			"buyerCompanyLegalName": null,
			"purchaseOrderNumber": null,
			"orderNumber": "702-9250079-2005058",
			"earliestDeliveryDate": "2024-12-05T08:00:00",
			"earliestShipDate": "2024-12-02T08:00:00",
			"salesChannel": "Amazon.ca",
			"orderStatus": "Unshipped",
			"numberOfItemsShipped": 1,
			"orderType": "StandardOrder",
			"isPremiumOrder": false,
			"isPrime": false,
			"fulfillmentChannel": "MFN",
			"numberOfItemsUnshipped": 0,
			"hasRegulatedItems": false,
			"isReplacementOrder": false,
			"isSoldByAB": false,
			"latestShipDate": "2024-12-04T07:59:59",
			"shipServiceLevel": "Std CA D2D Dom",
			"isISPU": false,
			"marketplaceId": "A2EUQ1WTGCTBG2",
			"latestDeliveryDate": "2024-12-11T07:59:59",
			"purchaseDate": "2024-11-30T12:25:28",
			"isAccessPointOrder": false,
			"paymentMethod": "Other",
			"isBusinessOrder": false,
			"ordertotalCurrencyCode": "CAD",
			"ordertotalAmount": 75.54,
			"isGlobalExpressEnabled": false,
			"lastUpdateDate": "2024-12-25T07:06:37",
			"shipmentServiceLevelCategory": "Standard",
			"paymentMethodDetails": "Standard",
			"hasAutomatedShippingSettings": null,
			"orderShipFromLocationAddresId": "00000000-0000-0000-0000-000000000000",
			"orderShippingAddresId": "00000000-0000-0000-0000-000000000000",
			"buyerSellerOrderNumber": "test2",
			"buyerSellerOrderDescription": null,
			"warehouseSuitName": "11216",
			"wareHouseId": "f274f468-5722-4b53-a7b8-2e2fb7634fca",
			"sellerMailAddress": null,
			"isGift": false,
			"trackingNumber": null,
			"orderUrl": null,
			"isOrderable": true,
			"orderStatusId": 10,
			"buyerOrderStatusId": 14,
			"orderFlag": null,
			"profitPrice": 8.18,
			"profitPercent": 15,
			"orderItemPriceAmount": 65.7,
			"labelData": null,
			"createdDate": "0001-01-01T00:00:00",
			"buyerOrderStatus": "Placed",
			"buyerDeliveryDateStart": null,
			"buyerDeliveryDateEnd": null,
			"isFastShipping": true,
			"customerMainSellerStoreName": "O'an Store",
			"specialCode": null,
			"extraWarehouseCost": null,
			"returnToWarehousePrice": null,
			"products": [
				{
					"imageUrl": "https://m.media-amazon.com/images/I/41OS8JtMETL.jpg",
					"asin": "B06Y1BQVP5",
					"quantityOrdered": 1,
					"quantityShipped": 1,
					"productCategory": null,
					"sourceMarketCountryCode": "US",
					"couponValue": null,
					"primePriceValue": 0,
					"primePriceCurrencyCode": "",
					"packageHeightUnit": "inches",
					"packageHeightValue": 2.049999997909,
					"packageLengthUnit": "inches",
					"packageLengthValue": 9.1299999906874,
					"packageWeightUnit": "pounds",
					"packageWeightValue": 0.70988848364,
					"packageWidthUnit": "inches",
					"packageWidthValue": 7.049999992809
				}
			],
			"shippingAddress": {
				"addressLine1": null,
				"addressLine2": null,
				"addressLine3": null,
				"city": "Saint-Laurent",
				"county": null,
				"district": null,
				"stateOrRegion": "Quebec",
				"municipality": null,
				"postalCode": "H4R 3E2",
				"countryCode": "CA",
				"phone": null,
				"name": null
			}
		}
	]
}


export const createLabelOrderDetailMockData = {
  "customerMainSellerId": "938c4529-b9d5-42a7-aa10-e915c2abb9ec",
  "buyerEmail": "7j3pfbgvtvl3vwg@marketplace.amazon.ca",
  "buyerName": null,
  "buyerCounty": null,
  "buyerTaxingRegion": null,
  "buyerCompanyLegalName": null,
  "purchaseOrderNumber": null,
  "orderNumber": "702-0777358-6950652",
  "earliestDeliveryDate": "2025-01-13T08:00:00",
  "earliestShipDate": "2025-01-07T08:00:00",
  "salesChannel": "Amazon.ca",
  "orderStatus": "Confirmed",
  "numberOfItemsShipped": 0,
  "orderType": "StandardOrder",
  "isPremiumOrder": false,
  "isPrime": false,
  "fulfillmentChannel": "MFN",
  "numberOfItemsUnshipped": 4,
  "hasRegulatedItems": false,
  "isReplacementOrder": false,
  "isSoldByAB": false,
  "latestShipDate": "2025-01-09T07:59:59",
  "shipServiceLevel": "Std CA D2D Dom",
  "isISPU": false,
  "marketplaceId": "A2EUQ1WTGCTBG2",
  "latestDeliveryDate": "2025-01-21T07:59:59",
  "purchaseDate": "2025-01-06T17:49:46",
  "isAccessPointOrder": false,
  "paymentMethod": "Other",
  "isBusinessOrder": false,
  "ordertotalCurrencyCode": "CAD",
  "ordertotalAmount": 300.64,
  "isGlobalExpressEnabled": false,
  "lastUpdateDate": "2025-01-07T07:34:19",
  "shipmentServiceLevelCategory": "Standard",
  "paymentMethodDetails": "Standard",
  "hasAutomatedShippingSettings": null,
  "orderUrl": null,
  "carrierCode": "ups",
  "carrierServiceCode": "ups_standard_international",
  "wareHouseId": "f274f468-5722-4b53-a7b8-2e2fb7634fca",
  "buyerSellerOrderNumber": "111-1398761-6233803",
  "buyerSellerOrderDescription": "Extension OrderNumber update",
  "defaultShipFromLocationAddress": {
    "addressLine1": null,
    "addressLine2": null,
    "addressLine3": null,
    "city": null,
    "county": null,
    "district": null,
    "stateOrRegion": null,
    "municipality": null,
    "postalCode": null,
    "countryCode": null,
    "phone": null,
    "name": null
  },
  "shippingAddress": {
    "addressLine1": "2203 16 Ave",
    "addressLine2": null,
    "addressLine3": null,
    "city": "Coaldale",
    "county": null,
    "district": "AB",
    "stateOrRegion": "AB",
    "municipality": null,
    "postalCode": "T1M 1N7",
    "countryCode": "CA",
    "phone": "4033082078",
    "name": "Sandy Regier"
  },
  "orderDetailList": [
    {
      "orderId": "4496f030-c4a9-422c-a028-7cb1bb6d477a",
      "imageUrl": "https://m.media-amazon.com/images/I/61PoSvijpIL.jpg",
      "deemedResellerCategory": null,
      "asin": "B06Y1BQVP5",
      "sellerSKU": "SF-CA-13268193105",
      "orderItemId": "119072869434001",
      "title": "Alimens & Gentle Men's Dress Shirts French Cuff Long Sleeve Regular Fit (Include Metal Cufflinks and Metal Collar Stays) (15.5\" Neck 32\"-33\" Sleeve, White)",
      "quantityOrdered": 4,
      "quantityShipped": 4,
      "productInfoNumberOfItems": 1,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 286.32,
      "shippingCurrencyCode": null,
      "shippingPriceAmount": null,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 14.32,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "CAD",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "trackingNumber": null,
      "deliveryTime": "1-3 gün",
      "isBuybox": false,
      "price": 34.090107,
      "desi": 0.70988846,
      "shippingCost": 0,
      "customsDutyPrice": 0,
      "progressPayment": 62.73521,
      "profitPercent": 15,
      "profitPrice": 8.182854,
      "amazonFeePercent": 15,
      "amazonFeePrice": 11.07092,
      "warehousePrice": 20.46225,
      "sellingPrice": 73.80613,
      "productCategory": "Clothing & Accessories",
      "itemHeightUnit": "",
      "itemHeightValue": 0.0,
      "itemLengthUnit": "",
      "itemLengthValue": 0.0,
      "itemWeightUnit": "kilograms",
      "itemWeightValue": 0.2,
      "itemWidthUnit": "",
      "itemWidthValue": 0.0,
      "packageHeightUnit": "",
      "packageHeightValue": 2.049999997909,
      "packageLengthUnit": "",
      "packageLengthValue": 9.1299999906874,
      "packageWeightUnit": "",
      "packageWeightValue": 0.70988848364,
      "packageWidthUnit": "inches",
      "packageWidthValue": 7.049999992809,
      "itemPackageQuantity": "",
      "size": "",
      "sourceMarketCountryCode": "US",
      "specialCode": 11233,
      "productSourceMarketPrice": 35.92250025,
      "isSelected": true
    },
    {
      "orderId": "4496f030-c4a9-422c-a028-7cb1bb6d477b",
      "imageUrl": "https://m.media-amazon.com/images/I/61PoSvijpIL.jpg",
      "deemedResellerCategory": null,
      "asin": "B06Y1BQVP8",
      "sellerSKU": "SF-CA-13268193105",
      "orderItemId": "119072869434002",
      "title": "item 2",
      "quantityOrdered": 2,
      "quantityShipped": 1,
      "productInfoNumberOfItems": 1,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 286.32,
      "shippingCurrencyCode": null,
      "shippingPriceAmount": null,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 14.32,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "CAD",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "trackingNumber": null,
      "deliveryTime": "1-3 gün",
      "isBuybox": false,
      "price": 34.090107,
      "desi": 0.70988846,
      "shippingCost": 0,
      "customsDutyPrice": 0,
      "progressPayment": 62.73521,
      "profitPercent": 15,
      "profitPrice": 8.182854,
      "amazonFeePercent": 15,
      "amazonFeePrice": 11.07092,
      "warehousePrice": 20.46225,
      "sellingPrice": 73.80613,
      "productCategory": "Clothing & Accessories",
      "itemHeightUnit": "",
      "itemHeightValue": 0.0,
      "itemLengthUnit": "",
      "itemLengthValue": 0.0,
      "itemWeightUnit": "kilograms",
      "itemWeightValue": 0.2,
      "itemWidthUnit": "",
      "itemWidthValue": 0.0,
      "packageHeightUnit": "",
      "packageHeightValue": 2.049999997909,
      "packageLengthUnit": "",
      "packageLengthValue": 9.1299999906874,
      "packageWeightUnit": "",
      "packageWeightValue": 0.70988848364,
      "packageWidthUnit": "inches",
      "packageWidthValue": 7.049999992809,
      "itemPackageQuantity": "",
      "size": "",
      "sourceMarketCountryCode": "US",
      "specialCode": 11233,
      "productSourceMarketPrice": 35.92250025,
      "isSelected": false
    },
    {
      "orderId": "4496f030-c4a9-422c-a028-7cb1bb6d477c",
      "imageUrl": "https://m.media-amazon.com/images/I/61PoSvijpIL.jpg",
      "deemedResellerCategory": null,
      "asin": "B06Y1BQVP2",
      "sellerSKU": "SF-CA-13268193105",
      "orderItemId": "119072869434003",
      "title": "item 3",
      "quantityOrdered": 1,
      "quantityShipped": 0,
      "productInfoNumberOfItems": 1,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 286.32,
      "shippingCurrencyCode": null,
      "shippingPriceAmount": null,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 14.32,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "CAD",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "trackingNumber": null,
      "deliveryTime": "1-3 gün",
      "isBuybox": false,
      "price": 34.090107,
      "desi": 0.70988846,
      "shippingCost": 0,
      "customsDutyPrice": 0,
      "progressPayment": 62.73521,
      "profitPercent": 15,
      "profitPrice": 8.182854,
      "amazonFeePercent": 15,
      "amazonFeePrice": 11.07092,
      "warehousePrice": 20.46225,
      "sellingPrice": 73.80613,
      "productCategory": "Clothing & Accessories",
      "itemHeightUnit": "",
      "itemHeightValue": 0.0,
      "itemLengthUnit": "",
      "itemLengthValue": 0.0,
      "itemWeightUnit": "kilograms",
      "itemWeightValue": 0.2,
      "itemWidthUnit": "",
      "itemWidthValue": 0.0,
      "packageHeightUnit": "",
      "packageHeightValue": 2.049999997909,
      "packageLengthUnit": "",
      "packageLengthValue": 9.1299999906874,
      "packageWeightUnit": "",
      "packageWeightValue": 0.70988848364,
      "packageWidthUnit": "inches",
      "packageWidthValue": 7.049999992809,
      "itemPackageQuantity": "",
      "size": "",
      "sourceMarketCountryCode": "US",
      "specialCode": 11233,
      "productSourceMarketPrice": 35.92250025,
      "isSelected": false
    },
    {
      "orderId": "4496f030-c4a9-422c-a028-7cb1bb6d477d",
      "imageUrl": "https://m.media-amazon.com/images/I/61PoSvijpIL.jpg",
      "deemedResellerCategory": null,
      "asin": "B06Y1BQVP0",
      "sellerSKU": "SF-CA-13268193105",
      "orderItemId": "119072869434004",
      "title": "item 4",
      "quantityOrdered": 1,
      "quantityShipped": 1,
      "productInfoNumberOfItems": 1,
      "itemPriceCurrencyCode": "CAD",
      "itemPriceAmount": 286.32,
      "shippingCurrencyCode": null,
      "shippingPriceAmount": null,
      "itemTaxCurrencyCode": "CAD",
      "itemTaxAmount": 14.32,
      "shippingTaxAmount": null,
      "shippingTaxCurrencyCode": null,
      "shippingDiscountCurrencyCode": null,
      "shippingDiscountAmount": null,
      "shippingDiscountTaxCurrencyCode": null,
      "shippingDiscountTaxAmount": null,
      "promotionDiscountCurrencyCode": "CAD",
      "promotionDiscountAmount": 0,
      "promotionDiscountTaxCurrencyCode": "CAD",
      "promotionDiscountTaxAmount": 0,
      "codFeeCurrencyCode": null,
      "codFeeAmount": null,
      "codFeeDiscountCurrencyCode": null,
      "codFeeDiscountAmount": null,
      "isGift": false,
      "conditionNote": null,
      "conditionId": "New",
      "conditionSubtypeId": "New",
      "scheduledDeliveryStartDate": null,
      "scheduledDeliveryEndDate": null,
      "priceDesignation": null,
      "serialNumberRequired": null,
      "isTransparency": false,
      "iossNumber": null,
      "storeChainStoreId": null,
      "isBuyerRequestedCancel": false,
      "buyerCancelReason": "",
      "serialNumbers": null,
      "trackingNumber": null,
      "deliveryTime": "1-3 gün",
      "isBuybox": false,
      "price": 34.090107,
      "desi": 0.70988846,
      "shippingCost": 0,
      "customsDutyPrice": 0,
      "progressPayment": 62.73521,
      "profitPercent": 15,
      "profitPrice": 8.182854,
      "amazonFeePercent": 15,
      "amazonFeePrice": 11.07092,
      "warehousePrice": 20.46225,
      "sellingPrice": 73.80613,
      "productCategory": "Clothing & Accessories",
      "itemHeightUnit": "",
      "itemHeightValue": 0.0,
      "itemLengthUnit": "",
      "itemLengthValue": 0.0,
      "itemWeightUnit": "kilograms",
      "itemWeightValue": 0.2,
      "itemWidthUnit": "",
      "itemWidthValue": 0.0,
      "packageHeightUnit": "",
      "packageHeightValue": 2.049999997909,
      "packageLengthUnit": "",
      "packageLengthValue": 9.1299999906874,
      "packageWeightUnit": "",
      "packageWeightValue": 0.70988848364,
      "packageWidthUnit": "inches",
      "packageWidthValue": 7.049999992809,
      "itemPackageQuantity": "",
      "size": "",
      "sourceMarketCountryCode": "US",
      "specialCode": 11233,
      "productSourceMarketPrice": 35.92250025,
      "isSelected": true
    }
  ],
  "orderStatusId": 10,
  "orderFlag": null,
  "sourceMarketCountryCode": null,
  "wareHouseShippingId": "0d674156-397a-403c-9ca3-f7140fed4243",
  "trackingNumber": "1ZC526R99112310004",
  "isOrderable": false,
  "specialCode": 11233,
  "isReturnStatu": null
}