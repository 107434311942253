import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter, DrPagination, DrReadMore, DrStars } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import i18next from 'i18next';
import { getAmazonFeedback, getRatingPercentage } from '../../state-management/actions/feedback.actions';

import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { setNegativeFeedbacksPageSelections } from '../../state-management/actions/settings.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';

function NegativeFeedbackListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('negativeFeedbackListPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const amazonFeedbackList = useSelector(state => state.feedback.amazonFeedbackList);
    const ratingPercentages = useSelector(state => state.feedback.ratingPercentages);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.negativeFeedbacksPageSelections);

    useEffect(() => {
        dispatch(getAmazonFeedback({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: 0, pageSize: 20, orderByParam: "date desc" }));
        dispatch(getRatingPercentage(selectedStore?.customerMainSellerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getAmazonFeedback({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: pageSelections?.tableSkip, pageSize: pageSelections?.pagelength, orderByParam: "date desc" }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.tableSkip, pageSelections?.pagelength]);

    const columns = [
        { data: "customerMainSellerId", title: "", minWidth: "45px", maxWidth: "45px", searchable: false, checkall: true, checkData: "customerMainSellerId" },
        { data: "amazonOrderId", title: t('dataTable.amazonOrderId'), minWidth: "160px", maxWidth: "160px", searchable: true },
        { data: "comments", title: t('dataTable.comments'), minWidth: "200px", searchable: true },
        { data: "raterEmail", title: t('dataTable.raterEmail'), minWidth: "150px", maxWidth: "150px", searchable: true },
        { data: "rating", title: t('dataTable.rating'), minWidth: "125px", maxWidth: "125px", searchable: true },
        { data: "date", title: t('dataTable.date'), minWidth: "130px", maxWidth: "130px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                if (rowData.isBlocked) {
                    td.closest("tr").classList.toggle("greyed-out");
                }

                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox" name="name" value={rowData.id}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                            onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(rowData.amazonOrderId);
                                toastr.info("Info", rowData.amazonOrderId + " is copied to clipboard", { timeOut: 1000 })
                            }}
                        >
                            {rowData.amazonOrderId}
                        </button>
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <DrReadMore max={100} text={rowData.comments || ""} classes='fs-12'
                            data-bs-placement="right" data-bs-toggle="tooltip"
                            title={rowData.comments}
                            onTextClicked={(e) => {
                                e.preventDefault();
                                // navigate('/product-detail/' + rowData.asin);
                            }}
                        ></DrReadMore>
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.raterEmail}</span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <DrStars value={rowData.rating}></DrStars>
                            </div>
                        </div>

                    </>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{moment.utc(rowData.date).fromNow()} </span>
                    </>);
            }
        }

    ];

    const refreshClickedCallBack = () => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getAmazonFeedback({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: 0, pageSize: 20, orderByParam: "date desc" }));
        }
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.feedback'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.negativeFeedbackList'), navigationPath: "" }]} >
            </DrBreadCrump>
            {ratingPercentages &&

                <div className="row justify-content-end mb-2">
                    <div className="col-12">
                        <div className="card custom-card mb-1">
                            <div className="card-body">
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        5 Star
                                    </div>
                                    <div className="col my-auto">
                                        <div className="progress progress-lg progress-animate custom-progress-4 warning" role="progressbar"
                                            aria-valuenow={(ratingPercentages.find(x => x.rating === 5)?.percentage || 0).toString()}
                                            aria-valuemin="0" aria-valuemax="100"
                                        >
                                            <div className="progress-bar bg-warning-gradient" style={{ width: ((ratingPercentages.find(x => x.rating === 5)?.percentage || 0).toString() + "%") }} ></div>
                                            <div className="progress-bar-label">{ratingPercentages.find(x => x.rating === 5)?.percentage || 0}%</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        4 Star
                                    </div>
                                    <div className="col my-auto">
                                        <div className="progress progress-lg progress-animate custom-progress-4 warning" role="progressbar"
                                            aria-valuenow={(ratingPercentages.find(x => x.rating === 4)?.percentage || 0).toString()}
                                            aria-valuemin="0" aria-valuemax="100"
                                        >
                                            <div className="progress-bar bg-warning-gradient" style={{ width: ((ratingPercentages.find(x => x.rating === 4)?.percentage || 0).toString() + "%") }} ></div>
                                            <div className="progress-bar-label">{ratingPercentages.find(x => x.rating === 4)?.percentage || 0}%</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        3 Star
                                    </div>
                                    <div className="col my-auto">
                                        <div className="progress progress-lg progress-animate custom-progress-4 warning" role="progressbar"
                                            aria-valuenow={(ratingPercentages.find(x => x.rating === 3)?.percentage || 0).toString()}
                                            aria-valuemin="0" aria-valuemax="100"
                                        >
                                            <div className="progress-bar bg-warning-gradient" style={{ width: ((ratingPercentages.find(x => x.rating === 3)?.percentage || 0).toString() + "%") }} ></div>
                                            <div className="progress-bar-label">{ratingPercentages.find(x => x.rating === 3)?.percentage || 0}%</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        2 Star
                                    </div>
                                    <div className="col my-auto">
                                        <div className="progress progress-lg progress-animate custom-progress-4 warning" role="progressbar"
                                            aria-valuenow={(ratingPercentages.find(x => x.rating === 2)?.percentage || 0).toString()}
                                            aria-valuemin="0" aria-valuemax="100"
                                        >
                                            <div className="progress-bar bg-warning-gradient" style={{ width: ((ratingPercentages.find(x => x.rating === 2)?.percentage || 0).toString() + "%") }} ></div>
                                            <div className="progress-bar-label">{ratingPercentages.find(x => x.rating === 2)?.percentage || 0}%</div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row mb-2">
                                    <div className="col-auto my-auto">
                                        1 Star
                                    </div>
                                    <div className="col my-auto">
                                        <div className="progress progress-lg progress-animate custom-progress-4 warning" role="progressbar"
                                            aria-valuenow={(ratingPercentages.find(x => x.rating === 1)?.percentage || 0).toString()}
                                            aria-valuemin="0" aria-valuemax="100"
                                        >
                                            <div className="progress-bar bg-warning-gradient" style={{ width: ((ratingPercentages.find(x => x.rating === 1)?.percentage || 0).toString() + "%") }} ></div>
                                            <div className="progress-bar-label">{ratingPercentages.find(x => x.rating === 1)?.percentage || 0}%</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }

            <div className="row mb-5">
                <div className="col-12">

                    <div className="card custom-card">
                        <div className="card-body">
                            <DrDataTable
                                data={amazonFeedbackList.data}
                                columns={columns}
                                columnDefs={columnDefs}
                                doesHaveBorder={false}
                                headerSearch={true}
                                // headerSelectbox={true}
                                minWidth="850px"
                                search={true}
                                order={pageSelections?.sortingInfo?.length > 0 ? [[parseInt(pageSelections?.sortingInfo[0]), pageSelections?.sortingInfo[2]]] : [[5, 'desc']]}
                                isMultiSelectEnabled={true}
                                detailedSearchChildren={
                                    <DrPageFilter
                                        refreshClickedCallBack={refreshClickedCallBack}
                                    >
                                    </DrPageFilter>
                                }
                                pageLength={pageSelections?.pagelength || 5}

                                paginationPlugin={
                                    <DrPagination
                                        paginationData={
                                            {
                                                tableSkip: (pageSelections?.tableSkip || 0),
                                                totalRecordCount: amazonFeedbackList?.totalRecordCount,// products?.totalRecordCount || pageSelections.tableLength,
                                                tablePageLength: pageSelections?.pagelength || 5
                                            }

                                        }
                                        tablePageLengthChanged={(e) => {
                                            dispatch(withActionPromise(setNegativeFeedbacksPageSelections({ tableSkip: 0, tableLength: (amazonFeedbackList?.totalRecordCount || pageSelections?.tableLength), pagelength: e, sortingInfo: pageSelections?.sortingInfo })));
                                        }}
                                        tablePageNumberChanged={(e) => {
                                            dispatch(withActionPromise(setNegativeFeedbacksPageSelections({ tableSkip: e, tableLength: (amazonFeedbackList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                                        }}
                                    >
                                    </DrPagination>

                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NegativeFeedbackListPage;