import classNames from 'classnames';
import './dr-input.component.css'
import XLSX from 'sheetjs-style'

function DrFileUploadInput({
    name,
    field,
    form,
    onChange,
    disabled,
    className,
    selectedValue,
    disableInputChange,
    accept,
    maxFiles,
    maxFileSizeInBytes,
    removeFileTypeStringFromContent = true,
    onExcelDataRequestedCallback,
    ...rest }) {


    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    var inputClass = classNames({
        'form-control': true,
    }, className);

    function onFileUploaded(e) {
        e.preventDefault();
        if (e.target.files && e.target.files.length > maxFiles) {
            e.currentTarget.value = null;
            if (form && field) {
                form.setFieldValue(field.name, []);
            }

            return;
        }

        var isFileSizeExceedsLimit = false;
        e.target.files.forEach(file => {
            if (file.size > maxFileSizeInBytes) {
                isFileSizeExceedsLimit = true;
            }
        });

        if (isFileSizeExceedsLimit) {
            e.currentTarget.value = null;
            if (form && field) {
                form.setFieldValue(field.name, []);
            }
            return;
        }

        const promises = []
        if (onExcelDataRequestedCallback) {
            promises.push(readExcelFile(e));
        }
        else {
            for (const file of e.target.files) {
                promises.push(readFile(file));
            }
        }


        Promise.all(promises).then((result) => {
            if (form && field) {
                form.setFieldValue(field.name, result);
            }
            if (onExcelDataRequestedCallback) {
                onExcelDataRequestedCallback(...result);
            }
        });
    }

    function readExcelFile(e) {
        e.preventDefault();
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            // reader.readAsDataURL(f)
            var files = e.target.files, f = files[0];
            reader.onload = function (e) {
                var data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                var data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (data?.length > 0 && data[0]?.length > 1) {
                    var columns = data[0];
                    var rows = XLSX.utils.sheet_to_row_object_array(readedData.Sheets[wsname]);

                    resolve({ columns: columns, rows: rows })
                }
                else {
                    resolve(null);
                }
            }
            reader.readAsBinaryString(f)

            reader.onerror = reject
        })
    }

    function readFile(f) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(f)
            reader.onload = event => resolve({ attachment: removeFileTypeStringFromContent ? event.target.result.split('base64,')[1] : event.target.result, contentType: f.type, name: f.name })
            reader.onerror = reject
        })
    }

    return (
        <div className={`dr-input-content input-group`}>
            <input
                id={name}
                type="file"
                name={name}
                accept={accept}
                className={inputClass}
                {...rest}
                onChange={(e) => {
                    if (onChange)
                        onChange(e);
                    if (!disableInputChange)
                        onFileUploaded(e);
                }}
            />
        </div>
    )
}

export default DrFileUploadInput;
