
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrDualDatePicker, DrInput, DrPageFilter, DrSweetAlertButton } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonTypes, sweetAlertVariants } from '../../utils/constants';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import i18next from 'i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import Restricted from '../../security/Restricted';
import { createCoupon, deletePackage, getCoupons, updateCoupon } from '../../state-management/actions/packages.action';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';

function CreateCouponsPage() {
    const { REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_STORE } = process.env;
    const dispatch = useDispatch();
    const { t } = useTranslation('createCouponsPage');
    const allCoupons = useSelector(state => state.packages.allCoupons);

    const [selectedCoupon, setSelectedCoupon] = useState(null);

    useEffect(() => {
        dispatch(withActionPromise(getCoupons()));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        { data: "couponName", title: t('dataTable.couponName'), minWidth: "100px", maxWidth: "140px", searchable: true },
        { data: "couponCode", title: t('dataTable.couponCode'), minWidth: "90px", maxWidth: "90px", searchable: true },
        { data: "discountRate", title: t('dataTable.discountRate'), minWidth: "100px", maxWidth: "100px", searchable: false },
        { data: "maxUseCount", title: t('dataTable.maxUseCount'), minWidth: "160px", maxWidth: "160px", searchable: true },
        { data: "multipleUseCount", title: t('dataTable.multipleUseCount'), minWidth: "160px", maxWidth: "160px", searchable: true },
        { data: "maxDiscountPrice", title: t('dataTable.maxDiscountPrice'), minWidth: "160px", maxWidth: "160px", searchable: false },
        { data: "minPackagePrice", title: t('dataTable.minPackagePrice'), minWidth: "120px", maxWidth: "120px", searchable: false },
        { data: "startDate", title: t('dataTable.startDate'), minWidth: "160px", maxWidth: "160px", searchable: false },
        { data: "endDate", title: t('dataTable.endDate'), minWidth: "160px", maxWidth: "160px", searchable: false },
        { data: null, title: "", minWidth: "80px", maxWidth: "80px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.couponName}</span>
                );
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.couponCode}</span>
                );
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">%{rowData.discountRate}</span>
                );
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.maxUseCount}</span>
                );
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{rowData.multipleUseCount}</span>
                );
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">${Number(rowData.maxDiscountPrice).toFixed(2)}</span>
                );
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">${Number(rowData.minPackagePrice).toFixed(2)}</span>
                );
            }
        },
        {
            targets: 7,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{moment.utc(rowData.startDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 8,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-14 my-auto mx-0 p-0">{moment.utc(rowData.endDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 9,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <DrSweetAlertButton
                        className={`ms-2 btn btn-danger btn-wave waves-effect waves-light shadow btn-sm`}
                        buttonText=""
                        buttonIcon={<i className="fe fe-trash-2 me-2"></i>}
                        buttonType={buttonTypes.button}
                        onCloseConfirmCallBack={(value) => {
                            if (value) {
                                dispatch(withActionPromise(deletePackage(rowData.id)));
                            }
                        }}
                        sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                    >
                    </DrSweetAlertButton>
                )
            }
        }
    ];
    
    const initialValues = {
        id: selectedCoupon?.id || null,
        couponName: selectedCoupon?.couponName || "",
        couponCode: selectedCoupon?.couponCode || "",
        discountRate: selectedCoupon?.discountRate || 0,
        maxUseCount: selectedCoupon?.maxUseCount || 0,
        startDate: selectedCoupon?.startDate || null,
        endDate: selectedCoupon?.endDate || null,
        multipleUseCount: selectedCoupon?.multipleUseCount || 0,
        maxDiscountPrice: selectedCoupon?.maxDiscountPrice || "",
        minPackagePrice: selectedCoupon?.minPackagePrice || "",
    }

    const unCheckAllSelections = () => {
        $(document.querySelectorAll('#couponsTable tr')).removeClass("selected");
        $(document.querySelectorAll('input[type="checkbox"]:checked')).prop("checked", false);
    }

    const couponSchema = Yup.object().shape({

        couponName: Yup.string().required('Please enter coupon name!')
            .min(3, 'Package Name needs to be minimum 3 character')
            .max(50, 'Package Name needs to be minimum 50 character'),

        couponCode: Yup.string().required('Please enter couppn code!')
            .min(10, 'Package Name needs to be 10 character')
            .max(10, 'Package Name needs to be 10 character'),

        // price: Yup.string().nullable().matches(/^[0-9]+$/, "Must be only digits")
        maxDiscountPrice: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                // (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),
        minPackagePrice: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                // (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),
        discountRate: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                // (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        maxUseCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),

        multipleUseCount: Yup.number().nullable().notRequired().min(0)
            .test(
                "noEOrSign", // type of the validator (should be unique)
                "You need to enter number only!", // error message
                // (value) => typeof value === "number" && !/[eE+-]/.test(value.toString())
                (value) => typeof value === "number" && /^[0-9]+$/.test(value.toString())
            ),
    });

    const rowClickedCallBack = async (rowData) => {
        setSelectedCoupon({ ...rowData });
    }

    const refreshClickedCallBack = () => {
        dispatch(withActionPromise(getCoupons()));
    };


    return (
        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.administrator'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.createCoupons'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            <Formik
                                initialValues={initialValues}
                                validateOnBlur={true}
                                // onSubmit={handleSubmit}
                                validationSchema={couponSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            // validateOnChange={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setTouched,
                                    setFieldValue,
                                    resetForm

                                }) => (
                                    <form>
                                        <div className={`row my-1 justify-content-end`} >
                                            <div className="col-auto my-auto">
                                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_STORE]}>
                                                    <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            unCheckAllSelections();
                                                            setSelectedCoupon(null);
                                                            resetForm();
                                                        }}>
                                                        <i className="me-2 fe fe-plus"></i>
                                                        {t('addNewCoupon')}
                                                    </button>
                                                </Restricted>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-xxl-4 col-xl-6 col-lg-6 col-12 d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('couponName')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput value={values.couponName || ""} className={`form-control`}
                                                                placeholder={t('couponName')}
                                                                name="couponName"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'couponName': true }) }}
                                                                isInvalid={(touched.couponName && errors.couponName)}
                                                                isValid={touched.couponName && !errors.couponName}
                                                                validationText={errors.couponName}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('couponCode')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput value={values.couponCode || ""} className={`form-control`}
                                                                placeholder={t('couponCode')}
                                                                name="couponCode"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'couponCode': true }) }}
                                                                isInvalid={(touched.couponCode && errors.couponCode)}
                                                                isValid={touched.couponCode && !errors.couponCode}
                                                                validationText={errors.couponCode}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('dateInterval')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrDualDatePicker
                                                                selectedDates={[values.startDate, values.endDate]}
                                                                onCloseCallBack={(dateValues) => {
                                                                    var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY-MM-DDTHH:mm:ss").startOf('day').format("YYYY-MM-DDTHH:mm:ss") : null)];
                                                                    setFieldValue("startDate", dates[0]);
                                                                    setFieldValue("endDate", dates[1]);
                                                                }}
                                                                timeOptionsSource={timeOptions}
                                                            ></DrDualDatePicker>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-6 col-lg-6 col-12 d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('discountRate')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('discountRate')} name="discountRate" type="text" min="0" step="1"
                                                                value={values.discountRate || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'discountRate': true }) }}
                                                                icon={<i className="bx bxs-discount"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.discountRate && errors.discountRate)}
                                                                isValid={touched.discountRate && !errors.discountRate}
                                                                validationText={errors.discountRate} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('maxDiscountPrice')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('maxDiscountPrice')} name="maxDiscountPrice" type="text" min="0" step="50"
                                                                value={values.maxDiscountPrice || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxDiscountPrice': true }) }}
                                                                icon={<i className="bx bx-dollar"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxDiscountPrice && errors.maxDiscountPrice)}
                                                                isValid={touched.maxDiscountPrice && !errors.maxDiscountPrice}
                                                                validationText={errors.maxDiscountPrice} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('minPackagePrice')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('minPackagePrice')} name="minPackagePrice" type="text" min="0" step="50"
                                                                value={values.minPackagePrice || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'minPackagePrice': true }) }}
                                                                icon={<i className="bx bx-dollar"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.minPackagePrice && errors.minPackagePrice)}
                                                                isValid={touched.minPackagePrice && !errors.minPackagePrice}
                                                                validationText={errors.minPackagePrice} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-6 col-lg-6 col-12 d-flex flex-column">
                                                <div className="card custom-card card-body bg-info bg-opacity-10">
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('maxUseCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('maxUseCount')} name="maxUseCount" type="text" min="0" step="100"
                                                                value={values.maxUseCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'maxUseCount': true }) }}
                                                                icon={<i className="bx bx-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.maxUseCount && errors.maxUseCount)}
                                                                isValid={touched.maxUseCount && !errors.maxUseCount}
                                                                validationText={errors.maxUseCount} />

                                                        </div>
                                                    </div>
                                                    <div className="row my-1">
                                                        <div className="col-4 my-auto">
                                                            <label className="text-wrap">{t('multipleUseCount')}</label>
                                                        </div>
                                                        <div className="col">
                                                            <DrInput className="form-control" placeholder={t('multipleUseCount')} name="multipleUseCount" type="text" min="0" step="100"
                                                                value={values.multipleUseCount || ""}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'multipleUseCount': true }) }}
                                                                icon={<i className="bx bx-package"></i>}
                                                                iconPosition="left"
                                                                isInvalid={(touched.multipleUseCount && errors.multipleUseCount)}
                                                                isValid={touched.multipleUseCount && !errors.multipleUseCount}
                                                                validationText={errors.multipleUseCount} />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                        <div className="row justify-content-end my-3">

                                            <div className="col-auto">
                                                <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (isValid) {
                                                            if (values.id) {
                                                                dispatch(withActionPromise(updateCoupon(values)));
                                                            } else {
                                                                dispatch(withActionPromise(createCoupon(values)));
                                                            }
                                                        }
                                                    }}
                                                >{t('saveChanges')}</button>
                                            </div>
                                        </div>
                                    </form>
                                )
                                }
                            </Formik>
                            <div id="couponsTable" className="row">
                                <div className="col-12">
                                    <DrDataTable
                                        data={allCoupons}
                                        columns={columns}
                                        columnDefs={columnDefs}
                                        doesHaveBorder={false}
                                        headerSearch={true}
                                        search={true}
                                        minWidth="850px"
                                        isRowClickEnabled={true}
                                        rowClickedCallBack={rowClickedCallBack}
                                        detailedSearchChildren={
                                            <DrPageFilter
                                                refreshClickedCallBack={refreshClickedCallBack}
                                            >
                                            </DrPageFilter>
                                        }

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateCouponsPage