import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrColumnChart, DrDataTable, DrDualDatePicker } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { timeOptions } from '../../components/hoc/Constants/dr-constants.component';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { buttonVariants, modals } from '../../utils/constants';
import { getWarehouseHomePageData, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { setActiveCreateLabelModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { setWarehouseHomePageSelections } from '../../state-management/actions/settings.actions';
import { getWarehouseOrders } from '../../state-management/actions/orders.actions';
import noImage from '../../assets/img/pngs/no-image.png'
import Restricted from '../../security/Restricted';
import { clearShipStationData } from '../../state-management/actions/shipstation.actions';

function WareHouseHomePage() {

  const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_STORE } = process.env;

  const dispatch = useDispatch();
  const { t } = useTranslation('wareHouseHomePage');
  const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);
  const pageSelections = useSelector(state => state.settings?.pageSelections?.warehouseHomePageSelections);
  const customerInfo = useSelector(state => state.customer.customerInfo);
  const warehouseHomePageData = useSelector(state => state.warehouses.warehouseHomePageData);
  const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
  const warehouseOrderList = useSelector(state => state.orders.warehouseOrderList);
  const pageOrderSelections = useSelector(state => state.settings?.pageSelections?.warehouseOrdersPageSelections);
  const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
  const [isManual, setIsManual] = useState(true);

  useEffect(() => {
    dispatch(getWarehouseHomePageData(
      {
        marketId: pageSelections?.marketOption || "-1",
        warehouseId: customerInfo?.warehouseId
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((customerInfo?.role?.includes(REACT_APP_ROLES_STORE) || customerInfo?.role?.includes(REACT_APP_ROLES_SUPERADMIN))) {
      if (!allWarehouses || (allWarehouses && allWarehouses.length === 0)) {
        dispatch(withActionPromise(getWarehouses()));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo?.role]);


  useEffect(() => {

    dispatch(getWarehouseHomePageData(
      {
        marketId: pageSelections?.marketOption !== "-1" ? pageSelections?.marketOption : null,
        warehouseId: customerInfo?.warehouseId
      }));
    setIsManual(allWarehouses?.find(x => x.id === customerInfo?.warehouseId)?.isManual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWarehouses, pageSelections?.marketOption]);

  useEffect(() => {

    var localDate = new Date();

    if (pageOrderSelections?.dateInterval && pageOrderSelections?.dateInterval.length > 0 && (pageOrderSelections.dateInterval[0] || pageOrderSelections.dateInterval[1])) {

      var localDate = new Date();
      var compareDateStart = moment.utc(localDate).subtract(90, 'days').startOf('day').format("YYYY-MM-DD");
      var compareDateEnd = moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

      let requestData = {

        orderStatus: 14,
        startOrderDate: compareDateStart,
        endOrderDate: compareDateEnd,
        skip: 0,
        pageSize: 20,
        orderByParam: "purchaseDate desc",// pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : null,
        warehouseId: customerInfo?.warehouseId
      }

      if (pageSelections?.marketOption && pageSelections?.marketOption !== "-1") {
        requestData.marketId = pageSelections?.marketOption;
      }

      dispatch(getWarehouseOrders(requestData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSelections?.marketOption]);


  return (
    <>
      <DrBreadCrump > </DrBreadCrump>
      {/* <div className="row justify-content-end mb-2">
        <div className="col-12">
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Tracking Id, Order Id"  aria-describedby="button-addon2"></input>
            <button className="btn btn-teal" type="button" id="button-addon2">Search</button>
          </div>
        </div>
      </div> */}

      <div className="row" >
        <div className="col-xl-8 d-flex">
          <div id="chart" className="card custom-card chart">
            <div className="card-body">
              <div className="row justify-content-start mb-2">
                <div className="col">
                  <DrColumnChart
                    chartData={{
                      // series: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment.utc(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf()).map(x => Number(x.yData).toFixed(1)) || [],
                      // categories: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment.utc(a.xData, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.xData, "YYYY-MM-DDThh:mm:ss").valueOf()).map((x, index) => {
                      //   return (
                      //     index % (warehouseHomePageData?.customerOrderGraphics?.axis.length <= 31 ? 2 : (warehouseHomePageData?.customerOrderGraphics?.axis.length > 31 && warehouseHomePageData?.customerOrderGraphics?.axis.length <= 90 ? 5 : 13)) === 0 ? moment.utc(x.xData).format('L') : ""
                      //   )
                      // }) || [],
                      data: warehouseHomePageData?.customerOrderGraphics?.axis?.sort((b, a) => moment.utc(b.xData, "YYYY-MM-DDThh:mm:ss").diff(moment.utc(a.xData, "YYYY-MM-DDThh:mm:ss"))),
                      colors: ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
                      peakText: t('peakText'),
                      // title: "Inventory Status"
                    }}
                  ></DrColumnChart>
                </div>
              </div>
            </div>
          </div>
          {/* <DrLineChart chartData={chartData} options={chartOptions} /> */}

        </div>
        <div className="col-xl-4 d-flex">
          <div id="sale-summary" className="card custom-card">
            <div className="card-header">
              <div className="card-title">
                {t('salesSummary')}
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive ">
                <table className="table table-hover table-bordered border-primary">
                  <thead>
                    <tr>
                      <th>{t('period')}</th>
                      <th className="text-center">{t('sales')}</th>
                      <th className="text-center">{t('quantity')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{t('today')}</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.today?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.today?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('sevenDays')}</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.sevenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.sevenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('fifteenDays')}</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.fifteenDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.fifteenDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('thirtyDays')}</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.thirtyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.thirtyDay?.totalSaleCount}</td>
                    </tr>
                    <tr>
                      <th scope="row">{t('ninetyDays')}</th>
                      <td className="text-center">{selectedStoreCurrencyType.currencyCode} {Number(warehouseHomePageData?.salesSumary?.ninetyDay?.totalSalePrice || 0.00).toFixed(2)}</td>
                      <td className="text-center">{warehouseHomePageData?.salesSumary?.ninetyDay?.totalSaleCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="row">
        <div id="draggable-bottom" className="col-xl-12 pb-5 mb-5">
          <div id="latest-orders" className="card custom-card">
            <div className="card-body flex-unset">
              <div>
                <h6 className="card-title mb-1">{t('orderWidget.title')}</h6>
              </div>
            </div>
            <div className="card-body">
              {warehouseOrderList && warehouseOrderList.data?.length > 0 &&
                <div className="table-responsive">
                  <table className="table table-hover table-bordered border-primary">
                    <thead>


                      <tr>
                        <th></th>
                        <th>{t('orderWidget.orderNumber')}</th>
                        <th className="text-center">{t('orderWidget.specialCode')}</th>
                        <th className="text-center">{t('orderWidget.warehouseSuitName')}</th>
                        <th className="text-center">{t('orderWidget.orderType')}</th>
                        <th className="text-center">{t('orderWidget.sellerMailAddress')}</th>
                        <th className="text-center">{t('orderWidget.trackingNumber')}</th>
                        {!isManual && <th className="text-center">{t('orderWidget.labelData')}</th>}
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {warehouseOrderList?.data?.sort((b, a) => moment.utc(a.purchaseDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.purchaseDate, "YYYY-MM-DDThh:mm:ss").valueOf()).slice(0, 10).map((orderItem, index) => {

                        return (
                          <tr key={"orderItem_" + index}>
                            <td className="text-center">
                              <div className="card-aside-img cursor-pointer border border-gray-300 rounded container mt-2" style={{ textAlign: 'start', minWidth: '64px', position: 'relative' }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (orderItem.products?.length > 0) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: orderItem.products?.map(x => x.imageUrl) || [noImage] }))
                                  }
                                }} >
                                <div className="row justify-content-start">
                                  {orderItem.products?.length > 0 && orderItem.products.slice(0, (orderItem.products.length > 1 ? 2 : orderItem.products.length)).map((product, index) => {

                                    return (
                                      <div key={"product-pic-" + index} className={`px-0 rounded-0 ${orderItem.products.length > 1 ? "col-6 " : "col-12 "}`} >
                                        <img src={product.imageUrl || noImage} className="img-thumbnail" alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                        ></img>
                                      </div>
                                    )
                                  })}

                                </div>
                                {orderItem.products?.length > 2 &&
                                  <div className="row justify-content-start">
                                    {orderItem.products.slice(2, (orderItem.products.length > 3 ? 4 : orderItem.products.length)).map((product, index) => {

                                      return (
                                        <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0">
                                          <img src={product.imageUrl || noImage} alt="img" data-tag="pic-modal-opener" style={{ minWidth: '30px' }}
                                          ></img>
                                        </div>
                                      )
                                    })}

                                  </div>
                                }
                                {orderItem.products.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{orderItem.products.length}</span>}

                              </div>
                            </td>
                            <td>
                              <p className="mb-0">
                                <span className="text-primary ms-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    // navigate('/order-detail/' + orderItem.id);
                                    window.open(window.location.origin + "/#/order-detail/" + orderItem.id, '_blank');
                                  }} >
                                  #{orderItem.orderNumber}
                                </span>
                              </p>
                            </td>
                            <td className="text-center">
                              {orderItem.specialCode}
                            </td>
                            <td className="text-center">
                              {orderItem.warehouseSuitName}
                            </td>
                            <td className="text-center">
                              <span className="badge bg-info">
                                {orderItem.orderType}
                              </span>
                            </td>
                            <td className="text-center">
                              {orderItem.customerMainSellerStoreName}
                            </td>
                            <td className="text-center">
                              {orderItem.trackingNumber?.length > 0 && orderItem.trackingNumber !== "." ? orderItem.trackingNumber : ""}
                            </td>
                            {!isManual &&
                              <td className="text-center">
                                {orderItem.labelData}
                              </td>
                            }
                            <td className="text-center">
                              <Restricted allowedTo={[REACT_APP_ROLES_STORE]}>
                                <div className="col-auto my-1">
                                  <button className={`ms-2 btn btn-wave waves-effect waves-light shadow btn-sm  ${orderItem.trackingNumber?.length > 0 && orderItem.trackingNumber !== "." ? "btn-warning" : "btn-teal"}`}

                                    onClick={(e) => {
                                      e.preventDefault();
                                      dispatch(clearShipStationData());
                                      dispatch(setActiveCreateLabelModal({
                                        name: modals.createLabel, isGlobal: true,
                                        source: orderItem.id
                                      }));

                                    }}>
                                    {/* {rowData.trackingNumber ? "Re-Create Label" : "Create Label"} */}
                                    {orderItem.trackingNumber?.length > 0 && orderItem.trackingNumber !== "." ? "Re-Create Label" : "Create Label"}
                                  </button>
                                </div>
                              </Restricted>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </>

  )
}
export default WareHouseHomePage