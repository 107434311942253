import XLSX from 'sheetjs-style'
import * as FileSaver from 'file-saver'
const ExcelJS = require('exceljs');

// export const ReadFromExcel = (e) => {
//     e.preventDefault();
//     var dataParse;
//     var files = e.target.files, f = files[0];
//     var reader = new FileReader();
//     reader.onload = function (e) {
//         var data = e.target.result;
//         let readedData = XLSX.read(data, { type: 'binary' });
//         const wsname = readedData.SheetNames[0];
//         const ws = readedData.Sheets[wsname];

//         /* Convert array to json*/
//         dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
//         // setFileUploaded(dataParse);

//     };
//     reader.readAsBinaryString(f)

//     return dataParse;
// }

export const ReadFromExcel = (e) => {
    e.preventDefault();
    var dataParse;
    var files = e.target.files, f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
        var data = e.target.result;
        let readedData = XLSX.read(data, { type: 'binary' });
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];

        var data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (data?.length > 0 && data[0]?.length > 1) {
            var columns = data[0];
            var rows = XLSX.utils.sheet_to_row_object_array(readedData.Sheets[wsname]);
            dataParse = { columns: columns, rows: rows }
        }
        else {
            dataParse = null;
        }


    };
    reader.readAsBinaryString(f)

    return dataParse;
}

export const ReadFromExcelClassic = (e) => {
    e.preventDefault();
    // const fileUpload = (document.getElementById('fileUpload'));

    const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
    if (regex.test(e.target.value.toLowerCase())) {
        let fileName = e.target.files[0].name;
        if (typeof (FileReader) !== 'undefined') {
            const reader = new FileReader();
            if (reader.readAsBinaryString) {
                reader.onload = (e) => {
                    var data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const firstSheet = workbook.SheetNames[0];
                    return XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);

                };
                reader.readAsBinaryString(e.target.files[0]);
            }
        } else {
            console.log("This browser does not support HTML5.");
        }
    } else {
        console.log("Please upload a valid Excel file.");
    }

}

export const ExportToExcel = ({ excelData, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xls';

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);

}

export const ExcelExport3 = ({ excelData, columns, fileName }) => {
    const ExcelJS = require('exceljs');
    // const workbook = new ExcelJS.Workbook();
    // const worksheet = workbook.addWorksheet("MySheet");
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // worksheet.properties.defaultRowHeight = 80;
    worksheet.pageSetup.orientation = 'landscape';

    worksheet.columns = columns;
    worksheet.addRows(excelData);

    worksheet.getRow(1).border = {
        top: { style: "thin", color: { argb: "ff030303" } },
        left: { style: "thin", color: { argb: "ff030303" } },
        bottom: { style: "thin", color: { argb: "ff030303" } },
        right: { style: "thin", color: { argb: "ff030303" } },
    }
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: "ff1e76c1" }
    }

    worksheet.getRow(1).font = { bold: true, size: 16, color: { argb: 'ffffffff' } };

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        if (rowNumber !== 1) {
            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
            });
        }
    });

    workbook.xlsx.writeBuffer().then(excelData => {
        const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        // const url = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.download = fileName + ".xlsx";
        // anchor.click();
        // window.URL.revokeObjectURL(url);

        FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });

}

export const ExcelExport2 = ({ excelData, columns, fileName }) => {
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("MySheet");
    // worksheet.properties.defaultRowHeight = 80;
    worksheet.pageSetup.orientation = 'landscape';

    worksheet.columns = columns;
    worksheet.addRows(excelData);

    worksheet.getRow(1).border = {
        top: { style: "thin", color: { argb: "ff030303" } },
        left: { style: "thin", color: { argb: "ff030303" } },
        bottom: { style: "thin", color: { argb: "ff030303" } },
        right: { style: "thin", color: { argb: "ff030303" } },
    }
    worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: "ff1e76c1" }
    }

    worksheet.getRow(1).font = { bold: true, size: 16, color: { argb: 'ffffffff' } };

    worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        if (rowNumber !== 1) {
            row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
                cell.alignment = { wrapText: true, vertical: 'top', horizontal: 'left' };
            });
        }
    });

    workbook.xlsx.writeBuffer().then(excelData => {
        const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        // const url = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.download = fileName + ".xlsx";
        // anchor.click();
        // window.URL.revokeObjectURL(url);

        FileSaver.saveAs(blob, `${fileName}.xlsx`);
    });

}