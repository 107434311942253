import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getOrderShipStationData, getOrderShipStationDataSuccess, getShipStationCarrierPackagesSuccess, getShipStationCarrierServicesSuccess, getShipStationCarriersSuccess, getShipStationWarehousesSuccess, saveOrderShipStationDataBulk, saveOrderShipStationDataBulkSuccess } from '../actions/shipstation.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';
import { getWarehouseOrders } from '../actions/orders.actions';

function* runGetShipStation(action) {
    const payload = action.payload;
    var path = `?OrderId=${payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getOrderShipStationDataSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error21') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSaveShipStation(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield all([
            put(getOrderShipStationData(payload.orderId)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info6'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error22') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runCreateLabelListBulk(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/CreateLabelList`, { createLabelList: payload.createLabelList }, false, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.data && response.resultType === 1) {

        yield all([
            put(saveOrderShipStationDataBulkSuccess(response.data)),
            put(getWarehouseOrders(payload.warehouseOrdersRequestData)),
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.info6'), 'success'))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error22') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetShipStationWarehouses() {

    var path = `/GetWarehouses`;
    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getShipStationWarehousesSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error23') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetShipStationCarriers() {

    var path = `/GetCarriers`;
    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getShipStationCarriersSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error24') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetShipStationCarrierServices(action) {
    const payload = action.payload;
    var path = `/GetCarrierServices?carrierCode=${payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getShipStationCarrierServicesSuccess({ carrierCode: payload, data: response.data }));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error25') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetShipStationCarrierPackages(action) {
    const payload = action.payload;
    var path = `/GetCarrierPackages?carrierCode=${payload}`;
    const response = yield call(apiGet(path, CONTROLLERS.SHIPSTATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getShipStationCarrierPackagesSuccess({ carrierCode: payload, data: response.data }));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error26') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* shipstationSaga() {
    yield all([
        takeLatest(types.GET_SHIPSTATION_REQUESTED, safe(runGetShipStation)),
        takeLatest(types.CREATE_SHIPSTATION_REQUESTED, safe(runSaveShipStation)),
        takeLatest(types.CREATE_SHIPSTATION_BULK_REQUESTED, safe(runCreateLabelListBulk)),
        takeLatest(types.GET_SHIPSTATION_WAREHOUSES_REQUESTED, safe(runGetShipStationWarehouses)),
        takeLatest(types.GET_SHIPSTATION_CARRIERS_REQUESTED, safe(runGetShipStationCarriers)),
        takeLatest(types.GET_SHIPSTATION_CARRIER_SERVICES_REQUESTED, safe(runGetShipStationCarrierServices)),
        takeLatest(types.GET_SHIPSTATION_CARRIER_PACKAGES_REQUESTED, safe(runGetShipStationCarrierPackages))
    ]);
}