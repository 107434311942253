import types from "../actions/feedback.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_FEEDBACK_CAMPAIGN_LIST_SUCCESS:
            return { ...state, allCampaigns: action.payload };
        case types.GET_AMAZON_FEEDBACK_LIST_SUCCESS:
            return { ...state, amazonFeedbackList: action.payload };
        case types.GET_AMAZON_FEEDBACK_RATING_PERCENT_SUCCESS:
            return { ...state, ratingPercentages: action.payload };
        case types.GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_SUCCESS:
            return { ...state, feedbackCampaignSendEmails: action.payload };
        case types.UPSERT_FEEDBACK_CAMPAIGN_SUCCESS:
            return { ...state, lastSavedCampaign: action.payload };
        case types.CLEAR_ALL_FEEDBACK_STATE:
            return initialState;;
        default:
            return state;
    }
}

const initialState = {
    "allCampaigns": [],
    "amazonFeedbackList": [],
    "lastSavedCampaign": null,
    "ratingPercentages": null,
    "feedbackCampaignSendEmails": null
}

export default reducer;