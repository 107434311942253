import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrInput, DrPageFilter, DrSweetAlertButton } from '../../components/component-index';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import { getProductsBrands, saveProductBrandBlock } from '../../state-management/actions/inventory.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { pageFilterTypes, sweetAlertVariants } from '../../utils/constants';
import { generatePdfReport } from '../../utils/utils';
import { ExportToExcel } from '../../excelexport';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { getRefunds, saveRefunds } from '../../state-management/actions/orders.actions';
import moment from 'moment';
import Swal from 'sweetalert2';

function RefundsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('refundsPage');
  const selectedStore = useSelector(state => state.stores.selectedStore);
  const refunds = useSelector(state => state.orders.refunds);

  useEffect(() => {
    if (selectedStore?.customerMainSellerId) {
      dispatch(withActionPromise(getRefunds(selectedStore?.customerMainSellerId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const openEditPopup = (rowData) => {
    Swal.fire({
      title: 'Enter Tracking Number',
      input: 'text',
      inputPlaceholder: 'Enter tracking number...',
      showCancelButton: true,
      confirmButtonText: 'Save',
      preConfirm: (trackingNumber) => {
        if (!trackingNumber) {
          Swal.showValidationMessage('Tracking number cannot be empty');
        }
        return trackingNumber;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const newTrackingNumber = result.value;

        // Dispatch the saveRefunds action with the amazonOrderNumber and trackingNumber
        var request = {
          customerMainSellerId: selectedStore?.customerMainSellerId,
          requestData: {
            amazonOrderNumber: rowData.amazonOrderNumber,
            returnTrackingNumber: newTrackingNumber
          }
        }
        console.log(request);
        dispatch(saveRefunds(request));

        // Optionally, provide feedback to the user
        Swal.fire({
          icon: 'success',
          title: 'Tracking Number Saved!',
          text: `Amazon Order: ${rowData.amazonOrderNumber}`,
          timer: 2000,
          showConfirmButton: false
        });
      }
    });
  };



  const columns = [
    { data: "amazonOrderNumber", title: t('dataTable.amazonOrderNumber'), width: "15%", searchable: true },
    { data: "trackingNumber", title: t('dataTable.trackingNumber'), width: "15%", searchable: true },
    { data: "isDelivered", title: t('dataTable.isDelivered'), width: "15%", searchable: true },
    { data: "createdAt", title: t('dataTable.createdAt'), width: "15%", searchable: true },
  ];

  const columnDefs = [
    {
      targets: 0,
      orderable: true,
      className: "text-center",
      width: "20%",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
              onClick={(e) => {
                e.preventDefault();
                window.open(window.location.origin + "/#/order-detail/" + rowData.amazonOrderNumber, '_blank');
              }}
            >
              {rowData.amazonOrderNumber}
            </button>
          </>);
      }
    },
    {
      targets: 1,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          rowData.trackingNumber ? (
            <>{rowData.trackingNumber}</>
          ) : (
            <button
              className="btn btn-sm btn-primary"
              onClick={() => openEditPopup(rowData)}
            >
              <i className="fe fe-edit me-2"></i>Edit
            </button>
          )
        );
      }
    },
    {
      targets: 2,
      orderable: false,
      className: "text-center",

      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          rowData.isDelivered ? (
            <i className="fe fe-check-square text-warning "></i>
          ) : (
            <i className="fe fe-x-square text-danger"></i>
          )
        );
      }
    },
    {
      targets: 3,
      orderable: true,
      className: "text-center",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.createRoot(td).render(
          <>
            <span className="badge bg-info">{moment(rowData.createdAt).format('L LT')}</span><br />
          </>);
      }
    },
  ];

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.orders'), navigationPath: "/orders" },
        { navigationText: i18next.t('constants:breadCrumps.refunds'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div id="inventoryTable" className="row mb-5">
        <div className="col-lg-12 col-xl-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body horizontal-scrollable">
              <>
                <Formik
                  validateOnBlur={true}
                  enableReinitialize={true}
                  validateOnMount={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    setFieldValue
                  }) => (
                    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values, { resetForm: () => setFieldValue('amazonOrderNumber', '') }); }}>
                      {/* <div className="row row-md row-lg justify-content-center">
                        <div className="col-6 px-0">
                          <div className="form-group">
                            <DrInput
                              value={values.amazonOrderNumber}
                              className="form-control"
                              placeholder={t('dataTable.amazonOrderNumber')}
                              name="amazonOrderNumber"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationText={errors.amazonOrderNumber}
                            />
                          </div>
                        </div>
                        <div className="col-6 px-0 ps-2">
                          <div className="form-group">
                            <DrInput
                              value={values.trackingNumber}
                              className="form-control"
                              placeholder={t('dataTable.trackingNumber')}
                              name="trackingNumber"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationText={errors.trackingNumber}
                            />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                        <div className="col-auto px-0">
                          <button
                            className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.amazonOrderNumber !== '' && values.trackingNumber !== '' ? "" : "disabled"}`}
                            type="submit"
                          >
                            {t('add')}
                          </button>
                        </div>
                      </div> */}
                    </form>
                  )}
                </Formik>
                <DrDataTable
                  data={refunds}
                  columns={columns}
                  columnDefs={columnDefs}
                  doesHaveBorder={false}
                  headerSearch={true}
                  // headerSelectbox={true}
                  minWidth="850px"
                  search={true}
                  order={[[1, 'asc']]}
                  isMultiSelectEnabled={true}
                />
              </>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default RefundsPage;