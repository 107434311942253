
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrOrdersFilter, DrPageFilter, DrPagination, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonDropdownDirection, buttonVariants, modals, pageFilterTypes, siteExtensions } from '../../utils/constants';
import { clearActiveBuyoutCarrierSelectionModal, setActiveBuyoutCarrierSelectionModal, setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrders, saveOrderFlag } from '../../state-management/actions/orders.actions';
import * as moment from 'moment';
import i18next from 'i18next';
import { flaggedCountryOptions, flagOptions, orderStatusOptions } from '../../components/hoc/Constants/dr-constants.component';
import noImage from '../../assets/img/pngs/no-image.png'
import { setHomePageSelections, setOrderPageSelections } from '../../state-management/actions/settings.actions';
import { toastr } from 'react-redux-toastr';
import { getPageFilters } from '../../state-management/actions/page-filters.actions';
import { getShipStationCarriers } from '../../state-management/actions/shipstation.actions';
import { Field, Formik } from 'formik';
import { ExcelExport2 } from '../../excelexport';
import { generatePdfReport } from '../../utils/utils';
// import { ordersMockData as  orderList} from '../../utils/mocks-data';

function OrdersPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('ordersPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const ordersPageFilters = useSelector(state => state.pageFilter?.ordersPageFilters);
    const storeOrderStatusCounts = useSelector(state => state.stores.storeOrderStatusCounts);
    const orderList = useSelector(state => state.orders.orderList);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.ordersPageSelections);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);
    const [orderStatusOptionsWithCounts, setOrderStatusOptionsWithCounts] = useState(orderStatusOptions);
    const [detailedFilterData, setDetailedFilterData] = useState(null);
    const { searchkey } = useParams();
    const [selectedFilterId, setSelectedFilterId] = useState(null);
    // document.querySelector('.dropdown-menu').addEventListener('click', function (event) {
    //     console.log(event)
    //     event.stopPropagation();
    //     event.preventDefault();
    // });

    useEffect(() => {
        if (!(pageSelections?.dateInterval) || !(pageSelections?.orderStatus)) {
            console.log("dsadasdas")
            dispatch(withActionPromise(setOrderPageSelections({ orderStatus: 0, dateInterval: [], tableSkip: 0, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
        }
        if (!(shipstationCarriers && shipstationCarriers?.length > 0)) {
            dispatch(withActionPromise(getShipStationCarriers()));
        }

        if (searchkey) {
            let searchModel = {
                keywords: searchkey
            }

            setDetailedFilterData(searchModel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        var newOptions = orderStatusOptions?.map(x => ({ value: x.value, label: x.label + "(" + (storeOrderStatusCounts?.find(y => y.status === x.value)?.orderCount || 0) + ")", isCritical: x.isCritical }))

        setOrderStatusOptionsWithCounts(newOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeOrderStatusCounts, orderStatusOptions]);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {

            dispatch(withActionPromise(getPageFilters(
                {
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    pageNumber: pageFilterTypes.orders,
                    pageName: Object.keys(pageFilterTypes)[Object.values(pageFilterTypes).indexOf(1)]
                })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId]);

    useEffect(() => {

        if (selectedStore) {
            let localDate = new Date();
            let compareDateStart = pageSelections?.dateInterval?.length > 0 ? (pageSelections.dateInterval[0] && moment.utc(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
            let compareDateEnd = pageSelections?.dateInterval?.length > 1 ? (pageSelections.dateInterval[1] && moment.utc(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;

            let requestData = {
                customerMainSellerId: selectedStore?.customerMainSellerId,
                orderStatus: pageSelections?.orderStatus,
                startOrderDate: compareDateStart,
                endOrderDate: compareDateEnd,

                filterData: !detailedFilterData?.keywords ? detailedFilterData : null,

                skip: pageSelections?.tableSkip,
                pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : orderList?.totalRecordCount,
                keywords: detailedFilterData?.keywords,
                orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "purchaseDate desc",
            }

            dispatch(getOrders(requestData));

            // if (detailedFilterData) {
            //     dispatch(getOrders(detailedFilterData));
            // }
            // else {
            //     dispatch(getOrders({
            //         customerMainSellerId: selectedStore?.customerMainSellerId,
            //         orderStatus: pageSelections?.orderStatus,
            //         startOrderDate: compareDateStart,
            //         endOrderDate: compareDateEnd
            //     }));
            // }
            // if (detailedFilterData) {
            //     detailedFilterData.isApplied = true;
            //     setDetailedFilterData(detailedFilterData);
            // }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, pageSelections?.orderStatus, pageSelections?.dateInterval, detailedFilterData, pageSelections?.tableSkip, pageSelections?.pagelength]);

    const columns = [
        { data: "orderFlag", title: "", width: "3%", minWidth: "10px", searchable: false, checkall: true, checkData: "id", searchDropdownSource: { flagOptions } }, //image
        { data: "orderNumber", name: "orderNumber", title: t('dataTable.orderNo'), width: "20%", minWidth: "130px", searchable: true },
        { data: "buyerSellerOrderNumber", title: t('dataTable.amazonOrderNumber'), width: "15%", minWidth: "120px", searchable: true },
        // { data: "shipmentStatus", title: t('dataTable.status'), width: "5%", minWidth: "70px", searchable: true },
        { data: "ordertotalAmount", title: t('dataTable.price'), width: "5%", minWidth: "55px", searchable: true },
        { data: "profitPrice", title: t('dataTable.profit'), width: "5%", minWidth: "20px", searchable: true },
        // { data: null, title: "Kar%", width: "10%", searchable: true },
        { data: "orderStatus", name: "orderStatus", title: t('dataTable.orderStatus'), width: "20%", searchable: true, visible: false },
        { data: "purchaseDate", title: t('dataTable.date'), width: "20%", minWidth: "80px", searchable: true },
        { data: null, title: "", minWidth: "115px", maxWidth: "115px", searchable: false },
        { data: null, title: "", width: "3%", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-start position-relative",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox position-absolute form-check-input form-checked-info " name="name" value={rowData.id} style={{ top: '1px', left: '10px' }}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                        <div className="card-aside-img cursor-pointer mx-auto container mt-2"
                            onClick={(e) => {
                                e.preventDefault();
                                if (rowData.products?.length > 0) {
                                    dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: rowData.products?.map(x => x.imageUrl) || [noImage] }))
                                }
                            }} >
                            <div className="row justify-content-start">
                                {rowData.products?.length > 0 && rowData.products.slice(0, (rowData.products.length > 1 ? 2 : rowData.products.length)).map((product, index) => {

                                    return (
                                        <div key={"product-pic-" + index} className={`px-0 rounded-0 text-center ${rowData.products.length > 1 ? "col-6 " : "col-12 "}`} >
                                            <img src={product.imageUrl || noImage} className="img-thumbnail" alt="img" data-tag="pic-modal-opener"
                                            ></img>
                                        </div>
                                    )
                                })}

                            </div>
                            {rowData.products?.length > 2 &&
                                <div className="row justify-content-start">
                                    {rowData.products.slice(2, (rowData.products.length > 3 ? 4 : rowData.products.length)).map((product, index) => {

                                        return (
                                            <div key={"product-pic-" + (index + 2)} className="col-6 px-0 rounded-0 text-center">
                                                <img src={product.imageUrl || noImage} alt="img" className="img-thumbnail" data-tag="pic-modal-opener"
                                                ></img>
                                            </div>
                                        )
                                    })}

                                </div>
                            }
                            {rowData.products.length > 1 && <span className="badge bg-danger tx-bold" style={{ position: 'absolute', top: '-8px', right: '-8px' }}>{rowData.products.length}</span>}
                            {flagOptions.find(x => x.value === rowData.orderFlag)?.icon &&

                                <span style={{ position: 'absolute', bottom: '-8px', right: '-8px' }}>{flagOptions.find(x => x.value === rowData.orderFlag)?.icon}</span>

                            }
                        </div>
                        {/* <div className="row justify-content-center p-0 m-0 mt-1">
                            <div className="col-auto px-0">
                                <button className="btn btn-primary btn-icon btn-sm btn-wave waves-effect waves-light shadow"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/order-detail/' + rowData.id);
                                    }}
                                >
                                    <i className="fe fe-info" style={{ width: "12px" }}></i>
                                </button>
                            </div>
                           
                        </div> */}
                        {rowData.products[0].quantityOrdered > 0 &&
                            <div className="position-absolute bottom-0 end-0 d-flex align-middle m-auto no-select badge rounded-circle bg-danger bg-opacity-50 shadow transform-top-hover text-default text-center"
                                style={{ width: '24px', height: '24px' }}>
                                <p className="fs-11 m-auto">{rowData.products?.map(x => x.quantityOrdered).reduce((a, curr) => a + (curr.quantityOrdered || 0))}</p>
                            </div>}


                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {

                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        // navigate('/order-detail/' + rowData.id);
                                        window.open(window.location.origin + "/#/order-detail/" + rowData.id, '_blank');
                                    }}
                                >
                                    {rowData.orderNumber}
                                </button>
                            </div>
                            <div className="col-auto px-0 my-auto cursor-pointer">
                                {/* <button className="btn btn-icon btn-outline-light rounded-pill btn-sm btn-wave waves-effect waves-light"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber)
                                }}
                            >
                                <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-primary cursor-pointer"></i>
                            </button> */}
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    navigator.clipboard.writeText(rowData.orderNumber);
                                    toastr.info("Info", rowData.orderNumber + " is copied to clipboard", { timeOut: 1000 })
                                }}>
                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                </span>

                            </div>

                        </div >
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <span> {rowData.orderStatus}</span>
                            </div>
                        </div>
                    </>

                )
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            width: "20%",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.buyerSellerOrderNumber &&
                            <div className="row justify-content-center">

                                <div className="col-auto px-0">
                                    <span className="mb-0 mt-1 text-info text-underline no-select cursor-pointer fs-12 px-1 py-1"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(rowData.buyerSellerOrderNumber);
                                            toastr.info("Info", rowData.buyerSellerOrderNumber + " is copied to clipboard", { timeOut: 1000 })
                                        }}
                                    >
                                        {rowData.buyerSellerOrderNumber}
                                    </span>
                                </div>
                                <div className="col-auto px-0 my-auto cursor-pointer">
                                    <span onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(rowData.buyerSellerOrderNumber);
                                        toastr.info("Info", rowData.buyerSellerOrderNumber + " is copied to clipboard", { timeOut: 1000 })
                                    }}>
                                        <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                    </span>

                                </div>

                            </div >
                        }
                        <div className="row justify-content-center">
                            <div className="col-auto px-0">
                                <span className="badge bg-secondary"> {rowData.buyerOrderStatus}</span>
                            </div>
                        </div>
                    </>)
            }
        },
        // {
        //     targets: 2,
        //     orderable: true,
        //     className: "text-center",
        //     createdCell: (td, cellData, rowData) => {
        //         ReactDOM.createRoot(td).render(
        //             <>
        //                 <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.shipmentStatus}</span><br />
        //             </>);
        //     }
        // },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {/* <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.orderTotal?.currencyCode} {rowData.orderTotal?.amount}</span><br /> */}
                        <span className="badge bg-info">
                            {/* {rowData.orderTotal?.currencyCode} {rowData.orderTotal?.amount} */}
                            {selectedStoreCurrencyType.currencyCode} {rowData.orderItemPriceAmount}
                        </span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div className="d-flex flex-wrap">
                        <div className="row justify-content-start w-90" >
                            <div className="col-12 px-0">
                                <span className={`text-end badge rounded-pill  fs-11 ms-2 ${(rowData.orderStatusId === 6) ? "text-line-through bg-danger" : "bg-success"}`} >Profit: {selectedStoreCurrencyType.currencyCode} {rowData.orderStatusId !== 6 ? Number(rowData.profitPrice || 0.00).toFixed(2) : Number(0).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="row justify-content-start w-90 mt-1" >
                            <div className="col-12 px-0">
                                <span className={`text-end badge rounded-pill fs-11 ms-2 ${(rowData.orderStatusId === 6) ? "text-line-through bg-danger" : "bg-success"}`}>Profit P.: {rowData.orderStatusId !== 6 ? Number(rowData.profitPercent || 0.00).toFixed(2) : Number(0).toFixed(2)}%</span>
                            </div>
                        </div>
                    </div>
                );
            }
        },
        // {
        //     targets: 5,
        //     orderable: true,
        //     className: "text-center",
        //     createdCell: (td, cellData, rowData) => {
        //         ReactDOM.createRoot(td).render(
        //             <>
        //                 <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.earning2}</span><br />
        //             </>);
        //     }
        // },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {/* {orderStatus.find(x => x.value === rowData.orderStatus)?.label} */}
                            {rowData.orderStatus}
                        </span><br />
                    </>);
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {/* <span style={{ marginBottom: '85px' }}>{moment.utc(rowData.purchaseDate).format('L LT')}</span> */}
                        <span className="fs-12 my-auto mx-0 p-0">{moment.utc(rowData.purchaseDate).fromNow()}</span>
                    </>
                );
            }
        },
        {
            targets: 7,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(

                    <>
                        <div className="row justify-content-start my-1">
                            {rowData.isFastShipping &&
                                <div className="col-auto">
                                    <i className="bx bxs-rocket text-warning fs-18 cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip" title={"Fast Shipping"}></i>
                                </div>
                            }
                        </div>
                        {rowData.products?.length > 0 && <div className="row justify-content-center">
                            <div className="col">
                                <button className={`cursor-pointer btn btn-block ripple btn-sm btn-teal ${rowData.isOrderable === false ? "disabled" : ""}`}
                                    onClick={(e) => {

                                        e.preventDefault();
                                        if (rowData.isOrderable) {
                                            dispatch(setActiveBuyoutCarrierSelectionModal({
                                                name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: rowData.id,
                                                carrierSelectionCallback: (response) => {
                                                    dispatch(clearActiveBuyoutCarrierSelectionModal());

                                                    if (selectedStore && pageSelections?.orderStatus) {

                                                        if (pageSelections?.dateInterval && pageSelections?.dateInterval.length > 0 && (pageSelections.dateInterval[0] || pageSelections.dateInterval[1])) {

                                                            let localDate = new Date();
                                                            let compareDateStart = (pageSelections.dateInterval[0] && moment.utc(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
                                                            let compareDateEnd = (pageSelections.dateInterval[1] && moment.utc(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

                                                            let requestData = {
                                                                customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                orderStatus: pageSelections?.orderStatus,
                                                                startOrderDate: compareDateStart,
                                                                endOrderDate: compareDateEnd,

                                                                filterData: !detailedFilterData?.keywords ? detailedFilterData : null,

                                                                skip: pageSelections?.tableSkip,
                                                                pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : orderList?.totalRecordCount,
                                                                keywords: detailedFilterData?.keywords,
                                                                orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "purchaseDate desc",
                                                            }
                                                            dispatch(getOrders(requestData));
                                                        }
                                                    }
                                                }
                                            }));
                                        }

                                    }}
                                >

                                    {(rowData.isOrderable || rowData.buyerOrderStatusId === 13) ? t('dataTable.placeOrder') : t('dataTable.orderCompleted')}
                                </button>
                            </div>
                        </div>
                        }
                    </>
                );
            }
        },
        {
            targets: 8,
            orderable: false,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <span id="droprightMenuButton" className="text-muted" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                            aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical fs-18 cursor-pointer"></i></span>
                        <div className="dropdown-menu dropdown-menu-start" style={{ width: 'auto' }}>

                            <span className="dropdown-item d-flex align-items-center cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/order-detail/' + rowData.id);
                                }}>
                                {t('dataTable.seeDetails')}
                            </span>
                            <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                href={"https://sellercentral.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/orders-v3/order/" + rowData.orderNumber}>
                                {t('dataTable.seeAtAmazon')}
                            </a>
                            {rowData.products?.length > 0 &&
                                // <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                //     href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === (rowData.products[0].sourceMarketCountryCode || (selectedStore?.countryCode || "US")))?.extension + "/gp/aws/cart/add.html?AmazonOrderId=" + rowData.orderNumber + "&sendType=1&ASIN.1=" + rowData.products[0].asin + "&Quantity.1=" + rowData.products[0].quantityOrdered}>
                                //     {t('dataTable.placeOrder')}
                                // </a>
                                <>
                                    <span className={`dropdown-item d-flex align-items-center ${!rowData.isOrderable ? "text-muted" : "cursor-pointer"}`}
                                        onClick={(e) => {

                                            e.preventDefault();
                                            if (rowData.isOrderable) {
                                                dispatch(setActiveBuyoutCarrierSelectionModal({
                                                    name: modals.buyoutCarrierSelection, isGlobal: true, title: "", source: rowData.id,
                                                    carrierSelectionCallback: (response) => {
                                                        dispatch(clearActiveBuyoutCarrierSelectionModal());
                                                    }
                                                }));
                                            }

                                        }}
                                    >
                                        {rowData.isOrderable ? t('dataTable.placeOrder') : t('dataTable.orderCompleted')}
                                    </span>
                                    <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === selectedStore.countryCode)?.extension + "/dp/" + rowData.products[0].asin}>

                                        {t("dataTable.seeAtSalesMarket", { salesMarket: flaggedCountryOptions?.find(x => x.value === selectedStore?.countryCode)?.label })}
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center cursor-pointer" target="_blank" rel="noreferrer"
                                        href={"https://www.amazon." + siteExtensions.find(x => x.countryCode === rowData.products[0].sourceMarketCountryCode)?.extension + "/dp/" + rowData.products[0].asin}>
                                        {t("dataTable.seeAtSourceMarket", { sourceMarket: flaggedCountryOptions?.find(x => x.value === rowData.products[0].sourceMarketCountryCode)?.label })}
                                    </a>
                                </>

                            }
                            <div class="dropdown-divider"></div>
                            <div className="row px-3 my-1">
                                <div className="col-auto my-auto">
                                    <span className="fs-13 fw-bold" >{t('dataTable.selectFlag')}</span>
                                </div>
                                <div className="col-auto">
                                    <Formik
                                        initialValues={
                                            {
                                                selectedFlag: rowData?.orderFlag || null
                                            }
                                        }
                                        validateOnBlur={true}
                                        enableReinitialize={false}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            setTouched,
                                            setFieldValue
                                        }) => (
                                            <Field
                                                onBlur={() => { setTouched({ ...touched.selectedFlag, 'selectedFlag': true }) }}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    if (e) {
                                                        if (e.value !== values.selectedFlag) {

                                                            let localDate = new Date();
                                                            let compareDateStart = pageSelections?.dateInterval?.length > 0 ? (pageSelections.dateInterval[0] && moment.utc(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD") : null;
                                                            let compareDateEnd = pageSelections?.dateInterval?.length > 1 ? (pageSelections.dateInterval[1] && moment.utc(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD") : null;


                                                            dispatch(saveOrderFlag({
                                                                orderFlagRequest: {
                                                                    orderIdList: [
                                                                        rowData.id
                                                                    ],
                                                                    orderFlag: e.value === 0 ? null : e.value
                                                                },
                                                                orderListRequestData: {
                                                                    customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                    orderStatus: pageSelections?.orderStatus,
                                                                    startOrderDate: compareDateStart,
                                                                    endOrderDate: compareDateEnd,

                                                                    filterData: !detailedFilterData?.keywords ? detailedFilterData : null,

                                                                    skip: pageSelections?.tableSkip,
                                                                    pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : orderList?.totalRecordCount,
                                                                    keywords: detailedFilterData?.keywords,
                                                                    orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "purchaseDate desc",
                                                                }

                                                            }));
                                                        }
                                                    }
                                                }}
                                                // selectedValue={values.selectedFlag || 0}
                                                className="text-start"
                                                name={'selectedFlag'}
                                                component={DrSelectBox}
                                                placeholder={t('flags')}
                                                options={flagOptions?.map(x => ({ value: x.value, label: x.icon }))}
                                            />
                                        )
                                        }
                                    </Formik>
                                </div>




                            </div>


                        </div>
                    </div >)
            }
        }
    ];

    const orderPageOptionClickCallBack = (value) => {
        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: value, dateInterval: pageSelections?.dateInterval, tableSkip: 0, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
    };
    const timeIntervalOptionCallBack = (dateValues) => {

        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: pageSelections?.orderStatus, dateInterval: dateValues, tableSkip: 0, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
        dispatch(withActionPromise(setHomePageSelections({ dateInterval: dateValues })));
    };

    const refreshClickedCallBack = (value) => {
        setDetailedFilterData(null);
        navigate("/orders")
        if (selectedStore && pageSelections?.orderStatus) {

            if (pageSelections?.dateInterval && pageSelections?.dateInterval.length > 0 && (pageSelections.dateInterval[0] || pageSelections.dateInterval[1])) {

                let localDate = new Date();
                let compareDateStart = (pageSelections.dateInterval[0] && moment.utc(pageSelections.dateInterval[0]).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
                let compareDateEnd = (pageSelections.dateInterval[1] && moment.utc(pageSelections.dateInterval[1]).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

                let requestData = {
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    orderStatus: pageSelections?.orderStatus,
                    startOrderDate: compareDateStart,
                    endOrderDate: compareDateEnd,

                    filterData: !detailedFilterData?.keywords ? detailedFilterData : null,

                    skip: pageSelections?.tableSkip,
                    pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : orderList?.totalRecordCount,
                    keywords: detailedFilterData?.keywords,
                    orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "purchaseDate desc",
                }
                dispatch(getOrders(requestData));
            }
        }
    };

    const detailedSearchClick = (e, searchModel) => {
        let localDate = new Date();
        let compareDateStart = (searchModel?.startOrderDate && moment.utc(searchModel?.startOrderDate).startOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).subtract(1, 'years').startOf('day').format("YYYY-MM-DD");
        let compareDateEnd = (searchModel?.endOrderDate && moment.utc(searchModel?.endOrderDate).endOf('day').format("YYYY-MM-DD")) || moment.utc(localDate).endOf('day').format("YYYY-MM-DD");

        dispatch(withActionPromise(setOrderPageSelections({ orderStatus: searchModel?.orderStatus || pageSelections?.orderStatus, dateInterval: [compareDateStart, compareDateEnd] || pageSelections?.dateInterval, tableSkip: 0, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));

        if (searchModel) {
            let searchData = Object.assign({}, searchModel);

            if (searchData) {
                searchData.orderFlag = searchModel?.orderFlag?.map(a => a.value) || null
            }

            setDetailedFilterData(searchData);
        }
        else {
            setDetailedFilterData(searchModel);
        }
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.orders'), navigationPath: "" }]}>
            </DrBreadCrump>

            <div className="row justify-content-center justify-content-sm-end justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end mb-2">

                <div className="col-auto my-1 ps-0">
                    <DrButtonDropdown className="ms-2 shadow" options={orderStatusOptionsWithCounts}
                        selectedValue={pageSelections?.orderStatus}
                        optionClickCallBack={orderPageOptionClickCallBack}
                        direction={buttonDropdownDirection.horizontal}
                        variant={buttonVariants.light}
                        selectedVariant={buttonVariants.teal}
                    >
                    </DrButtonDropdown>
                </div>
            </div>
            {detailedFilterData &&
                <div className="row">
                    <div className="col-12">
                        <div className="card rounded-10 mb-2">
                            <div className="card-body rounded p-2 bg-teal">
                                <div className="row justify-content-between">
                                    <div className="col-auto my-auto">
                                        <label className="text-white my-auto">
                                            Detaylı Arama Uygulanmıştır!
                                        </label>
                                    </div>
                                    <div className="col-auto my-auto">
                                        <button className="btn btn-danger btn-wave waves-effect waves-light shadow btn-sm rounded-pill"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setDetailedFilterData(null);
                                                // navigate("/orders")
                                            }}
                                        >
                                            <i className="fe fe-trash-2 me-2"></i>
                                            Filtreyi Kaldır</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div id="orderTable" className="row mb-5">
                <div className="col-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {orderList.data && <>
                                <DrDataTable
                                    data={orderList.data}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    //headerSelectbox={true}
                                    search={true}
                                    minWidth="850px"
                                    order={pageSelections?.sortingInfo?.length > 0 ? [[parseInt(pageSelections?.sortingInfo[0]), pageSelections?.sortingInfo[2]]] : [[6, 'desc']]}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            searchPlaceholder="ASIN,Order Number..."
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            isSearchSavingActive={true}
                                            isSearchActive={true}
                                            timeIntervalOptionCallBack={timeIntervalOptionCallBack}
                                            selectedDates={pageSelections?.dateInterval}

                                            filterType={pageFilterTypes.orders}

                                            savedFilters={ordersPageFilters}
                                            searchDetail={<DrOrdersFilter detailedFilterData={detailedFilterData} selectedFilterId={selectedFilterId} detailedSearchClickCallBack={detailedSearchClick} />}
                                            searchKeyUpCallBack={(e) => {
                                                if (e?.length > 0) {
                                                    let searchModel = {
                                                        keywords: e
                                                    }
                                                    setDetailedFilterData(searchModel);
                                                }
                                                else {
                                                    setDetailedFilterData(null);
                                                }
                                            }}
                                        // selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                        >
                                        </DrPageFilter>
                                    }
                                    isMultiSelectEnabled={true}
                                    pageLength={pageSelections?.pagelength || 20}

                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (pageSelections?.tableSkip || 0),
                                                    totalRecordCount: orderList?.totalRecordCount || pageSelections?.tableLength,
                                                    tablePageLength: pageSelections?.pagelength || 20
                                                }

                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(withActionPromise(setOrderPageSelections({ orderStatus: pageSelections?.orderStatus, dateInterval: pageSelections?.dateInterval, tableSkip: 0, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: e, sortingInfo: pageSelections?.sortingInfo })));
                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(withActionPromise(setOrderPageSelections({ orderStatus: pageSelections?.orderStatus, dateInterval: pageSelections?.dateInterval, tableSkip: e, tableLength: (orderList?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                                            }}
                                        >
                                        </DrPagination>

                                    }
                                />
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* </div>
            </div> */}
        </>
    )
}
export default OrdersPage