import { useDispatch, useSelector } from "react-redux";
import "./dr-create-label.component.scss";
import { useTranslation } from "react-i18next";
import { clearActiveCreateLabelModal, withActionPromise } from "../../../../state-management/actions/app.actions";
import { useEffect, useState } from "react";
import { DrInput, DrSelectBox } from "../../../component-index";
import { Field, Formik } from "formik";
import { clearShipStationData, getOrderShipStationData, getShipStationCarrierPackages, getShipStationCarrierPackagesSuccess, getShipStationCarrierServices, getShipStationCarrierServicesSuccess, saveOrderShipStationData } from "../../../../state-management/actions/shipstation.actions";
import { CONTROLLERS, apiGet } from "../../../../utils/axiosApiCalls";
import { getOrderItem } from "../../../../state-management/actions/orders.actions";
import moment from "moment";
import $ from 'jquery';
// import { createLabelOrderDetailMockData as selectedOrderDetail } from "../../../../utils/mocks-data";

function DrCreateLabel({ display, source, persistModal = false }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drCreateLabel');
    const selectedOrderShipstationData = useSelector(state => state.shipstation.selectedOrderShipstationData);
    const shipstationCarriers = useSelector(state => state.shipstation.shipstationCarriers);
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);
    const shipstationCarrierPackages = useSelector(state => state.shipstation.shipstationCarrierPackages);
    const selectedOrderDetail = useSelector(state => state.orders.selectedOrder);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const [carrierPackageOptions, setCarrierPackageOptions] = useState([]);
    const [advancedDDOptions, setAdvancedDDOptions] = useState([]);

    const [shipFromWarehouse, setShipFromWarehouse] = useState(null);

    const [shipstationData, setShipstationData] = useState(null);
    const [isCarrierServiceAlreadySelected, setIsCarrierServiceAlreadySelected] = useState(false);

    useEffect(() => {
        if (source) {
            dispatch(withActionPromise(getOrderShipStationData(source)));
            dispatch(withActionPromise(getOrderItem({ orderId: source })));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    useEffect(() => {

        if (allWarehouses && selectedOrderDetail && allWarehouses.length > 0 && selectedOrderDetail?.wareHouseId) {
            var shipfromWR = allWarehouses.find(x => x.id === selectedOrderDetail?.wareHouseId);
            setShipFromWarehouse(shipfromWR);

        }
        else {
            setShipFromWarehouse(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, selectedOrderDetail]);

    useEffect(() => {
        setAdvancedDDOptions([
            { value: "nonMachinable", label: "Non-Machinable" },
            { value: "saturdayDelivery", label: "Saturday Delivery" },
            { value: "containsAlcohol", label: "Contains Alcohol" },
            { value: "external", label: "External" }
        ])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedOrderShipstationData && selectedOrderShipstationData.length > 0) {
            setShipstationData(selectedOrderShipstationData[0]);
            // setIsCarrierServiceAlreadySelected(selectedOrderShipstationData[0].carrierCode && selectedOrderShipstationData[0].serviceCode);
            if (selectedOrderShipstationData[0].carrierCode) {
                callCarrierService(selectedOrderShipstationData[0].carrierCode);
                callCarrierPackage(selectedOrderShipstationData[0].carrierCode);
            }
        }
        else {
            setShipstationData(null);
            // setIsCarrierServiceAlreadySelected(selectedOrderDetail?.carrierCode && selectedOrderDetail?.carrierServiceCode);
            if (selectedOrderDetail?.carrierCode) {
                callCarrierService(selectedOrderDetail?.carrierCode);
                callCarrierPackage(selectedOrderDetail?.carrierCode);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrderShipstationData, selectedOrderDetail]);

    const initialValues = {
        shipmentId: shipstationData?.shipmentId || 0,
        orderId: (source || shipstationData?.orderId) || "",
        carrierCode: shipstationData?.carrierCode || selectedOrderDetail?.carrierCode || "",
        carrierName: shipstationCarriers?.find(x => x.code === (shipstationData?.carrierCode || selectedOrderDetail?.carrierCode))?.name || "",
        serviceCode: shipstationData?.serviceCode || selectedOrderDetail?.carrierServiceCode || "",
        serviceName: shipstationCarrierServices?.find(x => x.carrierCode === (shipstationData?.carrierCode || selectedOrderDetail?.carrierCode))?.data.find(x => x.code === (shipstationData?.serviceCode || selectedOrderDetail?.carrierServiceCode))?.name || "",
        packageCode: shipstationData?.packageCode || "",
        confirmation: (shipstationData?.confirmation && shipstationData?.confirmation.length > 0 ? shipstationData?.confirmation : "online"),
        // shipDate: (shipstationData?.shipDate || (selectedOrderDetail?.latestShipDate && moment.utc(selectedOrderDetail?.latestShipDate).format('YYYY-MM-DD'))) || "",
        shipDate: moment.utc().format('YYYY-MM-DD'),
        weight: {
            value: (shipstationData?.weight.value || (selectedOrderDetail?.orderDetailList?.filter(x => !x.isSelected).length > 0 ? Number(selectedOrderDetail?.orderDetailList.filter(x => !x.isSelected).reduce((a, curr) => a + (curr.packageWeightValue || 0) * ((curr.quantityOrdered > 1 ? (curr.quantityOrdered - curr.quantityShipped) : 1) || 0), 0)).toFixed(2) : 0)) || 0,
            units: selectedOrderDetail?.orderDetailList?.[0].packageWeightUnit || "ounces"
        },
        dimensions: {
            length: (shipstationData?.dimensions.length || (selectedOrderDetail?.orderDetailList?.filter(x => !x.isSelected).length > 0 ? Number(selectedOrderDetail?.orderDetailList.filter(x => !x.isSelected).reduce((a, curr) => a + (curr.packageLengthValue || 0) * ((curr.quantityOrdered > 1 ? (curr.quantityOrdered - curr.quantityShipped) : 1) || 0), 0)).toFixed(2) : 0)) || 0,
            width: (shipstationData?.dimensions.width || (selectedOrderDetail?.orderDetailList?.filter(x => !x.isSelected).length > 0 ? Number(selectedOrderDetail?.orderDetailList.filter(x => !x.isSelected).reduce((a, curr) => a + (curr.packageWidthValue || 0) * ((curr.quantityOrdered > 1 ? (curr.quantityOrdered - curr.quantityShipped) : 1) || 0), 0)).toFixed(2) : 0)) || 0,
            height: (shipstationData?.dimensions.height || (selectedOrderDetail?.orderDetailList?.filter(x => !x.isSelected).length > 0 ? Number(selectedOrderDetail?.orderDetailList.filter(x => !x.isSelected).reduce((a, curr) => a + (curr.packageHeightValue || 0) * ((curr.quantityOrdered > 1 ? (curr.quantityOrdered - curr.quantityShipped) : 1) || 0), 0)).toFixed(2) : 0)) || 0,
            units: shipstationData?.dimensions.units || "inches"
        },
        shipFrom: {
            name: (shipstationData?.shipFrom?.name || shipFromWarehouse?.wareHouseName) || "",
            company: shipstationData?.shipFrom?.company || "ShipStation",
            street1: (shipstationData?.shipFrom?.street1 || shipFromWarehouse?.addressLine1) || "",
            street2: (shipstationData?.shipFrom?.street2 || shipFromWarehouse?.addressLine2) || "",
            street3: (shipstationData?.shipFrom?.street3 || shipFromWarehouse?.addressLine3) || "",
            city: (shipstationData?.shipFrom?.city || shipFromWarehouse?.city) || "",
            state: (shipstationData?.shipFrom?.state || shipFromWarehouse?.district) || "",
            postalCode: (shipstationData?.shipFrom?.postalCode || shipFromWarehouse?.postalCode) || "",
            country: (shipstationData?.shipFrom?.country || shipFromWarehouse?.stateOrRegion) || "US",
            phone: (shipstationData?.shipFrom?.phone || shipFromWarehouse?.phone) || "",
            residential: shipstationData?.shipFrom?.residential || true
        },
        shipTo: {
            name: (shipstationData?.shipTo?.name || selectedOrderDetail?.shippingAddress?.name) || "",
            company: shipstationData?.shipTo?.company || "",
            street1: (shipstationData?.shipTo?.street1 || selectedOrderDetail?.shippingAddress?.addressLine1) || "",
            street2: (shipstationData?.shipTo?.street2 || selectedOrderDetail?.shippingAddress?.addressLine2) || "",
            street3: (shipstationData?.shipTo?.street3 || selectedOrderDetail?.shippingAddress?.addressLine3) || "",
            city: (shipstationData?.shipTo?.city || selectedOrderDetail?.shippingAddress?.city) || "",
            state: (shipstationData?.shipTo?.state || selectedOrderDetail?.shippingAddress?.stateOrRegion) || "",
            postalCode: (shipstationData?.shipTo?.postalCode || selectedOrderDetail?.shippingAddress?.postalCode) || "",
            country: (shipstationData?.shipTo?.country || selectedOrderDetail?.shippingAddress?.countryCode) || "",
            phone: (shipstationData?.shipTo?.phone || selectedOrderDetail?.shippingAddress?.phone) || "",
            residential: shipstationData?.shipTo?.residential || true
        },
        insuranceOptions: shipstationData?.insuranceOptions || null,
        // internationalOptions: shipstationData?.internationalOptions || null,
        // advancedOptions: shipstationData?.advancedOptions && shipstationData?.advancedOptions.length > 0 ? advancedDDOptions.filter(function (o) {
        //     return shipstationData?.advancedOptions.split(',').find(r => r === o.value);
        // }) : [],
        advancedOptions: [],
        // insuranceOptions: [{
        //     provider: (shipstationData?.insuranceOptions && shipstationData?.insuranceOptions?.length > 0 && shipstationData?.insuranceOptions[0].provider) || "none",
        //     insureShipment: (shipstationData?.insuranceOptions && shipstationData?.insuranceOptions?.length > 0 && shipstationData?.insuranceOptions[0].insureShipment) || true,
        //     insuredValue: (shipstationData?.insuranceOptions && shipstationData?.insuranceOptions?.length > 0 && shipstationData?.insuranceOptions[0].insuredValue) || 0,
        // }],
        internationalOptions: {
            contents: shipstationData?.internationalOptions && shipstationData?.internationalOptions.contents?.length > 0 ? shipstationData?.internationalOptions.contents : "merchandise",
            customsItems: shipstationData?.internationalOptions?.customsItems?.map(x => ({ ...x, orderItemId: null }))

                || selectedOrderDetail?.orderDetailList?.map(x => (
                    {
                        quantityToShip: (x.quantityOrdered > 1 ? (x.quantityOrdered - x.quantityShipped) : 1),
                        isSelected: x.isSelected,
                        checked: x.isSelected ? false : true,
                        orderItemId: x.orderItemId,
                        description: (x.title?.substring(0, 55) + "..."),
                        quantity: x.quantityOrdered,
                        value: x.itemPriceAmount,
                        countryOfOrigin: (shipstationData?.shipFrom?.country || shipFromWarehouse?.stateOrRegion || "US"),
                        harmonizedTariffCode: ""
                    }))
                || [
                    {
                        orderItemId: null,
                        description: "",
                        quantity: 0,
                        value: 0,
                        harmonizedTariffCode: "",
                        countryOfOrigin: (shipstationData?.shipFrom?.country || shipFromWarehouse?.stateOrRegion || "US")
                    }
                ],
            nonDelivery: (shipstationData?.internationalOptions && shipstationData?.internationalOptions?.length > 0 && shipstationData?.internationalOptions[0].nonDelivery) || "return_to_sender"
        },

        // advancedOptions: [{
        //     warehouseId: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].warehouseId) || 0,
        //     nonMachinable: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].nonMachinable) || false,
        //     saturdayDelivery: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].saturdayDelivery) || false,
        //     containsAlcohol: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].containsAlcohol) || false,
        //     storeId: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].storeId) || 0,
        //     customField1: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].customField1) || "",
        //     customField2: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].customField2) || "",
        //     customField3: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].customField3) || "",
        //     source: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].source) || "",
        //     mergedOrSplit: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].mergedOrSplit) || false,
        //     mergedIds: [0],
        //     parentId: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].parentId) || 0,
        //     billToParty: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].billToParty) || "",
        //     billToAccount: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].billToAccount) || "",
        //     billToPostalCode: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].billToPostalCode) || "",
        //     billToCountryCode: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].billToCountryCode) || "",
        //     billToMyOtherAccount: (shipstationData?.advancedOptions && shipstationData?.advancedOptions?.length > 0 && shipstationData?.advancedOptions[0].billToMyOtherAccount) || ""
        // }],
        orderItem: selectedOrderDetail?.orderDetailList?.length > 0 ? selectedOrderDetail?.orderDetailList.map(x => (
            {
                asin: x.asin,
                itemName: (x.title?.substring(0, 55) + "..."),
                quantity: x.quantityOrdered,
                price: x.itemPriceAmount
            })) :
            [
                {
                    itemName: "test",
                    quantity: 1,
                    price: 1
                }],
        products: selectedOrderDetail?.orderDetailList?.length > 0 ? selectedOrderDetail?.orderDetailList.map(x => (
            {
                orderItemId: x.orderItemId,
                itemName: (x.title?.substring(0, 55) + "..."),
                price: x.itemPriceAmount,
                isSelected: x.isSelected,
                checked: x.isSelected ? false : true,
                imageUrl: x.imageUrl,
                title: x.title,
                asin: x.asin,
                quantityToShip: (x.quantityOrdered > 1 ? (x.quantityOrdered - x.quantityShipped) : 1),
                quantityOrdered: x.quantityOrdered,
                quantityShipped: x.quantityShipped,
                productCategory: x.productCategory,
                sourceMarketCountryCode: x.sourceMarketCountryCode,
                couponValue: x.couponValue,
                itemPriceAmount: x.itemPriceAmount,
                primePriceValue: x.PrimePriceValue,
                primePriceCurrencyCode: x.primePriceCurrencyCode,

                packageWeightUnit: x.packageWeightUnit,
                packageWeightValue: x.packageWeightValue,

                packageHeightUnit: x.packageHeightUnit,
                packageHeightValue: x.packageHeightValue,

                packageWidthUnit: x.packageWidthUnit,
                packageWidthValue: x.packageWidthValue,

                packageLengthUnit: x.packageLengthUnit,
                packageLengthValue: x.packageLengthValue,

            })) : null,

        testLabel: shipstationData?.testLabel || true,

        isManual: shipFromWarehouse?.isManual || false,
        trackingNumber: selectedOrderDetail?.trackingNumber || ""
    }

    const callCarrierService = async (value) => {
        if (!(shipstationCarrierServices?.length > 0 && shipstationCarrierServices?.find(x => x.carrierCode === value))) {
            dispatch(getShipStationCarrierServices(value));
        }
    }

    const callCarrierPackage = async (value) => {
        if (!(shipstationCarrierPackages?.length > 0 && shipstationCarrierPackages?.find(x => x.carrierCode === value))) {
            dispatch(getShipStationCarrierPackages(value));
        }
    }

    const handleSubmit = async (values) => {
        var data = {
            ...values,
            orderItem: Object.assign([], values.products?.filter(x => x.checked && !x.isSelected).map(x => ({ itemName: x.itemName, quantity: x.quantityToShip, price: x.price })))
        };
        data.internationalOptions.customsItems = Object.assign([], values.internationalOptions.customsItems?.filter(x => x.orderItemId === null || values.products?.find(y => y.checked && y.orderItemId === x.orderItemId))).map(x => ({ ...x, quantity: x.quantityToShip }));

        dispatch(saveOrderShipStationData(data));
    }
    return (
        <div className={`modal fade modal-background no-pointer-events ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="createLabelModal" aria-modal="true" aria-hidden="true" data-backdrop="false"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveCreateLabelModal());
                }
            }}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-dialog-full-height" role="document" style={{ position: 'absolute', right: '0px', top: '40px' }}>
                <div className="modal-content modal-content-full-height">
                    <div className="modal-header">
                        <h6 className="modal-title">Order Label</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveCreateLabelModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        {display &&
                            <>
                                <Formik
                                    initialValues={initialValues}
                                    validateOnBlur={true}
                                    // onSubmit={handleSubmit}
                                    // validationSchema={storePreferencesSchema}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                >
                                    {({
                                        isValid,
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        setTouched,
                                        setFieldValue
                                    }) => (
                                        <>

                                            <div className="card custom-card bg-info bg-opacity-25 p-3 m-0 mb-2">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <h5>SHIPPING</h5>
                                                    </div>
                                                </div>

                                                <div className="row m-0 p-0">
                                                    <div className="col-12 px-0">
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Ship From :</label>
                                                            <label className="col col-form-label col-form-label-sm ms-0 ps-0">

                                                                {(values.shipFrom?.street1 || "") + " " + (values.shipFrom?.postalCode || "") + (values.shipFrom?.street1 ? ", " : "") + (values.shipFrom?.city || "") + " " + (values.shipFrom?.state || "") + " " + (values.shipFrom?.phone || "")}

                                                            </label>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Weight :</label>
                                                            <div className="col-2 pe-0 ps-0">
                                                                <DrInput className="form-control form-control-sm" placeholder="" name="weight" type="number" step="0.01"
                                                                    value={values.weight.value || 0}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue('weight', { value: e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0, units: values.weight.units })
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'weight': true }) }}
                                                                    isInvalid={(touched.weight && errors.weight)}
                                                                    isValid={touched.weight && !errors.weight}
                                                                    validationText={errors.weight} />
                                                            </div>
                                                            <div className="col-auto pe-0 ps-0">
                                                                <label className="ms-2 me-2 mt-2">{values.weight.units ? "(" + values.weight.units + ")" : "(lb)"}</label>
                                                            </div>
                                                        </div>
                                                        {!values.isManual &&
                                                            <>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Carrier :</label>
                                                                    {isCarrierServiceAlreadySelected && <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.carrierName}</label>}
                                                                    {!isCarrierServiceAlreadySelected && <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                        <Field
                                                                            onBlur={() => { setTouched({ ...touched.carrierCode, 'carrierCode': true }) }}
                                                                            onChange={(e) => {
                                                                                setFieldValue('carrierCode', e.value);
                                                                                callCarrierService(e.value);
                                                                                callCarrierPackage(e.value);
                                                                            }}
                                                                            isMulti={false}
                                                                            name={'carrierCode'}
                                                                            component={DrSelectBox}
                                                                            placeholder="Select Carrier"
                                                                            options={shipstationCarriers?.map(x => ({ value: x.code, label: x.name }))}

                                                                        />
                                                                    </div>}
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Service :</label>
                                                                    {isCarrierServiceAlreadySelected && <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.serviceName}</label>}
                                                                    {!isCarrierServiceAlreadySelected &&
                                                                        <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                            <Field
                                                                                onBlur={() => { setTouched({ ...touched.serviceCode, 'serviceCode': true }) }}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('serviceCode', e.value);
                                                                                    callCarrierPackage(values.carrierCode);
                                                                                }}
                                                                                isMulti={false}
                                                                                name={'serviceCode'}
                                                                                component={DrSelectBox}
                                                                                placeholder="Select Carrier"
                                                                                options={shipstationCarrierServices?.find(x => x.carrierCode === values.carrierCode)?.data?.map(x => ({ value: x.code, label: x.name })) || []}
                                                                            />
                                                                        </div>
                                                                    }

                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Package :</label>
                                                                    <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                        <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                            <Field
                                                                                onBlur={() => { setTouched({ ...touched.packageCode, 'packageCode': true }) }}
                                                                                onChange={(e) => {
                                                                                    setFieldValue('packageCode', e.value);
                                                                                }}
                                                                                isMulti={false}
                                                                                name={'packageCode'}
                                                                                component={DrSelectBox}
                                                                                placeholder="Select Package"
                                                                                options={shipstationCarrierPackages?.find(x => x.carrierCode === values.carrierCode)?.data?.map(x => ({ value: x.code, label: x.name })) || []}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Size :</label>
                                                            <div className="col-2 pe-0 ps-0">
                                                                <DrInput className="form-control form-control-sm" placeholder="" name="dimensions" type="number" step="0.01"
                                                                    value={values.dimensions.length || 0}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue('dimensions', { length: e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0, width: values.dimensions.width, height: values.dimensions.height, units: values.dimensions.units })
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'dimensions': true }) }}
                                                                    isInvalid={(touched.dimensions && errors.dimensions)}
                                                                    isValid={touched.dimensions && !errors.dimensions}
                                                                    validationText={errors.dimensions} />
                                                            </div>
                                                            <div className="col-auto pe-0 ps-0">
                                                                <label className="ms-2 me-2 mt-2">L</label>
                                                            </div>
                                                            <div className="col-2 pe-0 ps-0">
                                                                <DrInput className="form-control form-control-sm" placeholder="" name="dimensions" type="number" step="0.01"
                                                                    value={values.dimensions.width || 0}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue('dimensions', { width: e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0, length: values.dimensions.length, height: values.dimensions.height, units: values.dimensions.units })
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'dimensions': true }) }}
                                                                    isInvalid={(touched.dimensions && errors.dimensions)}
                                                                    isValid={touched.dimensions && !errors.dimensions}
                                                                    validationText={errors.dimensions} />
                                                            </div>
                                                            <div className="col-auto pe-0 ps-0">
                                                                <label className="ms-2 me-2 mt-2">
                                                                    W
                                                                </label>
                                                            </div>
                                                            <div className="col-2 pe-0 ps-0">
                                                                <DrInput className="form-control form-control-sm" placeholder="" name="dimensions" type="number" step="0.01"
                                                                    value={values.dimensions.height || 0}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue('dimensions', { height: e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0, length: values.dimensions.length, width: values.dimensions.width, units: values.dimensions.units })
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'dimensions': true }) }}
                                                                    isInvalid={(touched.dimensions && errors.dimensions)}
                                                                    isValid={touched.dimensions && !errors.dimensions}
                                                                    validationText={errors.dimensions} />
                                                            </div>
                                                            <div className="col-auto pe-0 ps-0">
                                                                <label className="ms-2 me-2 mt-2">
                                                                    H
                                                                </label>
                                                            </div>
                                                            <div className="col-auto pe-0 ps-0">
                                                                <label className="ms-2 me-2 mt-2">(in)</label>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Confirmation :</label>
                                                            <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.confirmation, 'confirmation': true }) }}
                                                                    name={'confirmation'}
                                                                    component={DrSelectBox}
                                                                    placeholder={'confirmation'}
                                                                    selectedValue={values.confirmation}
                                                                    options={
                                                                        [
                                                                            { value: null, label: "None" },
                                                                            { value: "online", label: "Online" },
                                                                            { value: "delivery", label: "Delivery" },
                                                                            { value: "signature", label: "Signature" },
                                                                            { value: "adultsignature", label: "Adult Signature" },
                                                                            { value: "verbal", label: "Verbal" }
                                                                        ]
                                                                    }

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Insurance :</label>
                                                            <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                <Field
                                                                    name={'insuranceOptions'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Insurance Options"
                                                                    disableInputChange={true}
                                                                    selectedValue={values.insuranceOptions || "none"}
                                                                    options={
                                                                        [
                                                                            { value: "none", label: "None" },
                                                                            { value: "parcelguard", label: "ParcelGuard" },
                                                                            { value: "carrier", label: "Carrier" },
                                                                            { value: "external", label: "External" }
                                                                        ]
                                                                    }
                                                                    onBlur={() => { setTouched({ ...touched.insuranceOptions, 'insuranceOptions': true }) }}
                                                                    onChange={(e) => {

                                                                        if (e.value === "none") {
                                                                            setFieldValue(`insuranceOptions`, null);
                                                                        } else {
                                                                            setFieldValue(`insuranceOptions`, e.value);
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Insurance Amt :</label>
                                                            <div className="col-9 pe-3 ps-0">
                                                                <DrInput className="form-control form-control-sm" placeholder="Insurance Value" name="insuranceOptions" type="number" step="0.01"
                                                                    value={(values.insuranceOptions && values.insuranceOptions.length > 0 ? values.insuranceOptions[0].insuredValue : "") || "0"}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue(`insuranceOptions[0].insuredValue`, e.currentTarget.value);
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'insuranceOptions': true }) }}
                                                                    icon={<i className="fe fe-dollar-sign"></i>}
                                                                    iconPosition="left"
                                                                    disabled={values.insuranceOptions[0].provider === "none"}
                                                                />
                                                            </div>

                                                        </div> */}
                                                    </div>
                                                </div>
                                                {values.isManual &&
                                                    <div className="row">
                                                        <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Tracking Number :</label>
                                                        <div className="col-auto ps-0">
                                                            <DrInput className="form-control form-control-sm pulse pulse-danger" placeholder="" name="trackingNumber" type="text"
                                                                value={values.trackingNumber || ""}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} />
                                                        </div>

                                                    </div>
                                                }
                                                <div className="row justify-content-between mt-3 pb-3">
                                                    <div className="col ms-5 my-auto ">
                                                        <div className="row">
                                                            <div className="col-auto mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.testLabel) ? "" : "text-muted"}`} htmlFor="testInput"
                                                                    style={{ marginTop: '2px' }}>
                                                                    Test Label ?
                                                                </label>
                                                            </div>


                                                            <div className="col-auto mt-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="testInput" className="form-check-input form-checked-success" role="switch" placeholder="" name="testLabel" type="checkbox"
                                                                        value=""
                                                                        checked={values.testLabel}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'testLabel': true }) }}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                    <div className="col-auto">
                                                        <button type="button" className="btn btn-primary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                                            onClick={(e) => {
                                                                // dispatch(clearActiveCreateLabelModal())

                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    var requestData = {
                                                                        ...values,
                                                                        advancedOptions: null
                                                                    }

                                                                    var selectedAdvancedOptions = values.advancedOptions?.map(a => a.value);
                                                                    if (selectedAdvancedOptions?.length > 0) {
                                                                        requestData.advancedOptions = "";
                                                                        selectedAdvancedOptions.forEach(option => {
                                                                            if (requestData.advancedOptions?.length > 0) {
                                                                                requestData.advancedOptions += ",";
                                                                            }
                                                                            requestData.advancedOptions += option;
                                                                        });
                                                                    }

                                                                    handleSubmit(requestData);
                                                                }
                                                            }}
                                                        >Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card custom-card bg-success bg-opacity-10 p-3 m-0 mb-2">

                                                <h5>ITEMS</h5>
                                                {values.products && values.products.length > 0 && values.products.map((item, index) => {
                                                    return (
                                                        <div key={"orderItem_" + index} className={`row m-0 mb-1 p-0 pb-2 border rounded  ${(item.checked) ? "bg-success bg-opacity-50 border-success" : "bg-light"}`} >
                                                            <div className="col-12 px-0">
                                                                <div className="row">
                                                                    <div className="col-auto">

                                                                        <div className="form-check form-check-md form-switch mt-2 ms-2">
                                                                            <DrInput id={item.orderItemId} className="form-check-input form-checked-info" role="switch" placeholder=""
                                                                                name="products"//{`products[${index}].checked`}
                                                                                type="checkbox"
                                                                                value=""
                                                                                checked={item.checked}
                                                                                disabled={item.isSelected ? "disabled" : ""}
                                                                                onChange={(e) => {
                                                                                    if (!item.isSelected) {
                                                                                        setFieldValue(`products[${index}].checked`, e.currentTarget.checked);

                                                                                        if (e.currentTarget.checked) {
                                                                                            setFieldValue(`products[${index}].quantityToShip`, (item.quantityOrdered > 1 ? (item.quantityOrdered - item.quantityShipped) : 1));
                                                                                        }



                                                                                        let currentProduct = Object.assign({}, item);
                                                                                        currentProduct.quantityToShip = (e.currentTarget.checked ? ((currentProduct.quantityOrdered > 1 ? (currentProduct.quantityOrdered - currentProduct.quantityShipped) : 1)) : 0);
                                                                                        currentProduct.checked = e.currentTarget.checked;

                                                                                        let selectedProducts = [
                                                                                            ...values.products?.filter(x => x.orderItemId !== item.orderItemId && !x.isSelected),
                                                                                            currentProduct
                                                                                        ]

                                                                                        setFieldValue('dimensions',
                                                                                            {
                                                                                                width: selectedProducts?.filter(x => x.checked).reduce((a, curr) => a + (curr.packageWidthValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                                length: selectedProducts?.filter(x => x.checked).reduce((a, curr) => a + (curr.packageLengthValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                                height: selectedProducts?.filter(x => x.checked).reduce((a, curr) => a + (curr.packageHeightValue || 0) * (curr.quantityToShip || 1), 0)
                                                                                            })
                                                                                        setFieldValue('weight',
                                                                                            {
                                                                                                value: selectedProducts?.filter(x => x.checked).reduce((a, curr) => a + (curr.packageWeightValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                                units: shipstationData?.weight.units || "ounces"
                                                                                            })
                                                                                    }
                                                                                }}
                                                                                onBlur={handleBlur} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">ASIN :</label>
                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{item.asin}</label>
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-0">Title :</label>
                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{item.itemName}</label>
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Quantity :</label>
                                                                    <div className="col-auto">
                                                                        <DrInput className="form-control form-control-sm" placeholder="" name="products" type="number" step="1" min="1" max={((values.products?.[index].quantityOrdered > 1 ? (values.products?.[index].quantityOrdered - values.products?.[index].quantityShipped) : 1) || 0).toString()}
                                                                            value={item.quantityToShip}
                                                                            disabled={item.isSelected}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                if (!item.isSelected) {
                                                                                    setFieldValue(`products[${index}].quantityToShip`, (e.currentTarget.value?.length > 0 ? parseInt(e.currentTarget.value) : 0));


                                                                                    let currentProduct = Object.assign({}, item);
                                                                                    currentProduct.quantityToShip = (e.currentTarget.value?.length > 0 ? parseInt(e.currentTarget.value) : 0);

                                                                                    let selectedProducts = [
                                                                                        ...values.products?.filter(x => x.orderItemId !== item.orderItemId && !x.isSelected),
                                                                                        currentProduct
                                                                                    ]
                                                                                    setFieldValue('dimensions',
                                                                                        {
                                                                                            width: selectedProducts?.filter(x => x.checked)?.reduce((a, curr) => a + (curr.packageWidthValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                            length: selectedProducts?.filter(x => x.checked)?.reduce((a, curr) => a + (curr.packageLengthValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                            height: selectedProducts?.filter(x => x.checked)?.reduce((a, curr) => a + (curr.packageHeightValue || 0) * (curr.quantityToShip || 1), 0)
                                                                                        })
                                                                                    setFieldValue('weight',
                                                                                        {
                                                                                            value: selectedProducts?.filter(x => x.checked).reduce((a, curr) => a + (curr.packageWeightValue || 0) * (curr.quantityToShip || 1), 0),
                                                                                            units: shipstationData?.weight.units || "ounces"
                                                                                        })
                                                                                }

                                                                            }}
                                                                            onBlur={handleBlur} />
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Price :</label>
                                                                    <div className="col-auto">
                                                                        <DrInput className="form-control form-control-sm" placeholder="" name="products" type="text" min="0" step="0.1"
                                                                            value={values.orderItem[index].price || ""}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                setFieldValue(`orderItem[${index}].price`, (e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                                                                setFieldValue(`internationalOptions.customsItems[${index}].value`, (e.currentTarget.value?.length > 0 ? parseFloat(e.currentTarget.value) : 0));
                                                                            }}
                                                                            onBlur={handleBlur} />
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Weight :</label>
                                                                    <div className="col-auto my-auto fw-bold">
                                                                        <span className="badge bg-info fs-12 me-1">
                                                                            {Number(item.packageWeightValue || 0).toFixed(2)} {item.packageWeightUnit ? "(" + item.packageWeightUnit + ")" : "(lb)"}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Size :</label>
                                                                    <div className="col-auto my-auto fw-bold">
                                                                        <span className="badge bg-orange fs-12 me-1">
                                                                            {Number(item.packageLengthValue || 0).toFixed(2)} {"L"}
                                                                        </span>
                                                                        <span className="badge bg-orange fs-12 me-1">
                                                                            {Number(item.packageWidthValue || 0).toFixed(2)} {"W"}
                                                                        </span>
                                                                        <span className="badge bg-orange fs-12 me-1">
                                                                            {Number(item.packageHeightValue || 0).toFixed(2)} {"H"}
                                                                        </span>
                                                                        {item.packageWidthUnit ? "(" + item.packageWidthUnit + ")" : "(inches)"}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }

                                            </div>
                                            <div className="card custom-card bg-warning bg-opacity-25 p-3 m-0 mb-2">

                                                <h5>RECIPIENT</h5>
                                                <div className="row m-0 p-0">
                                                    <div className="col-12 px-0">
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-0">Ship To :</label>
                                                            <label className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                {(values.shipTo?.street1 || "") + " " + (values.shipTo?.postalCode || "") + (values.shipTo?.street1 ? ", " : "") + (values.shipTo?.city || "") + " " + (values.shipTo?.state || "") + " " + (values.shipTo?.phone || "")}

                                                            </label>
                                                        </div>
                                                        <hr className="my-3"></hr>
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-0">Sold To :</label>
                                                            <div className="col-9">
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-0">Phone :</label>
                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.shipTo.phone}</label>
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-0">Name :</label>
                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.shipTo.name}</label>
                                                                </div>
                                                                <div className="row">
                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-0">Email :</label>
                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.shipTo.buyerEmail}</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card custom-card bg-warning bg-opacity-25 p-3 m-0 mb-2">

                                                <h5>OTHER SHIPPING OPTIONS</h5>
                                                <div className="row">
                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Advanced Options :</label>
                                                    <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                        <Field
                                                            name={'advancedOptions'}
                                                            component={DrSelectBox}
                                                            placeholder="Advanced Options"
                                                            // disableInputChange={true}
                                                            // selectedValue={values.advancedOptions || ""}
                                                            options={advancedDDOptions}
                                                            isMulti={true}
                                                            onBlur={() => { setTouched({ ...touched.advancedOptions, 'advancedOptions': true }) }}
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    var result = e.map(a => a.value);
                                                                    setFieldValue("advancedOptions", result);
                                                                }
                                                                // setFieldValue(`advancedOptions`, e.value);
                                                                // if (e.value === "none") {
                                                                //     setFieldValue(`insuranceOptions[0].insuredValue`, 0);
                                                                // }
                                                            }} />
                                                    </div>
                                                </div>

                                            </div>

                                            {/* <section>
                                                <hr className="my-3"></hr>
                                                <h5>OTHER SHIPPING OPTIONS</h5>
                                                <div className="row m-0 p-0">
                                                    <div className="col-12 px-0">
                                                        <div className="row justify-content-end">
                                                            <label className="col-11 custom-switch cursor-pointer">
                                                                <DrInput id="nonMachinableInput" className="form-check-input" placeholder="" name="advancedOptions" type="checkbox"
                                                                    value=""
                                                                    // disabled={`${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "disabled"}`}
                                                                    checked={values.advancedOptions[0].nonMachinable || false}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        var data = [
                                                                            {
                                                                                ...values.advancedOptions[0],
                                                                                nonMachinable: e.currentTarget.checked
                                                                            }
                                                                        ]
                                                                        setFieldValue("advancedOptions", data);
                                                                        // setFieldValue(`advancedOptions[0].nonMachinable`, e.currentTarget.checked);
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                />
                                                                <label htmlFor="nonMachinableInput" className={`noselect ms-2 mt-2 text-start cursor-pointer`}>
                                                                    Non-machinable Shipment
                                                                </label>
                                                            </label>
                                                        </div>
                                                        <div className="row justify-content-end">
                                                            <label className="col-11 custom-switch cursor-pointer">
                                                                <DrInput id="saturdayDeliveryInput" className="form-check-input" placeholder="" name="advancedOptions" type="checkbox"
                                                                    value=""
                                                                    checked={values.advancedOptions[0].saturdayDelivery || false}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue(`advancedOptions[0].saturdayDelivery`, e.currentTarget.checked);
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'advancedOptions': true }) }}
                                                                />
                                                                <label htmlFor="saturdayDeliveryInput" className={`noselect ms-2 mt-2 text-start cursor-pointer`}>
                                                                    Saturday Delivery
                                                                </label>
                                                            </label>
                                                        </div>
                                                        <div className="row justify-content-end">
                                                            <label className="col-11 custom-switch cursor-pointer">
                                                                <DrInput id="containsAlcoholInput" className="form-check-input" placeholder="" name="advancedOptions" type="checkbox"
                                                                    value=""
                                                                    checked={values.advancedOptions[0].containsAlcohol || false}
                                                                    onChange={(e) => {
                                                                        e.preventDefault();
                                                                        setFieldValue(`advancedOptions[0].containsAlcohol`, e.currentTarget.checked);
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'advancedOptions': true }) }}
                                                                />
                                                                <label htmlFor="containsAlcoholInput" className={`noselect ms-2 mt-2 text-start cursor-pointer`}>
                                                                    Contains Alcohol
                                                                </label>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section> */}
                                            <div className="card custom-card bg-danger bg-opacity-25 p-3 m-0 mb-2">

                                                <h5>CUSTOMS DECLERATIONS</h5>
                                                <div className="row m-0 p-0">
                                                    <div className="col-12 px-0">
                                                        <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Contents :</label>
                                                            <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.internationalOptions, 'internationalOptions': true }) }}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`internationalOptions.contents`, e.value);
                                                                        }}
                                                                        isMulti={false}
                                                                        name={'internationalOptions'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select Contents"
                                                                        disableInputChange={true}
                                                                        selectedValue={values.internationalOptions.contents || ""}
                                                                        options={
                                                                            [
                                                                                { value: "merchandise", label: "Merchandise" },
                                                                                { value: "documents", label: "Documents" },
                                                                                { value: "returnedgoods", label: "Returned Goods" },
                                                                                { value: "sample", label: "Sample" }
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">

                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Custom Items :</label>
                                                            <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                {values.internationalOptions && values.internationalOptions.customsItems.length > 0 && values.internationalOptions.customsItems?.filter(x => x.orderItemId === null || values.products?.find(y => y.checked && y.orderItemId === x.orderItemId)).map((item, index) => {
                                                                    return (
                                                                        <div key={"orderItem_" + index} className="row mb-1 m-0 p-0 border rounded bg-light">
                                                                            <div className="col-12 px-0">
                                                                                <div className="row">
                                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Description:</label>
                                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{item.description}</label>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-0">Quantity:</label>
                                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{values.products?.find(x => x.orderItemId === item.orderItemId).quantityToShip || item.quantity}</label>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Value:</label>
                                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{item.value || values.orderItem[index].price}</label>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">Country:</label>
                                                                                    <label className="col col-form-label col-form-label-sm ms-0 ps-0">{item.countryOfOrigin}</label>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <label className="col-3 col-form-label col-form-label-sm text-end my-auto">HTC:</label>
                                                                                    <div className="col-4 pe-0 ps-0">
                                                                                        <DrInput className="form-control form-control-sm" placeholder="" name="harmonizedTariffCode" type="text"
                                                                                            value={values.internationalOptions.customsItems[index].harmonizedTariffCode}
                                                                                            onChange={(e) => {

                                                                                                setFieldValue(`internationalOptions.customsItems[${index}].harmonizedTariffCode`, e.currentTarget.value);
                                                                                            }}
                                                                                            onBlur={handleBlur}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="row">
                                                            <label className="col-3 col-form-label col-form-label-sm text-end my-auto">If Undeliverable :</label>
                                                            <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                <div className="col col-form-label col-form-label-sm ms-0 ps-0">
                                                                    <Field
                                                                        onBlur={() => { setTouched({ ...touched.internationalOptions, 'internationalOptions': true }) }}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`internationalOptions[0].nonDelivery`, e.value);
                                                                        }}
                                                                        isMulti={false}
                                                                        name={'internationalOptions'}
                                                                        component={DrSelectBox}
                                                                        placeholder="Select Contents"
                                                                        disableInputChange={true}
                                                                        selectedValue={values.internationalOptions[0].nonDelivery}
                                                                        options={
                                                                            [
                                                                                { value: "returntosender", label: "Return To Sender" },
                                                                                { value: "abondoned", label: "Threat As Abondoned" }
                                                                            ]
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    }
                                </Formik>
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(withActionPromise(clearShipStationData()));
                                        dispatch(clearActiveCreateLabelModal());
                                    }}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DrCreateLabel