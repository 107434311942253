import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { createCustomerFailed, getPreRegisteredPersonListSuccess } from '../actions/create-customer.actions';
import i18next from 'i18next';
import { loginWithEmail } from '../actions/customer.actions';
import Swal from 'sweetalert2';

function* runCreateCustomer(action) {
    const payload = action.payload;

    // var data = {
    //     customerDto: {
    //         subscriptionType: payload.createCustomerSnapshot.subscriptionType,
    //         customerInformation: payload.createCustomerSnapshot.customerInformation,
    //         saveCard: payload.createCustomerSnapshot.saveCard
    //     }
    // }

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.CUSTOMER, false, false));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            // put(loginWithEmail({ email: payload.email, password: payload.password })),
        ]);
    }
    else {
console.log("ssss")
        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error2') + " " + (response && response.message ? response.message : ""), 'error')),
            put(createCustomerFailed(response))
        ]);
    }
}


function* runExtensionCreateCustomer(action) {
    const payload = action.payload;

    // var data = {
    //     customerDto: {
    //         subscriptionType: payload.createCustomerSnapshot.subscriptionType,
    //         customerInformation: payload.createCustomerSnapshot.customerInformation,
    //         saveCard: payload.createCustomerSnapshot.saveCard
    //     }
    // }

    const response = yield call(apiPost(``, payload, false, CONTROLLERS.LANDINGPAGE, false, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(Swal.fire(i18next.t('drSweetAlertButton:successTitle'), i18next.t('drSweetAlertButton:infoTexts.infoDefaultMessage'), 'success'))
        ]);
    }
    else {

        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error2') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetPreRegisteredPersonList() {

    const response = yield call(apiGet(``, CONTROLLERS.LANDINGPAGE, true, true));

    if (response && response.isSuccess && response.resultType === 1) {
        yield put(getPreRegisteredPersonListSuccess(response.data || []));
    }
}

export default function* createCustomerSaga() {
    yield all([
        takeLatest(types.CREATE_CUSTOMER_REQUESTED, safe(runCreateCustomer)),
        takeLatest(types.CREATE_EXTENSION_CUSTOMER_REQUESTED, safe(runExtensionCreateCustomer)),
        takeLatest(types.GET_EXTENSION_CUSTOMERS_REQUESTED, safe(runGetPreRegisteredPersonList)),
    ]);
}