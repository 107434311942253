import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrInput, DrDataTable, DrSweetAlertButton } from '../../components/component-index';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { buttonTypes, sweetAlertVariants } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { getBlockedWords, updateBlockedWords, deleteBlockedWords } from '../../state-management/actions/inventory.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import moment from 'moment';



function BlockedWordsPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('blockedWordsPage');
    const blockedWords = useSelector(state => state.inventory.blockedWords);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getBlockedWords(selectedStore.customerMainSellerId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore]);

    useEffect(() => {
        if (blockedWords) {
            const formattedData = blockedWords.map(word => ({ id: word.id, words: word.word, createdDate: word.createdDate }));
            setTableData(formattedData);
        }
    }, [blockedWords]);

    const initialValues = {
        word: ''
    };

    const handleSubmit = async (values, { resetForm }) => {
        const newWord = values.word.trim();
        if (newWord) {
            dispatch(updateBlockedWords({ customerMainSellerId: selectedStore.customerMainSellerId, word: newWord })); // Save the new word
            resetForm(); // Reset the form input
        }
    };

    const handleDeleteBlockedWord = async (id) => {
        const updatedWords = tableData.filter(item => item.id !== id);
        setTableData(updatedWords);
        dispatch(deleteBlockedWords({customerMainSellerId: selectedStore.customerMainSellerId,  id: id })); // Dispatch the action to delete the word by id
    };

    const columns = [
        { data: "words", title: t('blockedWordsTitle'), minWidth: "300px", maxWidth: "300px", searchable: true },
        { data: "createdDate", title: t('createdDate'), minWidth: "200px", maxWidth: "200px", searchable: false },
        { data: null, title: "", minWidth: "50px", maxWidth: "50px", searchable: false }
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="mb-0 mt-1 fs-14 px-1 py-1">{rowData.words}</span>
                );
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="mb-0 mt-1 fs-14 px-1 py-1">{moment.utc(rowData.createdDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 2,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <div>
                        <DrSweetAlertButton
                            className="btn btn-icon btn-sm btn-danger btn-wave waves-effect waves-light shadow"
                            buttonText={""}
                            buttonIcon={<i className="fe fe-trash-2"></i>}
                            buttonType={buttonTypes.button}
                            onCloseConfirmCallBack={(value) => {
                                if (value) {
                                    handleDeleteBlockedWord(rowData.id);
                                }
                            }}
                            sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                        />
                    </div>
                );
            }
        }
    ];


    return (
        <>
            <DrBreadCrump
                breadCrumps={[
                    { navigationText: i18next.t('constants:breadCrumps.inventory'), navigationPath: "/inventory" },
                    { navigationText: i18next.t('constants:breadCrumps.blockedWords'), navigationPath: "" }
                ]}
            />
            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            {selectedStore?.customerMainSellerId.length > 0 &&
                                <>
                                    <Formik
                                        initialValues={initialValues}
                                        validateOnBlur={true}
                                        onSubmit={handleSubmit}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isValid,
                                            setFieldValue
                                        }) => (
                                            <form onSubmit={(e) => { e.preventDefault(); handleSubmit(values, { resetForm: () => setFieldValue('word', '') }); }}>
                                                <div className="row row-md row-lg justify-content-center">
                                                    <div className="col-12 px-0">
                                                        <div className="form-group">
                                                            <DrInput
                                                                value={values.word}
                                                                className="form-control"
                                                                placeholder={t('blockedWordsInputHintText')}
                                                                name="word"
                                                                type="text"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                validationText={errors.word}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row row-md row-lg justify-content-end mb-3 mt-2">
                                                    <div className="col-auto px-0">
                                                        <button
                                                            className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid && values.word !== '' ? "" : "disabled"}`}
                                                            type="submit"
                                                        >
                                                            {t('blockedWordsButton')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Formik>

                                    <DrDataTable
                                        data={tableData}
                                        columns={columns}
                                        columnDefs={columnDefs}
                                        doesHaveBorder={false}
                                        headerSearch={true}
                                        minWidth="850px"
                                        search={true}
                                        order={[[0, 'asc']]}
                                        isMultiSelectEnabled={true}
                                    />
                                </>
                            }
                            {!selectedStore &&
                                <div className="row row-md row-lg justify-content-center">
                                    <div className="col-lg-auto col-md-2">
                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                Promise.all([
                                                    dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                ]).then((e) => {
                                                    navigate(getRouteByKey("MARKET_SELECTION"));
                                                });;

                                            }}>
                                            <i className="me-2 fe fe-chevrons-right"></i>
                                            {t('gotoMarketSelection')}
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlockedWordsPage;
