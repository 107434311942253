import { useDispatch, useSelector } from "react-redux";
import { DrInput, DrMaskedInput, DrNavigator } from "../../components/component-index";
import { cardBrandNames, cardBrands, modals } from "../../utils/constants";
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { getCardNumber, getCreditCardIcon, getCreditCardType, getNumbersFromString, maskCreditCard } from "../../utils/utils";
import { cardNumberMask, expirationDateMask, amexCardNumberMask, threeDigitCVC, fourDigitCVC } from "../../utils/masks";
import { setActiveModal, syncedState, withActionPromise } from "../../state-management/actions/app.actions";
import Card from "react-credit-cards-2";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { createPackagePayment, setCreatePackageSnapshotPageSubmission, setCreditCardInformation, setPackagesSnapshotMeta, setSaveCard } from "../../state-management/actions/create-customer-packages.actions";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import { getPackages } from "../../state-management/actions/packages.action";
import i18next from "i18next";
import Swal from "sweetalert2";
// import { paymentPageMockData as allPackages } from "../../utils/mocks-data";

function PaymentPage() {
    const { REACT_APP_ROLES_GUEST, REACT_APP_ROLES_USER } = process.env;
    const dispatch = useDispatch();
    const { t } = useTranslation('paymentPage');
    const customerInfo = useSelector(state => state.customer.customerInfo);

    const [focusedInput, setFocusedInput] = useState("name");
    const [creditCardBrand, setCreditCardBrand] = useState("");
    const [creditCardMask, setCreditCardMask] = useState(amexCardNumberMask);
    const [cvcMask, setCvcMask] = useState(threeDigitCVC);

    const creaditCardInfo = useSelector(state => state.createPackagesSnapshot.creaditCardInfo);
    const pageSubmissions = useSelector(state => state.createPackagesSnapshot.pageSubmissions);
    const createPackagesSnapshot = useSelector(state => state.createPackagesSnapshot);
    const allPackages = useSelector(state => state.packages.allPackages);
    const [selectedMainPackage, setSelectedMainPackage] = useState([]);
    const [selectedFeaturePackages, setSelectedFeaturePackages] = useState([]);
    const [selectedAppliedCouponCode, setSelectedAppliedCouponCode] = useState("");

    const [packagesInfo, setPackagesInfo] = useState();
    const isSubmittedBefore = useSelector(state => state.createPackagesSnapshot.packagesCreateSubmitted);
    const [availableCouponList, setAvailableCouponList] = useState([]);

    useEffect(() => {
        if (!(allPackages?.length > 0)) {
            dispatch(getPackages())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allPackages?.length > 0) {
            var coupons = [];
            allPackages.filter(x => x.couponList?.length > 0).map((subPackage, index) => {
                subPackage.couponList?.forEach(coupon => {
                    if (!coupons.includes(coupon.couponCode)) {
                        coupons.push(coupon.couponCode.toString())
                    }
                });

            })

            setAvailableCouponList(coupons)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPackages]);

    const getTotalPrice = (arr, couponCode) => {
        let priceObject = {
            packagesExtraFeatures: [],
            totalPrice: 0,
            totalSavings: 0
        };
        if (arr?.length > 0) {
            arr.forEach(subPackage => {
                let discountPrice = subPackage.discount && subPackage.discount > 0 ? (subPackage.price * subPackage.discount / 100) : 0;
                let discountedPackagePrice = subPackage.price - discountPrice;
                let couponApplied = subPackage.couponList && subPackage.couponList?.length > 0 ? subPackage.couponList.find(x => x.couponCode === couponCode) : null;
                let couponPrice = couponApplied && couponApplied.discountRate > 0 ? (discountedPackagePrice * couponApplied.discountRate / 100) : 0;
                let couponPackagePrice = discountedPackagePrice - couponPrice;

                priceObject.packagesExtraFeatures.push({
                    packageId: subPackage.id,
                    discountPrice: discountPrice,
                    discountedPackagePrice: discountedPackagePrice,
                    couponApplied: couponApplied,
                    couponPrice: couponPrice,
                    couponPackagePrice: couponPackagePrice

                });
                priceObject.totalPrice += couponPackagePrice;
                priceObject.totalSavings += (subPackage.price - couponPackagePrice);

            });
        }
        return priceObject;
    }

    const initialValues = {
        appliedCouponCode: selectedAppliedCouponCode,
        couponCode: "",

        cardHolderName: creaditCardInfo?.cardHolderName || "",
        cardNumber: (creaditCardInfo?.cardNumber && getNumbersFromString(creaditCardInfo?.cardNumber)) || "",
        expirationDate: creaditCardInfo.expirationDateMonth + "/" + creaditCardInfo.expirationDateYear,
        expirationDateMonth: creaditCardInfo.expirationDateMonth ? creaditCardInfo.expirationDateMonth.toString() : "",
        expirationDateYear: creaditCardInfo.expirationDateYear ? creaditCardInfo.expirationDateYear.toString() : "",
        cvc: creaditCardInfo?.cvc,
        isSaveCard: creaditCardInfo?.isSaveCard || false,

        packagesInfo: {
            mainPackage: selectedMainPackage,
            subPackages: selectedFeaturePackages,
            priceObject: getTotalPrice([...selectedMainPackage, ...selectedFeaturePackages], selectedAppliedCouponCode)

        }
    };

    var containerClass = classNames({
        'col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-8': customerInfo?.role?.includes(REACT_APP_ROLES_GUEST) || customerInfo?.role?.includes(REACT_APP_ROLES_USER),
        'col-12': true
    });
    // "creaditCardInfo": {
    //     "cardHolderName": "string",
    //     "cardNumber": "string",
    //     "cvc": "string",
    //     "expirationDateMonth": 0,
    //     "expirationDateYear": 0,
    //     "isSaveCard": true
    //   }

    useEffect(() => {
        var mainPackage = (allPackages?.length > 0 && allPackages.find(x => x.id === createPackagesSnapshot.mainPackageId)) || null;
        if (mainPackage) {
            setSelectedMainPackage([mainPackage])
        }
        else {
            setSelectedMainPackage([])
        }

        var subPackages = allPackages.length > 0 ? allPackages?.filter(function (o) {
            return createPackagesSnapshot?.packageFeaturesIds?.find(r => r === o.id);
        }) : []

        setSelectedFeaturePackages(subPackages);

        setPackagesInfo({
            mainPackage: mainPackage ? [mainPackage] : [],
            subPackages: subPackages,
            priceObject: getTotalPrice([...(mainPackage ? [mainPackage] : []), ...subPackages], "")

        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPackagesSnapshot?.mainPackageId, createPackagesSnapshot?.packageFeaturesIds]);

    useEffect(() => {
        if (creaditCardInfo?.cardNumber && creaditCardInfo?.cardNumber.length > 0) {
            if (getCreditCardType(creaditCardInfo?.cardNumber) === cardBrandNames[cardBrands.amex]
                // || e.issuer === cardBrandNames[cardBrands.discover]
            ) {
                setCreditCardMask(amexCardNumberMask);
                setCvcMask(fourDigitCVC);
            } else {
                setCreditCardMask(cardNumberMask);
                setCvcMask(threeDigitCVC);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creaditCardInfo]);



    const handleSubmit = async (values) => {
        //dispatch(createCustomer(createPackagesSnapshot));
    }


    Yup.addMethod(Yup.string, "expirationDate", function (errorMessage) {
        return this.test(`expirationDate`, errorMessage, function (value) {
            if (value && value.includes("/")) {
                const { path, createError } = this;
                const terms = value.split("/");
                let month = terms[0], year = terms[1];

                if ((typeof month === "number" && isNaN(month))
                    || (typeof year === "number" && isNaN(year))
                    || parseInt(month) > 12
                    || parseInt(month) === 0
                    || year.includes("_")
                    || month.includes("_")) {
                    return createError({ path, message: "Expiration date is not valid" });
                }

                year = parseInt("20" + year);
                month = parseInt(month);
                const dateNow = new Date();
                const yearNow = dateNow.getFullYear();
                const monthNow = dateNow.getMonth() + 1;
                if (year > yearNow) {
                    return true;
                }

                if ((yearNow === year && month < monthNow) || year < yearNow) {
                    return createError({ path, message: errorMessage });
                }

                return true;
            }
        });
    });

    Yup.addMethod(Yup.string, "cardBrand", function (errorMessage) {
        return this.test(`cardNumber`, errorMessage, function () {
            const { path, createError } = this;

            if (creditCardBrand === cardBrands.unknown) {
                return createError({ path, message: errorMessage });
            }

            return true;
        });
    });

    Yup.addMethod(Yup.string, "cardNumber", function (errorMessage) {
        return this.test(`cardNumber`, errorMessage, function (value) {
            if (!value) {
                return true;
            }

            const { path, createError } = this;

            var creditCardNumber = getNumbersFromString(value);
            if (creditCardBrand === cardBrands.amex) {
                return creditCardNumber.length === 15 || createError({ path, message: errorMessage });
            }

            return creditCardNumber.length === 16 || createError({ path, message: errorMessage });
        });
    });

    Yup.addMethod(Yup.string, "cvc", function (errorMessage) {
        return this.test(`cvc`, errorMessage, function (value) {
            if (!value) {
                return true;
            }

            const { path, createError } = this;

            var cvc = getNumbersFromString(value);
            return cvc.length === cvcMask?.length || createError({ path, message: errorMessage });
            // if (creditCardBrand === cardBrands.amex || creditCardBrand === cardBrands.discover) {
            //     return cvc.length === cvcMask?.length || createError({ path, message: errorMessage });
            // }

            // return cvc.length === 3 || createError({ path, message: errorMessage });
        });
    });

    const paymentValidationSchema = Yup.object().shape({
        cardHolderName: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Name is required'),
        cardNumber: Yup.string().required('Card Number is required').cardNumber("Please enter a valid credit card number"),//.cardBrand("Card Brand is unknown"),
        expirationDate: Yup.string().required('Expiration Date is required').expirationDate("Card is expired"),
        cvc: Yup.string().required("CVC is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .cvc("CVC is not valid")
    });


    const handlePayment = (values) => {
        if (!isSubmittedBefore) {
            const submitPaymentSnapshot = async () => {
                const terms = values.expirationDate.split("/");
                let cardInfo = {
                    cardHolderName: values.cardHolderName,
                    cardNumber: (values.cardNumber && getNumbersFromString(values.cardNumber)) || "",
                    cvc: values.cvc,
                    expirationDateMonth: parseInt(terms[0]),
                    expirationDateYear: parseInt(terms[1]),
                    isSaveCard: true
                }

                const lastState = await dispatch(syncedState());

                var requestData = {
                    packageIds: [
                        lastState.createPackagesSnapshot.mainPackageId, ...lastState.createPackagesSnapshot?.packageFeaturesIds
                    ],
                    couponCode: values.appliedCouponCode,
                    creaditCardInfo: cardInfo
                }

                dispatch(createPackagePayment(requestData));
            }
            submitPaymentSnapshot();

        }
    }

    useEffect(() => {
        if (isSubmittedBefore) {
            dispatch(setActiveModal({
                name: modals.description,
                isGlobal: true,
                title: i18next.t('drSweetAlertButton:infoTitle'),
                header: i18next.t('drSweetAlertButton:infoTitle'),
                text: i18next.t('drSweetAlertButton:infoTexts.info5'),
                navigationPath: "/customer-packages",
                navigationText: t('goToMyPackages')
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmittedBefore]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={paymentValidationSchema}
            enableReinitialize={true}
            validateOnMount={true}>
            {({
                isValid,
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                setTouched,
                setFieldValue
            }) => (
                <div className="content clearfix">
                    <div className="row justify-content-center">
                        <div className={containerClass}>
                            <form name="payment-form" autoComplete="off">
                                <section>

                                    <div className="row justify-content-center">
                                        <div className="col-12">
                                            <div className="row">

                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
                                                    <div className="card custom-card shadow">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                Credit Card Information
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <Card
                                                                        preview={true}
                                                                        // number={values.cardNumber}
                                                                        number={values.cardNumber ? getCardNumber(values.cardNumber) : ""}
                                                                        name={values.cardHolderName || ""}
                                                                        expiry={values.expirationDate || ""}
                                                                        cvc={values.cvc || ""}
                                                                        focused={focusedInput}
                                                                        callback={(e) => {
                                                                            if (e.issuer === cardBrandNames[cardBrands.amex]) {
                                                                                setCreditCardBrand(0);
                                                                            }
                                                                            else if (e.issuer === cardBrandNames[cardBrands.discover]) {
                                                                                setCreditCardBrand(2);
                                                                            }
                                                                            else {
                                                                                setCreditCardBrand(cardBrands[e.issuer]);
                                                                            }

                                                                            if (e.issuer === cardBrandNames[cardBrands.amex]
                                                                                // || e.issuer === cardBrandNames[cardBrands.discover]
                                                                            ) {
                                                                                setCreditCardMask(amexCardNumberMask);
                                                                                setCvcMask(fourDigitCVC);
                                                                            } else {
                                                                                setCreditCardMask(cardNumberMask);
                                                                                setCvcMask(threeDigitCVC);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 justify-content-center">
                                                                <div className="col-xxl-8 col-xl-10 col-lg-10 col-md-12 col-12 ">
                                                                    <div className="row my-2">
                                                                        <div className="col-12">
                                                                            <label className="text-wrap">{t('cardHolderName')}</label>
                                                                            <DrInput
                                                                                value={values.cardHolderName || ""}
                                                                                placeholder={t('cardHolderName')}
                                                                                name="cardHolderName"
                                                                                type="text"
                                                                                className="form-control"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                // onFocus={() => { setFocusedInput("cardHolderName") }} 
                                                                                isInvalid={(touched.cardHolderName && errors.cardHolderName)}
                                                                                isValid={touched.cardHolderName && !errors.cardHolderName}
                                                                                validationText={errors.cardHolderName}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row my-2">
                                                                        <div className="col-12">
                                                                            <label className="text-wrap">{t('cardNumber')}</label>
                                                                            <Field
                                                                                placeholder={t('cardNumber')}
                                                                                onBlur={() => { setTouched({ ...touched, 'cardNumber': true }) }}
                                                                                name={'cardNumber'}
                                                                                className="form-control"
                                                                                component={DrMaskedInput}
                                                                                mask={values.cardNumber?.slice(0, 1) === "3" ? amexCardNumberMask : cardNumberMask}
                                                                                type="text"
                                                                                rawvalue={values.cardNumber || ""}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("cardNumber", e.currentTarget.value);
                                                                                }}
                                                                                onFocus={() => { setFocusedInput("number") }}
                                                                                isInvalid={(touched.cardNumber && errors.cardNumber)}
                                                                                isValid={touched.cardNumber && !errors.cardNumber}
                                                                                validationText={errors.cardNumber}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row my-2">
                                                                        <div className="col-sm-8">
                                                                            <label className="text-wrap">{t('expirationDate')}</label>
                                                                            <Field
                                                                                placeholder="MM/YY"
                                                                                onBlur={() => { setTouched({ ...touched, 'expirationDate': true, 'expirationDateMonth': true, 'expirationDateYear': true }) }}
                                                                                name={'expirationDate'}
                                                                                className="form-control"
                                                                                component={DrMaskedInput}
                                                                                mask={expirationDateMask}
                                                                                type="text"
                                                                                onChange={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (moment.utc(e.target.value, "MM/YY", true).isValid()) {
                                                                                        setFieldValue("expirationDate", e.target.value);
                                                                                        var month = moment.utc(e.target.value, "MM/YY").format('MM').valueOf();
                                                                                        var year = moment.utc(e.target.value, "MM/YY").format('YY').valueOf();
                                                                                        setFieldValue("expirationDateMonth", parseInt(month));
                                                                                        setFieldValue("expirationDateYear", parseInt(year));
                                                                                    }
                                                                                }}
                                                                                onFocus={() => { setFocusedInput("expiry") }}
                                                                                isInvalid={(touched.expirationDate && errors.expirationDate)}
                                                                                isValid={touched.expirationDate && !errors.expirationDate}
                                                                                validationText={errors.expirationDate}
                                                                            />

                                                                        </div>
                                                                        <div className="col-sm-4">
                                                                            <label className="text-wrap">CVC</label>
                                                                            <Field
                                                                                placeholder="CVC"
                                                                                onBlur={() => { setTouched({ ...touched, 'cvc': true }) }}
                                                                                name="cvc"
                                                                                className="form-control"
                                                                                component={DrMaskedInput}
                                                                                mask={values?.cardNumber?.slice(0, 1) === "3" ? fourDigitCVC : threeDigitCVC}
                                                                                type="text"
                                                                                onChange={handleChange}
                                                                                onFocus={() => { setFocusedInput("cvc") }}
                                                                                isInvalid={(touched.cvc && errors.cvc)}
                                                                                isValid={touched.cvc && !errors.cvc}
                                                                                validationText={errors.cvc}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row justify-content-start my-2">
                                                                        <div className="col-auto mt-0">
                                                                            <div className="form-check form-check-md form-switch">
                                                                                <DrInput id="isSaveCard" className="form-check-input form-checked-success" role="switch" placeholder="" name="isSaveCard" type="checkbox"
                                                                                    value=""
                                                                                    checked={values.isSaveCard}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'isSaveCard': true }) }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col mt-0">
                                                                            <label className={`form-check-label cursor-pointer no-select ${(values.isSaveCard) ? "" : "text-muted"}`} htmlFor="isSaveCard"
                                                                                style={{ marginTop: '2px' }}>
                                                                                {t('isSaveCard')}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="row justify-content-end my-2">
                                                                        <div className="col-auto">
                                                                            <button className={`btn btn-info btn-wave waves-effect waves-light shadow ${(isValid) ? "" : "disabled"}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (isValid) {
                                                                                        dispatch(withActionPromise(setCreditCardInformation({
                                                                                            cardHolderName: values.cardHolderName,
                                                                                            cardNumber: values.cardNumber,
                                                                                            expirationDate: values.expirationDate,
                                                                                            expirationDateMonth: values.expirationDate.split('/')[0],
                                                                                            expirationDateYear: values.expirationDate.split('/')[1],
                                                                                            cvc: values.cvc,
                                                                                            isSaveCard: values.isSaveCard || true,
                                                                                            isCardConfirmed: true
                                                                                        })));
                                                                                    }
                                                                                }}>
                                                                                {values.isCardConfirmed ? "Confirmed" : "Confirm Card Info"}
                                                                            </button>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 d-flex">

                                                    <div className="card custom-card shadow">
                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                Payment Summary
                                                            </div>
                                                        </div>
                                                        <div className="card-body">

                                                            {packagesInfo?.mainPackage?.length > 0 && packagesInfo?.mainPackage?.map((mainPackage, index) => {

                                                                let mainPackagePrices = packagesInfo?.priceObject?.packagesExtraFeatures?.find(x => x.packageId === mainPackage.id);

                                                                return (
                                                                    <>
                                                                        <div className="d-flex align-items-center justify-content-between">
                                                                            <div className=" fs-14">{mainPackage.planName}</div>
                                                                            <div className="fw-semibold fs-14">
                                                                                {(mainPackagePrices?.discountPrice === 0 && mainPackagePrices?.couponPrice === 0) && ("$" + Number(mainPackage.price).toFixed(2))}
                                                                                {(mainPackagePrices?.discountPrice > 0 || mainPackagePrices?.couponPrice > 0) &&
                                                                                    <span className="fs-12 text-muted text-decoration-line-through ms-1 d-inline-block op-6">
                                                                                        {("$" + Number(mainPackage.price).toFixed(2))}
                                                                                    </span>}
                                                                            </div>
                                                                        </div>
                                                                        {(mainPackagePrices?.discountPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="fs-12 text-muted ps-3"><i className="bx bxs-discount me-2"></i>Discount {mainPackage.discount}% applied</div>
                                                                                <div className="fs-12 text-muted ms-1 d-inline-block op-6">
                                                                                    {("-$" + Number(mainPackagePrices?.discountPrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                        {(mainPackagePrices?.couponPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="fs-12 text-muted ps-3"><i className="ri-coupon-2-line me-2"></i>Coupon {mainPackagePrices?.couponApplied.discountRate}% applied</div>
                                                                                <div className="fs-12 text-muted ms-1 d-inline-block op-6">
                                                                                    {("-$" + Number(mainPackagePrices?.couponPrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                        {(mainPackagePrices?.couponPrice > 0 || mainPackagePrices?.discountPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-end">
                                                                                <div className="fw-semibold fs-14">
                                                                                    {("$" + Number(mainPackagePrices?.couponPackagePrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                    </>
                                                                )
                                                            })}



                                                            {packagesInfo?.subPackages && packagesInfo?.subPackages?.map((subPackage, index) => {
                                                                let subPackagePrices = packagesInfo?.priceObject?.packagesExtraFeatures?.find(x => x.packageId === subPackage.id);

                                                                return (
                                                                    <div key={"_subPackageItem_" + index}>
                                                                        <div className="d-flex align-items-center justify-content-between mt-3">
                                                                            <div className=" fs-14">{subPackage.planName}</div>
                                                                            <div className="fw-semibold fs-14">
                                                                                {(subPackagePrices.discountPrice === 0 && subPackagePrices.couponPrice === 0) && ("$" + Number(subPackage.price).toFixed(2))}
                                                                                {(subPackagePrices.discountPrice > 0 || subPackagePrices.couponPrice > 0) &&
                                                                                    <span className="fs-12 text-muted text-decoration-line-through ms-1 d-inline-block op-6">
                                                                                        {("$" + Number(subPackage.price).toFixed(2))}
                                                                                    </span>}
                                                                            </div>
                                                                        </div>
                                                                        {(subPackagePrices.discountPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="fs-12 text-muted ps-3"><i className="bx bxs-discount me-2"></i>Discount {subPackage.discount}% applied</div>
                                                                                <div className="fs-12 text-muted ms-1 d-inline-block op-6">
                                                                                    {("-$" + Number(subPackagePrices.discountPrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                        {(subPackagePrices.couponPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="fs-12 text-muted ps-3"><i className="ri-coupon-2-line me-2"></i>Coupon {subPackagePrices.couponApplied.discountRate}% applied</div>
                                                                                <div className="fs-12 text-muted ms-1 d-inline-block op-6">
                                                                                    {("-$" + Number(subPackagePrices.couponPrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                        {(subPackagePrices.couponPrice > 0 || subPackagePrices.discountPrice > 0) &&
                                                                            <div className="d-flex align-items-center justify-content-end">
                                                                                <div className="fw-semibold fs-14">
                                                                                    {("$" + Number(subPackagePrices.couponPackagePrice).toFixed(2))}
                                                                                </div>
                                                                            </div>}
                                                                    </div>
                                                                )
                                                            })
                                                            }

                                                            <div className="d-flex align-items-center justify-content-center mt-3">
                                                                <div className="row w-100">
                                                                    <div className="col-8 ps-0">
                                                                        <div className="row">
                                                                            <div className="col-6 px-0">
                                                                                <DrInput className="form-control" placeholder="Coupon Code" name="couponCode" type="text"
                                                                                    value={values.couponCode || ""}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    icon={<i className="ri-coupon-2-line"></i>}
                                                                                    iconPosition="left"
                                                                                />
                                                                            </div>
                                                                            <div className="col-6 px-0">

                                                                                <div class="btn-group" role="group">
                                                                                    <button class="btn btn-teal btn-wave waves-effect waves-light" value={values.couponCode}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            if (e.target.value) {

                                                                                                if (availableCouponList.includes(e.target.value)) {
                                                                                                    setFieldValue("appliedCouponCode", e.target.value);
                                                                                                    setPackagesInfo({
                                                                                                        ...packagesInfo,
                                                                                                        priceObject: getTotalPrice([packagesInfo?.mainPackage, ...packagesInfo?.subPackages], e.target.value)
                                                                                                    })
                                                                                                }
                                                                                                else {
                                                                                                    setFieldValue("appliedCouponCode", "");
                                                                                                    setFieldValue("couponCode", "");
                                                                                                    Swal.fire(i18next.t('drSweetAlertButton:warningTitle'), i18next.t('drSweetAlertButton:warningTexts.warning3'), 'warning');
                                                                                                }

                                                                                                // setSelectedAppliedCouponCode(e.target.value);
                                                                                            }
                                                                                        }}>
                                                                                        Add
                                                                                    </button>
                                                                                    <button type="button" class="btn btn-icon  btn-danger btn-wave waves-effect waves-light"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setFieldValue("appliedCouponCode", "");
                                                                                            setFieldValue("couponCode", "");
                                                                                            // setSelectedAppliedCouponCode("");
                                                                                            setPackagesInfo({
                                                                                                ...packagesInfo,
                                                                                                priceObject: getTotalPrice([packagesInfo?.mainPackage, ...packagesInfo?.subPackages], "")
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <i className="fe fe-trash-2"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4 text-end my-auto pe-0">
                                                                        <div className="badge bg-success-transparent fs-11">{values.appliedCouponCode}</div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="card-footer">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="fs-18 fw-semibold">Total</div>
                                                                <h4 className="fw-semibold">${Number((packagesInfo?.priceObject?.totalPrice || 0)).toFixed(2)}</h4>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                {/* <div className="fs-18 fw-semibold"><i className="ri-coupon-2-line me-2"></i>Total Savings</div>
                                                                            <h4 className="fw-semibold">$12</h4> */}
                                                                <p className="text-muted d-flex align-items-center mb-0 ps-2">
                                                                    <i class="ri-coupon-2-line me-2 fs-14 d-inline-flex"></i>
                                                                    <span>Total Savings</span>
                                                                </p>
                                                                <span className="text-muted">${Number((packagesInfo?.priceObject?.totalSavings || 0)).toFixed(2)}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-end mt-2">
                                                                <button className={`btn btn-info btn-wave waves-effect waves-light shadow ${(isValid) ? "" : "disabled"}`}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dispatch(withActionPromise(dispatch(setCreditCardInformation(values))));
                                                                        if (isValid && !pageSubmissions?.paymentPage) {
                                                                            Promise.all([
                                                                                // dispatch(withActionPromise(dispatch(setPackagesSnapshotMainPackage(mainPackageId)))),

                                                                                dispatch(withActionPromise(dispatch(setCreatePackageSnapshotPageSubmission({ paymentPage: true }))))
                                                                            ]).then((e) => {
                                                                                handlePayment(values);
                                                                            });

                                                                        }
                                                                        else {
                                                                            Swal.fire(i18next.t('drSweetAlertButton:warningTitle'), i18next.t('drSweetAlertButton:warningTexts.warning4'), 'warning');
                                                                        }

                                                                    }}
                                                                >
                                                                    {t('completePayment')}
                                                                </button>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </section>
                                {<DrNavigator backAddress="SUB_FEATURES" nextAddress="" disabled={!isValid}
                                    callback={() => {
                                        console.log(values)
                                        return Promise.all([
                                            dispatch(withActionPromise(dispatch(setCreditCardInformation(values))))
                                        ]);
                                    }}
                                />}
                            </form>
                        </div>
                    </div>

                </div>
            )
            }
        </Formik>
    )
}

export default PaymentPage
