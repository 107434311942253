const types = {
    GET_SHIPSTATION_REQUESTED: "GET_SHIPSTATION_REQUESTED",
    GET_SHIPSTATION_SUCCESS: "GET_SHIPSTATION_SUCCESS",
    CLEAR_SHIPSTATION_DATA: "CLEAR_SHIPSTATION_DATA",

    CREATE_SHIPSTATION_REQUESTED: "CREATE_SHIPSTATION_REQUESTED",
    CREATE_SHIPSTATION_BULK_REQUESTED: "CREATE_SHIPSTATION_BULK_REQUESTED",
    CREATE_SHIPSTATION_BULK_SUCCESS: "CREATE_SHIPSTATION_BULK_SUCCESS",

    GET_SHIPSTATION_WAREHOUSES_REQUESTED: "GET_SHIPSTATION_WAREHOUSES_REQUESTED",
    GET_SHIPSTATION_WAREHOUSES_SUCCESS: "GET_SHIPSTATION_WAREHOUSES_SUCCESS",

    GET_SHIPSTATION_CARRIERS_REQUESTED: "GET_SHIPSTATION_CARRIERS_REQUESTED",
    GET_SHIPSTATION_CARRIERS_SUCCESS: "GET_SHIPSTATION_CARRIERS_SUCCESS",

    GET_SHIPSTATION_CARRIER_SERVICES_REQUESTED: "GET_SHIPSTATION_CARRIER_SERVICES_REQUESTED",
    GET_SHIPSTATION_CARRIER_SERVICES_SUCCESS: "GET_SHIPSTATION_CARRIER_SERVICES_SUCCESS",

    GET_SHIPSTATION_CARRIER_PACKAGES_REQUESTED: "GET_SHIPSTATION_CARRIER_PACKAGES_REQUESTED",
    GET_SHIPSTATION_CARRIER_PACKAGES_SUCCESS: "GET_SHIPSTATION_CARRIER_PACKAGES_SUCCESS",

    CLEAR_ALL_SHIPSTATION_DATA: "CLEAR_ALL_SHIPSTATION_DATA"
}

export default types;

export const getOrderShipStationData = (payload) => {
    return { type: types.GET_SHIPSTATION_REQUESTED, payload };
};

export const getOrderShipStationDataSuccess = (payload) => {
    return { type: types.GET_SHIPSTATION_SUCCESS, payload };
};

export const saveOrderShipStationData = (payload) => {
    return { type: types.CREATE_SHIPSTATION_REQUESTED, payload };
};

export const saveOrderShipStationDataBulk = (payload) => {
    return { type: types.CREATE_SHIPSTATION_BULK_REQUESTED, payload };
};

export const saveOrderShipStationDataBulkSuccess = (payload) => {
    return { type: types.CREATE_SHIPSTATION_BULK_SUCCESS, payload };
};

export const clearShipStationData = () => {
    return { type: types.CLEAR_SHIPSTATION_DATA }
}

export const getShipStationWarehouses = () => {
    return { type: types.GET_SHIPSTATION_WAREHOUSES_REQUESTED };
};

export const getShipStationWarehousesSuccess = (payload) => {
    return { type: types.GET_SHIPSTATION_WAREHOUSES_SUCCESS, payload };
};

export const getShipStationCarriers = () => {
    return { type: types.GET_SHIPSTATION_CARRIERS_REQUESTED };
};

export const getShipStationCarriersSuccess = (payload) => {
    return { type: types.GET_SHIPSTATION_CARRIERS_SUCCESS, payload };
};

export const getShipStationCarrierServices = (payload) => {
    return { type: types.GET_SHIPSTATION_CARRIER_SERVICES_REQUESTED, payload };
};

export const getShipStationCarrierServicesSuccess = (payload) => {
    return { type: types.GET_SHIPSTATION_CARRIER_SERVICES_SUCCESS, payload };
};

export const getShipStationCarrierPackages = (payload) => {
    return { type: types.GET_SHIPSTATION_CARRIER_PACKAGES_REQUESTED, payload };
};

export const getShipStationCarrierPackagesSuccess = (payload) => {
    return { type: types.GET_SHIPSTATION_CARRIER_PACKAGES_SUCCESS, payload };
};

export const clearAllShipStationData = () => {
    return { type: types.CLEAR_ALL_SHIPSTATION_DATA }
}
