import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRouteByKey } from "../../routes";
import { DrBreadCrump } from "../../components/component-index";
import i18next from "i18next";
import moment from "moment";
import { getCustomerPackageInfo } from "../../state-management/actions/customer.actions";
import { clearCreatePackagesState } from "../../state-management/actions/create-customer-packages.actions";

function CustomerPackagesPage() {
  const { t } = useTranslation("customerPackages");
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const selectedStore = useSelector((state) => state.stores.selectedStore);
  const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);


  const [totalUsage, setTotalUsage] = useState({
    totalProducts: 0,
    totalScanning: 0,
    totalSellers: 0,
    totalUsers: 0,
  });

  useEffect(() => {
    dispatch(getCustomerPackageInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if (customerPackageInfo.customerPackages) {
      const totals = customerPackageInfo.customerPackages.reduce((acc, pkg) => ({
        totalProducts: acc.totalProducts + pkg.productCount,
        totalScanning: acc.totalScanning + pkg.scanningCount,
        totalSellers: acc.totalSellers + pkg.sellerCount,
        totalUsers: acc.totalUsers + pkg.userCount,
      }), {
        totalProducts: 0,
        totalScanning: 0,
        totalSellers: 0,
        totalUsers: 0,
      });

      setTotalUsage(totals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerPackageInfo]);

  return (
    <>
      <DrBreadCrump
        breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.navigatorText2'), navigationPath: "" },
        { navigationText: i18next.t('constants:breadCrumps.customerPackages'), navigationPath: "" }]} >
      </DrBreadCrump>
      <div className="row">
        <div className="col-md-8 col-lg-12">
          <div className="card custom-card">
            {/* <div className="card-header border-bottom">
            <div className="card-title">{t("subscriptionDetails")}</div>
          </div> */}
            <div className="card-body">
              <div className="row g-3 mb-5">
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <div className="card bg-primary-transparent bg-opacity-25 shadow-sm">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <span className="avatar avatar-md bg-white">
                            <i className="ri-shopping-bag-line fs-20 text-primary"></i>
                          </span>
                        </div>
                        <div>
                          <p className="mb-1 text-primary">{t("totalProducts")}</p>
                          <h4 className="mb-0 fw-semibold">{totalUsage.totalProducts}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <div className="card bg-success-transparent bg-opacity-25 shadow-sm">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <span className="avatar avatar-md bg-white">
                            <i className="ri-scan-line fs-20 text-success"></i>
                          </span>
                        </div>
                        <div>
                          <p className="mb-1 text-success">{t("totalScanning")}</p>
                          <h4 className="mb-0 fw-semibold">{totalUsage.totalScanning}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <div className="card bg-warning-transparent bg-opacity-25 shadow-sm">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <span className="avatar avatar-md bg-white">
                            <i className="ri-user-star-line fs-20 text-warning"></i>
                          </span>
                        </div>
                        <div>
                          <p className="mb-1 text-warning">{t("totalSellers")}</p>
                          <h4 className="mb-0 fw-semibold">{totalUsage.totalSellers}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-xl-3">
                  <div className="card bg-info-transparent bg-opacity-25 shadow-sm">
                    <div className="card-body p-4">
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <span className="avatar avatar-md bg-white">
                            <i className="ri-user-line fs-20 text-info"></i>
                          </span>
                        </div>
                        <div>
                          <p className="mb-1 text-info">{t("totalUsers")}</p>
                          <h4 className="mb-0 fw-semibold">{totalUsage.totalUsers}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h6 className="mb-0 text-default">{t("activePackages")}</h6>
                  <button
                    className="btn btn-success btn-sm"
                    onClick={(e) => {
                      dispatch(clearCreatePackagesState());
                      navigate(getRouteByKey("MAIN_PACKAGES"))
                    }}
                  >
                    <i className="ri-add-line me-1"></i>
                    {t("addPackage")}
                  </button>
                </div>
                <div className="table-responsive border rounded">
                  <table className="table table-hover text-nowrap mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th>{t("packageName")}</th>
                        <th>{t("products")}</th>
                        <th>{t("scanning")}</th>
                        <th>{t("sellers")}</th>
                        <th>{t("users")}</th>
                        <th>{t("features")}</th>
                        <th>{t("validity")}</th>
                        <th>{t("price")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerPackageInfo?.customerPackages?.map((pkg, index) => (
                        <tr key={`package_${index}`}>
                          <td>{pkg.planName}</td>
                          <td>{pkg.productCount}</td>
                          <td>{pkg.scanningCount}</td>
                          <td>{pkg.sellerCount}</td>
                          <td>{pkg.userCount}</td>
                          <td>
                            <div className="d-flex flex-column gap-1">
                              {pkg.isFBA && <span className="badge bg-success">{t("fbaOption")}</span>}
                              {pkg.isWarehouse && <span className="badge bg-success">{t("warehouseOption")}</span>}
                              {pkg.isDropAmazon2Amazon && <span className="badge bg-success">{t("dropAmazonToAmazon")}</span>}
                              {pkg.isSmartPricer && <span className="badge bg-success">{t("smartPricer")}</span>}
                            </div>
                          </td>
                          <td>
                            {moment.utc(pkg.startDate, "YYYY/MM/DD").format("DD/MM/YYYY")} -  {moment.utc(pkg.endDate, "YYYY/MM/DD").format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {pkg.discount > 0 ? (
                              <>
                                <span className="text-success fw-bold">
                                  ${Number(pkg.price - (pkg.price * pkg.discount) / 100).toFixed(0)}
                                </span>
                                <span className="text-muted text-decoration-line-through ms-2">
                                  ${Number(pkg.price).toFixed(0)}
                                </span>
                              </>
                            ) : (
                              <span>${Number(pkg.price).toFixed(0)}</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerPackagesPage;
