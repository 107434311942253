import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getBestSellingProductsSuccess, getInventoryProductCategoriesSuccess, getInventoryProductStatusCountsSuccess, getReportSuccess } from '../actions/reports.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetBestSellingProducts(action) {
    const payload = action.payload;

    var path = `/GetBestSellingProducts?CustomerMainSellerId=${payload.customerMainSellerId}&Take=${payload.take}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getBestSellingProductsSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetCategories(action) {
    const payload = action.payload;
    var path = `/GetCategories?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getInventoryProductCategoriesSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetProductStatusCount(action) {
    const payload = action.payload;
    var path = `/ProductStatusCount?CustomerMainSellerId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getInventoryProductStatusCountsSuccess(response.data)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSalesGeneralReport(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/SalesGeneralReport`, payload, false, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getReportSuccess(response))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSalesReport(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/SalesReport`, payload, false, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getReportSuccess(response))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSummarySalesReport(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/SummarySalesReport`, payload, false, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getReportSuccess(response))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runProfitReport(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/ProfitReport`, payload, false, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getReportSuccess(response))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeliveryReport(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/DeliveryReport`, payload, false, CONTROLLERS.REPORTS, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getReportSuccess(response))
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* reportsSaga() {
    yield all([
        takeLatest(types.GET_BEST_SELLING_PRODUCTS_REQUESTED, safe(runGetBestSellingProducts)),
        takeLatest(types.GET_INVENTORY_PRODUCT_CATEGORIES_REQUESTED, safe(runGetCategories)),
        takeLatest(types.GET_INVENTORY_PRODUCT_STATUS_COUNTS_REQUESTED, safe(runGetProductStatusCount)),

        takeLatest(types.GET_SALES_GENERAL_REPORT_REQUESTED, safe(runSalesGeneralReport)),
        takeLatest(types.GET_SALES_REPORT_REQUESTED, safe(runSalesReport)),
        takeLatest(types.GET_SALES_SUMMARY_REPORT_REQUESTED, safe(runSummarySalesReport)),
        takeLatest(types.GET_PROFIT_REPORT_REQUESTED, safe(runProfitReport)),
        takeLatest(types.GET_DELIVERY_REPORT_REQUESTED, safe(runDeliveryReport)),
    ]);
}