import moment from 'moment';
import types from '../actions/settings.actions';

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLEAR_SETTINGS:
            return { ...initialState };
        case types.SET_LANGUAGE:
            return { ...state, language: action.payload };
        case types.SET_THEME:
            return { ...state, themeColor: action.payload };
        case types.SET_LAST_VISITED_MARKET:
            return { ...state, lastVisitedMarketId: action.payload };
        case types.SET_LAST_VISITED_PAGE:
            return { ...state, lastVisitedPage: action.payload };
        case types.SET_SELECTED_STORE_CURRENCY_CODE_COEFFICIENT:
            return {
                ...state, "selectedStoreCurrencyType": {
                    ...state.selectedStoreCurrencyType,
                    ...action.payload
                }
            };
        case types.SET_SEARCH_TASK_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "reprizerPageSelections": {
                        ...state.pageSelections.reprizerPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_ORDER_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "ordersPageSelections": {
                        ...state.pageSelections.ordersPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_HOME_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "homePageSelections": {
                        ...state.pageSelections.homePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_WAREHOUSE_ORDER_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "warehouseOrdersPageSelections": {
                        ...state.pageSelections.warehouseOrdersPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_WAREHOUSE_HOME_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "warehouseHomePageSelections": {
                        ...state.pageSelections.warehouseHomePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_MY_STORE_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "myStorePageSelections": {
                        ...state.pageSelections.myStorePageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_MY_WALLET_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "walletPageSelections": {
                        ...state.pageSelections.walletPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_INVENTORY_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "inventoryPageSelections": {
                        ...state.pageSelections.inventoryPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_PRODUCT_DETAIL_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "productDetailPageSelections": {
                        ...state.pageSelections.productDetailPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_NEGATIVE_FEEDBACKS_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "negativeFeedbacksPageSelections": {
                        ...state.pageSelections.negativeFeedbacksPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_FEEDBACK_CAMPAIGN_SENT_MAILS_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "feedbackCampaignSentMailListPageSelections": {
                        ...state.pageSelections.feedbackCampaignSentMailListPageSelections,
                        ...action.payload
                    }
                }
            };
        case types.SET_ALL_REPORTS_SALES_GENERAL_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "allReportsPageSelections": {
                        ...state.pageSelections.allReportsPageSelections,
                        "salesGeneralReport": {
                            ...state.pageSelections.allReportsPageSelections.salesGeneralReport,
                            ...action.payload
                        }

                    }
                }
            };
        case types.SET_ALL_REPORTS_SALES_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "allReportsPageSelections": {
                        ...state.pageSelections.allReportsPageSelections,
                        "salesReport": {
                            ...state.pageSelections.allReportsPageSelections.salesReport,
                            ...action.payload
                        }

                    }
                }
            };
        case types.SET_ALL_REPORTS_SUMMARY_SALES_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "allReportsPageSelections": {
                        ...state.pageSelections.allReportsPageSelections,
                        "summarySalesReport": {
                            ...state.pageSelections.allReportsPageSelections.summarySalesReport,
                            ...action.payload
                        }

                    }
                }
            };
        case types.SET_ALL_REPORTS_PROFIT_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "allReportsPageSelections": {
                        ...state.pageSelections.allReportsPageSelections,
                        "profitReport": {
                            ...state.pageSelections.allReportsPageSelections.profitReport,
                            ...action.payload
                        }

                    }
                }
            };
        case types.SET_ALL_REPORTS_DELIVERY_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...state.pageSelections,
                    "allReportsPageSelections": {
                        ...state.pageSelections.allReportsPageSelections,
                        "deliveryReport": {
                            ...state.pageSelections.allReportsPageSelections.deliveryReport,
                            ...action.payload
                        }

                    }
                }
            };
        case types.CLEAR_PAGE_SELECTIONS:
            return {
                ...state, "pageSelections": {
                    ...initialState.pageSelections
                }
            };
        default:
            return state;
    }
};

export default reducer;

const initialState = {
    language: "tr",
    themeColor: "light",
    lastVisitedMarketId: "6fdac382-b16f-4705-b4c3-1f1c9b4505f4",
    lastVisitedPage: "/home",
    selectedStoreCurrencyType: {
        currencyCode: "USD",
        currencyCoefficient: 1
    },
    userSelectedCurrencyType: {
        currencyCode: "USD",
        currencyCoefficient: 1
    },
    pageSelections: {
        homePageSelections: {
            dateInterval: [
                // "2024-01-01T00:00:00Z",
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            graphicType: 0
        },
        ordersPageSelections: {
            orderStatus: 18,
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            tableSkip: 0,
            tableLength: 20,
            pagelength: 20,
            sortingInfo: ["6", "purchaseDate", "desc"]
        },
        warehouseOrdersPageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            orderStatus: 13,
            marketOption: null,
            tableSkip: 0,
            tableLength: 20,
            pagelength: 20,
            sortingInfo: ["8", "updatedDate", "desc"]
        },
        warehouseHomePageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            marketOption: null
        },
        reprizerPageSelections: {
            taskStatus: "0",
            taskType: "0",
            tableSkip: 0,
            tableLength: 5,
            pagelength: 5,
            sortingInfo: ["4", "createdDate", "desc"]
        },
        myStorePageSelections: {
            tabId: "mystoreTab",
            warehouseMarketOption: "",
            pricingMarketOption: ""
        },
        walletPageSelections: {
            dateInterval: [
                moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                null
            ],
            transactionSummaryTypeOption: "0"
        },
        inventoryPageSelections: {
            inventoryStatus: 4,
            tableSkip: 0,
            tableLength: 20,
            detailedSearchShownAccordions: {
                panelSalesMarket: null,
                panelSourceMarket: null,
                panelCommon: null
            },
            pagelength: 20,
            sortingInfo: ["7", "updatedDate", "desc"]

        },
        productDetailPageSelections: {
            productDetailShownAccordions: {
                panelDBSource: null,
                panelKeepaSource: null
            },
        },
        negativeFeedbacksPageSelections: {
            tableSkip: 0,
            tableLength: 5,
            pagelength: 5,
            sortingInfo: ["5", "date", "desc"]
        },
        feedbackCampaignSentMailListPageSelections: {
            tableSkip: 0,
            tableLength: 5,
            pagelength: 5,
            sortingInfo: ["6", "createdDate", "desc"]
        },
        allReportsPageSelections: {
            salesGeneralReport: {
                dateInterval: [
                    moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                    null
                ],
                tableSkip: 0,
                tableLength: 5,
                pagelength: 5,
                selectedColumns: []
            },
            salesReport: {
                dateInterval: [
                    moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                    null
                ],
                tableSkip: 0,
                tableLength: 5,
                pagelength: 5,
                selectedColumns: []
            },
            summarySalesReport: {
                dateInterval: [
                    moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                    null
                ],
                tableSkip: 0,
                tableLength: 5,
                pagelength: 5,
                selectedColumns: []
            },
            profitReport: {
                dateInterval: [
                    moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                    null
                ],
                tableSkip: 0,
                tableLength: 5,
                pagelength: 5,
                selectedColumns: []
            },
            deliveryReport: {
                dateInterval: [
                    moment.utc(moment.utc(), "YYYY/MM/DD").startOf('day').format("YYYY/MM/DD"),
                    null
                ],
                tableSkip: 0,
                tableLength: 5,
                pagelength: 5,
                selectedColumns: []
            }
        }
    },
};