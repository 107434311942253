import types from "../actions/warehouses.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_WAREHOUSES_SUCCESS:
            return {
                ...state, "allWarehouses": action.payload
            }
        case types.SET_CUSTOMER_WAREHOUSES:
            return {
                ...state, "customerWarehouses": action.payload
            };
        case types.SET_DEFAULT_WAREHOUSES:
            return {
                ...state, "defaultWarehouses": action.payload
            };
        case types.GET_SHIPPINGS_SUCCESS:
            return {
                ...state, "allShippings": action.payload
            }
        case types.GET_WAREHOUSE_SHIPPINGS_SUCCESS:
            return {
                ...state, "allWarehouseShippings": action.payload
            }
        case types.GET_WAREHOUSES_EXCEL_SUCCESS:
            return {
                ...state, "warehousesExcelList": action.payload
            }
        case types.GET_WAREHOUSES_EXCEL_FAILED:
            return {
                ...state, "warehousesExcelList": []
            }
        case types.GET_WAREHOUSE_LOST_PACKAGES_SUCCESS:
            return {
                ...state, "warehouseLostPackages": action.payload
            }
        case types.GET_WAREHOUSE_HOME_PAGE_DATA_SUCCESS:
            return {
                ...state, "warehouseHomePageData": action.payload
            }
        case types.GET_WAREHOUSE_ORDERS_INVOICES_SUCCESS:
            return {
                ...state, "warehouseOrdersInvoices": action.payload
            }
        case types.GET_WAREHOUSE_ORDER_INVOICE_BY_ID_SUCCESS:
            return {
                ...state, "warehouseOrderInvoiceById": action.payload
            }
        case types.GET_WAREHOUSE_ORDER_MARKETS_SUCCESS:
            return {
                ...state, "wareHouseOrdersMarkets": action.payload
            }
        case types.CLEAR_WAREHOUSES:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "customerWarehouses": [],
    "defaultWarehouses": [],
    "allWarehouses": [],
    "allWarehouseShippings": [],
    "allShippings": [],
    "warehousesExcelList": [],
    "warehouseLostPackages": [],
    "warehouseHomePageData": null,
    "warehouseOrdersInvoices": [],
    "warehouseOrderInvoiceById": null,
    "wareHouseOrdersMarkets": []
}

export default reducer;