import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import './chart.scss'
import moment from "moment";
import DrCustomChartTooltip from "./dr-custom-chart-tooltip.component";
import { render as RenderHtml } from '@react-email/render';

function DrColumnChart({ chartData, showLessOnxAxis = false }) {
    const [newChartData, setNewChartData] = useState(null);

    useEffect(() => {
        // console.log("chartData",chartData)
        let newChartData = Object.assign({}, chartData);
        // console.log("newChartData",newChartData)
        if (showLessOnxAxis) {

            let categories = Object.assign([], newChartData?.data?.map((x, index) => {
                return (

                    index % (newChartData.data?.length <= 31 ? 2 : (newChartData.data?.length > 31 && newChartData.data?.length <= 90 ? 5 : 13)) === 0 ? ((x.xDataType.toLowerCase() === "datetime" ? moment.utc(x.xData).format('DD.MM.YYYY') : x.xData) + "|true") : ((x.xDataType.toLowerCase() === "datetime" ? moment.utc(x.xData).format('DD.MM.YYYY') : x.xData) + "|false")

                )
            }))

            let series = Object.assign([], newChartData?.data?.map(x => {
                return x.yDataType.toLowerCase() === "datetime" ? moment.utc(x.yData).format('DD.MM.YYYY') : (x.yDataType.toLowerCase() === "int32" ? parseInt(x.yData) : Number(x.yData).toFixed(1))

            }))



            newChartData.categories = categories;
            newChartData.series = series;
            // console.log("latest newChartData", newChartData);
            setNewChartData(newChartData);

        } else {
            let categories = Object.assign([], newChartData?.data?.map((x, index) => {
                return (
                    x.xDataType.toLowerCase() === "datetime" ? moment.utc(x.xData).format('DD.MM.YYYY') : x.xData
                )
            }));

            let series = Object.assign([], newChartData.data?.map(x => {
                return x.yDataType.toLowerCase() === "datetime" ? moment.utc(x.yData).format('DD.MM.YYYY') : (x.yDataType.toLowerCase() === "int32" ? parseInt(x.yData) : Number(x.yData).toFixed(1))

            }));

            newChartData.categories = categories;
            newChartData.series = series;
            // console.log("latest newChartData", newChartData);
            setNewChartData(newChartData);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    var options = {
        series: [{
            name: newChartData?.peakText || "",
            data: newChartData?.series || []
        }],
        chart: {
            height: "100%",
            type: 'bar',
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                }
            }
        },
        grid: {
            borderColor: '#f2f5f7',
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val > 0 ? val : "";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#8c9097"]
            }
        },
        colors: chartData?.colors || ['var(--info-color)', 'var(--danger-color)', 'var(--success-color)'],
        xaxis: {
            categories: newChartData?.categories || [],
            // tickPlacement: 'on',
            position: 'bottom',
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            crosshairs: {
                fill: {
                    type: 'gradient',
                    gradient: {
                        colorFrom: '#D8E3F0',
                        colorTo: '#BED1E6',
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    }
                }
            },
            tooltip: {
                enabled: true,
            },
            labels: {
                show: true,
                style: {
                    colors: "#8c9097",
                    fontSize: '11px',
                    fontWeight: 600,
                    cssClass: 'apexcharts-xaxis-label',
                },
                formatter: function (val) {
                    // console.log("val", val);

                    let splittedValues = val.toString().split('|');
                    // console.log(splittedValues);
                    if (splittedValues?.length > 0) {

                        if (splittedValues.length > 1) {
                            if (splittedValues[1] === "true") {
                                return splittedValues[0];
                            }
                            else {
                                return "";
                            }
                        }
                        else {
                            return splittedValues[0];
                        }

                    }
                    else {
                        return val;
                    }


                }
            }
        },
        tooltip: {
            enabled: true,
            custom({ series, seriesIndex, dataPointIndex, w }) {
                // console.log(dataPointIndex);

                if (newChartData?.categories?.length > 0) {

                    let newTitle = newChartData?.categories[dataPointIndex];
                    let splittedValues = newChartData?.categories[dataPointIndex].toString().split('|');
                    // console.log(splittedValues);
                    if (splittedValues?.length > 0) {
                        newTitle = splittedValues[0];
                    }
                    else {
                        newTitle = newChartData?.categories[dataPointIndex];
                    }

                    return (RenderHtml(<DrCustomChartTooltip title={newTitle} source={[{ label: newChartData.peakText, value: newChartData?.series[dataPointIndex] }]}></DrCustomChartTooltip>))

                }
                else {
                    return ("")
                }

            }
        },
        // tooltip: {
        //     enabled: true,
        //     custom({ series, seriesIndex, dataPointIndex, w }) {
        //         return (
        //             '<div style="width: 100px; height: 50px;">' +
        //             "<span>" +
        //             series[seriesIndex][dataPointIndex] +
        //             "</span>" +
        //             "</div>"
        //         );
        //     }
        // },
        // tooltip: {
        //     custom: function({series, seriesIndex, dataPointIndex, w}) {
        //       var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        //       return '<ul>' +
        //       '<li><b>Price</b>: ' + data.x + '</li>' +
        //       '<li><b>Number</b>: ' + data.y + '</li>' +
        //       '<li><b>Product</b>: \'' + data.product + '\'</li>' +
        //       '<li><b>Info</b>: \'' + data.info + '\'</li>' +
        //       '<li><b>Site</b>: \'' + data.site + '\'</li>' +
        //       '</ul>';
        //     }
        //   },
        yaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                // formatter: function (val) {
                //     return val + "%";
                // }
            }

        },
        title: {
            text: chartData?.title || "",
            floating: true,
            offsetY: 0,
            align: 'center',
            style: {
                color: '#444'
            }
        }
    };


    return (


        <Chart
            options={options}
            series={options.series}
            type="bar"
            height="250px"
            width="100%"
            className="apexcharts-canvas apexcharts7ngldghr apexcharts-theme-light"
        />

    );
}

export default DrColumnChart;

