import classNames from 'classnames';
import './dr-input.component.css'
import { numberOnlyAllowNumberDotComma, numberWithThousandSeperator } from '../../../utils/masks';

function DrInput({
    label,
    name,
    type,
    placeholder,
    value,
    className,
    icon,
    iconPosition,
    image,
    width,
    isValid,
    isInvalid,
    validationText,
    pattern,
    enableThousandSeperator = false,
    min = "0",
    max ,
    step = "1",
    ...rest }) {

    const numberValidation = (e) => {
        // const notAllowedInput = ["e", "E", "+"];
        // notAllowedInput.includes(e.key) && e.preventDefault()

        if (!(e.which === 8 || e.which === 46)) {
            if (step.includes('.')) {
                if (!/^[0-9]*\.?[0-9]*$/.test(e.key)) {
                    e.preventDefault();
                }
            }
            else {
                if (!/^[0-9]*?[0-9]*$/.test(e.key)) {
                    e.preventDefault();
                }
            }

        }

    }

    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    var inputClass = classNames({
        'cursor-pointer': className.includes('form-check-input'),
        'form-control-has-icon': icon,
        'form-control-has-icon-left': iconPosition === "left",
        'form-control-has-icon-right': iconPosition === "right",
        'is-invalid': isInvalid,
        'is-valid': isValid
    }, className);


    /*

<div className="input-group mb-3">
                                            <div className="input-group-text">
                                                <span>$</span>
                                            </div>
                                            <input aria-label="Amount (to the nearest dollar)" className="form-control" type="text">
                                            <div className="input-group-text">
                                                <span>.00</span>
                                            </div>
                                        </div>
    */

    var iconClassLeft = classNames({
        'input-group-text px-1': true,
        'hide-icon': !(iconPosition && iconPosition === "left"),
    });

    var iconClassRight = classNames({
        'input-group-text px-1': true,
        'hide-icon': !(iconPosition && iconPosition === "right"),
    });

    const toNumber = (value) => {

        return value.replace(/[^\d]+/g, '');
    }

    const formatNumber = (value) => {
        return new Intl.NumberFormat('en-US').format(toNumber(value))
    }

    function seperator(e) {

        // if (!numberWithThousandSeperator.test(e.target.value)) {
        //     console.log("adadad")
        //     e.preventDefault();
        //     e.target.value = "";
        //     return;
        // }

        if (max && parseFloat(e.target.value) > parseInt(max)) {
            e.target.value = parseInt(max)
        }

        let nums = e.target.value.replace(',', '');
        if (!nums || nums.endsWith('.')) return;
        e.target.value = parseFloat(nums).toLocaleString();
    }

    return (
        <div className={`dr-input-content input-group ${width ? width : ""}`}>
            {icon && <div className={iconClassLeft}>
                <span>
                    {icon}
                </span>
            </div>}
            <input
                id={name}
                type={type}
                name={name}
                // value={value ? value.toLocaleString('en-US', { useGrouping: true }) : value}
                value={value}
                // defaultValue={value ? formatNumber(value) : value}
                className={inputClass}
                placeholder={placeholder}
                // onKeyDown={type === "number" ? (evt) => numberValidation(evt) : rest.onKeyDown}
                onInput={enableThousandSeperator ? (e) => seperator(e) : rest.onInput}
                onKeyDown={enableThousandSeperator ? (e) => numberValidation(e) : rest.onKeyDown}
                {...rest}
                min={min}
                max={max}
            // onChange={enableThousandSeperator ? (e) => toNumber(e.currentTarget.value) : rest.onChange}
            />

            {icon && <div className={iconClassRight}>
                <span>
                    {icon}
                </span>
            </div>}
            {/* {icon && <div className={`input-group-text has-icon ${iconPosition && iconPosition === "left" ? "has-icon-left" : ""}`}>
                <span>
                    {icon}
                </span>
            </div>} */}
            {image &&
                <div className={`has-image`}>
                    {image}
                </div>
            }

            {inputClass.includes('custom-switch-input') &&
                <span className={`custom-switch-indicator ${inputClass.includes('custom-switch-input-gold') ? "custom-switch-indicator-gold" : ""}`} />
            }

            {label &&
                <label className="ms-1 cursor-pointer no-select" htmlFor={name}>{label}</label>}

            {isInvalid && <div type="text" className="invalid-feedback">
                {validationText}
            </div>}
        </div>
    )
}

export default DrInput;
