import { useDispatch, useSelector } from "react-redux";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { setMyStorePageSelections } from "../../../state-management/actions/settings.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { generateRandomUniqueId } from "../../../utils/utils";
import { siteExtensions } from "../../../utils/constants";

function DrConnectAmazonWarning() {
    const { t } = useTranslation('drPackageWarnings');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const pageSelections = useSelector(state => state.settings?.pageSelections?.myStorePageSelections);
    const selectedStore = useSelector(state => state.stores.selectedStore);

    const getRefreshToken = async () => {
        let uniqueId = generateRandomUniqueId(parseInt(50));
        let appId = "amzn1.sp.solution.1b75e99b-e5f6-4070-8ca1-2dc5e45a703b"
        let url = `${siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.url}/apps/authorize/consent?application_id=${appId}&state=${uniqueId}&redirect_uri=${window.location.origin}&version=beta`
        window.location = url;
    }

    return (
        <>
            <div className="alert alert-danger alert-dismissible fade show custom-alert-icon shadow-sm bg-danger bg-opacity-10" role="alert">
                <svg className="svg-danger" xmlns="http://www.w3.org/2000/svg" height="1.5rem" viewBox="0 0 24 24" width="1.5rem" fill="#000000">
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM12 17.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2V7h2v6z"></path></svg>

                <div className="row justify-content-left ms-2 ps-3">
                    <div className="col-auto">
                        <div className="row">
                            <div className="col-auto">
                                <ul className="mb-0 list-unstyled">
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            {t("amazonConnectionProblem")}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-auto">
                                <span className="fw-bold">
                                    {t("followingStepsSuggested")}
                                </span>

                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-auto">
                                <ul className="mb-0 list-unstyled">
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            {t("connectionProblemStep1")}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            {t("connectionProblemStep2")}
                                        </span>
                                    </li>
                                    <li className="d-flex align-items-top mb-2">
                                        <span className="me-2">
                                            <i className="bx bxs-circle fs-9 me-2 op-5 text-danger"></i>
                                        </span>
                                        <span>
                                            {t("connectionProblemStep3")}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-auto">

                                <button type="button" className="btn btn-outline-danger btn-wave waves-effect waves-light"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(withActionPromise(setMyStorePageSelections({
                                            tabId: "mystoreTab",
                                            warehouseMarketOption: pageSelections?.warehouseMarketOption || null,
                                            pricingMarketOption: pageSelections?.pricingMarketOption || null
                                        })));

                                        getRefreshToken();

                                        // navigate("/mystore")
                                    }}>
                                    {t("goToAmazonConnectionPage")}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default DrConnectAmazonWarning
