import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import { notificationMessagesTypes } from '../../utils/constants';
import i18next from 'i18next';
import types, { getMessagingActionsForOrderSuccess, getNotificationAttributesSuccess, getNotificationsByOrderNo, getNotificationsByOrderNoSuccess, getNotificationsSuccess, getSeaboxMessages, getSeaboxMessagesSuccess, getSupportedMessagesListSuccess } from '../actions/notification.actions';
import Swal from 'sweetalert2';

function* runGetNotifications(action) {
    const payload = action.payload;
    var path = `?CustomerMainSellerId=` + payload
    const response = yield call(apiGet(path, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getNotificationsSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error14') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetNotificationByOrderNo(action) {
    const payload = action.payload;
    var path = `/GetAmazonOrderMessage?AmazonOrderNumber=` + payload
    const response = yield call(apiGet(path, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getNotificationsByOrderNoSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetSupportedMessagesList() {

    const response = yield call(apiGet(`/GetSupportedMessageList`, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getSupportedMessagesListSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetMessagingActionsForOrder(action) {
    const payload = action.payload;
    var path = `/GetMessagingActionsForOrder?AmazonOrderNumber=` + payload.orderNo + `&CustomerMainSellerId=` + payload.customerMainSellerId;
    const response = yield call(apiGet(path, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getMessagingActionsForOrderSuccess(response.data));
    }
    else {
        yield all([
            put(getMessagingActionsForOrderSuccess([])),
            put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'))
        ]);
    }
}

function* runGetCreateNegativeFeedbackRemoval(action) {
    const payload = action.payload;
    var path = `/CreateNegativeFeedbackRemoval?AmazonOrderNumber=` + payload.orderNo + `&CustomerMainSellerId=` + payload.customerMainSellerId;
    const response = yield call(apiGet(path, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getNotificationsByOrderNo(payload.orderNo)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetNotificationAttributes(action) {
    const payload = action.payload;
    var path = `/GetAttributes?AmazonOrderNumber=` + payload.orderNo + `&CustomerMainSellerId=` + payload.customerMainSellerId;
    const response = yield call(apiGet(path, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getNotificationAttributesSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSendNotification(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/MailListening`, payload, false, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getNotificationsByOrderNo(payload.orderNumber)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error9') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSendNotificationMessage(action) {
    const payload = action.payload;
    var path = ``;
    if (payload.selectedMessageTypeOption === notificationMessagesTypes.confirmOrderDetails) {
        path = `/CreateConfirmOrderDetails`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.confirmServiceDetails) {
        path = `/CreateConfirmServiceDetails`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.unexpectedProblem) {
        path = `/CreateUnexpectedProblem`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.confirmCustomizationDetails) {
        path = `/ConfirmCustomizationDetails`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.amazonMotors) {
        path = `/CreateAmazonMotors`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.confirmDeliveryDetails) {
        path = `/CreateConfirmDeliveryDetails`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.digitalAccessKey) {
        path = `/CreateDigitalAccessKey`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.legalDisclose) {
        path = `/CreateLegalDisclosure`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.warranty) {
        path = `/CreateWarranty`;
    }
    else if (payload.selectedMessageTypeOption === notificationMessagesTypes.sendInvoice) {
        path = `/SendInvoice`;
    }

    const response = yield call(apiPost(path, payload.notificationRequestData, false, CONTROLLERS.NOTIFICATION, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getNotificationsByOrderNo(payload.notificationRequestData.amazonOrderNumber)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


function* runSendSeaboxMessage(action) {
    const payload = action.payload;

    const response = yield call(apiPost(``, payload.messageRequest, false, CONTROLLERS.MESSAGING, true, true));
    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getSeaboxMessages(payload.messageListRequest)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runGetSeaboxMessages(action) {
    const payload = action.payload;

    var path = `?OrderId=` + payload.orderId + `&Skip=` + payload.skip + `&PageSize=` + payload.pageSize;
    const response = yield call(apiGet(path, CONTROLLERS.MESSAGING, true, true));

    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getSeaboxMessagesSuccess(response)),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}


export default function* notificationSaga() {
    yield all([
        takeLatest(types.GET_NOTIFICATIONS_REQUESTED, safe(runGetNotifications)),
        takeLatest(types.GET_NOTIFICATION_BY_ORDER_NO_REQUESTED, safe(runGetNotificationByOrderNo)),
        takeLatest(types.GET_SUPPORTED_MESSAGES_LIST_REQUESTED, safe(runGetSupportedMessagesList)),
        takeLatest(types.GET_MESSAGING_ACTIONS_LIST_REQUESTED, safe(runGetMessagingActionsForOrder)),
        takeLatest(types.GET_CREATE_NEGATIVE_FEEDBACK_REMOVAL_REQUESTED, safe(runGetCreateNegativeFeedbackRemoval)),
        takeLatest(types.GET_NOTIFICATION_ATTRIBUTES_REQUESTED, safe(runGetNotificationAttributes)),
        takeLatest(types.SEND_NOTIFICATION_REQUESTED, safe(runSendNotification)),
        takeLatest(types.SEND_NOTIFICATION_MESSAGE_REQUESTED, safe(runSendNotificationMessage)),
        takeLatest(types.SEND_SEABOX_MESSAGE_REQUESTED, safe(runSendSeaboxMessage)),
        takeLatest(types.GET_SEABOX_MESSAGES_REQUESTED, safe(runGetSeaboxMessages)),
    ]);
}