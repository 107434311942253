import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrToastr from "../Toastr/dr-toastr.component";
import { DrConnectAmazonWarning, DrPackageWarningLimitExceeds, DrPageLoadingIndicator, DrSubscriptionSuspended } from "../../component-index";
import { useLocation } from "react-router-dom";
import { getRouteByPath } from "../../../routes";
import Restricted from "../../../security/Restricted";
import { Trans, useTranslation } from "react-i18next";

function DrContainer({ children, isExtension = false }) {
    const { t } = useTranslation('drPackageWarnings');
    const isPageLoading = useSelector(state => state.app.showPageLoader) > 0;
    const language = useSelector(state => state.settings.language);
    const customer = useSelector(state => state.customer);
    const customerPackageInfo = useSelector(state => state.customer.customerPackageInfo);
    const location = useLocation();
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const customerStores = useSelector(state => state.stores.customerStores);
    const inventorySummary = useSelector(state => state.inventory.inventorySummary);
    const [showConnectAmazonWarning, setShowConnectAmazonWarning] = useState(false);
    const [showPackageWarningLimitExceeds, setShowPackageWarningLimitExceeds] = useState(false);
    const [showSubscriptionSuspended, setShowSubscriptionSuspended] = useState(false);
    const [currentPageKey, setCurrentPageKey] = useState("");
    const [limitationWarningData, setLimitationWarningData] = useState([]);


    const { REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN,
        REACT_APP_ROLES_ORDERS, REACT_APP_ROLES_INVENTORY, REACT_APP_ROLES_REPORT, REACT_APP_ROLES_LOGISTICS } = process.env;


    useEffect(() => {
        var currentRoute = getRouteByPath(location?.pathname);
        setCurrentPageKey(currentRoute?.name || "");
        // if ((location?.pathname === "/inventory" || location?.pathname === "/addproduct")  && selectedStore?.customerMainSellerId) {
        //     dispatch(withActionPromise(getInventorySummary({ customerMainSellerId: selectedStore?.customerMainSellerId })));
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, location?.pathname]);

    useEffect(() => {
        if (location?.pathname) {

            if (currentPageKey === "mainPackage"
                || currentPageKey === "subFeatures"
                || currentPageKey === "login"
                || currentPageKey === "error"
                || currentPageKey === "payment"

                // || currentPageKey === "marketSelection"
                || currentPageKey === "storePreferences"
                || currentPageKey === "warehouseSelection"
                || currentPageKey === "connectAmazon"
                || currentPageKey === "completeCreateStore"

                || currentPageKey === "smartPricerOptions"
                || currentPageKey === "smartPricerFilters"
                || currentPageKey === "smartPricerTarget"
                || currentPageKey === "smartPricerComplete"
                || currentPageKey === "smartPricerCompleted"
            ) {
                setShowConnectAmazonWarning(false);
                setShowPackageWarningLimitExceeds(false);
                setShowSubscriptionSuspended(false);
            }
            else {
                if (customerPackageInfo) {
                    setShowPackageWarningLimitExceeds(false);
                    setShowSubscriptionSuspended(customer.customerInfo?.role?.includes(REACT_APP_ROLES_SUSPENDED_ADMIN));
                    setShowConnectAmazonWarning(selectedStore?.isRefreshTokenValid === false ? true : false);

                    let warningSource = [];


                    if (currentPageKey === "inventory" || currentPageKey === "addProduct" || currentPageKey === "home") {

                        if ((inventorySummary?.totalProductCount > 0 && inventorySummary?.totalProductCount >= (customerPackageInfo?.totalProductCount || 0))) {

                            warningSource.push({
                                title: t("productLimitTitle"),
                                notes: [
                                    <Trans t={t} i18nKey="packageProducyCountText" values={{ totalPackageProductCount: (customerPackageInfo?.totalProductCount || 0) }} />,
                                    <Trans t={t} i18nKey="inventoryProducyCountText" values={{ totalInventoryProductCount: (inventorySummary?.totalProductCount || 0) }} />
                                ]
                            })
                        }
                    }
                    if (currentPageKey === "subSellers" && customer.subUsers?.length > 0) {
                        if (customer.subUsers?.length >= (customerPackageInfo?.totalUserCount || 0)) {
                            warningSource.push({
                                title: t("subSellerLimitTitle"),
                                notes: [
                                    <Trans t={t} i18nKey="packageSubSellerCountText" values={{ totalPackageUserCount: (customerPackageInfo?.totalUserCount || 0) }} />,
                                    <Trans t={t} i18nKey="currentSubSellerCountText" values={{ totalCurrentUserCount: (customer.subUsers?.length || 0) }} />
                                ]
                            })
                        }
                    }
                    if (currentPageKey === "marketSelection" && customerStores?.length > 0) {

                        if (!((customerPackageInfo?.totalSellerCount || 0) > 0 && (customerStores?.length || 0) < (customerPackageInfo?.totalSellerCount || 0))) {

                            warningSource.push({
                                title: t("marketLimitTitle"),
                                notes: [
                                    <Trans t={t} i18nKey="packageTotalSellerCountText" values={{ totalPackageSellerCount: (customerPackageInfo?.totalSellerCount || 0) }} />,
                                    <Trans t={t} i18nKey="currentTotalSellerCountText" values={{ totalCurrentSellerCount: (customerStores?.length || 0) }} />
                                ]
                            })
                        }
                    }

                    if (warningSource.length > 0) {
                        setShowPackageWarningLimitExceeds(true);
                    }
                    setLimitationWarningData(warningSource);
                }

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageKey, customer?.customerInfo?.role, language, customerPackageInfo, selectedStore]);

    return (

        // <div className={`main-content side-content ${customer.isLoggedin ? "main-content-pt-0" : ""}`} style={{marginBottom:'50px'}}>
        //     <div className="side-app">
        //         <div className="main-container container">
        //             {children}
        //         </div>
        //     </div>
        // </div>
        <>

            <div className={`main-content position-relative mb-5 ${!isExtension ? "app-content" : ""}`}>
                <DrToastr />

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN
                ]}>
                    {showConnectAmazonWarning && <DrConnectAmazonWarning></DrConnectAmazonWarning>}
                </Restricted>

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN,
                    REACT_APP_ROLES_ORDERS,
                    REACT_APP_ROLES_LOGISTICS,
                    REACT_APP_ROLES_INVENTORY,
                    REACT_APP_ROLES_REPORT,
                ]}>
                    {showPackageWarningLimitExceeds && <DrPackageWarningLimitExceeds source={[...limitationWarningData]}></DrPackageWarningLimitExceeds>}
                </Restricted>

                <Restricted allowedTo={[
                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                    REACT_APP_ROLES_ADMIN
                ]}>
                    {showSubscriptionSuspended && <DrSubscriptionSuspended></DrSubscriptionSuspended>}
                </Restricted>
                <div className="container-fluid">
                    <DrPageLoadingIndicator show={isPageLoading} />
                    {children}
                </div>
            </div>
        </>

    );
}

export default DrContainer;

