import { useDispatch } from "react-redux";
import './dr-search-task-products.scss'
import { useTranslation } from "react-i18next";

import { clearActiveSearchTaskProductsModal } from "../../../../state-management/actions/app.actions";
import { useEffect, useState } from "react";
import { generatePdfReport } from "../../../../utils/utils";
import $ from 'jquery';

function DrSearchTaskProducts({ display, source, persistModal = false }) {
    const dispatch = useDispatch();
    const { t } = useTranslation('drSearchTaskProducts');
    // {"Asins":["B0B1X18BXZ"],"CountryCode":"US"}  searchType 1
    //  [{ASIN:"sdsds" , SourceMarketCountryCode : "US", CountryCode:"US",...} , {}]
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (source && source.requestData) {
            var dataArr = [];
            var parsedData = JSON.parse(source.requestData);

            if (source.searchType === 0) {
                parsedData.forEach(element => {
                    dataArr.push({ asin: element.ASIN, sourceMarketCountryCode: element.SourceMarketCountryCode, marketCountryCode: element.MarketCountryCode, wareHouseCountryCode: element.WareHouseCountryCode });
                });
            }
            else if (source.searchType === 1) {
                parsedData.forEach(element => {
                    dataArr.push({ asin: element.ASIN, sourceMarketCountryCode: element.SourceMarketCountryCode, marketCountryCode: element.MarketCountryCode, wareHouseCountryCode: element.WareHouseCountryCode });
                });
            }
            setTableData(dataArr);
        }
        else {
            setTableData([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const data = [{
        "ASIN": "B001BKWXOY",
        "SourceMarketCountryCode": "US",
        "CountryCode": "CA",
        "CustomerMainSellerId": "e1afa174-bde1-48ae-b643-ae0b57164295",
        "ProductMarketId": "be9b2989-2710-4681-adff-9bab944c9bfc",
        "Detail": {
            "Asin": "B0DCRV45VR",
            "Id": "a9e72ddf-7279-466e-ab7b-f0edb078c261",
            "RatingValue": 12,
            "StarsValue": 3.9,
            "StockCount": 999,
            "CustomsDutyPrice": 5.31,
            "CustomsDutyPriceCurrencyCode": "USD",
            "DiscountValue": "",
            "DiscountPercentValue": "",
            "PrimePercentValue": 0.0,
            "PrimePriceValue": 0.0,
            "PrimePriceCurrencyCode": "",
            "SourceMarketDeliveryTime": "3",
            "HasBrandAndSellerNameSameAtSourceMarket": false,
            "HasNoShip": false,
            "SourceMarketShippingPrice": 9.87,
            "SourceMarketShippingPriceCurrencyCode": "USD",
            "HasReturnableProduct": false,
            "HasPriceInCart": false,
            "HasBestSellerProduct": false,
            "QuantityAtSourceMarket": 999,
            "SourceMarketplaceId": "ATVPDKIKX0DER",
            "MarketplaceId": "A2EUQ1WTGCTBG2",
            "SourceMarketSellerName": "FITRAHO"
        }
    }]
    const exportToExcelProductDetail = () => {
        if (source && source.data) {
            var parsedData = JSON.parse(source.data);
            if (source.searchType === 0 || source.searchType === 1) {
                const tableColumn = ["ASIN", "RatingValue", "StarsValue", "StockCount",
                    "SourceMarketShippingPrice", "CustomsDutyPrice", "DiscountValue",
                    "PrimePercentValue", "PrimePriceValue", "CouponPercentValue", "CouponPriceValue", "SourceMarketDeliveryTime",
                    "HasBrandAndSellerNameSameAtSourceMarket", "HasNoShip", "HasReturnableProduct",
                    "HasPriceInCart", "HasBestSellerProduct", "SourceMarketSellerName","HasAsinIsDifferent"
                ];


                // define an empty array of rows
                const tableRows = [];
                // for each ticket pass all its data into an array
                parsedData.forEach(product => {
                    if (product.Detail) {
                        const productData = [
                            product.Detail?.Asin,
                            product.Detail?.RatingValue,
                            product.Detail?.StarsValue,
                            product.Detail?.QuantityAtSourceMarket,
                            product.Detail?.SourceMarketShippingPrice ? (product.Detail?.SourceMarketShippingPriceCurrencyCode + " " + Number(product.Detail?.SourceMarketShippingPrice).toFixed(3)) : "-",
                            product.Detail?.CustomsDutyPrice ? (product.Detail?.CustomsDutyPriceCurrencyCode + " " + Number(product.Detail?.CustomsDutyPrice).toFixed(3)) : "-",
                            product.Detail?.DiscountValue,
                            product.Detail?.PrimePercentValue ? ("%" + Number(product.Detail?.PrimePercentValue).toFixed(3)) : "-",
                            product.Detail?.PrimePriceValue ? (product.Detail?.PrimePriceCurrencyCode + " " + Number(product.Detail?.PrimePriceValue).toFixed(3)) : "-",
                            product.Detail?.CouponPercentValue ? "%" + Number(product.Detail?.CouponPercentValue).toFixed(3) : "-",
                            product.Detail?.CouponPriceValue ? (product.Detail?.CustomsDutyPriceCurrencyCode + " " + Number(product.Detail?.CouponPriceValue).toFixed(3)) : "-",
                            product.Detail?.SourceMarketDeliveryTime,
                            product.Detail?.HasBrandAndSellerNameSameAtSourceMarket,
                            product.Detail?.HasNoShip,
                            product.Detail?.HasReturnableProduct,
                            product.Detail?.HasPriceInCart,
                            product.Detail?.HasBestSellerProduct,
                            product.Detail?.SourceMarketSellerName,
                            product.Detail?.HasAsinIsDifferent
                        ];
                        // push each tickcet's info into a row
                        tableRows.push(productData);
                    }

                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Search Task Product Details", orientation: 'l', format: "a1", reportName: "products_details" });
            }
        }
    }
    return (
        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="searchTaskModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveSearchTaskProductsModal());
                }
            }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header" style={{ minHeight: '48px' }}>
                        <h6 className="modal-title" id="exampleModalLongTitle">{t('title')}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveSearchTaskProductsModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        {display &&
                            <div id="searchTaskProducts" className="table-responsive border p-0">

                                <table className="table mg-b-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">{t('datatable.asin')}</th>
                                            <th className="text-center">{t('datatable.countryCode')}</th>
                                            <th className="text-center">{t('datatable.warehouseCode')}</th>
                                            <th className="text-center">{t('datatable.sourceCountryCode')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData && tableData.map((item, index) => {
                                            return (
                                                <tr key={"search-task-products_" + (index + 1)} style={{ backgroundColor: "var(--default-border) !important" }}>
                                                    <td className="text-start" width={"25%"}>{item.asin || item.ASIN}</td>
                                                    <td className="text-center" width={"25%"}> {item.marketCountryCode}</td>
                                                    <td className="text-center" width={"25%"}> {item.wareHouseCountryCode}</td>
                                                    <td className="text-center" width={"25%"}> {item.sourceMarketCountryCode}</td>
                                                </tr>
                                            )
                                        }
                                        )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <button type="button" className={`btn btn-success btn-wave waves-effect waves-light shadow ${source?.data ? "" : "disabled"}`} data-dismiss="modal"
                                    onClick={() => {
                                        exportToExcelProductDetail();
                                    }}>
                                    <i className="me-2 fe fe-download"></i>
                                    {t('Pdf')}
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => dispatch(clearActiveSearchTaskProductsModal())}>{t('close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DrSearchTaskProducts