
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { buttonDropdownDirection, buttonTypes, buttonVariants, sweetAlertVariants } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import './dr-button-dropdown.component.scss'
import DrSweetAlertButton from '../SweetAlertButton/dr-sweetalert-button.component';

function DrButtonDropdown({ buttonDropdownKey, fixedTextKey, field, form, options, optionsMore, variant = buttonVariants.primaryGhost, optionClickCallBack, selectedValue, direction = buttonDropdownDirection.vertical, selectedVariant = buttonVariants.primary, ...rest }) {
    const { t } = useTranslation('constants');
    const [btnText, setBtnText] = useState((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || (options && options?.length > 0 ? options[0].label : ""));
    const [selectedOption, setSelectedOption] = useState(options && options?.length > 0 ? ((selectedValue && selectedValue !== "") ? options?.find(x => x.value === selectedValue) : options[0]) : null);
    const settings = useSelector(state => state.settings);


    if (rest.disabled && rest.disabled === "") {
        delete rest.disabled;
    }

    // useEffect(() => {
    //     if (selectedValue && options && options.length > 0) {
    //         var option = options.find(x => x.value === selectedValue);
    //         setSelectedOption(option);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedValue]);

    useEffect(() => {
        if (selectedValue && options && options?.length > 0) {
            var option = options?.find(x => x.value === selectedValue);
            if (option) {
                setSelectedOption(option);
                if (form && field) {
                    form.setFieldValue(field.name, option.value)
                }
                setBtnText((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || option.label);
            }
            else {
                setBtnText((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || btnText);
            }
        }
        // else if (!selectedValue && options && options?.length > 0) {
        //     setSelectedOption(options[0]);
        //     setBtnText((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || options[0].label);
        // }
        else {
            setBtnText((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || btnText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings?.language, options, selectedValue]);

    useEffect(() => {
        if (selectedOption) {
            setBtnText((fixedTextKey && t(("buttonDropdownComponent." + fixedTextKey))) || (options && options?.find(x => x.value === selectedOption.value)?.label));
            // if (optionClickCallBack) {
            //     optionClickCallBack(selectedOption.value, selectedOption.label);
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    var btnClass = classNames({
        'btn': true,
        "btn-wave": true,
        "waves-effect": true,
        "waves-light": true,
        [`${variant}`]: direction === buttonDropdownDirection.vertical,
        'dropdown-toggle': direction === buttonDropdownDirection.vertical,
        'p-1': direction === buttonDropdownDirection.horizontal && !options?.find(x => x.label?.length > 0),
    }, rest.className);

    var btnClassMore = classNames({
        'btn': true,
        "btn-wave": true,
        "waves-effect": true,
        "waves-light": true,
        [`${variant}`]: true,
        'dropdown-toggle': true,
    }, rest.className);

    return (
        <>
            {direction === buttonDropdownDirection.vertical &&
                <>
                    <div className="btn-group cursor-pointer">
                        <button key={"dropButton_" + buttonDropdownKey} {...rest} type="button" className={btnClass}
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {(!fixedTextKey && selectedOption?.icon) && selectedOption?.icon}
                            {btnText}
                        </button>
                        <ul key={"dropOptions_" + buttonDropdownKey} className={`dropdown-menu ${options?.find(x => x.label?.length > 0) ? "" : "no-min-width"}`}>
                            {/* <li>
                                <hr className="dropdown-divider"></hr>
                            </li> */}
                            {options && options?.length > 0 && options?.map((option, index) => {
                                return (
                                    <>
                                        {!option.isCritical &&
                                            <li key={(index + 1)}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // setBtnText(option.label);
                                                    setSelectedOption(option);
                                                    if (optionClickCallBack) {
                                                        optionClickCallBack(option.value);
                                                    }
                                                }}>
                                                <div className={`dropdown-item no-wrap ${selectedValue === option.value ? "active" : ""}`}>
                                                    {option.icon && option.icon}
                                                    {option.label?.length > 0 &&
                                                        <span className={`no-select ${option.isCritical ? "text-danger" : ""} ${option.icon ? "ms-2" : ""}`}>
                                                            {option.label}
                                                        </span>}
                                                </div>
                                            </li>
                                        }
                                        {option.isCritical &&
                                            <li key={(index + 1)}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    // setBtnText(option.label);

                                                }}>

                                                <div className={`dropdown-item no-wrap ${selectedValue === option.value ? "active" : ""}`}>
                                                    {option.icon && option.icon}
                                                    {/* {option.label?.length > 0 &&
                                                        <span className={`no-select ${option.isCritical ? "text-danger" : ""} ${option.icon ? "ms-2" : ""}`}>
                                                            {option.label}
                                                        </span>} */}

                                                    <DrSweetAlertButton
                                                        className={`align-items-center cursor-pointer text-danger`}
                                                        buttonText={option.label}
                                                        buttonIcon={option.icon}
                                                        // buttonType={buttonTypes.button}
                                                        // buttonVariant={buttonVariants.light}
                                                        onCloseConfirmCallBack={(value) => {
                                                            if (value) {
                                                                setSelectedOption(option);
                                                                if (optionClickCallBack) {
                                                                    optionClickCallBack(option.value);
                                                                }
                                                            }
                                                        }}
                                                        sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                                                    >
                                                    </DrSweetAlertButton>
                                                </div>
                                            </li>
                                        }
                                    </>

                                )
                            })}
                        </ul>
                    </div>
                </>
            }
            {direction === buttonDropdownDirection.horizontal &&
                <div className="btn-list">
                    {options && options?.length > 0 && options.filter(e => !optionsMore?.find(x => x.value === e.value))?.map((option, index) => {

                        return (


                            <button key={"btn_" + (index + 1)}
                                // className={btnClassHorizontal}
                                className={`${btnClass} ${selectedValue === option.value ? (selectedVariant + (option.label?.length > 0 ? "" : " pulse pulse-success")) : variant}`}
                                value={option.value}
                                onClick={(e) => {
                                    e.preventDefault();
                                    var selected = options?.find(x => x.value.toString() === e.currentTarget.value.toString());
                                    setSelectedOption(selected);
                                    // if (selected && form && field) {
                                    //     form.setFieldValue(field.name, selected.value)
                                    // }
                                    if (optionClickCallBack) {
                                        optionClickCallBack(selected?.value, selected?.label);
                                    }

                                }}
                                onBlur={field?.onBlur}
                            >
                                {option.icon && option.icon}
                                {option.label}
                            </button>




                        )
                    })}
                    {optionsMore && optionsMore?.length > 0 &&
                        <div className="btn-group cursor-pointer m-0">
                            <button key={"dropButton_" + buttonDropdownKey} {...rest} type="button" className={`${btnClassMore} ${optionsMore.find(x => x.value === selectedValue) ? selectedVariant : variant}`}
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {selectedOption?.icon && selectedOption?.icon}
                                More Options
                            </button>
                            <ul key={"dropOptions_" + buttonDropdownKey} className="dropdown-menu">
                                {/* <li>
                                <hr className="dropdown-divider"></hr>
                            </li> */}
                                {optionsMore?.map((option, index) => {
                                    return (
                                        <li key={(index + 1)}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // setBtnText(option.label);
                                                setSelectedOption(option);
                                                if (optionClickCallBack) {
                                                    optionClickCallBack(option.value);
                                                }
                                            }}>
                                            <div className={`dropdown-item no-wrap ${selectedValue === option.value ? "active" : ""}`}>
                                                {option.icon && option.icon}
                                                {option.label?.length > 0 &&
                                                    <span className={`no-select ${option.isCritical ? "text-danger" : ""} ${option.icon ? "ms-2" : ""}`}>
                                                        {option.label}
                                                    </span>}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                    }

                </div>
            }
        </>
    )
}

export default DrButtonDropdown
