export const phoneNumberMask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export const phoneNumberMaskGlobal = [
    /(\s*)?(\+)/,
    /\d{1,1}/,
    /\d{0,1}/,
    /\d{0,1}/,
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
]

// (\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?

export const ssnMask = [
    /[1-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export const cardNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export const amexCardNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export const expirationDateMask = [
    /[0-1]/,
    /\d/,
    "/",
    /\d/,
    /\d/
];

export const threeDigitCVC = [
    /\d/,
    /\d/,
    /\d/
];

export const fourDigitCVC = [
    /\d/,
    /\d/,
    /\d/,
    /\d/
];

export const numberWithThousandSeperator = /^(?:(?=.{1,10}$)\d{1,3}(?:,\d{3})+|(?=.{1,8}$)\d+)$/;

export const numberOnlyAllowNumberDotComma = /^[0-9]*\.?[0-9]*$/;