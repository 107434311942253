import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrButtonDropdown, DrInput, DrSelectBox, DrWarehousePricing } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { clearCreateStoreInformationState } from '../../state-management/actions/create-store.actions';
import { deleteCustomerStore, getCustomerStore, getCustomerStoreFilter, getMarketPricingData, getMarketWarehouseSettings, setLastSelectedStoreMarketId, updateCustomerStore, updateCustomerStoreFilter, updateCustomerStoreSettings, updateMarketPricingData, upsertStoreToken } from '../../state-management/actions/store.actions';
import { handlingTimes, autoDeleteIntervals, currencyConversionTypes, maxDeliveryDays15to30, sourceMarketOptions, filterValueOptions, filterBuyboxOrLowestOptions, flaggedMarketOptions } from '../../components/hoc/Constants/dr-constants.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import { generateRandomUniqueId, getURLParams2 } from '../../utils/utils';
import i18next from 'i18next';
import { siteExtensions } from '../../utils/constants';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { getShippings, getWarehouseShippings, getWarehouses } from '../../state-management/actions/warehouses.actions';
import { setMyStorePageSelections } from '../../state-management/actions/settings.actions';

function MyStorePage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation('storePreferencesPage');
    // const { t } = useTranslation('storeFiltersPage');
    // const { t } = useTranslation('storePricingPage');

    const customerInfo = useSelector(state => state.customer.customerInfo);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const myStore = useSelector(state => state.stores.myStore);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.myStorePageSelections);
    const selectedStoreFilter = useSelector(state => state.stores.selectedStoreFilter);
    const selectedMarketWarehouseSettings = useSelector(state => state.stores.selectedMarketWarehouseSettings);
    const selectedMarketPricingData = useSelector(state => state.stores.selectedMarketPricingData);
    const [warehouseMarketOptions, setWarehouseMarketOptions] = useState(null);
    const allWarehouses = useSelector(state => state.warehouses.allWarehouses);
    const allWarehouseShippings = useSelector(state => state.warehouses.allWarehouseShippings);
    const [warehousesData, setWarehousesData] = useState([]);

    const amazonCode = getURLParams2('spapi_oauth_code', location.search);
    const amazonState = getURLParams2('state', location.search);

    useEffect(() => {
        if (amazonCode?.length > 0 && amazonState?.length > 0) {
            if (!myStore?.isRefreshTokenValid && selectedStore?.customerMainSellerId) {
                dispatch(upsertStoreToken({
                    customerMainSellerId: selectedStore?.customerMainSellerId,
                    code: amazonCode,
                    state: amazonState,
                    redirectUrl: window.location.origin,
                }));
            }
            else if (myStore?.isRefreshTokenValid) {
                window.location = window.location.origin + "/#/mystore";
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amazonCode, amazonState, selectedStore?.isRefreshTokenValid]);

    useEffect(() => {
        dispatch(withActionPromise(getShippings()));
        dispatch(withActionPromise(getWarehouses()));
        dispatch(withActionPromise(getWarehouseShippings({ customerMainSellerId: selectedStore.customerMainSellerId })));

        if (pageSelections?.warehouseMarketOption && pageSelections?.warehouseMarketOption !== "") {
            dispatch(withActionPromise(getMarketWarehouseSettings({
                marketId: pageSelections?.warehouseMarketOption,
                customerMainSellerId: selectedStore?.customerMainSellerId
            })))
        }

        dispatch(withActionPromise(getMarketPricingData({
            marketId: pageSelections?.pricingMarketOption || (warehouseMarketOptions?.length > 0 ? warehouseMarketOptions[0].value : ""),
            customerMainSellerId: selectedStore?.customerMainSellerId
        })))


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allWarehouses) {
            let data = Object.assign([], allWarehouses);
            data.forEach(warehouse => {
                warehouse.warehouseShippings = Object.assign([], allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.shippings);
                warehouse.customerMainSellerId = selectedStore?.customerMainSellerId;
                warehouse.wareHouseSuitId = allWarehouseShippings && allWarehouseShippings.find(x => x.wareHouseId === warehouse.id)?.wareHouseSuitId;
            });
            setWarehousesData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allWarehouses, allWarehouseShippings]);

    useEffect(() => {
        if (warehousesData && warehousesData.length > 0) {
            var selectedWarehousesMarkets = [];
            warehousesData.forEach(warehouse => {
                let warehouseSelected = false;
                if (warehouse.warehouseShippings && warehouse.warehouseShippings.length > 0) {
                    warehouse.warehouseShippings.forEach(shipment => {
                        if (shipment.isSelected) {
                            warehouseSelected = true;
                        }
                    });
                    if (warehouseSelected) {
                        if (!selectedWarehousesMarkets.find(x => x.value === warehouse.marketId)) {
                            selectedWarehousesMarkets = selectedWarehousesMarkets.concat(flaggedMarketOptions.find(x => x.value === warehouse.marketId));
                        }
                    }
                }
            });

            setWarehouseMarketOptions(selectedWarehousesMarkets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehousesData]);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getCustomerStore({
                marketId: selectedStore?.marketId,
                customerMainSellerId: selectedStore?.customerMainSellerId
            }));
            dispatch(getCustomerStoreFilter(selectedStore?.customerMainSellerId));

            dispatch(withActionPromise(setMyStorePageSelections({
                tabId: pageSelections?.tabId,
                warehouseMarketOption: pageSelections?.warehouseMarketOption || (warehouseMarketOptions?.length > 0 ? warehouseMarketOptions[0].value : ""),
                pricingMarketOption: pageSelections?.pricingMarketOption || (warehouseMarketOptions?.length > 0 ? warehouseMarketOptions[0].value : "")
            })));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, warehouseMarketOptions]);

    useEffect(() => {
        if (selectedStore && pageSelections?.pricingMarketOption && pageSelections?.pricingMarketOption !== "") {
            dispatch(withActionPromise(getMarketPricingData({
                marketId: pageSelections?.pricingMarketOption,
                customerMainSellerId: selectedStore?.customerMainSellerId
            })))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.pricingMarketOption]);

    useEffect(() => {
        if (selectedStore && pageSelections?.warehouseMarketOption && pageSelections?.warehouseMarketOption !== "") {
            dispatch(getMarketWarehouseSettings({
                marketId: pageSelections?.warehouseMarketOption,
                customerMainSellerId: selectedStore?.customerMainSellerId
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.warehouseMarketOption]);

    const initialFiltersValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId || "", //magazam
        onlyAmazonProductsValue: selectedStoreFilter?.onlyAmazonProductsValue || 0, //remove/out of stock
        onlySHouseProductsValue: selectedStoreFilter?.onlySHouseProductsValue || 0,//remove/out of stock
        filterTrademarkProductsValue: selectedStoreFilter?.filterTrademarkProductsValue || 0, // only remove option
        checkFromUSPTO: selectedStoreFilter?.checkFromUSPTO || false,
        checkFromCIPO: selectedStoreFilter?.checkFromCIPO || false,
        filterDiscountedProductsValue: selectedStoreFilter?.filterDiscountedProductsValue || 0, //remove/out of stock
        filterNoShipProductsValue: selectedStoreFilter?.filterNoShipProductsValue || 0, //remove/out of stock
        filterMissingShippingProductsValue: selectedStoreFilter?.filterMissingShippingProductsValue || 0, //remove/out of stock
        filterUnAvailableProductsValue: selectedStoreFilter?.filterUnAvailableProductsValue || 0,//only remove option
        filterNoImportFeeProductsValue: selectedStoreFilter?.filterNoImportFeeProductsValue || 0, //remove/out of stock
        filterNoSalesRankProductsValue: selectedStoreFilter?.filterNoSalesRankProductsValue || 0, //remove/out of stock
        filterSameSellerBrandProductsValue: selectedStoreFilter?.filterSameSellerBrandProductsValue || 0, //remove/out of stock
        filterPrimeSellersInStoreProductsValue: selectedStoreFilter?.filterPrimeSellersInStoreProductsValue || 0, //remove/out of stock
        filterByMaxSellersInStoreProductsValue: selectedStoreFilter?.filterByMaxSellersInStoreProductsValue || null, //count
        filterByMinSellersInStoreProductsValue: selectedStoreFilter?.filterByMinSellersInStoreProductsValue || null, //count
        filterHaveCNSellersProductsValue: selectedStoreFilter?.filterHaveCNSellersProductsValue || 0, //remove/out of stock
        filterHaveAmazonSellersProductsValue: selectedStoreFilter?.filterHaveAmazonSellersProductsValue || 0, //remove/out of stock
        filterPrimeExclusiveProductsValue: selectedStoreFilter?.filterPrimeExclusiveProductsValue || 0, //remove/out of stock
        // filterBuyboxOrLowestPriceValue: selectedStoreFilter?.filterBuyboxOrLowestPriceValue || 0, // lowest/buybox
    }

    const initialMyStoreValues = {
        id: myStore?.id,
        customerSubUserId: customerInfo?.customerId,
        marketId: selectedStore?.marketId,
        name: myStore?.name || "",
        companySellerSerialNumber: myStore?.companySellerSerialNumber || "",
        maxStock: myStore?.maxStock || "",
        primePercentage: myStore?.primePercentage || null,
        currencyConversion: myStore?.currencyConversion || 1,
        conversionRate: myStore?.conversionRate || null,
        enableTaxPayer: myStore?.enableTaxPayer || false,
        enableSalesTaxRates: myStore?.enableSalesTaxRates || false,
        aboveSalePrice: myStore?.aboveSalePrice || null,
        aboveSalePriceRate: myStore?.aboveSalePriceRate || null,
        aboveSalePriceOffset: myStore?.aboveSalePriceOffset || null,
        belowSalePrice: myStore?.belowSalePrice || null,
        belowSalePriceRate: myStore?.belowSalePriceRate || null,
        belowSalePriceOffset: myStore?.belowSalePriceOffset || null,
        enableImportFee: myStore?.enableImportFee || false,
        importFeeRate: myStore?.importFeeRate || null,
        enableCommunalPoolForbiddenProducts: myStore?.enableCommunalPoolForbiddenProducts || false,
        enableCommunalPoolForbiddenProductsBrands: myStore?.enableCommunalPoolForbiddenProductsBrands || false,
        enableCommunalPoolBlockedBrands: myStore?.enableCommunalPoolBlockedBrands || false,
        enableCommunalPoolAutoAddBrands: myStore?.enableCommunalPoolAutoAddBrands || false,
        enableCommunalPoolAutoAddProducts: myStore?.enableCommunalPoolAutoAddProducts || false,
        enableHandlingTime: myStore?.enableHandlingTime || false,
        handlingTime: myStore?.handlingTime || null,
        enableMaxDelivery: myStore?.enableMaxDelivery || false,
        maxDeliveryTime: myStore?.maxDeliveryTime || null,
        enableAutoDeleteOutCriteria: myStore?.enableAutoDeleteOutCriteria || false,
        autoDeleteTimePeriods: myStore?.autoDeleteTimePeriods || null,
        enableAutoCarrierChange: myStore?.enableAutoCarrierChange || false,
        presentCardSender: myStore?.presentCardSender || "",
        presentNote: myStore?.presentNote || "",
        enableAutoOrderConfirm: myStore?.enableAutoOrderConfirm || false,
        enableAutoOrderPreConfirm: myStore?.enableAutoOrderPreConfirm || false,
        enableAfterOrderPlaced: myStore?.enableAfterOrderPlaced || false,
        afterOrderPlacedTime: myStore?.afterOrderPlacedTime || null,
        enableAutoPriceUpdate: myStore?.enableAutoPriceUpdate || false,
        enableAutoStockUpdate: myStore?.enableAutoStockUpdate || false,
        enableAutoProductDeletion: myStore?.enableAutoProductDeletion || false,
        enableAutoProductAdd: myStore?.enableAutoProductAdd || false,
        askConfirmationForNewProducts: myStore?.askConfirmationForNewProducts || false,
        code: amazonCode || "",
        state: amazonState || "",
        isRefreshTokenValid: myStore?.isRefreshTokenValid,
        sourceMarketList: myStore?.sourceMarketList?.length > 0 ? sourceMarketOptions.filter(function (o) {
            return myStore?.sourceMarketList.find(r => r.marketId === o.value);
        }) : []
    }

    const handleMyStoreSubmit = async (values) => {
        var selectedSourceMarkets = values.sourceMarketList.map(a => a.value);

        var data = {
            id: values.id,
            customerSubUserId: customerInfo?.customerId,
            marketId: selectedStore?.marketId,
            name: values.name || "",
            companySellerSerialNumber: values.companySellerSerialNumber || "",
            maxStock: values.maxStock || "",
            primePercentage: values.primePercentage || null,
            currencyConversion: values.currencyConversion || 1,
            conversionRate: values.conversionRate || null,
            enableTaxPayer: values.enableTaxPayer || false,
            enableSalesTaxRates: values.enableSalesTaxRates || false,
            aboveSalePrice: values.aboveSalePrice || null,
            aboveSalePriceRate: values.aboveSalePriceRate || null,
            aboveSalePriceOffset: values.aboveSalePriceOffset || null,
            belowSalePrice: values.belowSalePrice || null,
            belowSalePriceRate: values.belowSalePriceRate || null,
            belowSalePriceOffset: values.belowSalePriceOffset || null,
            enableImportFee: values.enableImportFee || false,
            importFeeRate: values.importFeeRate || null,
            enableCommunalPoolForbiddenProducts: values.enableCommunalPoolForbiddenProducts || false,
            enableCommunalPoolForbiddenProductsBrands: values.enableCommunalPoolForbiddenProductsBrands || false,
            enableCommunalPoolBlockedBrands: values.enableCommunalPoolBlockedBrands || false,
            enableCommunalPoolAutoAddBrands: values.enableCommunalPoolAutoAddBrands || false,
            enableCommunalPoolAutoAddProducts: values.enableCommunalPoolAutoAddProducts || false,
            enableHandlingTime: values.enableHandlingTime || false,
            handlingTime: values.handlingTime || null,
            enableMaxDelivery: values.enableMaxDelivery || false,
            maxDeliveryTime: values.maxDeliveryTime || null,
            enableAutoDeleteOutCriteria: values.enableAutoDeleteOutCriteria || false,
            autoDeleteTimePeriods: values.autoDeleteTimePeriods || null,
            enableAutoCarrierChange: values.enableAutoCarrierChange || false,
            presentCardSender: values.presentCardSender || "",
            presentNote: values.presentNote || "",
            enableAutoOrderConfirm: values.enableAutoOrderConfirm || false,
            enableAutoOrderPreConfirm: values.enableAutoOrderPreConfirm || false,
            enableAfterOrderPlaced: values.enableAfterOrderPlaced || false,
            afterOrderPlacedTime: values.afterOrderPlacedTime || null,
            enableAutoPriceUpdate: values.enableAutoPriceUpdate || false,
            enableAutoStockUpdate: values.enableAutoStockUpdate || false,
            enableAutoProductDeletion: values.enableAutoProductDeletion || false,
            enableAutoProductAdd: values.enableAutoProductAdd || false,
            askConfirmationForNewProducts: values.askConfirmationForNewProducts || false,
            code: values.code || "",
            state: values.state || "",
            redirectUrl: "",
            sourceMarkets: []
        }

        data.sourceMarkets.push(...selectedSourceMarkets);

        dispatch(updateCustomerStore(data));
    }

    const handleMyStoreDelete = async (marketId) => {

        dispatch(deleteCustomerStore({
            marketId: marketId,
            customerSubUserId: customerInfo?.customerId
        }));
    }

    const upsertToken = async (code, state) => {
        dispatch(upsertStoreToken({
            customerMainSellerId: selectedStore?.customerMainSellerId,
            code: code,
            state: state,
            redirectUrl: window.location.origin,
        }));
    }

    const getRefreshToken = async () => {
        let uniqueId = generateRandomUniqueId(parseInt(50));
        let appId = "amzn1.sp.solution.1b75e99b-e5f6-4070-8ca1-2dc5e45a703b"
        let url = `${siteExtensions.find(x => x.countryCode === selectedStore?.countryCode)?.url}/apps/authorize/consent?application_id=${appId}&state=${uniqueId}&redirect_uri=${window.location.origin}&version=beta`
        window.location = url;
    }

    const handleFiltersSubmit = async (values) => {
        dispatch(updateCustomerStoreFilter(values));
    }

    const storePreferencesSchema = Yup.object().shape({
        companySellerSerialNumber: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Company Serial number is required'),
        name: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Amazon store name is required'),
        maxStock: Yup.string().required("Maximum stock is required")
            .matches(/^[0-9]+$/, "Must be only digits"),
        primePercentage: Yup.string()
            .required('Amazon percentage required')
            .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),

        currencyConversion: Yup.number().required('Required'),
        conversionRate: Yup.string().nullable().when('currencyConversion', {
            is: (value) => value && value !== 1,
            then: () => Yup.string().required('Currency conversion rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableSalesTaxRates: Yup.bool().required('Required'),
        aboveSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        belowSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableImportFee: Yup.bool().required('Required'),
        importFeeRate: Yup.string().nullable().when('enableImportFee', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Import fee rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableHandlingTime: Yup.bool().required('Required'),
        handlingTime: Yup.string().nullable().when('enableHandlingTime', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Handling Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        enableMaxDelivery: Yup.bool().required('Required'),
        maxDeliveryTime: Yup.string().nullable().when('enableMaxDelivery', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Max Delivery Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        enableAutoDeleteOutCriteria: Yup.bool().required('Required'),
        autoDeleteTimePeriods: Yup.string().nullable().when('enableAutoDeleteOutCriteria', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Delete Time Period is required'),
            otherwise: () => Yup.string().nullable(),
        }),
        // disclaimer: Yup.boolean().oneOf([true], 'You must accept the disclaimer')
        // termsOfService: Yup.boolean()
        // .required("The terms and conditions must be accepted.")
        // .oneOf([true], "The terms and conditions must be accepted.")
    });

    const storePricingSchema = Yup.object().shape({
        costUnitList: Yup.array(
            Yup.object().test(
                "empty-check",
                // The error message that should appears if test failed
                "You need enter Stock value",
                // Function that does the custom validation to this array
                validateInputArea
            )
        )
    });
    function validateInputArea() {

        if (this.originalValue &&
            (this.originalValue.cargo && this.originalValue.cargo !== undefined)
            && (this.originalValue.min && this.originalValue.min !== undefined)
            && (this.originalValue.max && this.originalValue.max !== undefined)
            && (this.originalValue.max2 && this.originalValue.max2 !== undefined)
            && (this.originalValue.standart && this.originalValue.standart !== undefined)
            && (this.originalValue.stock && this.originalValue.stock !== undefined)) {
            return true;
        }

        return false;

    }





    const initialStoreSettingsValues = {
        customerMainSellerID: selectedStore?.customerMainSellerId,
        marketId: selectedMarketWarehouseSettings?.marketId,
        // enableSalesTaxRates: selectedMarketWarehouseSettings?.enableSalesTaxRates || false,
        // aboveSalePrice: selectedMarketWarehouseSettings?.aboveSalePrice || null,
        // aboveSalePriceRate: selectedMarketWarehouseSettings?.aboveSalePriceRate || null,
        // aboveSalePriceOffset: selectedMarketWarehouseSettings?.aboveSalePriceOffset || null,
        // belowSalePrice: selectedMarketWarehouseSettings?.belowSalePrice || null,
        // belowSalePriceRate: selectedMarketWarehouseSettings?.belowSalePriceRate || null,
        // belowSalePriceOffset: selectedMarketWarehouseSettings?.belowSalePriceOffset || null,
        enableCommercialInvoiceOption: selectedMarketWarehouseSettings?.enableCommercialInvoiceOption || false,
        enableOnlyFBAProducts: selectedMarketWarehouseSettings?.enableOnlyFBAProducts || false,
        // enableMaxDelivery: selectedMarketWarehouseSettings?.enableMaxDelivery || false,
        // maxDeliveryTime: selectedMarketWarehouseSettings?.maxDeliveryTime || null,
        enableAlternativeCarrier: selectedMarketWarehouseSettings?.enableAlternativeCarrier || false,
        enableShowProductLabelOnPackage: selectedMarketWarehouseSettings?.enableShowProductLabelOnPackage || false,
        enableShowASINOnPackage: selectedMarketWarehouseSettings?.enableShowASINOnPackage || false,
        // enableHandlingTime: selectedMarketWarehouseSettings?.enableHandlingTime || false,
        // handlingTime: selectedMarketWarehouseSettings?.handlingTime || null,
        enableInsurance: selectedMarketWarehouseSettings?.enableInsurance || false
        // ...selectedMarketWarehouseSettings
    }

    const handleStoreSettingsSubmit = async (values) => {
        dispatch(updateCustomerStoreSettings(values));
    }

    const storeSettingsOptionClickCallBack = (value) => {
        saveMyStorePageSelections(null, value, null);
    };

    const pricingPageOptionClickCallBack = (value) => {
        saveMyStorePageSelections(null, null, value);
    };

    const initialPricingDataValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId,
        marketId: pageSelections?.pricingMarketOption,
        costUnitList: selectedMarketPricingData || []
    }

    const handlePricingDataSubmit = async (values) => {
        dispatch(updateMarketPricingData(values));
    }

    const saveMyStorePageSelections = (tabId, warehouseMarketOption, pricingMarketOption) => {
        dispatch(withActionPromise(setMyStorePageSelections({
            tabId: tabId || pageSelections?.tabId,
            warehouseMarketOption: warehouseMarketOption || pageSelections?.warehouseMarketOption,
            pricingMarketOption: pricingMarketOption || pageSelections?.pricingMarketOption
        })));
    }
    return (

        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t(('constants:breadCrumps.' + pageSelections?.tabId || "mystoreTab")), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="row row-md row-lg justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="card custom-card">

                                <div className="card-body">
                                    <ul className="nav nav-pills mb-3 nav-justified tab-style-2 d-sm-flex d-block" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${!pageSelections?.tabId || (pageSelections?.tabId === "mystoreTab") ? "active" : ""}`}
                                                data-bs-toggle="pill" data-bs-target="#mystoreTab" type="button" role="tab" aria-controls="mystoreTab"
                                                onClick={() => {
                                                    saveMyStorePageSelections("mystoreTab", null, null);
                                                }}>
                                                <i className="fe fe-home ms-1 me-1"></i>
                                                {i18next.t('constants:breadCrumps.mystoreTab')}
                                            </button>

                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${pageSelections?.tabId === "filtersTab" ? "active" : ""}`}
                                                data-bs-toggle="pill" data-bs-target="#filtersTab" type="button" role="tab" aria-controls="filtersTab"
                                                onClick={() => {
                                                    saveMyStorePageSelections("filtersTab", null, null);
                                                }}>
                                                <i className="fe fe-sliders ms-1 me-1"></i>
                                                {i18next.t('constants:breadCrumps.filtersTab')}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${pageSelections?.tabId === "storeSettingsTab" ? "active" : ""}`}
                                                data-bs-toggle="pill" data-bs-target="#storeSettingsTab" type="button" role="tab" aria-controls="storeSettingsTab"
                                                onClick={() => {
                                                    saveMyStorePageSelections("storeSettingsTab", null, null);
                                                }}>
                                                <i className="fe fe-truck ms-1 me-1"></i>
                                                {i18next.t('constants:breadCrumps.storeSettingsTab')}
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className={`nav-link ${pageSelections?.tabId === "pricingTab" ? "active" : ""}`}
                                                data-bs-toggle="pill" data-bs-target="#pricingTab" type="button" role="tab" aria-controls="pricingTab"
                                                onClick={() => {
                                                    saveMyStorePageSelections("pricingTab", null, null);
                                                }}>
                                                <i className="fe fe-trending-up ms-1 me-1"></i>
                                                {i18next.t('constants:breadCrumps.pricingTab')}
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className={`tab-pane bg-primary bg-opacity-05 ${pageSelections?.tabId === "mystoreTab" ? "active" : ""}`} id="mystoreTab">
                                            <Formik
                                                initialValues={initialMyStoreValues}
                                                validateOnBlur={true}
                                                // onSubmit={handleSubmit}
                                                validationSchema={storePreferencesSchema}
                                                enableReinitialize={true}
                                                validateOnMount={true}
                                            >
                                                {({
                                                    isValid,
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setTouched,
                                                    setFieldValue
                                                }) => (
                                                    <form>
                                                        <div className={`row row-md row-lg my-1 px-3 ${(values.isRefreshTokenValid || !selectedStore) ? "justify-content-end " : "justify-content-between "}`} >
                                                            {selectedStore && !values.isRefreshTokenValid && (!values.code || values.code?.length === 0) &&
                                                                <div className="col-auto col-xl-10">
                                                                    <div className="row bg-primary text-white p-3 rounded border m-0">
                                                                        <div className="col-auto">
                                                                            <label className="m-0" role="alert">
                                                                                Please
                                                                                <label className="cursor-pointer m-0">
                                                                                    <img htmlFor="lwa" border="0" alt="Login with Amazon"
                                                                                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                                                                                        width="156" height="32" />
                                                                                    <DrInput id="lwa" value="0"
                                                                                        className="custom-control-input"
                                                                                        placeholder="" name="lwa" type="button" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            getRefreshToken(values);
                                                                                        }
                                                                                        } />
                                                                                </label>
                                                                                and allow SellerDoping and press <strong>Confirm</strong> to continue
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {selectedStore && !values.isRefreshTokenValid && (values.code && values.code.length > 0) && false &&
                                                                <div className="col-auto col-xl-10">
                                                                    <label className="w-100 alert alert-success border m-0" role="alert">
                                                                        <div className="row">
                                                                            <div className="col-9">
                                                                                <div className="form-group row m-0">
                                                                                    <h6 className="my-auto me-2">Code</h6>
                                                                                    <DrInput value={values.code} className={`form-control text-muted`} disabled="disabled" placeholder="Code" name="code" type="text"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'code': true }) }} width={"w-90"}
                                                                                        isInvalid={(touched.code && errors.code)}
                                                                                        isValid={touched.code && !errors.code}
                                                                                        validationText={errors.code}
                                                                                    />

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-3">
                                                                                <button className="btn btn-primary btn-wave waves-effect waves-light shadow"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        upsertToken(values.code, values.state);
                                                                                    }}
                                                                                >{t('saveToken')}</button>
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            }
                                                            <div className="col-auto my-auto">
                                                                <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dispatch(withActionPromise(setLastSelectedStoreMarketId(selectedStore?.marketId)));
                                                                        dispatch(withActionPromise(clearCreateStoreInformationState()));
                                                                        navigate(getRouteByKey("MARKET_SELECTION"));
                                                                    }}>
                                                                    <i className="me-2 fe fe-plus"></i>
                                                                    {t('addNew')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {myStore && selectedStore &&
                                                            <section>
                                                                {/* <form> */}
                                                                <div className="row row-md row-lg px-3">
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('merchantToken')}</label>
                                                                        <DrInput value={values.companySellerSerialNumber} disabled="disabled" className="form-control text-muted" placeholder={t('merchantToken')} name="companySellerSerialNumber" type="text" />
                                                                        <small className="custom-switch-description">{t('merchantTokenDescription')}</small>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('amazonStoreName')}</label>
                                                                        <DrInput value={values.name} className="form-control text-muted" disabled="disabled" placeholder={t('amazonStoreName')} name="name" type="text" />
                                                                        <small className="custom-switch-description">{t('amazonStoreNameDescription')}</small>
                                                                    </div>
                                                                    <div className="col-mg-4 col-lg-4 mt-3">
                                                                        <div className="form-group has-validation">
                                                                            <label>{t('sourceMarkets')}</label>
                                                                            <Field
                                                                                onBlur={() => { setTouched({ ...touched.sourceMarketList, 'sourceMarketList': true }) }}
                                                                                onChange={(e) => {
                                                                                    if (e) {
                                                                                        var result = e.filter(x => x.countryCode === "US").map(a => a.id);


                                                                                        setFieldValue("sourceMarketList", result);
                                                                                    }
                                                                                }}
                                                                                isMulti={true}
                                                                                name={'sourceMarketList'}
                                                                                component={DrSelectBox}
                                                                                placeholder={t('sourceMarketsPlaceholder')}
                                                                                options={sourceMarketOptions} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg px-3">
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('maxStock')}</label>
                                                                        <DrInput value={values.maxStock || ""} className="form-control" placeholder={t('maxStock')} name="maxStock" type="number" onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'maxStock': true }) }}
                                                                            isInvalid={(touched.maxStock && errors.maxStock)}
                                                                            isValid={touched.maxStock && !errors.maxStock}
                                                                            validationText={errors.maxStock}
                                                                            icon={<i className="fe fe-package text-muted"></i>}
                                                                            iconPosition="left"
                                                                        />
                                                                        <small className="custom-switch-description">{t('maxStockDescription')}</small>
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('amazonComission')}</label>
                                                                        <DrInput value={values.primePercentage || ""} className="form-control" placeholder={t('amazonComission')} name="primePercentage" type="number" step="0.1" onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'primePercentage': true }) }}
                                                                            isInvalid={(touched.primePercentage && errors.primePercentage)}
                                                                            isValid={touched.primePercentage && !errors.primePercentage}
                                                                            validationText={errors.primePercentage}
                                                                            icon={<i className="fe fe-percent text-muted"></i>}
                                                                            iconPosition="left"
                                                                        />
                                                                        <small className="custom-switch-description">{t('amazonComissionDescription')}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg px-3">
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('conversion')}</label>
                                                                        <Field
                                                                            onBlur={() => { setTouched({ ...touched.currencyConversion, 'currencyConversion': true, 'conversionRate': true }) }}
                                                                            onChange={(e) => {
                                                                                setFieldValue("currencyConversion", e.value);
                                                                                if (e.value === 1) {
                                                                                    setFieldValue("conversionRate", null);
                                                                                }
                                                                            }}
                                                                            name={'currencyConversion'}
                                                                            component={DrSelectBox}
                                                                            placeholder="Currency Conversion"
                                                                            options={currencyConversionTypes} />
                                                                    </div>
                                                                    <div className="col-md-4 col-lg-4 mt-3">
                                                                        <label>{t('conversionRate')}</label>
                                                                        <DrInput className="form-control" placeholder={t('conversionRate')} name="conversionRate" type="number" step="0.01"
                                                                            value={values.conversionRate || ""}
                                                                            disabled={`${values.currencyConversion === 2 ? "" : "disabled"}`}
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'conversionRate': true }) }}
                                                                            isInvalid={(touched.conversionRate && errors.conversionRate)}
                                                                            isValid={touched.conversionRate && !errors.conversionRate}
                                                                            validationText={errors.conversionRate}
                                                                            icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                                                            iconPosition="left"
                                                                        />

                                                                        <small className="custom-switch-description">{t('conversionRatedescription')}</small>
                                                                    </div>
                                                                </div>
                                                                {/* </form> */}
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="taxPayer" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('taxPayer')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="taxPayer" value="" checked={values.enableTaxPayer} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableTaxPayer" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableTaxPayer': true }) }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableTaxPayer) ? "" : "text-muted"}`}>{t('taxPayerDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="salesTaxRates" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableTaxPercent')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="salesTaxRates" value="" checked={values.enableSalesTaxRates} className="form-check-input form-checked-info" placeholder="" name="enableSalesTaxRates" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableSalesTaxRates': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-chevron-right"></i></label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="aboveSalePrice" type="number" step="0.01"
                                                                                    value={values.aboveSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'aboveSalePrice': true }) }}
                                                                                    icon={<i className="fe fe-package text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.aboveSalePrice && errors.aboveSalePrice)}
                                                                                    isValid={touched.aboveSalePrice && !errors.aboveSalePrice}
                                                                                    validationText={errors.aboveSalePrice}
                                                                                />

                                                                            </div>
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="aboveSalePriceRate" type="number" step="0.01"
                                                                                    value={values.aboveSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'aboveSalePriceRate': true }) }}
                                                                                    icon={<i className="fe fe-percent text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.aboveSalePriceRate && errors.aboveSalePriceRate)}
                                                                                    isValid={touched.aboveSalePriceRate && !errors.aboveSalePriceRate}
                                                                                    validationText={errors.aboveSalePriceRate}
                                                                                />

                                                                            </div>
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">
                                                                                    <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="aboveSalePriceOffset" type="number" step="0.01"
                                                                                    value={values.aboveSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'aboveSalePriceOffset': true }) }}
                                                                                    icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.aboveSalePriceOffset && errors.aboveSalePriceOffset)}
                                                                                    isValid={touched.aboveSalePriceOffset && !errors.aboveSalePriceOffset}
                                                                                    validationText={errors.aboveSalePriceOffset}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="smaller than" data-placement="bottom" className="fe fe-chevron-left"></i></label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="belowSalePrice" type="number" step="0.01"
                                                                                    value={values.belowSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'belowSalePrice': true }) }}
                                                                                    icon={<i className="fe fe-package text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.belowSalePrice && errors.belowSalePrice)}
                                                                                    isValid={touched.belowSalePrice && !errors.belowSalePrice}
                                                                                    validationText={errors.belowSalePrice}
                                                                                />

                                                                            </div>
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="belowSalePriceRate" type="number" step="0.01"
                                                                                    value={values.belowSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'belowSalePriceRate': true }) }}
                                                                                    icon={<i className="fe fe-percent text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.belowSalePriceRate && errors.belowSalePriceRate)}
                                                                                    isValid={touched.belowSalePriceRate && !errors.belowSalePriceRate}
                                                                                    validationText={errors.belowSalePriceRate}
                                                                                />

                                                                            </div>
                                                                            <div className="col-auto pe-0 ps-0">
                                                                                <label className="ms-2 me-2 mt-2">
                                                                                    <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                                                                </label>
                                                                            </div>
                                                                            <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder="" name="belowSalePriceOffset" type="number" step="0.01"
                                                                                    value={values.belowSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'belowSalePriceOffset': true }) }}
                                                                                    icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.belowSalePriceOffset && errors.belowSalePriceOffset)}
                                                                                    isValid={touched.belowSalePriceOffset && !errors.belowSalePriceOffset}
                                                                                    validationText={errors.belowSalePriceOffset}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                                                            <div className="col-sm-12 pe-0 ps-2">
                                                                                <small className={`${(values.enableSalesTaxRates) ? "" : "text-muted"}`}>{t('enableTaxPercentDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="importFee" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableImportFee')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-6">

                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="importFee" value="" checked={values.enableImportFee} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableImportFee" type="checkbox"
                                                                                        onChange={(e) => {
                                                                                            setFieldValue("enableImportFee", e.currentTarget.checked);
                                                                                            if (!e.currentTarget.checked) {
                                                                                                setFieldValue("importFeeRate", null);
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableImportFee': true, 'importFeeRate': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6 pe-0 ps-0">
                                                                                <DrInput className="form-control" placeholder={t('importFee')} name="importFeeRate" type="number" step="0.01"
                                                                                    value={values.importFeeRate || ""} disabled={`${values.enableImportFee ? "" : "disabled"}`}
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'importFeeRate': true }) }}
                                                                                    icon={<i className="fe fe-percent text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                    isInvalid={(touched.importFeeRate && errors.importFeeRate)}
                                                                                    isValid={touched.importFeeRate && !errors.importFeeRate}
                                                                                    validationText={errors.importFeeRate}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-2">
                                                                                <small className={`${(values.enableImportFee) ? "" : "text-muted"}`}>{t('enableImportFeeDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="forbiddenProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableForbiddedProducts')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="forbiddenProducts" value="" checked={values.enableCommunalPoolForbiddenProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolForbiddenProducts" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolForbiddenProducts': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableCommunalPoolForbiddenProducts) ? "" : "text-muted"}`}>{t('enableForbiddedProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-1">
                                                                            </div>
                                                                            <div className="col-sm-11">
                                                                                <label className="custom-switch cursor-pointer my-2" style={{ display: values.enableCommunalPoolForbiddenProducts ? 'block' : 'none' }}>
                                                                                    <DrInput id="forbiddenProductsBrands" value=""
                                                                                        checked={values.enableCommunalPoolForbiddenProducts && values.enableCommunalPoolForbiddenProductsBrands}
                                                                                        className="form-check-input" placeholder="" name="enableCommunalPoolForbiddenProductsBrands" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolForbiddenProductsBrands': true }) }}
                                                                                    />
                                                                                    <label htmlFor="forbiddenProductsBrands" className="form-check-label no-select ms-2 mt-2 mb-0 text-start cursor-pointer">
                                                                                        {t('enableForbiddedProductsBrands')}
                                                                                    </label>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-1">
                                                                            </div>
                                                                            <div className="col-sm-11">
                                                                                <small style={{ display: values.enableCommunalPoolForbiddenProducts ? 'block' : 'none' }} className="custom-switch-description">{t('enableForbiddedProductsBrandsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="blockedBrands" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">
                                                                        {t('enableBlockedBrands')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="blockedBrands" value="" checked={values.enableCommunalPoolBlockedBrands} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolBlockedBrands" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolBlockedBrands': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableCommunalPoolBlockedBrands) ? "" : "text-muted"}`}>{t('enableBlockedBrandsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="autoAddBrands" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoAddBrands')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="autoAddBrands" value="" checked={values.enableCommunalPoolAutoAddBrands} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolAutoAddBrands" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolAutoAddBrands': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableCommunalPoolAutoAddBrands) ? "" : "text-muted"}`}>{t('enableAutoAddBrandsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="autoAddProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoAddProducts')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="autoAddProducts" value="" checked={values.enableCommunalPoolAutoAddProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolAutoAddProducts" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolAutoAddProducts': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableCommunalPoolAutoAddProducts) ? "" : "text-muted"}`}>{t('enableAutoAddProductsDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="handlingTimeInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableHandlingTime')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="handlingTimeInput" value="" checked={values.enableHandlingTime} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableHandlingTime" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableHandlingTime': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <Field onBlur={() => { setTouched({ ...touched.handlingTime, 'handlingTime': true }) }}
                                                                                    name={'handlingTime'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder={t('handlingTime')}
                                                                                    disabled={!values.enableHandlingTime}
                                                                                    options={handlingTimes} />
                                                                                {(touched.handlingTime && errors.handlingTime) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.handlingTime}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-2">
                                                                                <small className={`${(values.enableHandlingTime) ? "" : "text-muted"}`}>{t('enableHandlingTimeDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="maxDeliveryInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableMaxDelivery')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="maxDeliveryInput" value="" checked={values.enableMaxDelivery} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableMaxDelivery" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableMaxDelivery': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <Field onBlur={() => { setTouched({ ...touched.maxDeliveryTime, 'maxDeliveryTime': true }) }}
                                                                                    name={'maxDeliveryTime'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder={t('maxDelivery')}
                                                                                    disabled={!values.enableMaxDelivery}
                                                                                    options={maxDeliveryDays15to30} />
                                                                                {(touched.maxDeliveryTime && errors.maxDeliveryTimeF) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.maxDeliveryTime}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-2">
                                                                                <small className={`${(values.enableMaxDelivery) ? "" : "text-muted"}`}>{t('enableMaxDeliveryDescription')}<br></br>
                                                                                    {t('enableMaxDeliveryNote')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="autoDeleteOutCriteria" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoDeleteOutCriteria')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="autoDeleteOutCriteria" value="" checked={values.enableAutoDeleteOutCriteria} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoDeleteOutCriteria" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoDeleteOutCriteria': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <Field onBlur={() => { setTouched({ ...touched.autoDeleteTimePeriods, 'autoDeleteTimePeriods': true }) }}
                                                                                    name={'autoDeleteTimePeriods'}
                                                                                    component={DrSelectBox}
                                                                                    placeholder={t('autoDeleteOutCriteria')}
                                                                                    disabled={!values.enableAutoDeleteOutCriteria}
                                                                                    options={autoDeleteIntervals} />
                                                                                {(touched.autoDeleteTimePeriods && errors.autoDeleteTimePeriods) && <label type="invalid" className="form-control is-invalid">
                                                                                    {errors.autoDeleteTimePeriods}
                                                                                </label>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-2">
                                                                                <small className={`${(values.enableAutoDeleteOutCriteria) ? "" : "text-muted"}`}>{t('enableAutoDeleteOutCriteriaDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="autoCarrierChange" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoCarrierChange')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="form-check form-check-md form-switch mt-2">
                                                                                    <DrInput id="autoCarrierChange" value="" checked={values.enableAutoCarrierChange} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoCarrierChange" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoCarrierChange': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small className={`${(values.enableAutoCarrierChange) ? "" : "text-muted"}`}>{t('enableAutoCarrierChangeDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="cardSender" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('presentCardSender')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-0">
                                                                                <DrInput id="cardSender" value={values.presentCardSender || ""} className="form-control" placeholder={t('presentCardSender')} name="presentCardSender" type="text"
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'presentCardSender': true }) }}
                                                                                    icon={<i className="fe fe-mail text-muted"></i>}
                                                                                    iconPosition="left"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small >{t('presentCardSenderDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg px-3">
                                                                    <label htmlFor="cardNote" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('presentNote')}
                                                                        {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                    </label>
                                                                    <div className="col-md-8 col-lg-8 mt-3">
                                                                        <div className="row">
                                                                            <div className="col-sm-12 pe-0 ps-0">
                                                                                <DrInput id="cardNote" value={values.presentNote || ""} className="form-control" placeholder={t('presentNote')} name="presentNote" type="text"
                                                                                    onChange={handleChange}
                                                                                    onBlur={() => { setTouched({ ...touched, 'presentNote': true }) }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <small>{t('presentNoteDescription')}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-3"></hr>
                                                                <div className="row row-md row-lg">
                                                                    <div className="col-sm-12">
                                                                        <h3>{t('contentTitle')}</h3>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg border m-1">
                                                                    <div className="col-sm-12">
                                                                        <div className="row px-3 pt-3">
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoOrderConfirm" value="" checked={values.enableAutoOrderConfirm} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoOrderConfirm" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoOrderConfirm': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoOrderConfirm" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoOrderConfirm')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoOrderConfirm) ? "" : "text-muted"}`}>{t('enableAutoOrderConfirmDesciption')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className="row px-3">
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoOrderPreConfirm" value="" checked={values.enableAutoOrderPreConfirm} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoOrderPreConfirm" type="checkbox"
                                                                                        onChange={(e) => {
                                                                                            setFieldValue("enableAutoOrderPreConfirm", e.currentTarget.checked);
                                                                                            if (!e.currentTarget.checked) {
                                                                                                setFieldValue("enableAfterOrderPlaced", false);
                                                                                                setFieldValue("afterOrderPlacedTime", null);
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => {
                                                                                            setTouched({ ...touched, 'enableAutoOrderPreConfirm': true, 'enableAfterOrderPlaced': true, 'afterOrderPlacedTime': true })
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoOrderPreConfirm" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoOrderPreConfirm')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoOrderPreConfirm) ? "" : "text-muted"}`}>{t('enableAutoOrderPreConfirmDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={`row ${(values.enableAutoOrderPreConfirm) ? "d-block" : "d-none"}`}>
                                                                                    <div className="col-12 mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-auto py-auto">
                                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                                    <DrInput id="afterOrderPlaced" value="" disabled={`${values.enableAutoOrderPreConfirm ? "" : "disabled"}`} checked={values.enableAutoOrderPreConfirm && values.enableAfterOrderPlaced} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAfterOrderPlaced" type="checkbox"
                                                                                                        onChange={(e) => {
                                                                                                            setFieldValue("enableAfterOrderPlaced", e.currentTarget.checked);
                                                                                                            if (!e.currentTarget.checked) {
                                                                                                                setFieldValue("afterOrderPlacedTime", null);
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAfterOrderPlaced': true }) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-auto ps-0 py-auto">
                                                                                                <label htmlFor="afterOrderPlaced" className={`noselect text-start mt-3 cursor-pointer ${(values.enableAfterOrderPlaced) ? "" : "text-muted"}`}>
                                                                                                    {t('confirm')}
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="col-sm-2 col-md-2 col-lg-2 px-0 my-auto">

                                                                                                <DrInput className="form-control" placeholder="" name="afterOrderPlacedTime" type="number"
                                                                                                    value={values.afterOrderPlacedTime || ""}
                                                                                                    disabled={`${values.enableAutoOrderPreConfirm && values.enableAfterOrderPlaced ? "" : "disabled"}`}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={() => { setTouched({ ...touched, 'afterOrderPlacedTime': true }) }}
                                                                                                    isInvalid={(touched.afterOrderPlacedTime && errors.afterOrderPlacedTime)}
                                                                                                    isValid={touched.afterOrderPlacedTime && !errors.afterOrderPlacedTime}
                                                                                                    validationText={errors.afterOrderPlacedTime}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-auto">
                                                                                                <label htmlFor="afterOrderPlaced" className={`noselect text-start mt-3 cursor-pointer ${(values.enableAfterOrderPlaced) ? "" : "text-muted"}`}>
                                                                                                    {t('confirmHours')}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className="row px-3">
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoPriceUpdate" value="" checked={values.enableAutoPriceUpdate} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoPriceUpdate" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoPriceUpdate': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoPriceUpdate" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoPriceUpdate')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoPriceUpdate) ? "" : "text-muted"}`}>{t('enableAutoPriceUpdateDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className="row px-3">
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoStockUpdate" value="" checked={values.enableAutoStockUpdate} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoStockUpdate" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoStockUpdate': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoStockUpdate" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoStockUpdate')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoStockUpdate) ? "" : "text-muted"}`}>{t('enableAutoStockUpdateDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className="row px-3">
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoProductDeletion" value="" checked={values.enableAutoProductDeletion} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoProductDeletion" type="checkbox"
                                                                                        onChange={handleChange}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoProductDeletion': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoProductDeletion" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoProductDeletion')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoProductDeletion) ? "" : "text-muted"}`}>{t('enableAutoProductDeletionDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3"></hr>
                                                                        <div className="row px-3 pb-3" >
                                                                            <div className="col-auto">
                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                    <DrInput id="autoProductAdd" value="" checked={values.enableAutoProductAdd} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoProductAdd" type="checkbox"
                                                                                        onChange={(e) => {
                                                                                            setFieldValue("enableAutoProductAdd", e.currentTarget.checked);
                                                                                            if (!e.currentTarget.checked) {
                                                                                                setFieldValue("askConfirmationForNewProducts", false);
                                                                                            }
                                                                                        }}
                                                                                        onBlur={() => { setTouched({ ...touched, 'enableAutoProductAdd': true, 'askConfirmationForNewProducts': true }) }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col text-wrap">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label htmlFor="autoProductAdd" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {t('enableAutoProductAdd')}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className={`${(values.enableAutoProductAdd) ? "" : "text-muted"}`}>{t('enableAutoProductAddDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={`row ${(values.enableAutoProductAdd) ? "d-block" : "d-none"}`}>
                                                                                    <div className="col-12 mt-3">
                                                                                        <div className="row">
                                                                                            <div className="col-auto py-auto">
                                                                                                <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                                                    <DrInput id="confirmationForNewProducts" value="" disabled={`${values.enableAutoProductAdd ? "" : "disabled"}`} checked={values.enableAutoProductAdd && values.askConfirmationForNewProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="askConfirmationForNewProducts" type="checkbox"
                                                                                                        onChange={handleChange}
                                                                                                        onBlur={() => { setTouched({ ...touched, 'askConfirmationForNewProducts': true }) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col ps-0 text-wrap py-auto">
                                                                                                <label htmlFor="confirmationForNewProducts" className={`noselect text-start mt-3 cursor-pointer ${!values.askConfirmationForNewProducts ? "text-muted" : ""}`}>
                                                                                                    {t('askConfirmationForNewProducts')}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row row-md row-lg justify-content-between my-3 px-1">
                                                                    <div className="col-lg-auto col-md-2">
                                                                        <button className="btn btn-danger btn-wave waves-effect waves-light shadow"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleMyStoreDelete(values.id);
                                                                            }}
                                                                        >{t('deleteStore')}</button>
                                                                    </div>
                                                                    <div className="col-lg-auto col-md-2">
                                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (isValid) {
                                                                                    handleMyStoreSubmit(values);
                                                                                }
                                                                            }}
                                                                        >{t('saveChanges')}</button>
                                                                    </div>
                                                                </div>
                                                            </section>}
                                                    </form>
                                                )
                                                }
                                            </Formik>
                                        </div>
                                        <div className={`tab-pane bg-primary bg-opacity-05 ${pageSelections?.tabId === "filtersTab" ? "active" : ""}`} id="filtersTab">
                                            <Formik
                                                initialValues={initialFiltersValues}
                                                validateOnBlur={true}
                                                // onSubmit={handleSubmit}
                                                // validationSchema={storeFiltersSchema}
                                                enableReinitialize={true}
                                                validateOnMount={true}
                                            >
                                                {({
                                                    isValid,
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setTouched,
                                                    setFieldValue
                                                }) => (
                                                    <div className="row row-md row-lg d-flex justify-content-center">
                                                        <div className="col-md-8 col-lg-12">
                                                            <form>
                                                                {selectedStore &&
                                                                    <section>
                                                                        {/* <div className="row px-3 py-3 my-3">
                                                                                <div className="col-lg-12 col-sm-12 mt-3">
                                                                                    <div className="row justify-content-between">
                                                                                        <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                            <label className="noselect mt-3 text-start">
                                                                                                {i18next.t('storeFiltersPage:enableFilterBuyboxOrLowestPrice')}
                                                                                                <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                            <Field onBlur={() => { setTouched({ ...touched.filterBuyboxOrLowestPriceValue, 'filterBuyboxOrLowestPriceValue': true }) }}
                                                                                                name={'filterBuyboxOrLowestPriceValue'}
                                                                                                component={DrSelectBox}
                                                                                                placeholder="Select"
                                                                                                options={filterBuyboxOrLowestOptions} />
                                                                                            {(touched.filterBuyboxOrLowestPriceValue && errors.filterBuyboxOrLowestPriceValue) && <label type="invalid" className="form-control is-invalid">
                                                                                                {errors.filterBuyboxOrLowestPriceValue}
                                                                                            </label>}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-12">
                                                                                            <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterBuyboxOrLowestPriceDescription')}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableOnlyAmazonProducts')}
                                                                                            <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.onlyAmazonProductsValue, 'onlyAmazonProductsValue': true }) }}
                                                                                            name={'onlyAmazonProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.onlyAmazonProductsValue && errors.onlyAmazonProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.onlyAmazonProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableOnlyAmazonProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableOnlySHouseProducts')}
                                                                                            <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.onlySHouseProductsValue, 'onlySHouseProductsValue': true }) }}
                                                                                            name={'onlySHouseProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.onlySHouseProductsValue && errors.onlySHouseProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.onlySHouseProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableOnlySHouseProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label htmlFor="filterTrademarkProducts" className="noselect mt-3 text-start cursor-pointer">
                                                                                            {i18next.t('storeFiltersPage:enableFilterTrademarkProducts')}
                                                                                            {/* <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span> */}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterTrademarkProductsValue, 'filterTrademarkProductsValue': true }) }}
                                                                                            name={'filterTrademarkProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions}
                                                                                            onChange={(e) => {
                                                                                                if (e.value === '' || e.value === 0) {
                                                                                                    setFieldValue("checkFromUSPTO", false);
                                                                                                    setFieldValue("checkFromCIPO", false);
                                                                                                }
                                                                                            }} />
                                                                                        {(touched.filterTrademarkProductsValue && errors.filterTrademarkProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterTrademarkProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterTrademarkProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label className="custom-switch cursor-pointer">
                                                                                            <DrInput id="checkFromUSPTOInput" className="form-check-input" placeholder="" name="checkFromUSPTO" type="checkbox"
                                                                                                value=""
                                                                                                disabled={`${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "disabled"}`}
                                                                                                checked={values.checkFromUSPTO}
                                                                                                onChange={handleChange}
                                                                                                onBlur={() => { setTouched({ ...touched, 'checkFromUSPTO': true }) }}
                                                                                                isInvalid={(touched.checkFromUSPTO && errors.checkFromUSPTO)}
                                                                                                isValid={touched.checkFromUSPTO && !errors.checkFromUSPTO}
                                                                                                validationText={errors.checkFromUSPTO}
                                                                                            />
                                                                                            {/* <span className="custom-switch-indicator" style={{ minWidth: '36px' }} /> */}
                                                                                            <label htmlFor="checkFromUSPTOInput" className={`noselect ms-2 mt-2 text-start cursor-pointer ${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "text-muted"}`}>
                                                                                                {i18next.t('storeFiltersPage:enableFilterTrademarkProductsOption1')}
                                                                                            </label>
                                                                                        </label>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <label className="custom-switch cursor-pointer">
                                                                                            <DrInput id="checkFromCIPOInput" className="form-check-input" placeholder="" name="checkFromCIPO" type="checkbox"
                                                                                                value=""
                                                                                                disabled={`${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "disabled"}`}
                                                                                                checked={values.checkFromCIPO}
                                                                                                onChange={handleChange}
                                                                                                onBlur={() => { setTouched({ ...touched, 'checkFromCIPO': true }) }}
                                                                                                isInvalid={(touched.checkFromCIPO && errors.checkFromCIPO)}
                                                                                                isValid={touched.checkFromCIPO && !errors.checkFromCIPO}
                                                                                                validationText={errors.checkFromCIPO}
                                                                                            />
                                                                                            {/* <span className="custom-switch-indicator" style={{ minWidth: '36px' }} /> */}
                                                                                            <label htmlFor="checkFromCIPOInput" className={`noselect ms-2 mt-2 text-start cursor-pointer ${values.filterTrademarkProductsValue !== '' && values.filterTrademarkProductsValue !== 0 ? "" : "text-muted"}`}>
                                                                                                {i18next.t('storeFiltersPage:enableFilterTrademarkProductsOption2')}
                                                                                            </label>
                                                                                        </label>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterDiscountedProducts')}
                                                                                            <span className="ms-2 badge bg-warning header-badge">{i18next.t('constants:badges.notRecommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterDiscountedProductsValue, 'filterDiscountedProductsValue': true }) }}
                                                                                            name={'filterDiscountedProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterDiscountedProductsValue && errors.filterDiscountedProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterDiscountedProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterDiscountedProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterNoShipProducts')}
                                                                                            <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterNoShipProductsValue, 'filterNoShipProductsValue': true }) }}
                                                                                            name={'filterNoShipProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterNoShipProductsValue && errors.filterNoShipProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterNoShipProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterNoShipProductsDescription', { countryName: myStore?.countryCode })}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterMissingShippingProducts')}
                                                                                            <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterMissingShippingProductsValue, 'filterMissingShippingProductsValue': true }) }}
                                                                                            name={'filterMissingShippingProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterMissingShippingProductsValue && errors.filterMissingShippingProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterMissingShippingProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterMissingShippingProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterUnavailableProducts')}
                                                                                            <span className="ms-2 badge bg-success header-badge">{i18next.t('constants:badges.recommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterUnAvailableProductsValue, 'filterUnAvailableProductsValue': true }) }}
                                                                                            name={'filterUnAvailableProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterUnAvailableProductsValue && errors.filterUnAvailableProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterUnAvailableProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterUnavailableProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterNoImportFeeProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterNoImportFeeProductsValue, 'filterNoImportFeeProductsValue': true }) }}
                                                                                            name={'filterNoImportFeeProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterNoImportFeeProductsValue && errors.filterNoImportFeeProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterNoImportFeeProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterNoImportFeeProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterNoSalesRankProducts')}
                                                                                            <span className="ms-2 badge bg-warning header-badge">{i18next.t('constants:badges.notRecommended')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterNoSalesRankProductsValue, 'filterNoSalesRankProductsValue': true }) }}
                                                                                            name={'filterNoSalesRankProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterNoSalesRankProductsValue && errors.filterNoSalesRankProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterNoSalesRankProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{t('enableFilterNoSalesRankProductsDescription')}{i18next.t('storeFiltersPage:enableFilterNoSalesRankProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterSameSellerBrandProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterSameSellerBrandProductsValue, 'filterSameSellerBrandProductsValue': true }) }}
                                                                                            name={'filterSameSellerBrandProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterSameSellerBrandProductsValue && errors.filterSameSellerBrandProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterSameSellerBrandProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterSameSellerBrandProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterPrimeSellersInStoreProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterPrimeSellersInStoreProductsValue, 'filterPrimeSellersInStoreProductsValue': true }) }}
                                                                                            name={'filterPrimeSellersInStoreProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterPrimeSellersInStoreProductsValue && errors.filterPrimeSellersInStoreProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterPrimeSellersInStoreProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterPrimeSellersInStoreProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterByMaxSellersInStoreProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <DrInput className="form-control" placeholder="" name="filterByMaxSellersInStoreProductsValue" type="number" min="0"
                                                                                            value={values.filterByMaxSellersInStoreProductsValue || ""}
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'filterByMaxSellersInStoreProductsValue': true }) }}
                                                                                            isInvalid={(touched.filterByMaxSellersInStoreProductsValue && errors.filterByMaxSellersInStoreProductsValue)}
                                                                                            isValid={touched.filterByMaxSellersInStoreProductsValue && !errors.filterByMaxSellersInStoreProductsValue}
                                                                                            validationText={errors.filterByMaxSellersInStoreProductsValue}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterByMaxSellersInStoreProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterByMinSellersInStoreProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <DrInput className="form-control" placeholder="" name="filterByMinSellersInStoreProductsValue" type="number" min="0"
                                                                                            value={values.filterByMinSellersInStoreProductsValue || ""}
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'filterByMinSellersInStoreProductsValue': true }) }}
                                                                                            isInvalid={(touched.filterByMinSellersInStoreProductsValue && errors.filterByMinSellersInStoreProductsValue)}
                                                                                            isValid={touched.filterByMinSellersInStoreProductsValue && !errors.filterByMinSellersInStoreProductsValue}
                                                                                            validationText={errors.filterByMinSellersInStoreProductsValue}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterByMinSellersInStoreProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterHaveCNSellersProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterHaveCNSellersProductsValue, 'filterHaveCNSellersProductsValue': true }) }}
                                                                                            name={'filterHaveCNSellersProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterHaveCNSellersProductsValue && errors.filterHaveCNSellersProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterHaveCNSellersProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterHaveCNSellersProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border-top">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start">
                                                                                            {i18next.t('storeFiltersPage:enableFilterHaveAmazonSellersProducts')}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterHaveAmazonSellersProductsValue, 'filterHaveAmazonSellersProductsValue': true }) }}
                                                                                            name={'filterHaveAmazonSellersProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterHaveAmazonSellersProductsValue && errors.filterHaveAmazonSellersProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterHaveAmazonSellersProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterHaveAmazonSellersProductsDescription', { countryCode: myStore?.countryCode })}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row px-3 py-3 my-3 border border-gold bg-gold rounded">
                                                                            <div className="col-lg-12 col-sm-12 mt-3">
                                                                                <div className="row justify-content-between">
                                                                                    <div className="col-sm-8 col-xl-9 col-lg-9 col-md-8 ">
                                                                                        <label className="noselect mt-3 text-start text-darkgray">
                                                                                            {i18next.t('storeFiltersPage:enableFilterPrimeExclusiveProducts')}
                                                                                            <span className="ms-2 badge bg-dark-gold header-badge text-white">{i18next.t('constants:badges.onlyPrimeUser')}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="col-sm-4 col-xl-3 col-lg-3 col-md-4 ">
                                                                                        <Field onBlur={() => { setTouched({ ...touched.filterPrimeExclusiveProductsValue, 'filterPrimeExclusiveProductsValue': true }) }}
                                                                                            name={'filterPrimeExclusiveProductsValue'}
                                                                                            component={DrSelectBox}
                                                                                            placeholder="Select"
                                                                                            options={filterValueOptions} />
                                                                                        {(touched.filterPrimeExclusiveProductsValue && errors.filterPrimeExclusiveProductsValue) && <label type="invalid" className="form-control is-invalid">
                                                                                            {errors.filterPrimeExclusiveProductsValue}
                                                                                        </label>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <small className="custom-switch-description">{i18next.t('storeFiltersPage:enableFilterPrimeExclusiveProductsDescription')}</small>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                }

                                                                {selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                                                    <div className="col-lg-auto col-md-2">
                                                                        <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (isValid) {
                                                                                    handleFiltersSubmit(values);
                                                                                }
                                                                            }}
                                                                        >{i18next.t('storeFiltersPage:saveChanges')}</button>
                                                                    </div>
                                                                </div>}
                                                                {!selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">

                                                                    <div className="col-lg-auto col-md-2">
                                                                        <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                Promise.all([
                                                                                    dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                                                ]).then((e) => {
                                                                                    navigate(getRouteByKey("MARKET_SELECTION"));
                                                                                });;

                                                                            }}>
                                                                            <i className="me-2 fe fe-chevrons-right"></i>
                                                                            {i18next.t('storeFiltersPage:gotoMarketSelection')}
                                                                        </button>
                                                                    </div>
                                                                </div>}
                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                                }
                                            </Formik>

                                        </div>
                                        <div className={`tab-pane bg-primary bg-opacity-05 ${pageSelections?.tabId === "storeSettingsTab" ? "active" : ""}`} id="storeSettingsTab">
                                            <Formik
                                                initialValues={initialStoreSettingsValues}
                                                validateOnBlur={true}
                                                // onSubmit={handleSubmit}
                                                // validationSchema={storeSettingsSchema}
                                                enableReinitialize={true}
                                                validateOnMount={true}
                                            >
                                                {({
                                                    isValid,
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setTouched,
                                                    setFieldValue
                                                }) => (
                                                    <form>
                                                        {selectedStore && warehouseMarketOptions?.length > 0 &&
                                                            <>
                                                                <div className="row row-md row-lg justify-content-end my-1 px-3">
                                                                    <div className="col-auto col-lg-auto col-md-auto col-sm-auto px-0" >
                                                                        <DrButtonDropdown className="shadow" options={warehouseMarketOptions} optionClickCallBack={storeSettingsOptionClickCallBack}
                                                                            selectedValue={pageSelections?.warehouseMarketOption}>
                                                                        </DrButtonDropdown>
                                                                    </div>
                                                                </div>
                                                                <section>

                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="commercialInvoiceOption" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableCommercialInvoiceOption')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">

                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="commercialInvoiceOption" value="" checked={values.enableCommercialInvoiceOption} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommercialInvoiceOption" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableCommercialInvoiceOption': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableCommercialInvoiceOption) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableCommercialInvoiceOptionDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="onlyFBAProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableOnlyFBAProducts')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="onlyFBAProducts" value="" checked={values.enableOnlyFBAProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableOnlyFBAProducts" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableOnlyFBAProducts': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableOnlyFBAProducts) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableOnlyFBAProductsDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="alternativeCarrier" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableAlternativeCarrier')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="alternativeCarrier" value="" checked={values.enableAlternativeCarrier} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAlternativeCarrier" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableAlternativeCarrier': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableAlternativeCarrier) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableAlternativeCarrierDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="showProductLabelOnPackage" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableShowProductLabelOnPackage')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="showProductLabelOnPackage" value="" checked={values.enableShowProductLabelOnPackage} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableShowProductLabelOnPackage" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableShowProductLabelOnPackage': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableShowProductLabelOnPackage) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableShowProductLabelOnPackageDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="showASINOnPackage" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableShowASINOnPackage')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="showASINOnPackage" value="" checked={values.enableShowASINOnPackage} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableShowASINOnPackage" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableShowASINOnPackage': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableShowASINOnPackage) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableShowASINOnPackageDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg px-3">
                                                                        <label htmlFor="insurance" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{i18next.t('storePricingPage:enableInsurance')}
                                                                            {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                                                        </label>
                                                                        <div className="col-md-8 col-lg-8 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="form-check form-check-md form-switch mt-2">
                                                                                        <DrInput id="insurance" value="" checked={values.enableInsurance} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableInsurance" type="checkbox"
                                                                                            onChange={handleChange}
                                                                                            onBlur={() => { setTouched({ ...touched, 'enableInsurance': true }) }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <small className={`${(values.enableInsurance) ? "" : "text-muted"}`}>{i18next.t('storePricingPage:enableInsuranceDescription')}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="my-3"></hr>
                                                                    <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                                        <div className="col-lg-auto col-md-2">
                                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (isValid) {
                                                                                        handleStoreSettingsSubmit(values);
                                                                                    }
                                                                                }}
                                                                            >{i18next.t('storePricingPage:saveChanges')}</button>
                                                                        </div>
                                                                    </div>
                                                                </section>
                                                            </>
                                                        }

                                                        {selectedStore && (!warehouseMarketOptions || warehouseMarketOptions?.length === 0) &&
                                                            <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                                <div className="col-lg-auto col-md-2">
                                                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            navigate(getRouteByKey("GENERAL"));
                                                                        }}>
                                                                        <i className="me-2 fe fe-chevrons-right"></i>
                                                                        {i18next.t('storePricingPage:gotoWareHouseSelection')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }

                                                        {!selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                            <div className="col-lg-auto col-md-2">
                                                                <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        Promise.all([
                                                                            dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                                        ]).then((e) => {
                                                                            navigate(getRouteByKey("MARKET_SELECTION"));
                                                                        });;

                                                                    }}>
                                                                    <i className="me-2 fe fe-chevrons-right"></i>
                                                                    {i18next.t('storePricingPage:gotoMarketSelection')}
                                                                </button>
                                                            </div>
                                                        </div>}
                                                    </form>
                                                )
                                                }
                                            </Formik>
                                        </div>
                                        <div className={`tab-pane bg-primary bg-opacity-05 ${pageSelections?.tabId === "pricingTab" ? "active" : ""}`} id="pricingTab">

                                            <Formik
                                                initialValues={initialPricingDataValues}
                                                validateOnBlur={true}
                                                // onSubmit={handleSubmit}
                                                validationSchema={storePricingSchema}
                                                enableReinitialize={true}
                                                validateOnMount={true}

                                            >
                                                {({
                                                    isValid,
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    setTouched,
                                                    setFieldValue
                                                }) => (
                                                    <form>
                                                        {selectedStore && warehouseMarketOptions?.length > 0 &&
                                                            <>
                                                                <div className="row row-md row-lg justify-content-end my-1 px-3">
                                                                    <div className="col-auto col-lg-auto col-md-auto col-sm-auto px-0" >
                                                                        <DrButtonDropdown className="shadow" options={warehouseMarketOptions} optionClickCallBack={pricingPageOptionClickCallBack}
                                                                            selectedValue={pageSelections?.pricingMarketOption || (warehouseMarketOptions?.length > 0 ? warehouseMarketOptions[0].value : "")}
                                                                        >
                                                                        </DrButtonDropdown>
                                                                    </div>
                                                                </div>
                                                                <section>
                                                                    <div className="row row-md row-lg borderflex-nowrap overflow-auto">
                                                                        <Field target={document.body}
                                                                            onBlur={() => { setTouched({ ...touched, 'costUnitList': true }) }}
                                                                            label={'costUnitList'} name={'costUnitList'}
                                                                            component={DrWarehousePricing}
                                                                        // onBlur={handleBlur}
                                                                        />

                                                                        {/* {(touched.costUnitList && errors.costUnitList) &&
                                                                                <small className="is-invalid-description">{errors.costUnitList}</small>} */}

                                                                        {/* <small className="invalid-feedback">{errors.costUnitList}</small> */}
                                                                    </div>
                                                                    <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                                        <div className="col-lg-auto col-md-2">
                                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow ${isValid ? "" : "disabled"}`}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    if (isValid) {
                                                                                        handlePricingDataSubmit(values);
                                                                                    }
                                                                                }}
                                                                            >{i18next.t('storePricingPage:saveChanges')}</button>
                                                                            {!isValid &&
                                                                                <small className="is-invalid-description">Bütün alanları doldurunuz</small>}
                                                                        </div>

                                                                    </div>
                                                                </section>
                                                            </>
                                                        }

                                                        {selectedStore && (!warehouseMarketOptions || warehouseMarketOptions?.length === 0) &&
                                                            <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                                <div className="col-lg-auto col-md-2">
                                                                    <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            navigate(getRouteByKey("GENERAL"));
                                                                        }}>
                                                                        <i className="me-2 fe fe-chevrons-right"></i>
                                                                        {i18next.t('storePricingPage:gotoWareHouseSelection')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }

                                                        {!selectedStore && <div className="row row-md row-lg justify-content-center my-3 px-1">
                                                            <div className="col-lg-auto col-md-2">
                                                                <button className="btn btn-info btn-wave waves-effect waves-light shadow"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();

                                                                        Promise.all([
                                                                            dispatch(withActionPromise(clearCreateStoreInformationState()))
                                                                        ]).then((e) => {
                                                                            navigate(getRouteByKey("MARKET_SELECTION"));
                                                                        });;

                                                                    }}>
                                                                    <i className="me-2 fe fe-chevrons-right"></i>
                                                                    {i18next.t('storePricingPage:gotoMarketSelection')}
                                                                </button>
                                                            </div>
                                                        </div>}
                                                    </form>
                                                )
                                                }
                                            </Formik>
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default MyStorePage;