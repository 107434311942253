
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteByKey } from '../../routes';
import Swal from 'sweetalert2';
import { getConfirmUser, getConfirmUserSuccess } from '../../state-management/actions/customer.actions';

function ConfirmUserPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userId } = useParams();
    const isAccountConfirmed = useSelector(state => state.customer.isAccountConfirmed);
    useEffect(() => {
        if (userId) {
            dispatch(getConfirmUser(userId));

        } else {
            navigate(getRouteByKey("LOGIN"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAccountConfirmed) {
            let timerInterval;
            Swal.fire({
                title: 'Your acccount is approved!'.toUpperCase(),
                html: 'You will be redirected to login page in <b></b> milliseconds.',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading()
                    const b = Swal.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => {
                        b.textContent = Swal.getTimerLeft()
                    }, 100)
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                    navigate(getRouteByKey("LOGIN"));
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccountConfirmed]);

    return (
        <></>
    )
}
export default ConfirmUserPage