import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { DrForgotPasswordTemplate, DrInput, DrPasswordInput } from "../../components/component-index";
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { createCustomer } from "../../state-management/actions/create-customer.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFish } from "@fortawesome/free-solid-svg-icons";
import loginImg from '../../assets/img/login-img.png'
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { clearActiveTermAndConditionsModal, setActiveTermAndConditionsModal, setLoadIndicator, withActionPromise } from "../../state-management/actions/app.actions";
import { forgotPassword, loginWithEmail } from "../../state-management/actions/customer.actions";
import { getRouteByKey } from "../../routes";
import { render as EmailRender } from '@react-email/render';
import { modals } from "../../utils/constants";
import { getAgreements } from "../../state-management/actions/agreement.action";
import DrApproveUserTemplate from "../../components/core/MailTemplates/dr-approve-user-template.component";
import { apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls";
import i18next from "i18next";
import Swal from "sweetalert2";
import { sendMail } from "../../state-management/actions/utilities.actions";
import { generateStringToHtml } from "../../utils/utils";
import { renderToString } from 'react-dom/server';

function RegisterPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer = useSelector(state => state.customer);
    const agreement = useSelector(state => state.agreement?.agreements);
    const { t } = useTranslation('registerPage');
    const [pageChoice, setPageChoice] = useState("1");
    const { type } = useParams();

    useEffect(() => {
        if (!agreement) {
            dispatch(withActionPromise(getAgreements()))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (type) {
            setPageChoice(type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const initialValuesLogin = {
        email: "",
        password: ''
    };

    const initialValuesRegister = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordAgain: "",
        agreementId: "",
        isAgreementAccepted: false
    };

    const userInfoSchema = Yup.object().shape({
        // firstName: Yup.string()
        //     .min(3, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('First name is required'),
        // lastName: Yup.string()
        //     .min(3, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('Last name is required'),
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().nullable()
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),

        passwordAgain: Yup.string().nullable().when('password', {
            is: (value) => value && value !== '',
            then: () => Yup.string().nullable(true).required('Confirm Password is required')
                .ensure().required('Confirm Password is required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            otherwise: () => Yup.string().nullable(),
        }),
        agreementId: Yup.string().required('You need to accept Terms and Conditions!')
    });

    const loginAccountSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Password is required')
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),
    });

    const handleSubmit = async (values) => {
        var user = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password ? values.password : ""
        }
        dispatch(createCustomer(user));
    }

    const handleForgotPassword = async (value) => {

        let body = <DrForgotPasswordTemplate siteUrl={window.location.origin} ></DrForgotPasswordTemplate>;
        var request = {
            emailTo: value,
            emailToName: "",
            emailSubject: "Password Change",
            emailBody: EmailRender(body)
        }

        dispatch(forgotPassword(request));
    }

    return (
        <>
            <div className="container" style={{ paddingTop: "150px" }}>
                <div className="row justify-content-center mt-5 h-100">
                    <div className="col-md-6 col-lg-6 col-sm-10 col-xl-5 col-10 h-100" >
                        <div className="card custom-card" >
                            <div className="card-body">
                                {pageChoice === "2" &&
                                    <Formik
                                        initialValues={initialValuesRegister}
                                        validateOnBlur={true}
                                        // onSubmit={handleSubmit}
                                        validationSchema={userInfoSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            handleBlur,
                                            setFieldValue
                                        }) => (
                                            <form name="register-form" className="needs-validation" autoComplete="off" >
                                                <section>
                                                    <div className="row justify-content-center" style={{ height: "470px" }}>
                                                        <div className="col-10">
                                                            <h1 className="text-center">{t('titleSignUp')}</h1>
                                                            <h5 className="text-center">{t('infoSignUp')}</h5>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label>{t('firstName')}</label>
                                                                        <DrInput value={values.firstName} className="form-control" placeholder={t('firstName')} name="firstName" type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'firstName': true }) }}
                                                                            isInvalid={(touched.firstName && errors.firstName)}
                                                                            isValid={touched.firstName && !errors.firstName}
                                                                            validationText={errors.firstName}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group has-validation">
                                                                        <label>{t('lastName')}</label>
                                                                        <DrInput value={values.lastName} className="form-control" placeholder={t('lastName')} name="lastName" type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'lastName': true }) }}
                                                                            isInvalid={(touched.lastName && errors.lastName)}
                                                                            isValid={touched.lastName && !errors.lastName}
                                                                            validationText={errors.lastName}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group has-validation">
                                                                        <label>{t('email')}</label>
                                                                        <DrInput value={values.email} className="form-control" placeholder={t('email')} name="email" type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'email': true }) }}
                                                                            isInvalid={(touched.email && errors.email)}
                                                                            isValid={touched.email && !errors.email}
                                                                            validationText={errors.email}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group has-validation">
                                                                        <label>{t('password')}</label>
                                                                        <DrPasswordInput className="form-control" name="password" type="password" placeholder={t('password')} value={values.password} showRevealButton={true} onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'password': true }) }} />
                                                                        {(touched.password && errors.password) && <div type="invalid" className="form-control is-invalid"> {errors.password} </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group has-validation">
                                                                        <label>{t('confirmPassword')}</label>
                                                                        <DrPasswordInput name="passwordAgain" type="password" className="form-control" placeholder={t('confirmPassword')} value={values.passwordAgain} showRevealButton={true} onChange={handleChange}
                                                                            onBlur={() => { setTouched({ ...touched, 'passwordAgain': true }) }} />
                                                                        {(touched.passwordAgain && errors.passwordAgain) && <div type="invalid" className="form-control is-invalid"> {errors.passwordAgain} </div>}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row justify-content-start my-2">
                                                                <div className="col-auto mt-0 pe-0">
                                                                    <div className="form-check form-check-md">
                                                                        <DrInput id="isAgreementAccepted" className="form-check-input" role="switch" placeholder="" name="isAgreementAccepted" type="checkbox"
                                                                            value=""
                                                                            checked={values.isAgreementAccepted}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    
                                                                                    if (!agreement?.agreements?.agreementHtml) {
                                                                                        apiGet(``, CONTROLLERS.AGREEMENT, false, true, false, true)().then((response) => {
                                                                                            if (response && response.isSuccess && response.resultType === 1 && response.data) {

                                                                                                dispatch(setActiveTermAndConditionsModal({
                                                                                                    name: modals.termsAndConditions, isGlobal: true, title: "", source: null,
                                                                                                    onSelectionCallback: (response) => {

                                                                                                        if (response.isAccepted) {
                                                                                                            setFieldValue("isAgreementAccepted", response.isAccepted);
                                                                                                            setFieldValue("agreementId", response.agreementId);
                                                                                                        }
                                                                                                        else {
                                                                                                            setFieldValue("isAgreementAccepted", false);
                                                                                                            setFieldValue("agreementId", "");
                                                                                                        }
                                                                                                        dispatch(clearActiveTermAndConditionsModal());
                                                                                                    }
                                                                                                }));
                                                                                            }
                                                                                            else {
                                                                                                dispatch(setLoadIndicator(0));
                                                                                                Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error2') + " " + (response && response.message ? response.message : ""), 'error')
                                                                                            }
                                                                                        }).catch((err) => {

                                                                                            dispatch(setLoadIndicator(0));
                                                                                            Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), (err && err?.response?.data?.data ? err?.response?.data?.data : ""), 'error')
                                                                                        })
                                                                                    }
                                                                                    else {
                                                                                        dispatch(setActiveTermAndConditionsModal({
                                                                                            name: modals.termsAndConditions, isGlobal: true, title: "", source: null,
                                                                                            onSelectionCallback: (response) => {

                                                                                                if (response.isAccepted) {
                                                                                                    setFieldValue("isAgreementAccepted", response.isAccepted);
                                                                                                    setFieldValue("agreementId", response.agreementId);
                                                                                                }
                                                                                                else {
                                                                                                    setFieldValue("isAgreementAccepted", false);
                                                                                                    setFieldValue("agreementId", "");
                                                                                                }
                                                                                                dispatch(clearActiveTermAndConditionsModal());
                                                                                            }
                                                                                        }));
                                                                                    }

                                                                                }
                                                                                else {
                                                                                    setFieldValue("isAgreementAccepted", false);
                                                                                    setFieldValue("agreementId", "");
                                                                                }

                                                                            }}
                                                                            onBlur={() => { setTouched({ ...touched, 'isAgreementAccepted': true, 'agreementId': true }) }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col mt-0">
                                                                    <label className={`form-check-label cursor-pointer no-select ${(values.isAgreementAccepted) ? "" : "text-muted"}`} htmlFor="isAgreementAccepted"
                                                                        style={{ marginTop: '2px' }}>
                                                                        I accept the Terms and conditions
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center mt-3">
                                                                <div className="col-11 col-lg-6 col-xl-6">
                                                                    <button className={`btn btn-primary btn-wave waves-effect waves-light shadow btn-block ${isValid ? "" : "disabled"}`}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();

                                                                            if (isValid) {
                                                                                var user = {
                                                                                    firstName: values.firstName,
                                                                                    lastName: values.lastName,
                                                                                    email: values.email,
                                                                                    password: values.password ? values.password : "",
                                                                                    agreementId: values.agreementId
                                                                                }


                                                                                apiPost(``, user, false, CONTROLLERS.CUSTOMER, false, true)().then((response) => {
                                                                                    if (response && response.isSuccess && response.resultType === 1 && response.data) {

                                                                                        let body = <DrApproveUserTemplate siteUrl={window.location.origin + "/#/confirm/" + response.data}></DrApproveUserTemplate>
                                                                                        console.log(generateStringToHtml(renderToString(body)))
                                                                                        var emailData = {
                                                                                            emailTo: values.email,
                                                                                            emailToName: values.firstName + ' ' + values.lastName,
                                                                                            emailSubject: "Your account creation requires approval",// i18next.t('drMailTemplates:approveUserTemplate.subject'),
                                                                                            emailBody: generateStringToHtml(renderToString(body))// EmailRender(renderToString(body)) //EmailRender(body)
                                                                                        }

                                                                                        dispatch(sendMail(emailData));
                                                                                    }
                                                                                    else {
                                                                                        dispatch(setLoadIndicator(0));
                                                                                        Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.error2') + " " + (response && response.message ? response.message : ""), 'error')
                                                                                    }
                                                                                }).catch((err) => {

                                                                                    dispatch(setLoadIndicator(0));
                                                                                    Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), (err && err?.response?.data?.data ? err?.response?.data?.data : ""), 'error')
                                                                                })

                                                                            }
                                                                        }}
                                                                    >{t('signUpComplete')}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </form>
                                        )
                                        }
                                    </Formik>
                                }
                                {(pageChoice === "1" || pageChoice === "3") &&
                                    <Formik
                                        initialValues={initialValuesLogin}
                                        validateOnBlur={true}
                                        // onSubmit={handleLoginSubmit}
                                        validationSchema={loginAccountSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            handleBlur,
                                            setFieldValue
                                        }) => (
                                            <>
                                                {pageChoice === "1" &&
                                                    <form name="login-form" className="needs-validation" style={{ height: "470px" }}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-10 align-middle">
                                                                <h1 className="text-center">{t('titleLogin')}</h1>
                                                                <h5 className="text-center">{t('infoLogin')}</h5>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group has-validation">
                                                                            <label>{t('email')}</label>
                                                                            <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                isInvalid={(touched.email && errors.email)}
                                                                                isValid={touched.email && !errors.email}
                                                                                validationText={errors.email}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group has-validation">
                                                                            <label>{t('password')}</label>
                                                                            <DrPasswordInput className={`form-control ${(touched.password && errors.password) ? " is-invalid" : ""}`} name="password" type="password" placeholder={(touched.password && errors.password) ? errors.password : t('password')} value={values.password} showRevealButton={true} onChange={handleChange}
                                                                                onBlur={handleBlur} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row justify-content-center">
                                                                    <div className="col-auto">
                                                                        <FontAwesomeIcon icon={faFish} beat />
                                                                        <span className="text-primary ms-2 cursor-pointer"
                                                                            onClick={() => {
                                                                                setPageChoice("3");
                                                                                // dispatch(setActiveModal({ name: modals.forgotpassword, isGlobal: true, title: i18next.t('drForgotPassword:title') }))
                                                                            }
                                                                            }
                                                                        >{t('forgotPassword')}</span>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-10">
                                                                <div className="row justify-content-center mt-5">
                                                                    <div className="col-10 col-lg-5 col-xl-5">
                                                                        <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                Promise.all([
                                                                                    dispatch(withActionPromise(loginWithEmail(values)))
                                                                                ]).then((response) => {

                                                                                    // navigate(getRouteByKey("HOME"));
                                                                                });
                                                                            }}
                                                                        >
                                                                            {t('login')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row justify-content-center mt-4">
                                                                    <p className="mb-0 text-center">{t('dontHaveAccount')}
                                                                        <span className="text-primary ms-2 cursor-pointer"
                                                                            onClick={() => {
                                                                                // navigate(getRouteByKey("MAIN_PACKAGES"));
                                                                                setPageChoice("2");
                                                                            }} >
                                                                            {t('signUp')}
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </form>
                                                }
                                                {pageChoice === "3" &&

                                                    <form name="login-form" className="needs-validation" style={{ height: "470px" }}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-10 align-middle">
                                                                <h1 className="text-center">{t('titleForgotPassword')}</h1>
                                                                <h5 className="text-center">{t('infoForgotPassword')}</h5>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-group has-validation">
                                                                            <label>{t('email')}</label>
                                                                            <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text"
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                isInvalid={(touched.email && errors.email)}
                                                                                isValid={touched.email && !errors.email}
                                                                                validationText={errors.email}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row justify-content-center mt-5">
                                                                    <div className="col-10 col-lg-5 col-xl-5">
                                                                        <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                handleForgotPassword(values.email);
                                                                            }}
                                                                        >
                                                                            {t('sendMail')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row justify-content-center mt-4">
                                                                    <p className="mb-0 text-center">{t('doYouRememberPassword')}
                                                                        <span className="text-primary ms-2 cursor-pointer"
                                                                            onClick={() => {
                                                                                // navigate(getRouteByKey("MAIN_PACKAGES"));
                                                                                setPageChoice("1");
                                                                            }} >
                                                                            {t('signIn')}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>


                                                }
                                            </>


                                        )
                                        }
                                    </Formik>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-6 col-sm-10 col-xl-5 col-10 h-100" >
                        <div className="card custom-card" >
                            <div className="card-body">
                                <div className="row justify-content-center" style={{ height: "470px" }}>
                                    <div className="col-10">
                                        <div className="row justify-content-center mb-4">
                                            <img src={loginImg} className="img-lg" alt="dashleadlogo" style={{ maxHeight: "300px" }} />
                                        </div>
                                        <h4 className="text-center">{t('title')}</h4>
                                        <h5 className="text-center">{t('info')}</h5>
                                        <div className="row justify-content-center mt-4">
                                            <div className="col-10 col-lg-5 col-xl-5">
                                                <button className={`btn btn-wave waves-effect waves-light shadow btn-block mt-1 ${pageChoice === "1" ? "btn-secondary" : "btn-secondary-ghost"}`}
                                                    onClick={(e) => {
                                                        setPageChoice("1");
                                                    }
                                                    }
                                                >{t('login')}</button>
                                            </div>
                                            <div className="col-10 col-lg-5 col-xl-5">
                                                <button className={`btn btn-wave waves-effect waves-light shadow btn-block mt-1 ${pageChoice === "2" ? "btn-primary" : "bg-primary-ghost"}`}
                                                    onClick={(e) => {
                                                        setPageChoice("2");
                                                    }
                                                    }
                                                >{t('signUp')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterPage;