import * as Yup from 'yup';
import { Field, Formik } from "formik";
import { withActionPromise } from "../../../state-management/actions/app.actions";
import { DrButtonDropdown, DrInput, DrSelectBox } from "../../component-index";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { buttonVariants } from '../../../utils/constants';
import { createWarehouseShipping, updateWarehouseShipping } from '../../../state-management/actions/warehouses.actions';
import { CONTROLLERS, apiGet } from '../../../utils/axiosApiCalls';
import { getShipStationCarrierServices, getShipStationCarrierServicesSuccess } from '../../../state-management/actions/shipstation.actions';

function DrNewWarehouseShipping({ source, shippingOptions, shipstationCarriers, shipstationWarehouses, ...rest }) {
    const dispatch = useDispatch();
    const shipstationCarrierServices = useSelector(state => state.shipstation.shipstationCarrierServices);

    const shippingRef = useRef(source);

    const defaultWarehouseShipping = {
        id: source?.wareHouseShippingId || "",
        warehouseId: source?.warehouseId,
        shippingId: source?.shippingId || "",
        userName: source?.userName || "",
        password: source?.password || "",
        token: source?.token || "",
        softwareTypeId: source?.softwareTypeId || 0,
        sorftWareLink: source?.sorftWareLink || "",
        mapWareHouseId: source?.mapWareHouseId || 0,
        carrierCode: source?.carrierCode || "",
        carrierServiceCode: source?.carrierServiceCode || "",
        isManual: source?.isManual || false,
    };


    const [warehouseShipping, setWarehouseShipping] = useState(defaultWarehouseShipping);

    useEffect(() => {
        setWarehouseShipping({
            id: source?.wareHouseShippingId || "",
            warehouseId: source?.warehouseId,
            shippingId: source?.shippingId || "",
            userName: source?.userName || "",
            password: source?.password || "",
            token: source?.token || "",
            softwareTypeId: source?.softwareTypeId || 0,
            sorftWareLink: source?.sorftWareLink || "",
            mapWareHouseId: source?.mapWareHouseId || 0,
            carrierCode: source?.carrierCode || "",
            carrierServiceCode: source?.carrierServiceCode || "",
            isManual: source?.isManual || false
        });

        if (source?.carrierCode && !source?.isManual) {
            callCarrierService(source?.carrierCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [source]);

    const handleSubmit = async (values) => {
        var a = values;
        //form.setFieldValue(`${field.name}`, values);
        var data = {
            warehouseShippingData: {
                id: values.id || "",
                warehouseId: values.warehouseId,
                shippingId: values.shippingId,
                userName: values.userName || "",
                password: values.password || "",
                token: values.token || "",
                softwareTypeId: values.softwareTypeId || 0,
                sorftWareLink: values.sorftWareLink || "",
                mapWareHouseId: values.mapWareHouseId || 0,
                carrierCode: values.carrierCode || "",
                carrierServiceCode: values.carrierServiceCode || ""
            }
        }

        if (data.warehouseShippingData.id) {
            dispatch(withActionPromise(updateWarehouseShipping(data)));
        }
        else {
            dispatch(withActionPromise(createWarehouseShipping(data)));
        }
    };

    const warehouseShippingSchema = Yup.object().shape({
        shippingId: Yup.string().required('Required'),
    });

    const callCarrierService = (value) => {
        if (!(shipstationCarrierServices?.length > 0 && shipstationCarrierServices?.find(x => x.carrierCode === value))) {
            dispatch(getShipStationCarrierServices(value));
        }
    }
    return (
        <>
            <Formik
                validateOnChange={true}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={warehouseShipping}
                validationSchema={warehouseShippingSchema}
                onSubmit={handleSubmit}>
                {({
                    errors,
                    values,
                    touched,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setTouched,
                    isValid
                }) => (

                    <div className="row m-1 justify-content-end collapse" id={("warehouseShippingDetail_" + values.warehouseId + "_" + values.id)}>
                        <form className="m-3 w-100 needs-validation">

                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="row">
                                        <div className="col-12 col-xl-2 col-lg-5">
                                            <div className="form-group has-validation">
                                                <label>Shipping</label>
                                                <DrButtonDropdown variant={buttonVariants.light}
                                                    className="form-control w-fit"
                                                    options={shippingOptions}
                                                    optionClickCallBack={(value) => {
                                                        setFieldValue('shippingId', value);
                                                    }}
                                                    selectedValue={values?.shippingId}
                                                    disabled={values?.id ? "disabled" : ""}
                                                >
                                                </DrButtonDropdown>
                                            </div>
                                        </div>
                                        {true&&
                                            <>
                                                <div className="col-12 col-xl-3 col-lg-6">
                                                    {shipstationWarehouses && shipstationWarehouses.length > 0 &&
                                                        <div className="form-group has-validation">
                                                            <label>Map Warehouse</label>
                                                            <Field
                                                                onBlur={() => { setTouched({ ...touched.mapWareHouseId, 'mapWareHouseId': true }) }}
                                                                onChange={(e) => {
                                                                    setFieldValue('mapWareHouseId', e);
                                                                }}
                                                                isMulti={false}
                                                                name={'mapWareHouseId'}
                                                                component={DrSelectBox}
                                                                placeholder="Select Warehouse"
                                                                options={shipstationWarehouses?.map(x => ({ value: x.warehouseId, label: x.warehouseName }))} />
                                                        </div>
                                                    }

                                                </div>
                                                <div className="col-12 col-xl-3 col-lg-5">
                                                    <div className="form-group has-validation">
                                                        <label>Carrier Code Selection</label>
                                                        <Field
                                                            onBlur={() => { setTouched({ ...touched.carrierCode, 'carrierCode': true }) }}
                                                            onChange={(e) => {
                                                                setFieldValue('carrierCode', e.value);
                                                                callCarrierService(e.value);
                                                            }}
                                                            isMulti={false}
                                                            name={'carrierCode'}
                                                            component={DrSelectBox}
                                                            placeholder="Select Carrier"
                                                            options={shipstationCarriers?.map(x => ({ value: x.code, label: x.name }))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-3 col-lg-6">
                                                    <div className="form-group has-validation">
                                                        <label>Carrier Service Selection</label>
                                                        <Field
                                                            onBlur={() => { setTouched({ ...touched.carrierServiceCode, 'carrierServiceCode': true }) }}
                                                            onChange={(e) => {
                                                                setFieldValue('carrierServiceCode', e.value);
                                                            }}
                                                            isMulti={false}
                                                            name={'carrierServiceCode'}
                                                            component={DrSelectBox}
                                                            placeholder="Select Carrier"
                                                            options={shipstationCarrierServices?.find(x => x.carrierCode === values.carrierCode)?.data?.map(x => ({ value: x.code, label: x.name })) || []} />
                                                    </div>
                                                </div>
                                            </>
                                        }


                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="form-group has-validation">
                                        <label>SoftWare Link</label>
                                        <DrInput className={`form-control`}
                                            name="sorftWareLink" type="text" placeholder={"SorftWare Link"}
                                            value={values.sorftWareLink || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={(touched.sorftWareLink && errors.sorftWareLink)}
                                            isValid={touched.sorftWareLink && !errors.sorftWareLink}
                                            validationText={errors.sorftWareLink} />
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="row justify-content-center">


                                        <div className="col-4">
                                            <div className="form-group has-validation">
                                                <label>User Name</label>
                                                <DrInput className={`form-control`}
                                                    name="userName" type="text" placeholder={"User Name"}
                                                    value={values.userName || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.userName && errors.userName)}
                                                    isValid={touched.userName && !errors.userName}
                                                    validationText={errors.userName} />
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group has-validation">
                                                <label>Password</label>
                                                <DrInput className={`form-control`}
                                                    name="password" type="text" placeholder={"Password"}
                                                    value={values.password || ""}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={(touched.password && errors.password)}
                                                    isValid={touched.password && !errors.password}
                                                    validationText={errors.password} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group has-validation">
                                                <label>SoftWare TypeId</label>
                                                <DrInput className={`form-control`}
                                                    name="softwareTypeId" type="number" step="1" placeholder={"SoftWare TypeId"}
                                                    value={values.softwareTypeId || ""}
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'softwareTypeId': true }) }}
                                                    isInvalid={(touched.softwareTypeId && errors.softwareTypeId)}
                                                    isValid={touched.softwareTypeId && !errors.softwareTypeId}
                                                    validationText={errors.softwareTypeId} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="row justify-content-center">
                                <div className="col-11">
                                    <div className="form-group has-validation">
                                        <label>Token</label>
                                        <DrInput className={`form-control ${(touched.token && errors.token) ? " is-invalid" : ""}`}
                                            name="token" type="text" placeholder={"Token"}
                                            value={values.token || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={(touched.token && errors.token)}
                                            isValid={touched.token && !errors.token}
                                            validationText={errors.token} />
                                    </div>
                                </div>
                            </div>


                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-wave waves-effect waves-light shadow btn-block mt-4" type="submit"
                                            onClick={handleSubmit}
                                        >Kaydet</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                )
                }
            </Formik>
        </>
    )
}
export default DrNewWarehouseShipping