import types from "../actions/utilities.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_BULK_OPERATION_DATA:
            return {
                ...state, "bulkOperationData": {
                    ...state.bulkOperationData,
                    ...action.payload
                }

            };
        case types.SET_BULK_OPERATION_RESPONSE:
            return {
                ...state, "bulkOperationData": {
                    ...state.bulkOperationData,
                    responses: state.bulkOperationData?.responses?.filter(x => x.value !== action.payload.checkValue).concat(action.payload)
                }
            };
        case types.CLEAR_BULK_OPERATION_DATA:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "bulkOperationData": {
        "responses": [],
        "totalJobs": 0,
        "completedJobs": 0
    }
}

export default reducer;




