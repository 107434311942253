import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrCampaignMailTemplate, DrCreateSubUserTemplate, DrInput, DrSelectBox } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Formik } from "formik";
import $ from 'jquery';
import { generateHtmlToString, generatePassword, generateStringToHtml } from '../../utils/utils';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { render as EmailRender } from '@react-email/render';
import i18next from 'i18next';
import { sendMail } from '../../state-management/actions/utilities.actions';
import Restricted from '../../security/Restricted';
import { toastr } from 'react-redux-toastr';
import { useNavigate, useParams } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import { feedbackSendTimeDelayDaysOptions, feedbackSendTimeOptions } from '../../components/hoc/Constants/dr-constants.component';
import { getAmazonFeedback, getFeedbackCampaign, getFeedbackCampaignSuccess, upsertFeedbackCampaign, upsertFeedbackCampaignSuccess } from '../../state-management/actions/feedback.actions';
import { getRouteByKey } from '../../routes';

function CreateCampaignPage() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN } = process.env;
    const { t } = useTranslation('createCampaignPage');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allCampaigns = useSelector(state => state.feedback.allCampaigns);
    const lastSavedCampaign = useSelector(state => state.feedback.lastSavedCampaign);
    const { feedbackId } = useParams();

    useEffect(() => {

        dispatch(getAmazonFeedback({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: 0, pageSize: 20, orderByParam: "date desc" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedStore?.customerMainSellerId) {
            if (feedbackId) {
                dispatch(getFeedbackCampaign({ customerMainSellerId: selectedStore?.customerMainSellerId, id: feedbackId }));
            }
            if (lastSavedCampaign) {
                let id = lastSavedCampaign;
                dispatch(upsertFeedbackCampaignSuccess(null));
                navigate("/create-campaign/" + id);
                // dispatch(getFeedbackCampaign({ customerMainSellerId: selectedStore?.customerMainSellerId, id: lastSavedCampaign }));
            }

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackId, lastSavedCampaign]);

    const initialValues = {
        id: allCampaigns?.length > 0 ? allCampaigns[0].id : null,
        customerMainSellerId: selectedStore?.customerMainSellerId,
        subject: allCampaigns?.length > 0 ? allCampaigns[0].subject : i18next.t('drMailTemplates:campaignDefaultTemplate.subject'),
        emailBody: allCampaigns?.length > 0 ? generateHtmlToString(allCampaigns[0].emailBody) : i18next.t('drMailTemplates:campaignDefaultTemplate.text'),
        negativeFeedbackBuyersIncluded: allCampaigns?.length > 0 ? allCampaigns[0].negativeFeedbackBuyersIncluded : false,
        negativeFeedbackOrdersIncluded: allCampaigns?.length > 0 ? allCampaigns[0].negativeFeedbackOrdersIncluded : false,
        canceledOrReturnedOrdersIncluded: allCampaigns?.length > 0 ? allCampaigns[0].canceledOrReturnedOrdersIncluded : true,
        notDeliveredOnTimeORdersIncluded: allCampaigns?.length > 0 ? allCampaigns[0].notDeliveredOnTimeORdersIncluded : false,
        feedbackSendTimeDelayDays: allCampaigns?.length > 0 ? allCampaigns[0].feedbackSendTimeDelayDays : 0,
        feedbackSendTime: allCampaigns?.length > 0 ? allCampaigns[0].feedbackSendTime : "09:00:00",
        isActive: allCampaigns?.length > 0 ? allCampaigns[0].isActive : true
    };

    const mailParams = [
        "{{AmazonOrderId}}", "{{ASIN}}", "{{BuyerEmail}}", "{{DeliveredDate}}", "{{FeedbackLink}}", "{{FLinkWithProduct}}",
        "{{ProductName}}", "{{ProductUrl}}", "{{ProductUrlWithName}}", "{{PurchaseDate}}", "{{SenderName}}", "{{ShipDate}}", "{{StoreName}}"
    ]

    const testEmail = async (values) => {
        var temporaryPassword = generatePassword(10);
        // let body = <DrCreateSubUserTemplate temporaryPassword={temporaryPassword} siteUrl={window.location.origin} firstName={values.firstName || "TEST"} mainUserFirstName="SellerDoping" ></DrCreateSubUserTemplate>;
        // let body = <DrCampaignMailTemplate htmlText={i18next.t('drMailTemplates:campaignDefaultTemplate.text')}></DrCampaignMailTemplate>
        let body = <DrCampaignMailTemplate htmlText={generateStringToHtml(renderToString(values.emailBody))}></DrCampaignMailTemplate>


        var emailData = {
            emailTo: "koraypapagan@live.com",
            emailToName: "koray papagan",
            emailSubject: values.subject,// i18next.t('drMailTemplates:campaignDefaultTemplate.subject'),
            emailBody: generateStringToHtml(renderToString(values.emailBody))
        }

        dispatch(sendMail(emailData));
    }

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.feedback'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.createCampaign'), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row row-md row-lg d-flex justify-content-center">
                <div className="col-md-8 col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validateOnBlur={true}
                                //   onSubmit={handleSubmit}
                                //   validationSchema={subUserInfoSchema}
                                enableReinitialize={true}
                                validateOnMount={true}
                            >
                                {({
                                    isValid,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    setTouched,
                                    setFieldValue,
                                    resetForm

                                }) => (
                                    <form>
                                        <div className={`row row-md row-lg my-1 justify-content-end`} >
                                            <div className="col-auto my-1">
                                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                                    <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            dispatch(getFeedbackCampaignSuccess([]));
                                                            navigate("/create-campaign")
                                                            resetForm();
                                                        }}>
                                                        <i className="w-6 me-2 fa fa-plus"></i>
                                                        {t('addNewCampaign')}
                                                    </button>
                                                </Restricted>
                                            </div>
                                            <div className="col-auto my-1  ps-0">
                                                <Restricted allowedTo={[REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUPERADMIN]}>
                                                    <button className={`btn btn-info btn-wave waves-effect waves-light shadow`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            resetForm();
                                                            navigate(getRouteByKey("FEEDBACK_CAMPAIGNS"));
                                                        }}>
                                                        <i className="w-6 me-2 fa fa-plus"></i>
                                                        {t('gotoCampaignList')}
                                                    </button>
                                                </Restricted>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div className="row">
                                                    <div className="col-12 col-xxl-6 col-xl-6">
                                                        <hr></hr>
                                                        <div className="row my-1">
                                                            <div className="col-6 my-auto pe-0">{t('feedbackSendTimeDelayDays')}</div>
                                                            <div className="col ps-0">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.feedbackSendTimeDelayDays, 'feedbackSendTimeDelayDays': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue('feedbackSendTimeDelayDays', e.value);
                                                                    }}
                                                                    isMulti={false}
                                                                    selectedValue={values.feedbackSendTimeDelayDays}
                                                                    name={'feedbackSendTimeDelayDays'}
                                                                    component={DrSelectBox}
                                                                    placeholder={t('feedbackSendTimeDelayDays')}
                                                                    options={feedbackSendTimeDelayDaysOptions} />
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-6 my-auto pe-0">{t('feedbackSendTime')}</div>
                                                            <div className="col-auto ps-0">
                                                                <Field
                                                                    onBlur={() => { setTouched({ ...touched.feedbackSendTime, 'feedbackSendTime': true }) }}
                                                                    onChange={(e) => {
                                                                        setFieldValue('feedbackSendTime', e.value);
                                                                    }}
                                                                    isMulti={false}
                                                                    selectedValue={values.feedbackSendTime}
                                                                    name={'feedbackSendTime'}
                                                                    component={DrSelectBox}
                                                                    placeholder="Select"
                                                                    options={feedbackSendTimeOptions} />
                                                            </div>

                                                        </div>
                                                        <hr></hr>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0 pe-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="isCampaignActive" className="form-check-input form-checked-success" role="switch" placeholder="" name="isActive" type="checkbox"
                                                                        value=""
                                                                        checked={values.isActive}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'isActive': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.isActive) ? "" : "text-muted"}`} htmlFor="isCampaignActive"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('isActive')}
                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-12 col-xxl-6 col-xl-6">
                                                        <hr></hr>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0 pe-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="negativeFeedbackBuyersIncluded" className="form-check-input form-checked-success" role="switch" placeholder="" name="negativeFeedbackBuyersIncluded" type="checkbox"
                                                                        value=""
                                                                        checked={values.negativeFeedbackBuyersIncluded}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'negativeFeedbackBuyersIncluded': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.negativeFeedbackBuyersIncluded) ? "" : "text-muted"}`} htmlFor="negativeFeedbackBuyersIncluded"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('negativeFeedbackBuyersIncluded')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0 pe-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="negativeFeedbackOrdersIncluded" className="form-check-input form-checked-success" role="switch" placeholder="" name="negativeFeedbackOrdersIncluded" type="checkbox"
                                                                        value=""
                                                                        checked={values.negativeFeedbackOrdersIncluded}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'negativeFeedbackOrdersIncluded': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.negativeFeedbackOrdersIncluded) ? "" : "text-muted"}`} htmlFor="negativeFeedbackOrdersIncluded"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('negativeFeedbackOrdersIncluded')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0 pe-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="canceledOrReturnedOrdersIncluded" className="form-check-input form-checked-success" role="switch" placeholder="" name="canceledOrReturnedOrdersIncluded" type="checkbox"
                                                                        value=""
                                                                        checked={values.canceledOrReturnedOrdersIncluded}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'canceledOrReturnedOrdersIncluded': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.canceledOrReturnedOrdersIncluded) ? "" : "text-muted"}`} htmlFor="canceledOrReturnedOrdersIncluded"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('canceledOrReturnedOrdersIncluded')} <span className="badge badge-sm bg-success">{t('recommended')}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row my-1">
                                                            <div className="col-auto mt-0 pe-0">
                                                                <div className="form-check form-check-md form-switch">
                                                                    <DrInput id="notDeliveredOnTimeORdersIncluded" className="form-check-input form-checked-success" role="switch" placeholder="" name="notDeliveredOnTimeORdersIncluded" type="checkbox"
                                                                        value=""
                                                                        checked={values.notDeliveredOnTimeORdersIncluded}
                                                                        onChange={handleChange}
                                                                        onBlur={() => { setTouched({ ...touched, 'notDeliveredOnTimeORdersIncluded': true }) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col mt-0">
                                                                <label className={`form-check-label cursor-pointer no-select ${(values.notDeliveredOnTimeORdersIncluded) ? "" : "text-muted"}`} htmlFor="notDeliveredOnTimeORdersIncluded"
                                                                    style={{ marginTop: '2px' }}>
                                                                    {t('notDeliveredOnTimeORdersIncluded')}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className="row ">
                                                    <div className="d-xxl-none d-xl-none d-block col-12 px-0 d-flex flex-column">
                                                        <div className="card custom-card bg-dark bg-opacity-10 flex-grow-shrink">
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    {mailParams.map((param, index) => {

                                                                        return (
                                                                            <div key={"mail-param-horizontal-" + index} className="col-auto pe-0">
                                                                                <label>
                                                                                    <span className="fs-14 fw-semibold mb-1 badge bg-info bg-opacity-75 cursor-pointer shadow"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            navigator.clipboard.writeText(param);
                                                                                            toastr.info("Info", param + " is copied to clipboard", { timeOut: 1000 })
                                                                                        }}>{param}</span>

                                                                                </label>
                                                                            </div>

                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 d-flex flex-column">
                                                        <div className="card custom-card bg-info bg-opacity-25  flex-grow-shrink">
                                                            <div className="card-body">
                                                                <div className="row mb-1">
                                                                    <div className="col-auto">
                                                                        <span className="fw-bold">{t('subject')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-2">
                                                                    <div className="col">
                                                                        <DrInput value={values.subject || ""} className={`form-control`}
                                                                            placeholder={t('subject')}
                                                                            name="subject"
                                                                            type="text"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            isInvalid={(touched.subject && errors.subject)}
                                                                            isValid={touched.subject && !errors.subject}
                                                                            validationText={errors.subject}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-1">
                                                                    <div className="col">
                                                                        <textarea id="campaign-message-textarea" className={`form-control w-100`} placeholder="Type your message here..." type="html"
                                                                            style={{ minHeight: '550px', height: "auto" }}
                                                                            value={values?.emailBody}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                var textarea = document.getElementById("campaign-message-textarea");
                                                                                textarea.style.height = "";
                                                                                textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";

                                                                                setFieldValue("emailBody", e.currentTarget.value)
                                                                            }}
                                                                            onBlur={() => { setTouched({ ...touched.emailBody, 'emailBody': true }) }}

                                                                            required=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-auto col-xl-auto d-xxl-flex d-xl-flex d-none col-12 pe-0 d-flex flex-column">
                                                        <div className="card custom-card bg-dark bg-opacity-10 flex-grow-shrink">
                                                            <div className="card-body">
                                                                {mailParams.map((param, index) => {

                                                                    return (
                                                                        <div key={"mail-param-vertical-" + index} className="row my-2">
                                                                            <div className="col-12">
                                                                                <label>
                                                                                    <span className="fs-14 fw-semibold mb-1 badge bg-info bg-opacity-75 cursor-pointer shadow"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            navigator.clipboard.writeText(param);
                                                                                            toastr.info("Info", param + " is copied to clipboard", { timeOut: 1000 })
                                                                                        }}>{param}</span>

                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-end">
                                                    <div className="col-auto my-1 pe-0">
                                                        <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                                            <button className={`btn btn-teal btn-wave waves-effect waves-light shadow`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    
                                                                }}
                                                            >
                                                                <i className="fe fe-search me-2"></i>
                                                                {t('preview')}</button>
                                                        </Restricted>
                                                    </div>
                                                    <div className="col-auto my-1 pe-0">
                                                        <Restricted allowedTo={[REACT_APP_ROLES_ADMIN]}>
                                                            <button className={`btn btn-primary btn-wave waves-effect waves-light shadow`}
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    // let body = <DrCampaignMailTemplate htmlText={generateStringToHtml(renderToString(values.emailBody))}></DrCampaignMailTemplate>

                                                                    var requestData = {
                                                                        ...values,
                                                                        emailBody: generateStringToHtml(renderToString(values.emailBody))
                                                                    }
                                                                    dispatch(upsertFeedbackCampaign(requestData))


                                                                }}
                                                            > {allCampaigns?.length > 0 ? t('update') : t('save')}</button>
                                                        </Restricted>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )
                                }
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateCampaignPage;