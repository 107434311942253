import types from "../actions/reports.actions";

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case types.GET_BEST_SELLING_PRODUCTS_SUCCESS:
            return { ...state, bestSellingProducts: action.payload };

        case types.GET_INVENTORY_PRODUCT_CATEGORIES_SUCCESS:
            return { ...state, inventoryProductsCategories: action.payload };
        case types.GET_INVENTORY_PRODUCT_STATUS_COUNTS_SUCCESS:
            return { ...state, productStatusCounts: action.payload };

        case types.GET_REPORT_SUCCESS:
            return { ...state, reportData: action.payload };
       
        case types.CLEAR_ALL_REPORTS:
            return initialState;
        default:
            return state;
    }
}

const initialState = {
    "bestSellingProducts": [],

    "inventoryProductsCategories": [],
    "productStatusCounts": [],
    "reportData": []
}

export default reducer;