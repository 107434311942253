import './dr-loading-indicator.scss';
import loadingImg from "../../../assets/images/media/loader.svg"
function DrLoadingIndicator({ show }) {
    return (<>
        {
            show && <>
                <div className="blockOverlay"></div>
                <div className="loader">
                    <img src={loadingImg} alt=""></img>
                </div>
                {/* <div id="loader">
                    <img src={loadingImg} alt=""></img>
                </div> */}
            </>
        }
    </>)
}

export default DrLoadingIndicator;