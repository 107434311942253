import { useTranslation } from "react-i18next";
import { clearActiveCreateCustomerSupportMessageModal } from "../../../../state-management/actions/app.actions";
import { useDispatch } from "react-redux";
import $ from 'jquery';

function DrCreateSupportMessageModal({ display, source, persistModal = false }) {

    const dispatch = useDispatch();
    const { t } = useTranslation('drCreateSupportMessage');

    return (
        <div className={`modal fade modal-background ${display ? "effect-scale show " : ""}`} style={{ display: display ? "block" : "none" }}
            tabIndex="-1" role="dialog" aria-labelledby="searchTaskModal" aria-modal="true" aria-hidden="true"
            onClick={(e) => {
                if (persistModal) {
                    return;
                }
                if ($(e.target).hasClass("modal-background")) {
                    dispatch(clearActiveCreateCustomerSupportMessageModal());
                }
            }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header" style={{ minHeight: '48px' }}>
                        <h6 className="modal-title" id="exampleModalLongTitle">{t('title')}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            onClick={() => dispatch(clearActiveCreateCustomerSupportMessageModal())} >
                        </button>
                    </div>
                    <div className="modal-body justify-contents-center">
                        {display &&
                            <>
                                hello
                            </>
                        }
                    </div>
                    <div className="modal-footer">
                        <div className="row justify-content-end">
                            <div className="col-auto">
                                <button type="button" className={`btn btn-success btn-wave waves-effect waves-light shadow ${source?.data ? "" : "disabled"}`} data-dismiss="modal"
                                    onClick={() => {

                                    }}>
                                    <i className="me-2 fe fe-download"></i>
                                    {t('save')}
                                </button>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary btn-wave waves-effect waves-light shadow" data-dismiss="modal"
                                    onClick={() => dispatch(clearActiveCreateCustomerSupportMessageModal())}>{t('close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DrCreateSupportMessageModal