import React from 'react'
import {  DrInput,  DrNavigator, DrSelectBox } from '../../components/component-index';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { setMarketPreferences, setSnapshotStorePageSubmission } from '../../state-management/actions/create-store.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { handlingTimes, maxDeliveryDays15to30, autoDeleteIntervals, currencyConversionTypes, sourceMarketOptions } from '../../components/hoc/Constants/dr-constants.component';

function StorePreferencesPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('storePreferencesPage');
    const marketPreferences = useSelector(state => state.createStoreSnapshot.marketPreferences);
    const marketSelection = useSelector(state => state.createStoreSnapshot.marketSelection);
    const customerInfo = useSelector(state => state.customer.customerInfo);
    const allMarkets = useSelector(state => state.markets.allMarkets);

    const initialValues = {
        customerSubUserId: customerInfo?.customerId,
        marketId: allMarkets.find(m => m.countryCode === marketSelection.countryCode)?.id,
        name: marketPreferences?.name || "",
        companySellerSerialNumber: marketPreferences?.companySellerSerialNumber || "",
        maxStock: marketPreferences?.maxStock || 20,
        primePercentage: marketPreferences?.primePercentage || 15,
        currencyConversion: marketPreferences?.currencyConversion || 1,
        conversionRate: marketPreferences?.conversionRate || null,
        enableTaxPayer: marketPreferences?.enableTaxPayer || false,
        enableSalesTaxRates: marketPreferences?.enableSalesTaxRates || false,
        aboveSalePrice: marketPreferences?.aboveSalePrice || null,
        aboveSalePriceRate: marketPreferences?.aboveSalePriceRate || null,
        aboveSalePriceOffset: marketPreferences?.aboveSalePriceOffset || null,
        belowSalePrice: marketPreferences?.belowSalePrice || null,
        belowSalePriceRate: marketPreferences?.belowSalePriceRate || null,
        belowSalePriceOffset: marketPreferences?.belowSalePriceOffset || null,
        enableImportFee: marketPreferences?.enableImportFee || false,
        importFeeRate: marketPreferences?.importFeeRate || null,
        enableCommunalPoolForbiddenProducts: marketPreferences?.enableCommunalPoolForbiddenProducts || false,
        enableCommunalPoolForbiddenProductsBrands: marketPreferences?.enableCommunalPoolForbiddenProductsBrands || false,
        enableCommunalPoolBlockedBrands: marketPreferences?.enableCommunalPoolBlockedBrands || false,
        enableCommunalPoolAutoAddBrands: marketPreferences?.enableCommunalPoolAutoAddBrands || false,
        enableCommunalPoolAutoAddProducts: marketPreferences?.enableCommunalPoolAutoAddProducts || false,
        enableHandlingTime: marketPreferences?.enableHandlingTime || false,
        handlingTime: marketPreferences?.handlingTime || 2,
        enableMaxDelivery: marketPreferences?.enableMaxDelivery || false,
        maxDeliveryTime: marketPreferences?.maxDeliveryTime || null,
        enableAutoDeleteOutCriteria: marketPreferences?.enableAutoDeleteOutCriteria || false,
        autoDeleteTimePeriods: marketPreferences?.autoDeleteTimePeriods || null,
        enableAutoCarrierChange: marketPreferences?.enableAutoCarrierChange || false,
        presentCardSender: marketPreferences?.presentCardSender || "",
        presentNote: marketPreferences?.presentNote || "",
        enableAutoOrderConfirm: marketPreferences?.enableAutoOrderConfirm || true,
        enableAutoOrderPreConfirm: marketPreferences?.enableAutoOrderPreConfirm || true,
        enableAfterOrderPlaced: marketPreferences?.enableAfterOrderPlaced || false,
        afterOrderPlacedTime: marketPreferences?.afterOrderPlacedTime || null,
        enableAutoPriceUpdate: marketPreferences?.enableAutoPriceUpdate || true,
        enableAutoStockUpdate: marketPreferences?.enableAutoStockUpdate || true,
        enableAutoProductDeletion: marketPreferences?.enableAutoProductDeletion || true,
        enableAutoProductAdd: marketPreferences?.enableAutoProductAdd || true,
        askConfirmationForNewProducts: marketPreferences?.askConfirmationForNewProducts || true,
        sourceMarkets: marketPreferences?.sourceMarkets?.length > 0 ? sourceMarketOptions.filter(function (o) {
            return marketPreferences?.sourceMarkets.find(r => r === o.value);
        }) : sourceMarketOptions.filter(x => x.countryCode === "US")
        // sourceMarkets: marketPreferences?.sourceMarkets || []
    }

    const storePreferencesSchema = Yup.object().shape({
        companySellerSerialNumber: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Company Serial number is required'),
        name: Yup.string()
            .min(3, 'Too Short!')
            .max(255, 'Too Long!')
            .required('Amazon store name is required'),
        maxStock: Yup.string().required("Maximum stock is required")
            .matches(/^[0-9]+$/, "Must be only digits"),
        primePercentage: Yup.string()
            .required('Amazon percentage required')
            .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),

        currencyConversion: Yup.number().required('Required'),
        conversionRate: Yup.string().nullable().when('currencyConversion', {
            is: (value) => value && value !== 1,
            then: () => Yup.string().required('Currency conversion rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableSalesTaxRates: Yup.bool().required('Required'),
        aboveSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        aboveSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        belowSalePrice: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceRate: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),
        belowSalePriceOffset: Yup.string().nullable().when('enableSalesTaxRates', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Sale Price offset is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableImportFee: Yup.bool().required('Required'),
        importFeeRate: Yup.string().nullable().when('enableImportFee', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Import fee rate is required')
                .matches(/^(\d{1,3})+(\.\d{1,2})?$/, "Must match the format"),
            otherwise: () => Yup.string().nullable(),
        }),

        enableHandlingTime: Yup.bool().required('Required'),
        handlingTime: Yup.string().nullable().when('enableHandlingTime', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Handling Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        enableMaxDelivery: Yup.bool().required('Required'),
        maxDeliveryTime: Yup.string().nullable().when('enableMaxDelivery', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Max Delivery Time is required'),
            otherwise: () => Yup.string().nullable(),
        }),

        enableAutoDeleteOutCriteria: Yup.bool().required('Required'),
        autoDeleteTimePeriods: Yup.string().nullable().when('enableAutoDeleteOutCriteria', {
            is: (value) => value && value !== false,
            then: () => Yup.string().required('Delete Time Period is required'),
            otherwise: () => Yup.string().nullable(),
        }),
        // disclaimer: Yup.boolean().oneOf([true], 'You must accept the disclaimer')
        // termsOfService: Yup.boolean()
        // .required("The terms and conditions must be accepted.")
        // .oneOf([true], "The terms and conditions must be accepted.")
    });

    const handleAmazonRedirect = () => {
        window.open('https://sellercentral.amazon.ca/sw/AccountInfo/MerchantToken/step/MerchantToken?ref_=macs_aimertok_cont_acinfohm&mons_redirect=stck_reroute', '_blank');
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validateOnBlur={true}
                // onSubmit={handleSubmit}
                validationSchema={storePreferencesSchema}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnChange={true}
            >
                {({
                    isValid,
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setTouched,
                    setFieldValue
                }) => (
                    <form>
                        <section>
                            {/* <form> */}

                            <div className="row row-md row-lg px-3">
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('merchantToken')}</label>
                                    <div className="d-flex align-items-center">
                                        <DrInput 
                                            value={values.companySellerSerialNumber || ""} 
                                            className="form-control me-2" 
                                            placeholder={t('merchantToken')} 
                                            name="companySellerSerialNumber" 
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={() => { setTouched({ ...touched, 'companySellerSerialNumber': true }) }}
                                            isInvalid={(touched.companySellerSerialNumber && errors.companySellerSerialNumber)}
                                            isValid={touched.companySellerSerialNumber && !errors.companySellerSerialNumber}
                                            validationText={errors.companySellerSerialNumber}
                                        />
                                        <button 
                                            type="button"
                                            className="btn btn-outline-warning ms-2 d-flex align-items-center"
                                            onClick={handleAmazonRedirect}
                                            style={{width: '300px'}}
                                        >
                                            <img 
                                                src={require('../../assets/img/cards/AmazonPay.png')} 
                                                alt="Amazon" 
                                                style={{width: '30px'}}
                                            />
                                            {t('showOnAmazon')}
                                        </button>
                                    </div>
                                    <small>{t('merchantTokenDescription')}</small>
                                </div>
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('amazonStoreName')}</label>
                                    <DrInput value={values.name || ""} className="form-control" placeholder={t('amazonStoreName')} name="name" type="text" onChange={handleChange}
                                        onBlur={() => { setTouched({ ...touched, 'name': true }) }}
                                        isInvalid={(touched.name && errors.name)}
                                        isValid={touched.name && !errors.name}
                                        validationText={errors.name}
                                    />

                                    <small >{t('amazonStoreNameDescription')}</small>
                                </div>
                                <div className="col-mg-4 col-lg-4 mt-3">
                                    <div className="form-group has-validation">
                                        <label>{t('sourceMarkets')}</label>
                                        <Field
                                            onBlur={() => { setTouched({ ...touched.sourceMarkets, 'sourceMarkets': true }) }}
                                            onChange={(e) => {
                                                if (e) {
                                                    setFieldValue("sourceMarkets", e);
                                                }
                                            }}

                                            isMulti={true}
                                            name={'sourceMarkets'}
                                            component={DrSelectBox}
                                            placeholder={t('sourceMarketsPlaceholder')}
                                            disableInputChange={true}
                                            options={sourceMarketOptions} />
                                    </div>
                                </div>
                            </div>
                            <div className="row row-md row-lg px-3">
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('maxStock')}</label>
                                    <DrInput value={values.maxStock || ""} className="form-control" placeholder={t('maxStock')} name="maxStock" type="number" onChange={handleChange}
                                        onBlur={() => { setTouched({ ...touched, 'maxStock': true }) }}
                                        isInvalid={(touched.maxStock && errors.maxStock)}
                                        isValid={touched.maxStock && !errors.maxStock}
                                        validationText={errors.maxStock}
                                        icon={<i className="fe fe-package text-muted"></i>}
                                        iconPosition="left"
                                    />
                                    <small >{t('maxStockDescription')}</small>
                                </div>
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('amazonComission')}</label>
                                    <DrInput value={values.primePercentage || ""} className="form-control" placeholder={t('amazonComission')} name="primePercentage" type="number" step="0.1" onChange={handleChange}
                                        onBlur={() => { setTouched({ ...touched, 'primePercentage': true }) }}
                                        isInvalid={(touched.primePercentage && errors.primePercentage)}
                                        isValid={touched.primePercentage && !errors.primePercentage}
                                        validationText={errors.primePercentage}
                                        icon={<i className="fe fe-percent text-muted"></i>}
                                        iconPosition="left"
                                    />
                                    <small >{t('amazonComissionDescription')}</small>
                                </div>
                            </div>
                            <div className="row row-md row-lg px-3">
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('conversion')}</label>
                                    <Field
                                        onBlur={() => { setTouched({ ...touched.currencyConversion, 'currencyConversion': true, 'conversionRate': true }) }}
                                        onChange={(e) => {
                                            setFieldValue("currencyConversion", e.value);
                                            if (e.value === 1) {
                                                setFieldValue("conversionRate", null);
                                            }
                                        }}
                                        name={'currencyConversion'}
                                        component={DrSelectBox}
                                        placeholder="Currency Conversion"
                                        options={currencyConversionTypes} />
                                </div>
                                <div className="col-md-4 col-lg-4 mt-3">
                                    <label>{t('conversionRate')}</label>
                                    <DrInput className="form-control" placeholder={t('conversionRate')} name="conversionRate" type="number" step="0.01"
                                        value={values.conversionRate || ""}
                                        disabled={`${values.currencyConversion === 2 ? "" : "disabled"}`}
                                        onChange={handleChange}
                                        onBlur={() => { setTouched({ ...touched, 'conversionRate': true }) }}
                                        isInvalid={(touched.conversionRate && errors.conversionRate)}
                                        isValid={touched.conversionRate && !errors.conversionRate}
                                        validationText={errors.conversionRate}
                                        icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                        iconPosition="left"
                                    />

                                    <small>{t('conversionRatedescription')}</small>
                                </div>
                            </div>
                            {/* </form> */}
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="taxPayer" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('taxPayer')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="taxPayer" value="" checked={values.enableTaxPayer} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableTaxPayer" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableTaxPayer': true }) }} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableTaxPayer) ? "" : "text-muted"}`}>{t('taxPayerDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="salesTaxRates" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableTaxPercent')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="salesTaxRates" value="" checked={values.enableSalesTaxRates} className="form-check-input form-checked-info" placeholder="" name="enableSalesTaxRates" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableSalesTaxRates': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-chevron-right"></i></label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="aboveSalePrice" type="number" step="0.01"
                                                value={values.aboveSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'aboveSalePrice': true }) }}
                                                icon={<i className="fe fe-package text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.aboveSalePrice && errors.aboveSalePrice)}
                                                isValid={touched.aboveSalePrice && !errors.aboveSalePrice}
                                                validationText={errors.aboveSalePrice}
                                            />

                                        </div>
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="aboveSalePriceRate" type="number" step="0.01"
                                                value={values.aboveSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'aboveSalePriceRate': true }) }}
                                                icon={<i className="fe fe-percent text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.aboveSalePriceRate && errors.aboveSalePriceRate)}
                                                isValid={touched.aboveSalePriceRate && !errors.aboveSalePriceRate}
                                                validationText={errors.aboveSalePriceRate}
                                            />

                                        </div>
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">
                                                <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                            </label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="aboveSalePriceOffset" type="number" step="0.01"
                                                value={values.aboveSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'aboveSalePriceOffset': true }) }}
                                                icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.aboveSalePriceOffset && errors.aboveSalePriceOffset)}
                                                isValid={touched.aboveSalePriceOffset && !errors.aboveSalePriceOffset}
                                                validationText={errors.aboveSalePriceOffset}
                                            />

                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">{t('productPrice')}<i data-toggle="tooltip" title="smaller than" data-placement="bottom" className="fe fe-chevron-left"></i></label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="belowSalePrice" type="number" step="0.01"
                                                value={values.belowSalePrice || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'belowSalePrice': true }) }}
                                                icon={<i className="fe fe-package text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.belowSalePrice && errors.belowSalePrice)}
                                                isValid={touched.belowSalePrice && !errors.belowSalePrice}
                                                validationText={errors.belowSalePrice}
                                            />

                                        </div>
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">{t('then')}</label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="belowSalePriceRate" type="number" step="0.01"
                                                value={values.belowSalePriceRate || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'belowSalePriceRate': true }) }}
                                                icon={<i className="fe fe-percent text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.belowSalePriceRate && errors.belowSalePriceRate)}
                                                isValid={touched.belowSalePriceRate && !errors.belowSalePriceRate}
                                                validationText={errors.belowSalePriceRate}
                                            />

                                        </div>
                                        <div className="col-auto pe-0 ps-0">
                                            <label className="ms-2 me-2 mt-2">
                                                <i data-toggle="tooltip" title="greater than" data-placement="bottom" className="fe fe-plus"></i>
                                            </label>
                                        </div>
                                        <div className="col-md-2 col-lg-2 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder="" name="belowSalePriceOffset" type="number" step="0.01"
                                                value={values.belowSalePriceOffset || ""} disabled={`${values.enableSalesTaxRates ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'belowSalePriceOffset': true }) }}
                                                icon={<i className="fe fe-dollar-sign text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.belowSalePriceOffset && errors.belowSalePriceOffset)}
                                                isValid={touched.belowSalePriceOffset && !errors.belowSalePriceOffset}
                                                validationText={errors.belowSalePriceOffset}
                                            />

                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className={`row row-md row-lg ${(values.enableSalesTaxRates) ? "" : "text-muted"}`} >
                                        <div className="col-sm-12 pe-0 ps-2">
                                            <small className={`${(values.enableSalesTaxRates) ? "" : "text-muted"}`}>{t('enableTaxPercentDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="importFee" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableImportFee')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-6">

                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="importFee" value="" checked={values.enableImportFee} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableImportFee" type="checkbox"
                                                    onChange={(e) => {
                                                        setFieldValue("enableImportFee", e.currentTarget.checked);
                                                        if (!e.currentTarget.checked) {
                                                            setFieldValue("importFeeRate", null);
                                                        }
                                                    }}
                                                    onBlur={() => { setTouched({ ...touched, 'enableImportFee': true, 'importFeeRate': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 pe-0 ps-0">
                                            <DrInput className="form-control" placeholder={t('importFee')} name="importFeeRate" type="number" step="0.01"
                                                value={values.importFeeRate || ""} disabled={`${values.enableImportFee ? "" : "disabled"}`}
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'importFeeRate': true }) }}
                                                icon={<i className="fe fe-percent text-muted"></i>}
                                                iconPosition="left"
                                                isInvalid={(touched.importFeeRate && errors.importFeeRate)}
                                                isValid={touched.importFeeRate && !errors.importFeeRate}
                                                validationText={errors.importFeeRate}
                                            />

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-2">
                                            <small className={`${(values.enableImportFee) ? "" : "text-muted"}`}>{t('enableImportFeeDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="forbiddenProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableForbiddedProducts')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="forbiddenProducts" value="" checked={values.enableCommunalPoolForbiddenProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolForbiddenProducts" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolForbiddenProducts': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableCommunalPoolForbiddenProducts) ? "" : "text-muted"}`}>{t('enableForbiddedProductsDescription')}</small>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1">
                                        </div>
                                        <div className="col-sm-11">
                                            <div className="row">
                                                <div className="col-auto my-auto">
                                                    <div className="form-check form-check-md form-switch mt-2" style={{ display: values.enableCommunalPoolForbiddenProducts ? 'block' : 'none' }}>
                                                        <DrInput id="forbiddenProductsBrands" value=""
                                                            checked={values.enableCommunalPoolForbiddenProducts && values.enableCommunalPoolForbiddenProductsBrands}
                                                            className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolForbiddenProductsBrands" type="checkbox"
                                                            onChange={handleChange}
                                                            onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolForbiddenProductsBrands': true }) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col my-auto ps-0">
                                                    <label htmlFor="forbiddenProductsBrands" className={`form-check-label text-wrap no-select ms-2 mt-2 mb-0 text-start cursor-pointer ${(values.enableCommunalPoolForbiddenProductsBrands) ? "" : "text-muted"} ${(values.enableCommunalPoolForbiddenProducts) ? "d-block" : "d-none"}`}>
                                                        {t('enableForbiddedProductsBrands')}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-sm-1">
                                        </div>
                                        <div className="col-sm-11">
                                            <small className={`${(values.enableCommunalPoolForbiddenProductsBrands) ? "" : "text-muted"} ${(values.enableCommunalPoolForbiddenProducts) ? "d-block" : "d-none"}`}>{t('enableForbiddedProductsBrandsDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="blockedBrands" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">
                                    {t('enableBlockedBrands')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="blockedBrands" value="" checked={values.enableCommunalPoolBlockedBrands} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolBlockedBrands" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolBlockedBrands': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableCommunalPoolBlockedBrands) ? "" : "text-muted"}`}>{t('enableBlockedBrandsDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="autoAddBrands" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoAddBrands')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="autoAddBrands" value="" checked={values.enableCommunalPoolAutoAddBrands} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolAutoAddBrands" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolAutoAddBrands': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableCommunalPoolAutoAddBrands) ? "" : "text-muted"}`}>{t('enableAutoAddBrandsDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="autoAddProducts" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoAddProducts')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="autoAddProducts" value="" checked={values.enableCommunalPoolAutoAddProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableCommunalPoolAutoAddProducts" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableCommunalPoolAutoAddProducts': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableCommunalPoolAutoAddProducts) ? "" : "text-muted"}`}>{t('enableAutoAddProductsDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="handlingTimeInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableHandlingTime')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="handlingTimeInput" value="" checked={values.enableHandlingTime} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableHandlingTime" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableHandlingTime': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <Field onBlur={() => { setTouched({ ...touched.handlingTime, 'handlingTime': true }) }}
                                                name={'handlingTime'}
                                                component={DrSelectBox}
                                                placeholder={t('handlingTime')}
                                                disabled={!values.enableHandlingTime}
                                                options={handlingTimes} />
                                            {(touched.handlingTime && errors.handlingTime) && <label type="invalid" className="form-control is-invalid">
                                                {errors.handlingTime}
                                            </label>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-2">
                                            <small className={`${(values.enableHandlingTime) ? "" : "text-muted"}`}>{t('enableHandlingTimeDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="maxDeliveryInput" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableMaxDelivery')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="maxDeliveryInput" value="" checked={values.enableMaxDelivery} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableMaxDelivery" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableMaxDelivery': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <Field onBlur={() => { setTouched({ ...touched.maxDeliveryTime, 'maxDeliveryTime': true }) }}
                                                name={'maxDeliveryTime'}
                                                component={DrSelectBox}
                                                placeholder={t('maxDelivery')}
                                                disabled={!values.enableMaxDelivery}
                                                options={maxDeliveryDays15to30} />
                                            {(touched.maxDeliveryTime && errors.maxDeliveryTimeF) && <label type="invalid" className="form-control is-invalid">
                                                {errors.maxDeliveryTime}
                                            </label>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-2">
                                            <small className={`${(values.enableMaxDelivery) ? "" : "text-muted"}`}>{t('enableMaxDeliveryDescription')}<br></br>
                                                {t('enableMaxDeliveryNote')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="autoDeleteOutCriteria" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoDeleteOutCriteria')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="autoDeleteOutCriteria" value="" checked={values.enableAutoDeleteOutCriteria} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoDeleteOutCriteria" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoDeleteOutCriteria': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <Field onBlur={() => { setTouched({ ...touched.autoDeleteTimePeriods, 'autoDeleteTimePeriods': true }) }}
                                                name={'autoDeleteTimePeriods'}
                                                component={DrSelectBox}
                                                placeholder={t('autoDeleteOutCriteria')}
                                                disabled={!values.enableAutoDeleteOutCriteria}
                                                options={autoDeleteIntervals} />
                                            {(touched.autoDeleteTimePeriods && errors.autoDeleteTimePeriods) && <label type="invalid" className="form-control is-invalid">
                                                {errors.autoDeleteTimePeriods}
                                            </label>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-2">
                                            <small className={`${(values.enableAutoDeleteOutCriteria) ? "" : "text-muted"}`}>{t('enableAutoDeleteOutCriteriaDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="autoCarrierChange" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('enableAutoCarrierChange')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-check form-check-md form-switch mt-2">
                                                <DrInput id="autoCarrierChange" value="" checked={values.enableAutoCarrierChange} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoCarrierChange" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoCarrierChange': true }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small className={`${(values.enableAutoCarrierChange) ? "" : "text-muted"}`}>{t('enableAutoCarrierChangeDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="cardSender" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('presentCardSender')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-0">
                                            <DrInput id="cardSender" value={values.presentCardSender || ""} className="form-control" placeholder={t('presentCardSender')} name="presentCardSender" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'presentCardSender': true }) }}
                                                icon={<i className="fe fe-mail text-muted"></i>}
                                                iconPosition="left"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small >{t('presentCardSenderDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-md row-lg px-3">
                                <label htmlFor="cardNote" className="noselect col-md-4 col-lg-3 mt-3 pt-2 text-start cursor-pointer">{t('presentNote')}
                                    {/* <i className="w-6 ms-2 fa fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="Tooltip on bottom"></i> */}
                                </label>
                                <div className="col-md-8 col-lg-8 mt-3">
                                    <div className="row">
                                        <div className="col-sm-12 pe-0 ps-0">
                                            <DrInput id="cardNote" value={values.presentNote || ""} className="form-control" placeholder={t('presentNote')} name="presentNote" type="text"
                                                onChange={handleChange}
                                                onBlur={() => { setTouched({ ...touched, 'presentNote': true }) }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <small>{t('presentNoteDescription')}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"></hr>
                            <div className="row row-md row-lg">
                                <div className="col-sm-12">
                                    <h3>{t('contentTitle')}</h3>
                                </div>
                            </div>
                            <div className="row row-md row-lg border m-1">
                                <div className="col-sm-12">
                                    <div className="row px-3 pt-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoOrderConfirm" value="" checked={values.enableAutoOrderConfirm} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoOrderConfirm" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoOrderConfirm': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoOrderConfirm" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoOrderConfirm')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoOrderConfirm) ? "" : "text-muted"}`}>{t('enableAutoOrderConfirmDesciption')}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className="row px-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoOrderPreConfirm" value="" checked={values.enableAutoOrderPreConfirm} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoOrderPreConfirm" type="checkbox"
                                                    onChange={(e) => {
                                                        setFieldValue("enableAutoOrderPreConfirm", e.currentTarget.checked);
                                                        if (!e.currentTarget.checked) {
                                                            setFieldValue("enableAfterOrderPlaced", false);
                                                            setFieldValue("afterOrderPlacedTime", null);
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        setTouched({ ...touched, 'enableAutoOrderPreConfirm': true, 'enableAfterOrderPlaced': true, 'afterOrderPlacedTime': true })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoOrderPreConfirm" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoOrderPreConfirm')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoOrderPreConfirm) ? "" : "text-muted"}`}>{t('enableAutoOrderPreConfirmDescription')}</small>
                                                </div>
                                            </div>
                                            <div className={`row ${(values.enableAutoOrderPreConfirm) ? "d-block" : "d-none"}`}>
                                                <div className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-auto py-auto">
                                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                <DrInput id="afterOrderPlaced" value="" disabled={`${values.enableAutoOrderPreConfirm ? "" : "disabled"}`} checked={values.enableAutoOrderPreConfirm && values.enableAfterOrderPlaced} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAfterOrderPlaced" type="checkbox"
                                                                    onChange={(e) => {
                                                                        setFieldValue("enableAfterOrderPlaced", e.currentTarget.checked);
                                                                        if (!e.currentTarget.checked) {
                                                                            setFieldValue("afterOrderPlacedTime", null);
                                                                        }
                                                                    }}
                                                                    onBlur={() => { setTouched({ ...touched, 'enableAfterOrderPlaced': true }) }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-auto ps-0 py-auto">
                                                            <label htmlFor="afterOrderPlaced" className={`noselect text-start mt-3 cursor-pointer ${(values.enableAfterOrderPlaced) ? "" : "text-muted"}`}>
                                                                {t('confirm')}
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-2 col-md-2 col-lg-2 px-0 my-auto">

                                                            <DrInput className="form-control" placeholder="" name="afterOrderPlacedTime" type="number"
                                                                value={values.afterOrderPlacedTime || ""}
                                                                disabled={`${values.enableAutoOrderPreConfirm && values.enableAfterOrderPlaced ? "" : "disabled"}`}
                                                                onChange={handleChange}
                                                                onBlur={() => { setTouched({ ...touched, 'afterOrderPlacedTime': true }) }}
                                                                isInvalid={(touched.afterOrderPlacedTime && errors.afterOrderPlacedTime)}
                                                                isValid={touched.afterOrderPlacedTime && !errors.afterOrderPlacedTime}
                                                                validationText={errors.afterOrderPlacedTime}
                                                            />
                                                        </div>
                                                        <div className="col-auto">
                                                            <label htmlFor="afterOrderPlaced" className={`noselect text-start mt-3 cursor-pointer ${(values.enableAfterOrderPlaced) ? "" : "text-muted"}`}>
                                                                {t('confirmHours')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className="row px-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoPriceUpdate" value="" checked={values.enableAutoPriceUpdate} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoPriceUpdate" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoPriceUpdate': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoPriceUpdate" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoPriceUpdate')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoPriceUpdate) ? "" : "text-muted"}`}>{t('enableAutoPriceUpdateDescription')}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className="row px-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoStockUpdate" value="" checked={values.enableAutoStockUpdate} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoStockUpdate" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoStockUpdate': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoStockUpdate" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoStockUpdate')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoStockUpdate) ? "" : "text-muted"}`}>{t('enableAutoStockUpdateDescription')}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className="row px-3">
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoProductDeletion" value="" checked={values.enableAutoProductDeletion} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoProductDeletion" type="checkbox"
                                                    onChange={handleChange}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoProductDeletion': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoProductDeletion" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoProductDeletion')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoProductDeletion) ? "" : "text-muted"}`}>{t('enableAutoProductDeletionDescription')}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-3"></hr>
                                    <div className="row px-3 pb-3" >
                                        <div className="col-auto">
                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                <DrInput id="autoProductAdd" value="" checked={values.enableAutoProductAdd} className="form-check-input form-checked-info" role="switch" placeholder="" name="enableAutoProductAdd" type="checkbox"
                                                    onChange={(e) => {
                                                        setFieldValue("enableAutoProductAdd", e.currentTarget.checked);
                                                        if (!e.currentTarget.checked) {
                                                            setFieldValue("askConfirmationForNewProducts", false);
                                                        }
                                                    }}
                                                    onBlur={() => { setTouched({ ...touched, 'enableAutoProductAdd': true, 'askConfirmationForNewProducts': true }) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col text-wrap">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label htmlFor="autoProductAdd" className="noselect mt-3 text-start cursor-pointer">
                                                        {t('enableAutoProductAdd')}
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <small className={`${(values.enableAutoProductAdd) ? "" : "text-muted"}`}>{t('enableAutoProductAddDescription')}</small>
                                                </div>
                                            </div>
                                            <div className={`row ${(values.enableAutoProductAdd) ? "d-block" : "d-none"}`}>
                                                <div className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-auto py-auto">
                                                            <div className="form-check form-check-md form-switch mt-3 mb-3">
                                                                <DrInput id="confirmationForNewProducts" value="" disabled={`${values.enableAutoProductAdd ? "" : "disabled"}`} checked={values.enableAutoProductAdd && values.askConfirmationForNewProducts} className="form-check-input form-checked-info" role="switch" placeholder="" name="askConfirmationForNewProducts" type="checkbox"
                                                                    onChange={handleChange}
                                                                    onBlur={() => { setTouched({ ...touched, 'askConfirmationForNewProducts': true }) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col ps-0 text-wrap py-auto">
                                                            <label htmlFor="confirmationForNewProducts" className={`noselect text-start mt-3 cursor-pointer ${!values.askConfirmationForNewProducts ? "text-muted" : ""}`}>
                                                                {t('askConfirmationForNewProducts')}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {<DrNavigator backAddress="MARKET_SELECTION" nextAddress="WAREHOUSE_SELECTION" disabled={!isValid}
                            callback={() => {
                                var marketPreferencesData = {
                                    ...values
                                }

                                marketPreferencesData.sourceMarkets = values.sourceMarkets?.map(a => a.value);
                                Promise.all([
                                    dispatch(withActionPromise(dispatch(setMarketPreferences(marketPreferencesData)))),
                                    dispatch(withActionPromise(dispatch(setSnapshotStorePageSubmission({ storePreferencesPage: true }))))
                                ]);
                            }}
                        />}
                    </form>
                )
                }
            </Formik>
        </>

    )
}

export default StorePreferencesPage;