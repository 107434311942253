const types = {
    UPSERT_FEEDBACK_CAMPAIGN_REQUESTED: "UPSERT_FEEDBACK_CAMPAIGN_REQUESTED",
    UPSERT_FEEDBACK_CAMPAIGN_SUCCESS: "UPSERT_FEEDBACK_CAMPAIGN_SUCCESS",
    GET_ALL_FEEDBACK_CAMPAIGN_LIST_REQUESTED: "GET_ALL_FEEDBACK_CAMPAIGN_LIST_REQUESTED",
    GET_ALL_FEEDBACK_CAMPAIGN_LIST_SUCCESS: "GET_ALL_FEEDBACK_CAMPAIGN_LIST_SUCCESS",

    UPDATE_FEEDBACKS_ACTIVE_REQUESTED: "UPDATE_FEEDBACKS_ACTIVE_REQUESTED",

    DELETE_FEEDBACK_CAMPAIGN_REQUESTED: "DELETE_FEEDBACK_CAMPAIGN_REQUESTED",
    DELETE_FEEDBACK_CAMPAIGN_SUCCESS: "DELETE_FEEDBACK_CAMPAIGN_SUCCESS",

    GET_AMAZON_FEEDBACK_LIST_REQUESTED: "GET_AMAZON_FEEDBACK_LIST_REQUESTED",
    GET_AMAZON_FEEDBACK_LIST_SUCCESS: "GET_AMAZON_FEEDBACK_LIST_SUCCESS",

    GET_AMAZON_FEEDBACK_RATING_PERCENT_REQUESTED: "GET_AMAZON_FEEDBACK_RATING_PERCENT_REQUESTED",
    GET_AMAZON_FEEDBACK_RATING_PERCENT_SUCCESS: "GET_AMAZON_FEEDBACK_RATING_PERCENT_SUCCESS",

    GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_REQUESTED: "GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_REQUESTED",
    GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_SUCCESS: "GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_SUCCESS",

    CLEAR_ALL_FEEDBACK_STATE: "CLEAR_ALL_FEEDBACK_STATE"
}

export default types;

export const upsertFeedbackCampaign = (payload) => {
    return { type: types.UPSERT_FEEDBACK_CAMPAIGN_REQUESTED, payload }
}

export const upsertFeedbackCampaignSuccess = (payload) => {
    return { type: types.UPSERT_FEEDBACK_CAMPAIGN_SUCCESS, payload };
};

export const deleteFeedbackCampaign = (payload) => {
    return { type: types.DELETE_FEEDBACK_CAMPAIGN_REQUESTED, payload }
}

export const deleteFeedbackCampaignSuccess = () => {
    return { type: types.DELETE_FEEDBACK_CAMPAIGN_SUCCESS };
};

export const getFeedbackCampaign = (payload) => {
    return { type: types.GET_ALL_FEEDBACK_CAMPAIGN_LIST_REQUESTED, payload }
}

export const getFeedbackCampaignSuccess = (payload) => {
    return { type: types.GET_ALL_FEEDBACK_CAMPAIGN_LIST_SUCCESS, payload };
};

export const upsertFeedbackActiveAndDeActive = (payload) => {
    return { type: types.UPDATE_FEEDBACKS_ACTIVE_REQUESTED, payload }
}

export const getAmazonFeedback = (payload) => {
    return { type: types.GET_AMAZON_FEEDBACK_LIST_REQUESTED, payload }
}

export const getAmazonFeedbackSuccess = (payload) => {
    return { type: types.GET_AMAZON_FEEDBACK_LIST_SUCCESS, payload };
};

export const getRatingPercentage = (payload) => {
    return { type: types.GET_AMAZON_FEEDBACK_RATING_PERCENT_REQUESTED, payload }
}

export const getRatingPercentageSuccess = (payload) => {
    return { type: types.GET_AMAZON_FEEDBACK_RATING_PERCENT_SUCCESS, payload };
};

export const getFeedbackCampaignSendEmails = (payload) => {
    return { type: types.GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_REQUESTED, payload }
}

export const getFeedbackCampaignSendEmailsSuccess = (payload) => {
    return { type: types.GET_FEEDBACK_CAMPAIGN_SENT_EMAILS_SUCCESS, payload };
};

export const clearAllFeedbackState = () => {
    return { type: types.CLEAR_ALL_FEEDBACK_STATE }
}