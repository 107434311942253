import { call, put, takeLatest, all } from 'redux-saga/effects'
import { apiDelete, apiGet, apiPost, CONTROLLERS } from "../../utils/axiosApiCalls"
import safe from '../errorHandlingUtils'
import types, { getTransactionSummarySuccess, getWalletInfo, getWalletInfoSuccess } from '../actions/wallet.actions';
import i18next from 'i18next';
import Swal from 'sweetalert2';

function* runGetWalletInfo() {
    const response = yield call(apiGet(`/GetWallet`, CONTROLLERS.WALLET, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {

        yield put(getWalletInfoSuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), (i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : "")), 'error'));
    }
}

function* runGetTransactionSummary(action) {
    const payload = action.payload;
    var path = `/GetWalletTransactions`;
    if (payload.transactionType || payload.startDate || payload.endDate) {
        path += "?";
        if (payload.transactionType != "-1") {
            path += `TransactionType=${payload.transactionType}`
        }
        if (payload.startDate) {
            if (payload.transactionType != "-1") {
                path += "&"
            }
            path += `StartDate=${payload.startDate}`
        }
        if (payload.endDate) {
            if (payload.startDate || payload.transactionType != "-1") {
                path += "&"
            }
            path += `EndDate=${payload.endDate}`
        }
    }

    const response = yield call(apiGet(path, CONTROLLERS.WALLET, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield put(getTransactionSummarySuccess(response.data));
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runMakePaymentToWallet(action) {
    const payload = action.payload;

    const response = yield call(apiPost(`/Payment`, payload, false, CONTROLLERS.WALLET, true, true));
    if (response && response.isSuccess && response.resultType === 1 && response.data) {
        yield all([
            put(getWalletInfo()),
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runDeleteCreditCard(action) {
    const payload = action.payload;

    var path = `/DeleteCreditCard?CardId=${payload}`;

    const response = yield call(apiDelete(path, CONTROLLERS.WALLET, true, true));

    if (response && response.isSuccess && response.resultType === 1) {
        yield all([
            put(getWalletInfo())
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

function* runSaveCardIsFavourite(action) {
    const payload = action.payload;

    var path = `/FavoriteCreditCard?CardId=${payload}`;

    const response = yield call(apiGet(path, CONTROLLERS.WALLET, true, true));
    if (response && response.isSuccess && response.resultType === 1) {

        yield all([
            put(getWalletInfo())
        ]);
    }
    else {
        yield put(Swal.fire(i18next.t('drSweetAlertButton:errorTitle'), i18next.t('drSweetAlertButton:errorTexts.errorDefaultMessage') + " " + (response && response.message ? response.message : ""), 'error'));
    }
}

export default function* walletSaga() {
    yield all([
        takeLatest(types.GET_WALLET_INFO_REQUESTED, safe(runGetWalletInfo)),
        takeLatest(types.GET_TRANSACTION_SUMMARY_REQUESTED, safe(runGetTransactionSummary)),
        takeLatest(types.MAKE_PAYMENT_TO_WALLET_REQUESTED, safe(runMakePaymentToWallet)),
        takeLatest(types.DELETE_SAVED_CARD_REQUESTED, safe(runDeleteCreditCard)),
        takeLatest(types.SAVE_CARD_IS_FAVOURITE_REQUESTED, safe(runSaveCardIsFavourite))
    ]);
}