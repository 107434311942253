
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrButtonDropdown, DrDataTable, DrPageFilter, DrPagination, DrSweetAlertButton } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { buttonTypes, buttonVariants, modals, pageFilterTypes, sweetAlertVariants } from '../../utils/constants';
import { clearActiveCaptchaModal, setActiveCaptchaModal, setActiveSearchTaskProductsModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import i18next from 'i18next';
import { searchTasksSearchTypeOptions, searchTasksStatusOptions } from '../../components/hoc/Constants/dr-constants.component';
import { deleteSearchTasks, getAllSearchTasks, saveSearchTasks } from '../../state-management/actions/reprizer.action';
import { ExportToExcel } from '../../excelexport';
import { generatePdfReport } from '../../utils/utils';
import { setSearchTasksPageSelections } from '../../state-management/actions/settings.actions';

function SearchTasksPage() {
    const dispatch = useDispatch();
    const { t } = useTranslation('searchTaskPage');
    const searchTasks = useSelector(state => state.reprizer.searchTasks);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.reprizerPageSelections);
    const [keyword, setKeyword] = useState(null);

    useEffect(() => {
        if (pageSelections?.taskStatus && pageSelections?.taskType) {
            var request = {
                taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                skip: pageSelections?.tableSkip,
                pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : searchTasks?.totalRecordCount,
                keywords: keyword,
                orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "createdDate desc",
            }
            dispatch(withActionPromise(getAllSearchTasks(request)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageSelections?.taskStatus && pageSelections?.taskType) {
            var request = {
                taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                skip: pageSelections?.tableSkip,
                pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : searchTasks?.totalRecordCount,
                keywords: keyword,
                orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "createdDate desc",
            }
            dispatch(withActionPromise(getAllSearchTasks(request)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.taskStatus, pageSelections?.taskType, pageSelections?.tableSkip, pageSelections?.pagelength, keyword]);

    const columns = [
        //{ data: "id", title: "", width: "13%", minWidth: "130px", searchable: false },

        { data: "captchaImage", title: "", minWidth: "60px", maxWidth: "60px", searchable: false, checkall: true, checkData: "id" }, //image
        { data: "taskStatus", title: t('dataTable.taskStatus'), minWidth: "135px", maxWidth: "135px", searchable: false },
        { data: "searchType", title: t('dataTable.searchType'), minWidth: "130px", maxWidth: "130px", searchable: false },
        { data: "productCount", title: t('dataTable.productCount'), minWidth: "130px", maxWidth: "130px", searchable: false },
        { data: "createdDate", title: t('dataTable.createdDate'), minWidth: "150px", maxWidth: "150px", searchable: false },
        { data: "startDate", title: t('dataTable.startDate'), minWidth: "150px", maxWidth: "150px", searchable: false },
        { data: "endDate", title: t('dataTable.endDate'), minWidth: "150px", maxWidth: "150px", searchable: false },
        { data: "serviceName", title: t('dataTable.service'), minWidth: "100px", searchable: false },
        { data: "id", title: "", width: "5%", minWidth: "30px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox" name="name" value={rowData.id}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                        {rowData.captchaImage && rowData.captchaImage.length > 0 &&
                            <div className="card-aside-img cursor-pointer"  >
                                <div className="img-sm p-0 rounded-0 m-auto">
                                    <img src={rowData.captchaImage} alt="img" data-tag="pic-modal-opener"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (rowData.captchaImage) {
                                                dispatch(setActiveCaptchaModal({
                                                    name: modals.captcha, isGlobal: true,
                                                    source: {
                                                        imgUrl: rowData.captchaImage,
                                                        id: rowData.id,
                                                        captchaText: ""
                                                    },
                                                    captchaTextInputCallback: captchaTextInputCallback
                                                }))
                                            }
                                        }}
                                    ></img>
                                </div>
                            </div>
                        }
                    </>);
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-center",
            searchable: true,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.taskStatus.toString() === "0" && <div className="badge bg-dark bg-opacity-50 w-100">
                            <span className="fs-11 m-1">{searchTasksStatusOptions.find(x => x.value === rowData.taskStatus.toString())?.label}</span>
                        </div>}
                        {rowData.taskStatus.toString() === "1" && <div className="badge bg-warning w-100">
                            <span className="fs-11 m-1">{searchTasksStatusOptions.find(x => x.value === rowData.taskStatus.toString())?.label}</span>
                        </div>}
                        {rowData.taskStatus.toString() === "2" && <div className="badge bg-success w-100">
                            <span className="fs-11 m-1">{searchTasksStatusOptions.find(x => x.value === rowData.taskStatus.toString())?.label}</span>
                        </div>}
                        {rowData.taskStatus.toString() === "3" && <div className="badge bg-warning w-100">
                            <span className="fs-11 m-1">{searchTasksStatusOptions.find(x => x.value === rowData.taskStatus.toString())?.label}</span>
                        </div>}
                        {rowData.taskStatus.toString() === "4" && <div className="badge bg-danger w-100">
                            <span className="fs-11 m-1">{searchTasksStatusOptions.find(x => x.value === rowData.taskStatus.toString())?.label}</span>
                        </div>}
                    </>
                );
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">
                            {searchTasksSearchTypeOptions.find(x => x.value === rowData.searchType.toString())?.label}
                        </span>
                    </>
                );
            }
        },
        {
            targets: 3,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="cursor-pointer w-50 badge bg-primary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (rowData.requestJson) {
                                            dispatch(setActiveSearchTaskProductsModal({
                                                name: modals.searchTaskProducts, isGlobal: true,
                                                source: { searchType: rowData.searchType, data: rowData.dataJson, requestData: rowData.requestJson }
                                            }))
                                        }
                                    }}>
                                    <span className="fs-12 my-auto mx-0 p-0" data-bs-placement="right" data-bs-toggle="tooltip"
                                        title={rowData.asins}>{rowData.productCount}</span>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <span className="fs-12 cursor-pointer my-auto mx-0 p-0" data-bs-placement="right" data-bs-toggle="tooltip"
                                    title={rowData.asins}>{rowData.asins?.length > 0 ? (rowData.asins.split(',')[0] + "...") : ""}</span>
                            </div>
                        </div>
                    </>
                );
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{rowData.createdDate && moment.utc(rowData.createdDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{rowData.startDate && moment.utc(rowData.startDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <span className="fs-12 my-auto mx-0 p-0">{rowData.endDate && moment.utc(rowData.endDate).format('L LT')}</span>
                );
            }
        },
        {
            targets: 7,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="fs-12 my-auto mx-0 p-0">{rowData.serviceName}</span><br />
                    </>)
            }
        },
        {
            targets: 8,
            orderable: true,
            className: "text-center",

            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.taskStatus.toString() !== "0" &&

                            <>
                                <DrSweetAlertButton
                                    className={`btn btn-icon btn-secondary-ghost btn-wave waves-effect waves-light shadow`}
                                    buttonText={t("dataTable.blockProductAsin")}
                                    buttonIcon={<i className="fe fe-refresh-cw" ></i>}
                                    buttonType={buttonTypes.icon}
                                    buttonVariant={buttonVariants.danger}
                                    onCloseConfirmCallBack={(value) => {
                                        if (value) {
                                            resetTask(rowData.id);
                                        }
                                    }}
                                    sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                                >
                                </DrSweetAlertButton>
                            </>}
                    </>)
            }

        }
    ];

    const captchaTextInputCallback = (values) => {
        var searchTask = searchTasks.data?.find(x => x.id === values.id);
        if (searchTask) {
            searchTask.captchaText = values.captchaText;

            var data = {
                taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                searchTasks: []
            }
            data.searchTasks.push(searchTask);

            dispatch(withActionPromise(saveSearchTasks(data)));
        }
        dispatch(clearActiveCaptchaModal());
    };

    const searchTasksStatusClickCallBack = (value) => {
        dispatch(withActionPromise(setSearchTasksPageSelections({ taskStatus: value, taskType: pageSelections?.taskType, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));

    };

    const searchTasksSearchTypeClickCallBack = (value) => {
        dispatch(withActionPromise(setSearchTasksPageSelections({ taskStatus: pageSelections?.taskStatus, taskType: value, tableSkip: pageSelections?.tableSkip, tableLength: pageSelections?.tableLength, pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
    };

    const refreshClickedCallBack = () => {
        if (pageSelections?.taskStatus && pageSelections?.taskType) {
            var request = {
                taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                skip: pageSelections?.tableSkip,
                pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : searchTasks?.totalRecordCount,
                keywords: keyword,
                orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "createdDate desc",
            }
            dispatch(withActionPromise(getAllSearchTasks(request)));
        }
    };

    const resetTask = (value) => {
        var searchTask = searchTasks.data?.find(x => x.id === value);
        if (searchTask) {
            searchTask.captchaText = "";
            // searchTask.createdDate = moment.utc();
            searchTask.startDate = null;
            searchTask.endDate = null;
            searchTask.taskStatus = 0;
            var data = {
                request: {
                    taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                    searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                    skip: pageSelections?.tableSkip,
                    pageSize: pageSelections?.pagelength !== -1 ? pageSelections?.pagelength : searchTasks?.totalRecordCount,
                    keywords: keyword,
                    orderByParam: pageSelections?.sortingInfo?.length > 0 ? (pageSelections?.sortingInfo[1] + " " + pageSelections?.sortingInfo[2]) : "createdDate desc",
                },
                searchTasks: []
            }
            data.searchTasks.push(searchTask);

            dispatch(withActionPromise(saveSearchTasks(data)));
        }
    };

    const deleteTask = (id) => {
        var searchTask = searchTasks.data?.find(x => x.id === id);
        if (searchTask) {

            var data = {
                taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                taskIdList: []
            }
            data.taskIdList.push(searchTask.id);

            dispatch(withActionPromise(deleteSearchTasks(data)));
        }
    };

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

        if (checkedValues && checkedValues.length > 0) {
            if (selectedOperation === "0" || selectedOperation === "1") //export
            {
                var productDetails = [];
                searchTasks.data?.filter(item1 =>
                    !!checkedValues.find(item2 => item1.id === item2)).map(task => {
                        return { endDate: task.endDate, products: JSON.parse(task.dataJson) };
                    }).forEach(task => {
                        task.products.forEach(product => {
                            productDetails.push({ ...product.Detail, endDate: task.endDate });
                        })
                    });

                if (selectedOperation === "0") {
                    ExportToExcel({ excelData: productDetails, fileName: "search-tasks" });
                }
                else if (selectedOperation === "1") {
                    const tableColumn = ["ASIN", "Review", "Rating", "Stars", "Stock",
                        "BuyboxOwnerSeller", "ShippingPrice", "CustomsDutyPrice",
                        "PrimeSellerCount", "TotalSellerCount", "IsSoldByAmazon",
                        "IsFBASellerExists", "IsChineseSellerExists", "IsPrimeExclusive",
                        "IsLiquidContent", "Discount", "Coupon", "Coupon-SalesMarket", "MainCategoryRank-SubCategoryRank-SourceMarket", "MainCategoryRank-SubCategoryRank-SalesMarket",
                        "UnDeliverable", "UnAvailable", "SameSellerBrandProductValue"
                    ];
                    // define an empty array of rows
                    const tableRows = [];

                    // for each ticket pass all its data into an array
                    productDetails.sort((a, b) => a.asin - b.asin).forEach(detail => {
                        if (detail) {
                            const productData = [
                                detail?.Asin,
                                detail?.Review,
                                detail?.Rating,
                                detail?.Stars,
                                detail?.Stock,
                                detail?.BuyboxOwnerSeller,
                                detail?.ShippingPriceCurrencyCode + " " + Number(detail?.ShippingPrice).toFixed(3),
                                detail?.CustomsDutyPriceCurrencyCode + " " + Number(detail?.CustomsDutyPrice).toFixed(3),
                                detail?.PrimeSellerCount,
                                detail?.TotalSellerCount,
                                detail?.IsSoldByAmazon,
                                detail?.IsFBASellerExists,
                                detail?.IsChineseSellerExists,
                                detail?.IsPrimeExclusive,
                                detail?.IsLiquidContent,
                                detail?.DiscountType + " " + detail?.DiscountValue,
                                detail?.CouponType + " " + detail?.CouponValue,
                                detail?.CouponTypeDestinationMarket + " " + detail?.CouponValueDestinationMarket,
                                detail?.SellerDisplayGroupRank + " - " + detail?.SellerClassificationRank,
                                detail?.SellerDisplayGroupRankAtDestinationMarket + " - " + detail?.SellerClassificationRankAtDestinationMarket,
                                detail?.UnDeliverable,
                                detail?.UnAvailable,
                                detail?.SameSellerBrandProductValue
                            ];
                            // push each tickcet's info into a row
                            tableRows.push(productData);
                        }

                    });
                    generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Search Task Product Details", orientation: 'l', format: "a1", reportName: "products_details" });

                }
            }
            else if (selectedOperation === "4") {
                var selectedTasks = searchTasks.data?.filter(item1 =>
                    !!checkedValues.find(item2 => item1.id === item2));

                var data = {
                    taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                    searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                    searchTasks: []
                }

                if (selectedTasks && selectedTasks.length > 0) {
                    selectedTasks.forEach(selectedTask => {
                        selectedTask.captchaText = "";
                        // searchTask.createdDate = moment.utc();
                        selectedTask.startDate = null;
                        selectedTask.endDate = null;
                        selectedTask.taskStatus = 0;

                        data.searchTasks.push(selectedTask);
                    });

                    dispatch(withActionPromise(saveSearchTasks(data)));
                }
            }
            else if (selectedOperation === "6") {
                var data = {
                    taskStatus: pageSelections?.taskStatus === "-1" ? "0,1,2,3,4,5" : pageSelections?.taskStatus,
                    searchType: pageSelections?.taskType === "-1" ? "0,1" : pageSelections?.taskType,
                    searchTasks: { taskIdList: checkedValues }
                }

                dispatch(withActionPromise(deleteSearchTasks(data)));
            }
        }
    }

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.administrator'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.searchTasks'), navigationPath: "" }]} >
            </DrBreadCrump>
            <div className="row justify-content-end mb-2">
                <div className="col-auto">
                    <div className="row">
                        <div className="col-auto">
                            <DrButtonDropdown className="shadow"
                                options={searchTasksStatusOptions}
                                selectedValue={pageSelections?.taskStatus}
                                optionClickCallBack={searchTasksStatusClickCallBack}
                                variant={buttonVariants.teal}>
                            </DrButtonDropdown>
                        </div>
                        <div className="col-auto ps-0">
                            <DrButtonDropdown className="shadow"
                                options={searchTasksSearchTypeOptions}
                                selectedValue={pageSelections?.taskType}
                                optionClickCallBack={searchTasksSearchTypeClickCallBack}
                                variant={buttonVariants.orange}>
                            </DrButtonDropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-lg-12 col-xl-12 col-md-12">
                    <div className="card custom-card">
                        <div className="card-body horizontal-scrollable">

                            {searchTasks.data && <>
                                <DrDataTable
                                    data={searchTasks.data}
                                    columns={columns}
                                    columnDefs={columnDefs}
                                    doesHaveBorder={false}
                                    headerSearch={true}
                                    search={true}
                                    // order={[[4, 'desc']]}
                                    order={pageSelections?.sortingInfo?.length > 0 ? [[parseInt(pageSelections?.sortingInfo[0]), pageSelections?.sortingInfo[2]]] : [[4, 'desc']]}
                                    minWidth="850px"
                                    isMultiSelectEnabled={true}
                                    detailedSearchChildren={
                                        <DrPageFilter
                                            refreshClickedCallBack={refreshClickedCallBack}
                                            isSearchSavingActive={false}
                                            isSearchActive={true}
                                            filterType={pageFilterTypes.searchTasks}
                                            selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                            searchKeyUpCallBack={(e) => {
                                                if (e?.length > 0) {
                                                    setKeyword(e);
                                                }
                                                else {
                                                    setKeyword(null);
                                                }
                                            }}
                                        >
                                        </DrPageFilter>
                                    }

                                    pageLength={pageSelections?.pagelength || 5}

                                    paginationPlugin={
                                        <DrPagination
                                            paginationData={
                                                {
                                                    tableSkip: (pageSelections?.tableSkip || 0),
                                                    totalRecordCount: searchTasks?.totalRecordCount,// products?.totalRecordCount || pageSelections.tableLength,
                                                    tablePageLength: pageSelections?.pagelength || 5
                                                }

                                            }
                                            tablePageLengthChanged={(e) => {
                                                dispatch(withActionPromise(setSearchTasksPageSelections({ taskStatus: pageSelections?.taskStatus, taskType: pageSelections?.taskType, tableSkip: 0, tableLength: (searchTasks?.totalRecordCount || pageSelections.tableLength), pagelength: e, sortingInfo: pageSelections?.sortingInfo })));
                                            }}
                                            tablePageNumberChanged={(e) => {
                                                dispatch(withActionPromise(setSearchTasksPageSelections({ taskStatus: pageSelections?.taskStatus, taskType: pageSelections?.taskType, tableSkip: e, tableLength: (searchTasks?.totalRecordCount || pageSelections.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                                            }}
                                        >
                                        </DrPagination>

                                    }
                                />
                            </>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SearchTasksPage