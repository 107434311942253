import React, { useEffect, useState } from 'react'
import { DrBreadCrump, DrDualDatePicker, DrEmptyMailTemplate, DrFileUploadInput, DrInput, DrSelectBox, DrTimeLine } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment';
import i18next from 'i18next';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import noImage from '../../assets/img/pngs/no-image.png'
import avatar from '../../assets/img/users/profile3.png'
import { getOrderItem, updateOrderItemAmazonOrderNumber } from '../../state-management/actions/orders.actions';
import { setActivePictureModal, withActionPromise } from '../../state-management/actions/app.actions';
import { useTranslation } from 'react-i18next';
import { consistantCapitalizeFirstLetter, modals, notificationMessagesTypes, siteExtensions, timelineVariants } from '../../utils/constants';
import { defaultMailTemplatesBodyTextOptions, notificationMessageTypesOptions } from '../../components/hoc/Constants/dr-constants.component';
import { getCreateNegativeFeedbackRemoval, getMessagingActionsForOrder, getNotificationsByOrderNo, sendNotificationMessage } from '../../state-management/actions/notification.actions';
import parse from 'html-react-parser';
import Restricted from '../../security/Restricted';
import { toastr } from 'react-redux-toastr';
// import { selectedOrderDetail } from '../../utils/mocks-data';

function MessageDetailPage() {
    const { REACT_APP_ROLES_SUPERADMIN, REACT_APP_ROLES_ADMIN, REACT_APP_ROLES_SUSPENDED_ADMIN, REACT_APP_ROLES_STORE, REACT_APP_ROLES_ORDERS } = process.env;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('messageDetailPage');
    const customerInfo = useSelector(state => state.customer.customerInfo);
    const { orderNo, messageId } = useParams();
    const selectedOrderDetail = useSelector(state => state.orders.selectedOrder);
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const notificationMessages = useSelector(state => state.notifications.notificationMessages);
    const messagingActionsForOrder = useSelector(state => state.notifications.messagingActionsForOrder);
    const orderTrackingStatuses = useSelector(state => state.orders.orderTrackingStatuses);
    const [orderDetailData, setOrderDetailData] = useState(null);
    const [selectedTemplateOption, setSelectedTemplateOption] = useState("-1");
    const language = useSelector(state => state.settings.language);
    const selectedStoreCurrencyType = useSelector(state => state.settings?.selectedStoreCurrencyType);

    useEffect(() => {
        if (orderNo) {
            dispatch(withActionPromise(getOrderItem({ orderId: orderNo })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStore?.customerMainSellerId, orderNo, messageId]);

    useEffect(() => {
        if (selectedOrderDetail) {
            var data = {
                productPicture: selectedOrderDetail?.productPicture,
                orderNo: selectedOrderDetail?.orderNumber,
                orderStatus: selectedOrderDetail?.orderStatus,
                totalPrice: selectedOrderDetail?.ordertotalCurrencyCode + " " + selectedOrderDetail?.ordertotalAmount,

                orderHistory: {
                    purchaseDate: selectedOrderDetail?.purchaseDate,
                    salesChannel: selectedOrderDetail?.salesChannel,
                    shipmentServiceLevelCategory: selectedOrderDetail?.shipmentServiceLevelCategory,
                    earliestShipDate: selectedOrderDetail?.earliestShipDate,
                    latestShipDate: selectedOrderDetail?.latestShipDate,
                    earliestDeliveryDate: selectedOrderDetail?.earliestDeliveryDate,
                    latestDeliveryDate: selectedOrderDetail?.latestDeliveryDate
                },
                shippingDetails: {
                    carrierName: selectedOrderDetail.shipServiceLevel,
                    trackingNo: selectedOrderDetail.trackingNo,
                    buyerName: selectedOrderDetail.buyerName,
                    phone: selectedOrderDetail?.shippingAddress?.phone,
                    addressLine1: selectedOrderDetail?.shippingAddress?.addressLine1,
                    addressLine2: selectedOrderDetail?.shippingAddress?.addressLine2,
                    city: selectedOrderDetail?.shippingAddress?.city,
                    stateOrRegion: selectedOrderDetail?.shippingAddress?.stateOrRegion,
                    countryCode: selectedOrderDetail?.shippingAddress?.countryCode,
                    postalCode: selectedOrderDetail?.shippingAddress?.postalCode
                },
                orderDetailList: selectedOrderDetail.orderDetailList,
                buyerSellerOrderNumber: selectedOrderDetail.buyerSellerOrderNumber
            }

            if (selectedOrderDetail.orderDetailList && selectedOrderDetail.orderDetailList.length > 0) {
                data.title = selectedOrderDetail.orderDetailList[0].title;
                data.asin = selectedOrderDetail.orderDetailList[0].asin;
                data.sellerSKU = selectedOrderDetail.orderDetailList[0].sellerSKU;
                data.price = selectedOrderDetail.orderDetailList[0].itemPriceCurrencyCode + " " + selectedOrderDetail.orderDetailList[0].itemPriceAmount;;
                data.quantity = selectedOrderDetail.orderDetailList[0].quantityOrdered;
            }

            setOrderDetailData(data);

            dispatch(withActionPromise(getNotificationsByOrderNo(selectedOrderDetail?.orderNumber)));
            dispatch(withActionPromise(getMessagingActionsForOrder({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
            // dispatch(withActionPromise(getCreateNegativeFeedbackRemoval({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
            // dispatch(withActionPromise(getNotificationAttributes({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrderDetail?.orderNumber]);

    // useEffect(() => {
    //     if (selectedTemplateOption && selectedTemplateOption !== "-1") {
    //         setTextareaValue(i18next.t('drMailTemplates:' + selectedTemplateOption + '.text', { customerName: orderDetailData?.shippingDetails?.buyerName || "NoName", storeName: selectedStore?.mainSellerName }))
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [language]);

    const initialNotificationValues = {
        customerMainSellerId: selectedStore?.customerMainSellerId,
        amazonOrderNumber: selectedOrderDetail?.orderNumber,

        allowedNotificationMessageTypeOptions: notificationMessageTypesOptions.filter(item1 =>
            !!messagingActionsForOrder.find(item2 => 
                (
                    item1.value === item2 
                    // || item1.value === notificationMessagesTypes.unexpectedProblem
                ))),

        selectedMessageTypeOption: null,
        selectedMessageTemplateOption: null,
        messageText: "",
        isTextAreaDisabled: true,
        isAttachmentDisabled: true,
        coverageDateInterval: null,
        fileContent: []
    }


    function getLatestUpdateDate(sourceData) {
        if (sourceData && sourceData.length > 0) {
            var sortedData = sourceData?.sort((b, a) => moment.utc(a.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf());
            return sortedData[0].sendDate;
        }
        else {
            return null;
        }
    };

    const handleSubmit = async (values) => {
        var requestData = {
            selectedMessageTypeOption: values.selectedMessageTypeOption,
            notificationRequestData: {
                customerMainSellerId: values.customerMainSellerId,
                amazonOrderNumber: values.amazonOrderNumber
            }
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.confirmOrderDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmServiceDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.unexpectedProblem
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmCustomizationDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmDeliveryDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.digitalAccessKey) {

            requestData.notificationRequestData.messageText = values.messageText;
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.confirmCustomizationDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.amazonMotors
            || values.selectedMessageTypeOption === notificationMessagesTypes.confirmDeliveryDetails
            || values.selectedMessageTypeOption === notificationMessagesTypes.digitalAccessKey
            || values.selectedMessageTypeOption === notificationMessagesTypes.legalDisclose
            || values.selectedMessageTypeOption === notificationMessagesTypes.warranty
            || values.selectedMessageTypeOption === notificationMessagesTypes.sendInvoice) {

            requestData.notificationRequestData.fileContent = values.fileContent;
        }

        if (values.selectedMessageTypeOption === notificationMessagesTypes.warranty) {

            requestData.notificationRequestData.coverageStartDate = values.coverageDateInterval && values.coverageDateInterval.length > 0 ? values.coverageDateInterval[0] : null;
            requestData.notificationRequestData.coverageEndDate = values.coverageDateInterval && values.coverageDateInterval.length > 0 ? values.coverageDateInterval[1] : null;
        }


        dispatch(sendNotificationMessage(requestData))
    }

    return (
        <>

            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.messages'), navigationPath: "/messages" },
                { navigationText: i18next.t('constants:breadCrumps.messageDetail'), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row">
                <div className="col-xxl-8 col-lg-8 col-xl-8 col-md-12 col-sm-12 d-flex flex-column">
                    <div className="card custom-card bg-success bg-opacity-10 flex-grow-shrink">
                        <div className="card-body p-3">
                            <div className="row row-md row-lg justify-content-center ">
                                <div className="col-12">
                                    {orderDetailData?.orderDetailList?.length > 0 && orderDetailData?.orderDetailList.map((product, index) => {
                                        return (

                                            <div key={"order_item_" + index + "_" + product.asin} className="row row-md row-lg justify-content-sm-center justify-content-md-center justify-content-center">
                                                <div className="col-12">
                                                    <div className="card custom-card">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            {/* <h5 className="mb-3">{product.title}</h5> */}
                                                                            <h3 className="card-title fw-bold mb-3">{product.title}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-4 col-md-4 col-sm-4">
                                                                            <p className="fs-13 fw-semibold mb-1">ASIN:</p>
                                                                        </div>
                                                                        <div className="col-8 col-md-8 col-sm-8">
                                                                            <label>

                                                                                <Restricted allowedTo={[
                                                                                    REACT_APP_ROLES_SUPERADMIN,
                                                                                    REACT_APP_ROLES_ADMIN,
                                                                                    REACT_APP_ROLES_SUSPENDED_ADMIN,
                                                                                    REACT_APP_ROLES_ORDERS
                                                                                ]}>
                                                                                    <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer" data-bs-placement="right" data-bs-toggle="tooltip"
                                                                                        title="Ürün Detayını görmek için tıklyınız!"
                                                                                        onClick={() => {

                                                                                            navigate('/product-detail/' + product.asin);
                                                                                        }}>
                                                                                        {product.asin}
                                                                                    </span>
                                                                                </Restricted>
                                                                                <Restricted allowedTo={[
                                                                                    REACT_APP_ROLES_STORE
                                                                                ]}>
                                                                                    <span className="text-white fs-13 fw-bold badge bg-info cursor-pointer">
                                                                                        {product.asin}
                                                                                    </span>
                                                                                </Restricted>

                                                                                <span onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    navigator.clipboard.writeText(product.asin);
                                                                                    toastr.info("Info", product.asin + " is copied to clipboard", { timeOut: 1000 })
                                                                                }}>
                                                                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                                                </span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-4 col-md-4 col-sm-4">
                                                                            <p className="fs-13 fw-semibold mb-1">SKU:</p>
                                                                        </div>
                                                                        <div className="col-8 col-md-8 col-sm-8">
                                                                            <label>
                                                                                <span className="fs-13 text-muted fw-semibold mb-1">{product.sellerSKU}</span>
                                                                                <span onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    navigator.clipboard.writeText(product.sellerSKU);
                                                                                    toastr.info("Info", product.sellerSKU + " is copied to clipboard", { timeOut: 1000 })
                                                                                }}>
                                                                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-4 col-md-4 col-sm-4">
                                                                            <p className="fs-13 fw-semibold mb-1">Price:</p>
                                                                        </div>
                                                                        <div className="col-8 col-md-8 col-sm-8">
                                                                            <p className="fs-13 text-muted mb-1">{selectedStoreCurrencyType.currencyCode} {product.itemPriceAmount}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-4 col-md-4 col-sm-4">
                                                                            <p className="fs-13 fw-semibold mb-1">Order No:</p>
                                                                        </div>
                                                                        <div className="col-8 col-md-8 col-sm-8">
                                                                            <label>
                                                                                <span className="fs-13 text-muted fw-semibold mb-1">{orderDetailData?.orderNo}</span>
                                                                                <span onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    navigator.clipboard.writeText(orderDetailData?.orderNo);
                                                                                    toastr.info("Info", orderDetailData?.orderNo + " is copied to clipboard", { timeOut: 1000 })
                                                                                }}>
                                                                                    <i className="bx bxs-copy bx-xs bx-burst-hover fw-bold text-info cursor-pointer" > </i>
                                                                                </span>
                                                                            </label>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-4 col-md-4 col-sm-4 my-auto">
                                                                            <p className="fs-13 fw-semibold mb-1">Amazon Order No:</p>
                                                                        </div>
                                                                        <div className="col-auto">

                                                                            <Formik
                                                                                initialValues={{
                                                                                    orderId: product.orderId, buyerSellerOrderNumber: orderDetailData?.buyerSellerOrderNumber || ""
                                                                                }}
                                                                                validateOnBlur={true}
                                                                                // onSubmit={handleSubmit}
                                                                                enableReinitialize={true}
                                                                                validateOnMount={true}
                                                                            >
                                                                                {({
                                                                                    isValid,
                                                                                    values,
                                                                                    errors,
                                                                                    touched,
                                                                                    handleChange,
                                                                                    handleBlur,
                                                                                    setTouched,
                                                                                    setFieldValue
                                                                                }) => (
                                                                                    <>
                                                                                        <div className="row">
                                                                                            <div className="col pe-0">
                                                                                                <DrInput className="form-control form-control-sm" placeholder="" name="buyerSellerOrderNumber" type="text"
                                                                                                    value={values.buyerSellerOrderNumber || ""}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                />
                                                                                            </div>
                                                                                            <Restricted allowedTo={[
                                                                                                REACT_APP_ROLES_SUPERADMIN,
                                                                                                REACT_APP_ROLES_ADMIN,
                                                                                                REACT_APP_ROLES_SUSPENDED_ADMIN,
                                                                                                REACT_APP_ROLES_ORDERS
                                                                                            ]}>
                                                                                                <div className="col-auto ps-0">
                                                                                                    <button className="btn btn-primary btn-wave waves-effect waves-light shadow btn-sm"
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            dispatch(updateOrderItemAmazonOrderNumber(values));
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className="fe fe-save"></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </Restricted>
                                                                                        </div>
                                                                                    </>

                                                                                )
                                                                                }
                                                                            </Formik>

                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-2">
                                                                        <div className="col-4 col-md-4 col-sm-4">
                                                                            <p className="fs-13 fw-semibold mb-1">Order Status:</p>
                                                                        </div>
                                                                        <div className="col-8 col-md-8 col-sm-8">
                                                                            <span className="fs-13 text-muted fw-semibold mb-1">{orderDetailData?.orderStatus}</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 px-0">
                                                                    <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center">
                                                                        <div className="col-auto ps-0">
                                                                            {orderDetailData.isOrderable === false &&
                                                                                <span className="badge bg-success bg-opacity-75 p-2 fs-14 shadow" style={{ width: '180px' }}>
                                                                                    Satın Alındı
                                                                                </span>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center mt-2">
                                                                        <div className="col-auto ps-0">
                                                                            <span className="badge bg-warning bg-opacity-75 p-2 fs-14 shadow" style={{ width: '180px' }}>Kategori: Teknoloji</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-center mt-2">
                                                                        <div className="col-auto ps-0 position-relative">
                                                                            <div className="position-absolute bottom-0 end-0 d-flex align-middle m-auto no-select badge rounded-circle bg-danger bg-opacity-50 shadow transform-top-hover text-default text-center"
                                                                                style={{ width: '30px', height: '30px' }}>
                                                                                <p className="m-auto">{product.quantityOrdered}</p>
                                                                            </div>
                                                                            <div className="w-100 h-100 bg-gray-300">
                                                                                <img alt="" className="img-thumbnail h-max cursor-pointer" src={product.imageUrl || noImage} style={{ maxWidth: '180px' }}
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        if (product.imageUrl) {
                                                                                            dispatch(setActivePictureModal({ name: modals.picture, isGlobal: true, title: "picture", imgsource: [product.imageUrl] }))
                                                                                        }
                                                                                    }} />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto col-xxl-4 col-lg-4 col-xl-4 col-md-12 col-sm-12 d-flex flex-column">
                    <div className="row row-md row-lg justify-content-center  flex-grow-shrink">
                        <div className="card custom-card bg-info bg-opacity-10">
                            <div className="card-body p-3">

                                <div className="row">
                                    <div className="card custom-card px-0">
                                        <div className="card-body" style={{ minWidth: '260px' }}>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Gönderim Tarihi:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{moment.utc(orderDetailData?.orderHistory.earliestShipDate).format('LLLL')} to  {moment.utc(orderDetailData?.orderHistory.latestShipDate).format('LLLL')}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Teslim Edilme Tarihi:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{moment.utc(orderDetailData?.orderHistory.earliestDeliveryDate).format('LLLL')} to  {moment.utc(orderDetailData?.orderHistory.latestDeliveryDate).format('LLLL')}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Satın Alma Tarihi:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{moment.utc(orderDetailData?.orderHistory.purchaseDate).format('LLLL')}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Kargo Hizmeti:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.orderHistory.shipmentServiceLevelCategory}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Fulfillment:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">Seller</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Satış Mağazası:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.orderHistory.salesChannel}</p>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-grow-shrink">
                                    <div className="card custom-card px-0">
                                        <div className="card-body">

                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Alıcı adı:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.buyerName || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Telefon:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.phone || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Adres 1:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.addressLine1 || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Adres 2:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.addressLine2 || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Şehir:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.city || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Posta kodu:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.postalCode || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Eyalet / İlçe:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.stateOrRegion || ""}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 col-md-4 col-sm-4">
                                                    <p className="fs-13 fw-semibold mb-1">Ülke kodu:</p>
                                                </div>
                                                <div className="col-8 col-md-8 col-sm-8">
                                                    <p className="fs-13 text-muted mb-1">{orderDetailData?.shippingDetails.countryCode || ""}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <h4 className="mb-3 text-center">Shipment Status</h4>
                                    <DrTimeLine variant={timelineVariants.center}
                                        timelineSource={(orderTrackingStatuses?.length > 0
                                            && orderTrackingStatuses.map(x => ({
                                                groupName: x.groupName,
                                                title: x.buyerSellerOrderStatus,
                                                text: x.CarrierName || x.trackingNumber,
                                                time: x.updatedDate

                                            }))) || []}
                                    ></DrTimeLine>
                                    {/* <div className="vtimeline">
                                                {orderTrackingStatuses?.length > 0 &&
                                                    orderTrackingStatuses.map((trackingObject, index) => {
                                                        var colorName = (trackingObject.groupName === "0" ? "bg-info-transparent" : "")
                                                            || (trackingObject.groupName === "1" ? "bg-success-transparent" : "")
                                                            || (trackingObject.groupName === "2" ? "bg-success" : "")
                                                            || "bg-info-transparent";
                                                        return (
                                                            <div key={"trackingObject_" + index} className={`timeline-wrapper timeline-wrapper-dark ${index % 2 === 0 ? "timeline-inverted" : ""}`} >
                                                                <div className="timeline-badge"></div>
                                                                <div className={`timeline-panel ${colorName}`} >
                                                                    <div className="timeline-heading ">
                                                                        <h6 className="timeline-title">{trackingObject.buyerSellerOrderStatus}</h6>
                                                                    </div>

                                                                    <div className="timeline-footer d-flex align-items-center flex-wrap">
                                                                        <span className="ms-md-auto ms-2">{moment.utc(trackingObject.updatedDate).format('LLLL')}</span>
                                                                    </div>
                                                                    <div className="timeline-body">
                                                                        {trackingObject.CarrierName?.length > 0 &&
                                                                            <p>
                                                                                {trackingObject.carrierName}
                                                                            </p>
                                                                        }
                                                                        {trackingObject.trackingNumber?.length > 0 &&
                                                                            <p>
                                                                                {trackingObject.trackingNumber}
                                                                            </p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> */}
                                    {!orderTrackingStatuses?.length > 0 &&

                                        <h6 className="mb-3 text-center">NO TRACKING INFO</h6>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {notificationMessages && notificationMessages.length > 0 && <div className="row">
                <div className="col-12">
                    <div className="card custom-card">
                        <div className="card-body">

                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className="row" style={{ maxHeight: '500px', overflowY: "auto" }}>
                                        <div className="col">

                                            <DrTimeLine
                                                variant={timelineVariants.standard}
                                                timelineSource={(notificationMessages?.length > 0
                                                    && notificationMessages?.sort((a, b) => moment.utc(a.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf())?.map((message, index) => ({
                                                        groupName: message.isSendMe ? "0" : "1",
                                                        icon: <img alt="avatar" src={customerInfo?.profilePicture || avatar} style={{ maxWidth: "30px" }} />,
                                                        title: !message.isSendMe ? (customerInfo?.firstName?.length > 0 ? (consistantCapitalizeFirstLetter(customerInfo?.firstName.toLowerCase()) + " " + consistantCapitalizeFirstLetter(customerInfo?.lastName.toLowerCase())) : customerInfo?.email) : "Buyer",
                                                        text: <DrEmptyMailTemplate templateKey={index} htmlText={message.messageText}></DrEmptyMailTemplate>,
                                                        // text: parse( message.emailBody	),
                                                        time: message.sendDate
                                                    }))) || []}

                                            ></DrTimeLine>
                                        </div>
                                    </div>
                                    {/* <div className="row" style={{ maxHeight: '500px', overflowY: "auto" }}>
                                    <div className="col">
                                        <div className="main-chat-body" id="ChatBody">
                                            <div className="content-inner">
                                                {notificationMessages && notificationMessages.length > 0 && <label className="main-chat-time"><span> {moment.utc(getLatestUpdateDate(notificationMessages), "YYYY-MM-DDThh:mm:ss").fromNow()}</span></label>}

                                                {notificationMessages && notificationMessages.length > 0
                                                    && notificationMessages?.sort((a, b) => moment.utc(a.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf() - moment.utc(b.sendDate, "YYYY-MM-DDThh:mm:ss").valueOf())?.map((message, index) => {
                                                        var newSendDate = "";
                                                        // var dayDifference = moment.utc().diff(moment.utc(message.sendDate, "YYYY-MM-DDThh:mm:ss"), 'days')
                                                        // if(dayDifference === 0){
                                                        //     newSendDate = moment.utc(message.sendDate, "YYYY-MM-DDThh:mm:ss").format('LT');
                                                        // }
                                                        // else if (dayDifference === 1){
                                                        //     newSendDate = moment.utc(message.sendDate, "YYYY-MM-DDThh:mm:ss").format("LT");
                                                        // }
                                                        // var fromNow = moment.utc(message.sendDate, "YYYY-MM-DDThh:mm:ss").fromNow();
                                                        newSendDate = moment.utc(message.sendDate, "YYYY-MM-DDThh:mm:ss").calendar(null, {
                                                            // when the date is closer, specify custom values
                                                            lastWeek: '[Last Week]',
                                                            lastDay: '[Yesterday At] LT',
                                                            sameDay: '[Today At] LT',
                                                            nextDay: '[Tomorrow At]',
                                                            nextWeek: 'dddd',
                                                            // when the date is further away, use from-now functionality    
                                                            sameElse: "LLLL"
                                                            // sameElse: function () {
                                                            //     return "[" + fromNow + "]";
                                                            // }
                                                        })

                                                        return (
                                                            <div key={"media_row_" + index} className={`media ${message.isSendMe ? "" : "flex-row-reverse"}`}>
                                                                {message.isSendMe && <div className="main-img-user online border bg-gray-200"><img alt="avatar" src={avatar} /></div>}
                                                                <div className="media-body">
                                                                    <DrEmptyMailTemplate templateKey={index} htmlText={message.messageText}></DrEmptyMailTemplate>
                                                                    <div>
                                                                        <span>{newSendDate}</span>
                                                                    </div>
                                                                </div>
                                                                {!message.isSendMe && <div className="main-img-user online border bg-gray-200"><img alt="avatar" src={customerInfo?.profilePicture || avatar} /></div>}
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="row justify-content-end">
                                        <div className="col-auto">
                                            {messagingActionsForOrder && messagingActionsForOrder?.find(item2 => notificationMessagesTypes.negativeFeedbackRemoval === item2) &&
                                                <button className="btn btn-warning-ghost btn-wave waves-effect waves-light shadow"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(withActionPromise(getCreateNegativeFeedbackRemoval({ orderNo: selectedOrderDetail?.orderNumber, customerMainSellerId: selectedStore?.customerMainSellerId })));
                                                    }}>
                                                    <i className="fe fe-x-circle me-1"></i>
                                                    Remove Negative Feedbacks
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <div className="row">
                <div className="col-12">
                    <Formik
                        initialValues={initialNotificationValues}
                        validateOnBlur={true}
                        onSubmit={handleSubmit}
                        // validationSchema={storePreferencesSchema}
                        enableReinitialize={true}
                        validateOnMount={true}
                    >
                        {({
                            isValid,
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setTouched,
                            setFieldValue,

                        }) => (
                            // <form className="was-validated">
                            <form >
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row justify-content-start mb-3">
                                            <div className="col-auto">
                                                <Field name={'selectedMessageTypeOption'}
                                                    onBlur={() => { setTouched({ ...touched.selectedMessageTypeOption, 'selectedMessageTypeOption': true }) }}
                                                    component={DrSelectBox}
                                                    placeholder="Select Message Type"
                                                    // disabled={!values.enableAutoDeleteOutCriteria}
                                                    options={values.allowedNotificationMessageTypeOptions}
                                                    selectedValue={values?.selectedMessageTypeOption}
                                                    onChange={(e) => {
                                                        setFieldValue("selectedMessageTypeOption", e.value);
                                                        setFieldValue("isTextAreaDisabled", (e.value === notificationMessagesTypes.amazonMotors
                                                            || e.value === notificationMessagesTypes.legalDisclose
                                                            || e.value === notificationMessagesTypes.warranty
                                                            || e.value === notificationMessagesTypes.sendInvoice));

                                                        setFieldValue("isAttachmentDisabled", (e.value === notificationMessagesTypes.confirmServiceDetails
                                                            || e.value === notificationMessagesTypes.confirmOrderDetails
                                                            || e.value === notificationMessagesTypes.unexpectedProblem));

                                                        setFieldValue("fileContent", []);
                                                        setFieldValue("messageText", "");
                                                        setFieldValue("selectedMessageTemplateOption", "-1");
                                                    }} />
                                                {(touched.selectedMessageTypeOption && errors.selectedMessageTypeOption) && <label type="invalid" className="form-control is-invalid">
                                                    {errors.selectedMessageTypeOption}
                                                </label>}
                                            </div>
                                            <div className="col-auto">
                                                <Field name={'selectedMessageTemplateOption'}
                                                    onBlur={() => { setTouched({ ...touched.selectedMessageTemplateOption, 'selectedMessageTemplateOption': true }) }}
                                                    component={DrSelectBox}
                                                    placeholder="Select Template"
                                                    disabled={values?.isTextAreaDisabled}
                                                    options={defaultMailTemplatesBodyTextOptions}
                                                    onChange={(e) => {

                                                        if (e.value && e.value !== "-1") {
                                                            var templateData = {
                                                                customerName: orderDetailData?.shippingDetails?.buyerName || "NoName",
                                                                storeName: selectedStore?.mainSellerName,
                                                                orderNo: orderDetailData?.orderNo,
                                                                carrierName: orderDetailData?.shippingDetails?.carrierName || "---",
                                                                trackingNo: orderDetailData?.shippingDetails?.trackingNo || "---"
                                                            }
                                                            setFieldValue("messageText", i18next.t('drMailTemplates:' + e.value + '.text', templateData))
                                                        }
                                                        else {
                                                            setFieldValue("messageText", "");
                                                        }

                                                    }}
                                                />
                                                {(touched.selectedMessageTemplateOption && errors.selectedMessageTemplateOption) && <label type="invalid" className="form-control is-invalid">
                                                    {errors.selectedMessageTemplateOption}
                                                </label>}
                                            </div>
                                            {values?.selectedMessageTypeOption === notificationMessagesTypes.warranty && <div className="col-auto">
                                                <DrDualDatePicker
                                                    selectedDates={values?.coverageDateInterval}
                                                    onCloseCallBack={(dateValues) => {
                                                        var dates = [(dateValues[0] ? moment.utc(dateValues[0].toString(), "YYYY/MM/DD").startOf('day').format() : null), (dateValues[1] ? moment.utc(dateValues[1].toString(), "YYYY/MM/DD").startOf('day').format() : null)];
                                                        setFieldValue("coverageDateInterval", dates);
                                                    }}>
                                                </DrDualDatePicker>
                                            </div>}
                                        </div>
                                        <div className="row w-100">
                                            <div className="col">
                                                <textarea id="message-textarea" className={`form-control w-100`} placeholder="Type your message here..." type="html"
                                                    style={{ minHeight: '200px', height: "auto" }}
                                                    value={values?.messageText}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        var textarea = document.getElementById("message-textarea");
                                                        textarea.style.height = "";
                                                        textarea.style.height = Math.min(textarea.scrollHeight, 500) + "px";

                                                        setFieldValue("messageText", e.currentTarget.value)
                                                    }}
                                                    onBlur={() => { setTouched({ ...touched.messageText, 'messageText': true }) }}
                                                    disabled={values?.isTextAreaDisabled}
                                                    required=""
                                                />
                                                <div className="invalid-feedback">Example invalid form file feedback</div>
                                            </div>
                                        </div>
                                        <div className="row w-100 justify-content-end">
                                            <div className="col-auto">
                                                <div className="row">

                                                    {/* <div className="col-auto px-0">
                                                        <nav className="nav">
                                                            <button type="file" accept="image/png, image/gif, image/jpeg" className="btn nav-link" data-bs-toggle="tooltip" title="Add Photo"
                                                                onChange={onFileUploaded} >
                                                                <i className="fe fe-image" />
                                                            </button>
                                                            <button className="btn nav-link" data-bs-toggle="tooltip" title="Attach a File"><i className="fe fe-paperclip" /></button>
                                                        </nav>
                                                    </div> */}
                                                    {!values?.isAttachmentDisabled &&
                                                        <div className="col-auto">
                                                            <Field onBlur={() => { setTouched({ ...touched.fileContent, 'fileContent': true }) }}
                                                                name={'fileContent'}
                                                                component={DrFileUploadInput}
                                                                placeholder="select files"
                                                                accept="application/pdf, image/png, image/jpeg, image/gif,image/bmp, image/webp,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/rdf+xml"
                                                                maxFiles={5}
                                                                maxFileSizeInBytes={10000000}
                                                                multiple
                                                            />
                                                        </div>
                                                    }
                                                    <div className="col-auto px-0">
                                                        <button className="btn btn-info btn-icon btn-wave waves-effect waves-light shadow main-msg-send" data-bs-toggle="tooltip" title="Send Message"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (isValid) {
                                                                    handleSubmit(values);
                                                                }

                                                                // var textarea = document.querySelector("#message-textarea");
                                                                // if (textarea) {

                                                                //     let body = <DrEmptyMailTemplate templateKey={"notification"} htmlText={textarea.value} ></DrEmptyMailTemplate>;

                                                                //     var emailData = {
                                                                //         emailFrom: "sellerzeynepyucel@gmail.com",
                                                                //         emailTo: "koray65d3@gmail.com",
                                                                //         emailSubject: "Notification",
                                                                //         emailBody: EmailRender(body),
                                                                //         emailDate: moment.utc()
                                                                //     }
                                                                //     dispatch(withActionPromise(sendNotification(emailData)));
                                                                // }
                                                                // if (selectedMessageType === notificationMessagesTypes.sendInvoice) {
                                                                // var requestData = {
                                                                //     customerMainSellerId: selectedStore?.customerMainSellerId,
                                                                //     amazonOrderNumber: selectedOrderDetail?.orderNumber,
                                                                //     fileContent: []
                                                                // }
                                                                // dispatch(sendInvoiceMessage(requestData));
                                                                // }


                                                            }}><i className="fe fe-send" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        )
                        }
                    </Formik>
                </div>

            </div>
        </>
    )
}
export default MessageDetailPage