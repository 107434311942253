
import * as Yup from 'yup';
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { forgotPassword, loginWithEmail, loginWithEmailExtension } from '../../state-management/actions/customer.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { DrForgotPasswordTemplate, DrInput, DrPasswordInput } from '../../components/component-index';
import { useEffect, useState } from 'react';
import { getRouteByKey } from '../../routes';
import { render as EmailRender } from '@react-email/render';
import { faFish } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie'

function LoginPage() {

    const { REACT_APP_HOST_URL } = process.env;
    const navigate = useNavigate();
    const customerStores = useSelector(state => state.stores.customerStores);
    const dispatch = useDispatch();
    const customer = useSelector(state => state.customer);
    const isLoggedin = useSelector(state => state.customer.isLoggedin);
    const customerMainSellerId = useSelector(state => state.stores.selectedStore?.customerMainSellerId);
    // const agreement = useSelector(state => state.agreement);
    const { t } = useTranslation('registerPage');
    const [pageChoice, setPageChoice] = useState("1");
    const { type } = useParams();

    useEffect(() => {
        if (isLoggedin) {
            Cookies.set('AuthToken',
                customer.token,
                {
                    expires: 1,
                    domain: REACT_APP_HOST_URL,
                    path: "/",
                    secure: true,
                    sameSite: "none"
                });
            Cookies.set('RefreshToken',
                customer.refreshToken,
                {
                    expires: 1,
                    domain: REACT_APP_HOST_URL,
                    path: "/",
                    secure: true,
                    sameSite: "none"
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     console.log("cid2", selectedStore?.customerMainSellerId)
    //     if (selectedStore?.customerMainSellerId) {
    //         console.log("cid3", selectedStore?.customerMainSellerId)
    //         Cookies.set('cid',
    //             selectedStore?.customerMainSellerId,
    //             {
    //                 expires: 1,
    //                 domain: REACT_APP_HOST_URL,
    //                 path: "/"
    //             });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [customerStores]);

    // useEffect(() => {
    //     if (selectedStore?.customerMainSellerId) {
    //         console.log("cookie posted")
    //         console.log("customer.isLoggedin", customer.isLoggedin)
    //         console.log("selectedStore?.customerMainSellerId", selectedStore?.customerMainSellerId)
    //         // window.close();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectedStore, customerStores]);


    //     useEffect(() => {
    //         if (selectedStore?.customerMainSellerId) {
    // console.log("cid1",selectedStore?.customerMainSellerId)
    //             Cookies.set('cid',
    //                 selectedStore?.customerMainSellerId,
    //                 {
    //                     expires: 1,
    //                     domain: REACT_APP_HOST_URL,
    //                     path: "/"
    //                 });
    //         }
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //     }, [selectedStore, customerStores]);

    useEffect(() => {
        if (type) {
            setPageChoice(type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    const initialValuesLogin = {

        email: "",
        password: '',
    };

    const initialValuesForgotPassword = {
        email: "",
    };

    const handleSubmit = async (values) => {
        dispatch(withActionPromise(loginWithEmail(values)));
    }

    const handleForgotPassword = async (value) => {

        let body = <DrForgotPasswordTemplate siteUrl={window.location.origin} ></DrForgotPasswordTemplate>;
        var request = {
            emailTo: value,
            emailToName: "",
            emailSubject: "Password Change",
            emailBody: EmailRender(body)
        }

        dispatch(forgotPassword(request));
    }

    const loginAccountSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Password is required')
            .matches(/^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i, 'Your password should be a minimum of 8 characters. Should have at least one letter and one number.'),
    });

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required')
    });


    return (
        <>
            <div className="container" style={{ paddingTop: "150px" }}>
                <div className="row justify-content-center mt-5 h-100">
                    <div className="col-md-6 col-lg-6 col-sm-10 col-xl-5 col-10 h-100" >
                        <div className="card custom-card" >
                            <div className="card-body">
                                {pageChoice === "1" &&
                                    <Formik
                                        initialValues={initialValuesLogin}
                                        validateOnBlur={true}
                                        // onSubmit={handleLoginSubmit}
                                        validationSchema={loginAccountSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            handleBlur,
                                            setFieldValue
                                        }) => (
                                            <form name="login-form" className="needs-validation" style={{ height: "470px" }}>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 align-middle">
                                                        <h1 className="text-center">{t('titleLogin')}</h1>
                                                        <h5 className="text-center">{t('infoLogin')}</h5>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group has-validation">
                                                                    <label>{t('email')}</label>
                                                                    <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={(touched.email && errors.email)}
                                                                        isValid={touched.email && !errors.email}
                                                                        validationText={errors.email}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group has-validation">
                                                                    <label>{t('password')}</label>
                                                                    <DrPasswordInput className={`form-control ${(touched.password && errors.password) ? " is-invalid" : ""}`} name="password" type="password" placeholder={(touched.password && errors.password) ? errors.password : t('password')} value={values.password} showRevealButton={true} onChange={handleChange}
                                                                        onBlur={handleBlur} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-auto">
                                                                <FontAwesomeIcon icon={faFish} beat />
                                                                <span className="text-primary ms-2 cursor-pointer"
                                                                    onClick={() => {
                                                                        setPageChoice("3");
                                                                        // dispatch(setActiveModal({ name: modals.forgotpassword, isGlobal: true, title: i18next.t('drForgotPassword:title') }))
                                                                    }
                                                                    }
                                                                >{t('forgotPassword')}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-10">
                                                        <div className="row justify-content-center mt-5">
                                                            <div className="col-10 col-lg-5 col-xl-5">
                                                                <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        Promise.all([
                                                                            dispatch(withActionPromise(loginWithEmail(values)))
                                                                        ]).then((response) => {

                                                                            // navigate(getRouteByKey("HOME"));
                                                                        });
                                                                    }}
                                                                >
                                                                    {t('login')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                        }
                                    </Formik>
                                }
                                {pageChoice === "3" &&
                                    <Formik
                                        initialValues={initialValuesForgotPassword}
                                        validateOnBlur={true}
                                        // onSubmit={handleLoginSubmit}
                                        validationSchema={forgotPasswordSchema}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                    >
                                        {({
                                            isValid,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            setTouched,
                                            handleBlur,
                                            setFieldValue
                                        }) => (
                                            <form name="login-form" className="needs-validation" style={{ height: "470px" }}>
                                                <div className="row justify-content-center">
                                                    <div className="col-10 align-middle">
                                                        <h1 className="text-center">{t('titleForgotPassword')}</h1>
                                                        <h5 className="text-center">{t('infoForgotPassword')}</h5>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-group has-validation">
                                                                    <label>{t('email')}</label>
                                                                    <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        isInvalid={(touched.email && errors.email)}
                                                                        isValid={touched.email && !errors.email}
                                                                        validationText={errors.email}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-center mt-5">
                                                            <div className="col-10 col-lg-5 col-xl-5">
                                                                <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleForgotPassword(values.email);
                                                                    }}
                                                                >
                                                                    {t('sendMail')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center mt-4">
                                                            <p className="mb-0 text-center">{t('doYouRememberPassword')}
                                                                <span className="text-primary ms-2 cursor-pointer"
                                                                    onClick={() => {
                                                                        // navigate(getRouteByKey("MAIN_PACKAGES"));
                                                                        setPageChoice("1");
                                                                    }} >
                                                                    {t('signIn')}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        )
                                        }
                                    </Formik>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // <Formik
        //     validateOnChange={true}
        //     validateOnBlur={true}
        //     enableReinitialize={true}
        //     validateOnMount={true}
        //     initialValues={{
        //         email: '',
        //         password: '',
        //         isExtension: true
        //     }}
        //     validationSchema={loginAccountSchema}
        //     onSubmit={handleSubmit}>
        //     {({
        //         errors,
        //         values,
        //         touched,
        //         dirty,
        //         handleChange,
        //         handleBlur,
        //         handleSubmit,
        //         isValid
        //     }) => (
        //         <>
        //             <div className="row justify-content-center">
        //                 <div className="col-12 col-sm-9 col-md-7 col-lg-7 col-xl-6">
        //                     <div className="row bg-primary-dark" style={{ minHeight: '48px', padding: '20px 25px' }}>
        //                         <div className="col">
        //                             <h5 className="modal-title text-center">SELLERDOPING LOGIN</h5>
        //                         </div>
        //                     </div>
        //                     <div className="row">
        //                         <div className="col border bg-gray-200">
        //                             <form className="needs-validation py-5 m-5">
        //                                 <div className="form-group has-validation">
        //                                     <label>{t('email')}</label>
        //                                     <DrInput value={values.email} className={`form-control`} placeholder={t('email')} name="email" type="text"
        //                                         onChange={handleChange}
        //                                         onBlur={handleBlur}
        //                                         isInvalid={(touched.email && errors.email)}
        //                                         isValid={touched.email && !errors.email}
        //                                         validationText={errors.email} />
        //                                 </div>
        //                                 <div className="form-group has-validation">
        //                                     <label>{t('password')}</label>
        //                                     <DrPasswordInput className={`form-control`} name="password" type="password" placeholder={t('password')} value={values.password} showRevealButton={true} onChange={handleChange}
        //                                         onBlur={handleBlur}
        //                                         isInvalid={(touched.password && errors.password)}
        //                                         isValid={touched.password && !errors.password}
        //                                         validationText={errors.password} />
        //                                 </div>
        //                                 <div className="form-group">
        //                                     <button className="btn btn-success btn-wave waves-effect waves-light shadow btn-block mt-4"
        //                                         onClick={handleSubmit}
        //                                     >{t('login')}</button>
        //                                 </div>
        //                             </form>
        //                         </div>

        //                     </div>
        //                 </div>

        //             </div>

        //         </>

        //     )
        //     }
        // </Formik>
    )
}

export default LoginPage