import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrPageFilter, DrPagination, DrReadMore } from '../../components/component-index';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { getAmazonFeedback, getFeedbackCampaignSendEmails, getRatingPercentage } from '../../state-management/actions/feedback.actions';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { setNegativeFeedbacksPageSelections } from '../../state-management/actions/settings.actions';
import { withActionPromise } from '../../state-management/actions/app.actions';

function FeedbackCampaignSentMailListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('feedbackCampaignSentMailListPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const feedbackCampaignSendEmails = useSelector(state => state.feedback.feedbackCampaignSendEmails);
    const pageSelections = useSelector(state => state.settings?.pageSelections?.feedbackCampaignSentMailListPageSelections);

    useEffect(() => {
        dispatch(getAmazonFeedback({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: 0, pageSize: 20 }));
        dispatch(getRatingPercentage(selectedStore?.customerMainSellerId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(getFeedbackCampaignSendEmails({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: pageSelections?.tableSkip, pageSize: pageSelections?.pagelength, orderByParam: "createdDate desc" }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelections?.tableSkip, pageSelections?.pagelength]);

    const columns = [
        { data: "id", title: "", minWidth: "45px", maxWidth: "45px", searchable: false, checkall: true, checkData: "Id" },
        { data: "amazonOrderNumber", title: t('dataTable.amazonOrderNumber'), minWidth: "180px", maxWidth: "180px", searchable: true },
        { data: "subject", title: t('dataTable.subject'), minWidth: "200px", searchable: true },
        { data: "recipient", title: t('dataTable.recipient'), minWidth: "230px", maxWidth: "230px", searchable: true },
        { data: "isSend", title: t('dataTable.isSend'), minWidth: "110px", maxWidth: "110px", searchable: false },
        { data: "isRead", title: t('dataTable.isRead'), minWidth: "110px", maxWidth: "110px", searchable: false },
        { data: "createdDate", title: t('dataTable.createdDate'), minWidth: "130px", maxWidth: "130px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                if (rowData.isBlocked) {
                    td.closest("tr").classList.toggle("greyed-out");
                }

                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox" name="name" value={rowData.id}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                            onClick={(e) => {
                                e.preventDefault();
                                navigator.clipboard.writeText(rowData.amazonOrderNumber);
                                toastr.info("Info", rowData.amazonOrderNumber + " is copied to clipboard", { timeOut: 1000 })
                            }}
                        >
                            {rowData.amazonOrderNumber}
                        </button>
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <DrReadMore max={100} text={rowData.subject || ""} classes='fs-12'
                            data-bs-placement="right" data-bs-toggle="tooltip"
                            title={rowData.subject}
                            onTextClicked={(e) => {
                                e.preventDefault();
                                // navigate('/product-detail/' + rowData.asin);
                            }}
                        ></DrReadMore>
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.recipient}</span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.isSend && <span className="badge badge-sm bg-success">Sent</span>}
                        {!rowData.isSend && <span className="badge badge-sm bg-danger">Not Sent</span>}
                    </>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        {rowData.isRead && <span className="badge badge-sm bg-success">Read</span>}
                        {!rowData.isRead && <span className="badge badge-sm bg-danger">Not Read</span>}
                    </>);
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{moment.utc(rowData.createdDate).fromNow()} </span>
                    </>);
            }
        }

    ];

    const refreshClickedCallBack = () => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getFeedbackCampaignSendEmails({ customerMainSellerId: selectedStore?.customerMainSellerId, skip: 0, pageSize: 20, orderByParam: "createdDate desc" }));
        }
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.feedback'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.feedbackCampaignSentMailList'), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row mb-5">
                <div className="col-12">

                    <div className="card custom-card">
                        <div className="card-body">
                            <DrDataTable
                                data={feedbackCampaignSendEmails.data}
                                columns={columns}
                                columnDefs={columnDefs}
                                doesHaveBorder={false}
                                headerSearch={true}
                                // headerSelectbox={true}
                                minWidth="850px"
                                search={true}
                                order={pageSelections?.sortingInfo?.length > 0 ? [[parseInt(pageSelections?.sortingInfo[0]), pageSelections?.sortingInfo[2]]] : [[6, 'desc']]}
                                isMultiSelectEnabled={true}
                                detailedSearchChildren={
                                    <DrPageFilter
                                        refreshClickedCallBack={refreshClickedCallBack}
                                    >
                                    </DrPageFilter>
                                }
                                pageLength={pageSelections?.pagelength || 5}

                                paginationPlugin={
                                    <DrPagination
                                        paginationData={
                                            {
                                                tableSkip: (pageSelections?.tableSkip || 0),
                                                totalRecordCount: feedbackCampaignSendEmails?.totalRecordCount,// products?.totalRecordCount || pageSelections.tableLength,
                                                tablePageLength: pageSelections?.pagelength || 5
                                            }

                                        }
                                        tablePageLengthChanged={(e) => {
                                            dispatch(withActionPromise(setNegativeFeedbacksPageSelections({ tableSkip: 0, tableLength: (feedbackCampaignSendEmails?.totalRecordCount || pageSelections?.tableLength), pagelength: e, sortingInfo: pageSelections?.sortingInfo })));
                                        }}
                                        tablePageNumberChanged={(e) => {
                                            dispatch(withActionPromise(setNegativeFeedbacksPageSelections({ tableSkip: e, tableLength: (feedbackCampaignSendEmails?.totalRecordCount || pageSelections?.tableLength), pagelength: pageSelections?.pagelength, sortingInfo: pageSelections?.sortingInfo })));
                                        }}
                                    >
                                    </DrPagination>

                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbackCampaignSentMailListPage;