import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client';
import { DrBreadCrump, DrDataTable, DrInventoryFilter, DrPageFilter, DrPagination, DrSweetAlertButton, } from '../../components/component-index';
import { withActionPromise } from '../../state-management/actions/app.actions';
import { buttonTypes, pageFilterTypes, sweetAlertVariants } from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getRouteByKey } from '../../routes';

import i18next from 'i18next';
import { deleteFeedbackCampaign, getFeedbackCampaign, upsertFeedbackActiveAndDeActive } from '../../state-management/actions/feedback.actions';
import { ExportToExcel } from '../../excelexport';
import { generatePdfReport } from '../../utils/utils';
import { feedbackSendTimeDelayDaysOptions } from '../../components/hoc/Constants/dr-constants.component';
import moment from 'moment';

function CampaignListPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation('campaignListPage');
    const selectedStore = useSelector(state => state.stores.selectedStore);
    const allCampaigns = useSelector(state => state.feedback.allCampaigns);

    useEffect(() => {
        dispatch(getFeedbackCampaign({ customerMainSellerId: selectedStore?.customerMainSellerId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*

{
    "data": [
        {
            "id": "8eef80eb-0347-4af8-892f-4b104f0e2580",
            "customerMainSellerId": "aaf59d3a-037f-4552-aeb1-144c5143b796",
            "subject": "Your Order Information From Amazon: {{AmazonOrderId}}",
            "emailBody": "<p>Hello,koray<br></br>Thank you for your recent purchase: #{{AmazonOrderId}} Our record indicates that your order has been delivered.<br></br>How do you like it?<br></br>• {{ASIN}}: {{FLinkWithProduct}}<br></br>We strive to provide great customer service and want to make sure you are completely satisfied. If there are any issues, please contact us. Our customer support team is ready to help!<br></br>We would love for you to share your user experience with others! Please take a minute to leave us a feedback:<br></br>• Leave Seller Feedback: {{FeedbackLink}}<br></br>We truly appreciate your business. Thank you!<br></br>Sincerely,<br></br>{{StoreName}}<br></br>Customer Service</p>",
            "negativeFeedbackBuyersIncluded": false,
            "negativeFeedbackOrdersIncluded": false,
            "canceledOrReturnedOrdersIncluded": true,
            "notDeliveredOnTimeORdersIncluded": false,
            "feedbackSendTimeDelayDays": 0,
            "feedbackSendTime": "09:00:00",
            "isActive": true,
            "createdBy": "18618064-5b7c-41a1-8b6d-067df5e3554e",
            "createdDate": "2024-12-12T12:58:11.641303",
            "updatedBy": "18618064-5b7c-41a1-8b6d-067df5e3554e",
            "updatedDate": "2024-12-12T12:58:11.641303",
            "isDeleted": false
        }
    ]
}
    */
    const columns = [
        { data: "id", title: "", minWidth: "45px", maxWidth: "45px", searchable: false, checkall: true, checkData: "id" },
        { data: "subject", title: t('dataTable.subject'), minWidth: "150px", searchable: true },
        { data: "feedbackSendTimeDelayDays", title: t('dataTable.feedbackSendTimeDelayDays'), minWidth: "230px", maxWidth: "230px", searchable: true },
        { data: "feedbackSendTime", title: t('dataTable.feedbackSendTime'), minWidth: "95px", maxWidth: "95px", searchable: true },
        { data: "isActive", title: t('dataTable.isActive'), minWidth: "125px", maxWidth: "125px", searchable: false },
        { data: "createdDate", title: t('dataTable.createdDate'), minWidth: "130px", maxWidth: "130px", searchable: false },
        { data: null, title: "", minWidth: "70px", maxWidth: "70px", searchable: false },
    ];

    const columnDefs = [
        {
            targets: 0,
            orderable: false,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                if (rowData.isBlocked) {
                    td.closest("tr").classList.toggle("greyed-out");
                }

                ReactDOM.createRoot(td).render(
                    <>
                        <input type="checkbox" className="ckbox" name="name" value={rowData.id}
                            onChange={(e) => {
                                e.target.closest("tr").classList.toggle("selected"); // highlight selected checkbox's row
                            }}
                        />
                    </>
                )
            }
        },
        {
            targets: 1,
            orderable: true,
            className: "text-start",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <button className="btn btn-link btn-sm btn-wave waves-effect waves-light"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open(window.location.origin + "/#/create-campaign/" + rowData.id, '_blank');
                            }}
                        >
                            {rowData.subject}
                        </button>
                    </>);
            }
        },
        {
            targets: 2,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{feedbackSendTimeDelayDaysOptions?.find(x => x.value === rowData.feedbackSendTimeDelayDays)?.label}</span><br />
                    </>);
            }
        },
        {
            targets: 3,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{rowData.feedbackSendTime}</span>
                    </>);
            }
        },
        {
            targets: 4,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <DrSweetAlertButton
                            className={`btn btn-wave waves-effect waves-light shadow btn-block btn-sm ${rowData.isActive ? "btn-success " : "btn-warning"}`}
                            buttonText={rowData.isActive ? t('dataTable.active') : t('dataTable.notActive')}
                            buttonIcon={<i className={`${rowData.isActive ? "fe fe-thumbs-up me-2" : "fe fe-thumbs-down me-2"} "`}></i>}
                            buttonType={buttonTypes.button}
                            title={t('dataTable.activeDeactiveTitle')}
                            text=" "
                            // buttonVariant={buttonVariants.light}
                            onCloseConfirmCallBack={(value) => {
                                if (value) {
                                    dispatch(upsertFeedbackActiveAndDeActive(
                                        {
                                            requestActivateFeedback: {
                                                feedbackIdList: [
                                                    rowData.id
                                                ],
                                                isActive: !rowData.isActive
                                            },
                                            customerMainSellerId: selectedStore?.customerMainSellerId

                                        }
                                    ));
                                }
                            }}
                            sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                        >
                        </DrSweetAlertButton>
                    </>);
            }
        },
        {
            targets: 5,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <span className="mb-0 mt-1 fs-12 px-1 py-1">{moment.utc(rowData.createdDate).fromNow()} </span>
                    </>);
            }
        },
        {
            targets: 6,
            orderable: true,
            className: "text-center",
            createdCell: (td, cellData, rowData) => {
                ReactDOM.createRoot(td).render(
                    <>
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <DrSweetAlertButton
                                    className={`btn btn-icon btn-danger btn-wave waves-effect waves-light shadow btn-sm`}
                                    // buttonText={t("dataTable.deleteCampaign")}
                                    buttonIcon={<i className="fe fe-trash-2"></i>}
                                    buttonType={buttonTypes.icon}
                                    // buttonVariant={buttonVariants.light}
                                    onCloseConfirmCallBack={(value) => {
                                        if (value) {
                                            dispatch(deleteFeedbackCampaign({ id: rowData.id, customerMainSellerId: selectedStore?.customerMainSellerId }));
                                        }
                                    }}
                                    sweetAlertVariant={sweetAlertVariants.areYouSureConfirm}
                                >
                                </DrSweetAlertButton>
                            </div>
                        </div>
                    </>);
            }
        }
    ];

    const selectedRowsOperationsCallBack = (checkedValues, selectedOperation, filterType) => {

        if (selectedOperation === "0" || (selectedOperation === "1" && allCampaigns && allCampaigns?.length > 0)) //export
        {
            let data = allCampaigns?.filter(item1 =>
                !!checkedValues.find(item2 => item1.id === item2));

            if (selectedOperation === "0" && data?.length > 0) {
                ExportToExcel({ excelData: data, fileName: "campaigns_list" });
            }
            else if (selectedOperation === "1" && data?.length > 0) {
                const tableColumn = ["SUBJECT"];
                // define an empty array of rows
                const tableRows = [];

                // for each ticket pass all its data into an array
                data.forEach(asindata => {
                    const campaignsData = [
                        asindata.subject
                    ];
                    // push each tickcet's info into a row
                    tableRows.push(campaignsData);
                });
                generatePdfReport({ columns: tableColumn, dataSource: tableRows, title: "Campaigns", orientation: 'l', reportName: "campaigns_list" });

            }
        }


    }


    const refreshClickedCallBack = () => {
        if (selectedStore?.customerMainSellerId) {
            dispatch(getFeedbackCampaign({ customerMainSellerId: selectedStore?.customerMainSellerId }));
        }
    };

    return (
        <>
            <DrBreadCrump
                breadCrumps={[{ navigationText: i18next.t('constants:breadCrumps.feedback'), navigationPath: "" },
                { navigationText: i18next.t('constants:breadCrumps.campaignList'), navigationPath: "" }]} >
            </DrBreadCrump>

            <div className="row justify-content-end mb-2">
                {selectedStore &&
                    <>
                        <div className="col-auto my-1">
                            <button className="btn btn-teal btn-wave waves-effect waves-light shadow"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(getRouteByKey("FEEDBACK_CREATE_CAMPAIGN"));
                                }}>
                                <i className="me-2 fa fa-forward"></i>
                                {t('gotoNewCampaign')}
                            </button>
                        </div>
                    </>
                }
            </div>

            <div className="row mb-5">
                <div className="col-12">

                    <div className="card custom-card">
                        <div className="card-body">
                            <DrDataTable
                                data={allCampaigns}
                                columns={columns}
                                columnDefs={columnDefs}
                                doesHaveBorder={false}
                                headerSearch={true}
                                // headerSelectbox={true}
                                minWidth="850px"
                                search={true}
                                order={[[1, 'asc']]}
                                isMultiSelectEnabled={true}
                                detailedSearchChildren={
                                    <DrPageFilter
                                        refreshClickedCallBack={refreshClickedCallBack}
                                        filterType={pageFilterTypes.campaigns}
                                        selectedRowsOperationsCallBack={selectedRowsOperationsCallBack}
                                    >
                                    </DrPageFilter>
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CampaignListPage;